import React, {useEffect} from 'react'
import {
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Table,
  Button,
  Form,
  notification,
  Modal,
} from 'antd'
import {UserAddOutlined, UsergroupAddOutlined} from '@ant-design/icons'

import {API} from '@aws-amplify/api'
import {
  listBranches,
  getBranch,
  getBranchWtType,
  listUsers,
  getUser,
} from '../../../backend/graphql/queries'
import {
  updateBranch,
  createBranch,
  createBranchWtType,
  createStaff,
  deleteStaff,
  updateStaff,
  adminCreateStaff,
  adminUpdateStaff,
  adminDeleteStaff,
} from '../../../backend/graphql/mutations'
import {Formik} from 'formik'
import {getSignedInUserDetails} from '../../../utils/checkValidity'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card
const {TextArea} = Input

export const Branch = ({_companyId, setBranchID, refetchBranchesParent}) => {
  const [branches, setBranches] = React.useState([])

  const [selectedBranch, setSelectedBranch] = React.useState('')
  const [selectedBranchStaffNumber, setSelectedBranchStaffNumber] =
    React.useState('')
  const [modalAttribute, setModalAttribute] = React.useState({
    isVisible: false,
    title: '',
  })
  const [modalAttribute2, setModalAttribute2] = React.useState({
    isVisible: false,
    title: '',
  })

  const branchFields = {
    branchId: '',
    branchName: '',
    branchCode: '',

    contactNo: '',
    contactInfo: '',

    address: '',
    remarks: '',

    isMainBranch: '',
    status: '',

    companyId: '',
  }

  const [fields, setFields] = React.useState(branchFields)

  const [chooseBranch, setChooseBranch] = React.useState('')

  const AddBranchModal = ({companyId, refetchBranches}) => {
    const [addBranchFields, setAddBranchFields] = React.useState({
      branchName: '',
      branchCode: '',
      status: '',
      mobileNo: '',
      telephoneNo: '',
      address: '',
      remarks: '',
    })

    return (
      <Modal
        title={`Add ${modalAttribute.title}`}
        visible={modalAttribute.isVisible}
        width={950}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={async () => {
              try {
                await API.graphql({
                  query: createBranchWtType,
                  variables: {
                    input: {
                      companyID: _companyId,
                      branchName: addBranchFields.branchName,
                      code: addBranchFields.branchCode,
                      status: 'ACTIVE',
                      phone: addBranchFields.telephoneNo,
                      address: addBranchFields.address,
                      isMainBranch: false,
                      type: 'SUPPLIER',
                      deleted: false,
                      remarks: addBranchFields.remarks,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                notification['success']({
                  message: 'Success',
                  description: 'Branch added!',
                })
                refetchBranches()
                refetchBranchesParent()
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: JSON.stringify(error),
                })
              } finally {
                setModalAttribute({
                  ...modalAttribute,
                  isVisible: false,
                  title: '',
                })
              }
            }}
            size="large"
          >
            Submit
          </Button>,
        ]}
        onOk={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
      >
        <RegistrationForm
          title={modalAttribute.title}
          {...{addBranchFields}}
          {...{setAddBranchFields}}
          companyId={fields.companyId}
        />
      </Modal>
    )
  }

  const RemoveBranchModal = ({companyId}) => {
    return (
      <Modal
        title=""
        visible={modalAttribute2.isVisible}
        width={855}
        footer={null}
        /*  footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Remove
          </Button>,
        ]} */
        onOk={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
      >
        <Space direction="vertical" align="center" style={{width: '100%'}}>
          {selectedBranchStaffNumber > 0 && (
            <Text style={{color: 'red', fontSize: 31}}>
              This Branch have {selectedBranchStaffNumber} Staff
            </Text>
          )}
          <Text style={{fontSize: 26}}>
            Are you sure wanna remove this Branch ?
          </Text>
          <img
            alt="example"
            src={require('../../../assets/image/warning.png')}
            style={{width: 170, height: 155}}
          />

          <Row
            style={{marginTop: 50, width: 500}}
            justify="space-around"
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#CECECE',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalAttribute2({
                    ...modalAttribute2,
                    isVisible: false,
                    title: '',
                  })
                }
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={async () => {
                  try {
                    /*   await API.graphql({
                      query: updateUser,
                      variables: {
                        input: {
                          id: 'avc',
                          branchID: selectedBranch.id,
                          deleted: true,
                        },
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    }) */

                    await API.graphql({
                      query: updateBranch,
                      variables: {
                        input: {
                          id: selectedBranch.id,
                          deleted: true,
                        },
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })
                    setSelectedBranch('')
                    setFields(branchFields)
                    notification['success']({
                      message: 'Success',
                      description: 'Branch deleted!',
                    })
                  } catch (error) {
                    notification['error']({
                      message: 'Error',
                      description: JSON.stringify(error),
                    })
                  } finally {
                    setChooseBranch('')
                    init()
                    setFields({
                      branchId: '',
                      branchName: '',
                      branchCode: '',

                      contactNo: '',
                      contactInfo: '',

                      address: '',
                      remarks: '',

                      isMainBranch: '',
                      status: '',

                      companyId: '',
                    })
                    setModalAttribute2({
                      ...modalAttribute2,
                      isVisible: false,
                      title: '',
                    })
                  }
                }}
                size="large"
              >
                Remove
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    )
  }

  const RegistrationForm = ({title, addBranchFields, setAddBranchFields}) => {
    const [form] = Form.useForm()

    return (
      <Form layout="inline" form={form}>
        <Space direction="horizontal" size={50} align="start">
          <Space direction="vertical">
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>{title} Name*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder={`${title} Name`}
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.branchName}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            branchName: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Branch Code</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Branch Code"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.branchCode}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            branchCode: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Status</Typography>
                    </Col>
                    <Col span={16}>
                      <Select
                        defaultValue={''}
                        style={{width: 300, marginLeft: 10}}
                        onChange={(value) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            status: value,
                          })
                        }
                      >
                        <Option value="default" disabled>
                          Select Status
                        </Option>
                        <Option value="ACTIVE">Active</Option>
                        <Option value="PENDING">Pending</Option>
                        <Option value="REJECTED">Rejected</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row> */}
            <Row hidden>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Mobile No.</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Mobile No."
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.mobileNo}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            mobileNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <Space direction="vertical">
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Phone No.*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Phone No."
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.telephoneNo}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            telephoneNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Address</Typography>
                    </Col>
                    <Col span={16}>
                      <Input.TextArea
                        style={{width: 380}}
                        placeholder="Address"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        value={addBranchFields.address}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            address: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Remarks</Typography>
                    </Col>
                    <Col span={16}>
                      <Input.TextArea
                        style={{width: 380}}
                        placeholder="Remarks"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        value={addBranchFields.remarks}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            remarks: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Space>
        <Space direction="horizontal"></Space>
      </Form>
    )
  }

  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _listBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            companyID: {
              eq: _listUsers.data.listUsers.items[0].branch.company.id,
            },
            deleted: {
              eq: false,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBranches(_listBranches.data.listBranches.items)
    } catch (error) {
      console.error(error)
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error.errors[0].message),
      })
    }
  }

  const _getBranch = async (branchId) => {
    try {
      const __getBranch = await API.graphql({
        query: getBranchWtType,
        variables: {
          id: branchId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setSelectedBranch(__getBranch.data.getBranch)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    const countStaffNumber = async () => {
      try {
        const _listUsers = await API.graphql({
          query: listUsers,
          variables: {
            filter: {
              branchID: {
                eq: selectedBranch.id,
              },
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })

        setSelectedBranchStaffNumber(_listUsers.data.listUsers.items.length)
      } catch (error) {
        return notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }
    if (selectedBranch) {
      countStaffNumber()
    }
  }, [selectedBranch])

  return (
    <Space direction="vertical" size={50}>
      <Row
        align="middle"
        justify="space-between"
        style={{backgroundColor: '#D8E2FF'}}
      >
        <Col>
          <Title style={{marginLeft: 15}}>BRANCH</Title>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text>CHOOSE BRANCH</Text>
            <Select
              defaultValue=""
              style={{width: 200}}
              value={chooseBranch}
              onChange={(value) => {
                setBranchID(value)
                setChooseBranch(value)
                _getBranch(value)
              }}
            >
              <Option value="" disabled></Option>
              {branches
                ? branches.map((item) => (
                    <Option value={item.id}>
                      {item.company?.companyName +
                        ' - ' +
                        (item.isMainBranch ? 'Main Branch' : item.branchName)}
                    </Option>
                  ))
                : branches.map((item) => (
                    <Option value={item.id}>
                      {item.company?.companyName +
                        ' - ' +
                        (item.isMainBranch ? 'Main Branch' : item.branchName)}
                    </Option>
                  ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center" style={{marginTop: 15}}>
            <Text>TOTAL BRANCH</Text>
            <Title>{branches.length}</Title>
          </Space>
        </Col>
        <Col>
          <Button
            type="text"
            htmlType="submit"
            style={{
              backgroundColor: '#0CDF1C',
              borderColor: 'transparent',
              color: '#FFF',
              width: 150,
              marginRight: 15,
              borderRadius: 9,
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: true,
                title: 'Branch',
              })
            }
            icon={<UsergroupAddOutlined />}
          >
            Add Branch
          </Button>
        </Col>
        <AddBranchModal
          companyId={fields.companyId}
          refetchBranches={() => init()}
        />
        <RemoveBranchModal companyId={fields.companyId} />
      </Row>
      <Formik
        enableReinitialize={true}
        initialValues={{
          branchId: selectedBranch?.id,
          branchName: selectedBranch?.branchName,
          telephoneNo: selectedBranch?.phone,
          branchCode: selectedBranch?.code,
          address: selectedBranch?.address,
          mobileNo: selectedBranch?.company?.user?.phoneNo,
          remarks: selectedBranch?.remarks,
          isMainBranch: selectedBranch?.isMainBranch,
          status: selectedBranch?.status,
        }}
        onSubmit={async (values) => {
          try {
            // TODO: update supplier detail
            await API.graphql({
              query: updateBranch,
              variables: {
                input: {
                  id: selectedBranch?.id,
                  code: values.branchCode,
                },
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            })

            notification['success']({
              message: 'Success',
              description: 'Supplier detail updated',
            })
          } catch (error) {
            notification['error']({
              message: 'Error',
              description: JSON.stringify(error),
            })
          }
        }}
      >
        {({handleSubmit, values, errors, setFieldValue, isSubmitting}) => {
          return (
            <Form name="branch_detail">
              <Row justify="space-around" align="middle">
                <Col span={10}>
                  <Form.Item name="branchName">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Branch Name</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Branch Name"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          // value={selectedBranch?.branchName}
                          value={values.branchName}
                          onChange={(e) =>
                            setFieldValue('branchName', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="contactInfo">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Phone No</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Phone No"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          /*value={selectedBranch?.phone} */
                          value={values.telephoneNo}
                          onChange={(e) =>
                            setFieldValue('telephoneNo', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-around" align="middle">
                <Col span={10}>
                  <Form.Item name="branchCode">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Branch Code</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Branch Code"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          /*readOnly
                          value={selectedBranch?.code}*/

                          value={values.branchCode}
                          onChange={(e) =>
                            setFieldValue('branchCode', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="address">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Address</Typography>
                      </Col>
                      <Col span={16}>
                        <TextArea
                          style={{width: 300}}
                          placeholder="Address"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          rows={4}
                          /*value={selectedBranch?.address} */
                          value={values.address}
                          onChange={(e) =>
                            setFieldValue('address', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              {/*   <Row justify="space-around" align="top">
                                <Col span={10}>
                  <Form.Item name="status">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Status</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Status"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          readOnly
                          value={selectedBranch?.status}
                          
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col> 
                <Col span={10}>
                  <Form.Item name="remarks">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Remarks</Typography>
                      </Col>
                      <Col span={16}>
                        <TextArea
                          style={{width: 300}}
                          placeholder="Remarks"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          rows={4}
                          readOnly
                          disabled
                          value={values.remarks}
                          onChange={(e) =>
                            setFieldValue('remarks', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row justify="space-around">
                <Col span={10} style={{visibility: 'hidden'}}>
                  <Form.Item name="status">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Mobile No</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Mobile No"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          /*
                          value={selectedBranch?.company?.user?.phoneNo} */
                          value={values.mobileNo}
                          onChange={(e) =>
                            setFieldValue('mobileNo', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="remarks">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Remarks</Typography>
                      </Col>
                      <Col span={16}>
                        <TextArea
                          style={{width: 300}}
                          placeholder="Remarks"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          rows={4}
                          /*
                          value={selectedBranch?.remarks} */
                          value={values.remarks}
                          onChange={(e) =>
                            setFieldValue('remarks', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                align="middle"
                justify="center"
                gutter={40}
                hidden={!values.branchId}
              >
                {!values.isMainBranch && (
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: '#8893F9',

                        borderColor: 'transparent',
                        width: 160,
                        borderRadius: 9,
                        color: '#FFF',
                      }}
                      onClick={() =>
                        setModalAttribute2({
                          ...modalAttribute2,
                          isVisible: true,
                          title: 'Staff',
                        })
                      }
                      size="large"
                    >
                      Remove
                    </Button>
                  </Col>
                )}

                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: '#34C217',
                      borderColor: 'transparent',
                      width: 160,
                      borderRadius: 9,
                      color: '#FFF',
                    }}
                    onClick={async () => {
                      try {
                        await API.graphql({
                          query: updateBranch,
                          variables: {
                            input: {
                              id: values.branchId,
                              branchName: values.branchName,
                              code: values.branchCode,
                              phone: values.telephoneNo,
                              address: values.address,
                              remarks: values.remarks,
                              status: values.status,
                            },
                          },
                          authMode: 'AMAZON_COGNITO_USER_POOLS',
                        })
                        notification['success']({
                          message: 'Success',
                          description: 'Branch Updated!',
                        })
                      } catch (error) {
                        notification['error']({
                          message: 'Error',
                          description: JSON.stringify(error),
                        })
                      }
                    }}
                    size="large"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
      <br />
    </Space>
  )
}

export const Staff = ({_companyId, branchID, branches}) => {
  const [users, setUsers] = React.useState([])
  const initSelectedUser = {
    designation: '',
    fullName: '',
    phoneNo: '',
    registrationNo: '',
    staffCode: '',
    staffID: '',
  }
  const [selectedUser, setSelectedUser] = React.useState(initSelectedUser)
  const [chooseStaff, setChooseStaff] = React.useState('')

  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            branchID: {
              eq: branchID,
            },
            /* deleted: {
              eq: false,
            }, */
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setUsers(_listUsers.data.listUsers.items)
      setSelectedUser('')
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _getUser = async (userId) => {
    try {
      const __getUser = await API.graphql({
        query: getUser,
        variables: {
          id: userId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setSelectedUser(__getUser.data.getUser)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
    setChooseStaff('')
  }, [branchID])

  const [modalAttribute, setModalAttribute] = React.useState({
    isVisible: false,
    title: '',
  })
  const [modalAttribute2, setModalAttribute2] = React.useState({
    isVisible: false,
    title: '',
  })

  const AddStaffModal = ({branchID}) => {
    console.log('AddStaffModal branches', branches)
    const [addStaffFields, setAddStaffFields] = React.useState({
      fullName: '',
      staffCode: '',
      idNumber: '',
      regNo: 0,
      branchCode: '',
      designation: '',
      email: '',
      mobileNo: '',
      telephoneNo: '',
      role: '',
      branchID: branches?.length > 0 ? branches[0].id : '',
    })

    return (
      <Modal
        title={`Add ${modalAttribute.title}`}
        visible={modalAttribute.isVisible}
        width={855}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={async () => {
              try {
                await API.graphql({
                  query: adminCreateStaff,
                  variables: {
                    branchID: addStaffFields.branchID,
                    designation: addStaffFields.designation,
                    email: addStaffFields.email,
                    fullName: addStaffFields.fullName,
                    mobileNo: addStaffFields.mobileNo,
                    phoneNo: addStaffFields.telephoneNo,
                    registrationNo: addStaffFields.regNo,
                    staffCode: addStaffFields.staffCode,
                    userType: 'SUPER_ADMIN_STAFF',
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                init()
                // get staff id to update others data
                /* const getStaffID = await API.graphql({
                  query: listUsers,
                  variables: {
                    filter: {
                      email: {
                        eq: addStaffFields.email,
                      },
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                await API.graphql({
                  query: updateUser,
                  variables: {
                    input: {
                      id: getStaffID.data.listUsers.items[0].id,
                      fullName: addStaffFields.fullName,
                      staffCode: addStaffFields.staffCode,
                      registrationNo: addStaffFields.regNo,
                      designation: addStaffFields.designation,
                      mobileNo: addStaffFields.mobileNo,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                }) */

                notification['success']({
                  message: 'Success',
                  description: 'Staff added!',
                })
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error?.errors[0]?.message,
                })
              } finally {
                setModalAttribute({
                  ...modalAttribute,
                  isVisible: false,
                  title: '',
                })
              }
            }}
            size="large"
          >
            Submit
          </Button>,
        ]}
        onOk={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
      >
        <RegistrationForm
          title={modalAttribute.title}
          {...{branchID}}
          {...{addStaffFields}}
          {...{setAddStaffFields}}
        />
      </Modal>
    )
  }

  const RemoveStaffModal = ({fields}) => {
    return (
      <Modal
        title=""
        visible={modalAttribute2.isVisible}
        width={855}
        footer={null}
        /* footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Submit
          </Button>,
        ]} */
        onOk={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
      >
        <Space direction="vertical" align="center" style={{width: '100%'}}>
          <Text style={{fontSize: 26}}>
            Are you sure wanna remove this Staff ?
          </Text>
          <img
            alt="example"
            src={require('../../../assets/image/warning.png')}
            style={{width: 170, height: 155}}
          />
          <Row
            style={{marginTop: 50, width: 500}}
            justify="space-around"
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#34C217',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalAttribute2({
                    ...modalAttribute2,
                    isVisible: false,
                    title: '',
                  })
                }
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={async () => {
                  try {
                    await API.graphql({
                      query: adminDeleteStaff,
                      variables: {
                        staffID: fields.id,
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })
                  } catch (error) {
                    if (error.errors[0].message === 'User does not exist.') {
                      return true
                    }
                    return notification['error']({
                      message: 'Error',
                      description: JSON.stringify(error),
                    })
                  } finally {
                    setChooseStaff('')
                    init()
                    setModalAttribute2({
                      ...modalAttribute2,
                      isVisible: false,
                      title: '',
                    })
                  }
                }}
                size="large"
              >
                Remove
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    )
  }

  const RegistrationForm = ({
    title,
    branchID,
    addStaffFields,
    setAddStaffFields,
  }) => {
    const [form] = Form.useForm()

    return (
      <Form layout="inline" form={form}>
        <Space direction="horizontal" size={50}>
          <Space direction="vertical">
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Full Name*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Full Name"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.fullName}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            fullName: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Staff Code</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Staff Code"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.staffCode}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            staffCode: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {/*  <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>ID Number</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="ID Number"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.idNumber}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            idNumber: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>
                        Reg No.{`  `}
                      </Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Registration No."
                        type="number"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.regNo}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            regNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <Space direction="vertical" style={{paddingTop: 50}}>
            {/*   <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Branch Code</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Branch Code"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.branchCode}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            branchCode: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Designation</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Designation"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.designation}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            designation: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>E-mail*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="E-mail"
                        type="email"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.email}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            email: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{visibility: 'hidden'}}>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Mobile No.</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Mobile No."
                        type="email"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.mobileNo}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            mobileNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Phone No.*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Phone No."
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.telephoneNo}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            telephoneNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Space>
        <Space direction="vertical">
          <Text strong>Branch</Text>
          <Row>
            <Col>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <Typography style={{width: 120}}>Branch Name</Typography>
                  </Col>
                  <Col span={16}>
                    <Select
                      defaultValue="selectStatus"
                      className="buttonBorderRadius"
                      style={{width: 250}}
                      value={addStaffFields.branchID}
                      onChange={(e) => {
                        setAddStaffFields({
                          ...addStaffFields,
                          branchID: e,
                        })
                      }}
                    >
                      <Option value="selectStatus" disabled>
                        Select Branch Name
                      </Option>
                      {branches.map((branch) => (
                        <Option value={branch.id}>{branch.branchName}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <Typography style={{width: 120}}>Status</Typography>
                  </Col>
                  <Col span={16}>
                    <Select defaultValue="default" style={{width: 250}}>
                      <Option value="default" disabled>
                        Select Status
                      </Option>
                      <Option value="active">Active</Option>
                      <Option value="inactive">Inactive</Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row> */}
        </Space>
      </Form>
    )
  }

  return (
    <Space direction="vertical" size={50}>
      <Row
        align="middle"
        justify="space-between"
        style={{backgroundColor: '#D8E2FF'}}
      >
        <Col>
          <Title style={{marginLeft: 15}}>STAFF</Title>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text>CHOOSE STAFF</Text>
            <Select
              value={chooseStaff}
              defaultValue=""
              style={{width: 200}}
              onChange={(value) => {
                setChooseStaff(value)
                _getUser(value)
              }}
            >
              <Option value="" disabled></Option>
              {users?.map((item) => (
                <Option value={item.id}>{item.email}</Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center" style={{marginTop: 15}}>
            <Text>TOTAL STAFF</Text>
            <Title>{users.length}</Title>
          </Space>
        </Col>
        <Col>
          <Button
            type="text"
            htmlType="submit"
            style={{
              backgroundColor: '#0CDF1C',
              borderColor: 'transparent',
              color: '#FFF',
              width: 150,
              marginRight: 15,
              borderRadius: 9,
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: true,
                title: 'Staff',
              })
            }
            icon={<UserAddOutlined />}
          >
            Add Staff
          </Button>
        </Col>
      </Row>
      <Form name="branch_detail">
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="branchName">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Full Name</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Full Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={selectedUser?.fullName}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        fullName: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="branchCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Email</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Email"
                    type="email"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    disabled
                    value={selectedUser?.email}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="staffCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Staff Code</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Staff Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={selectedUser?.staffCode}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        staffCode: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="address">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Designation</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Designation"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={selectedUser?.designation}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        designation: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-around">
          <Col span={10}>
            <Form.Item name="idNumber">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Reg No</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Reg No"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={selectedUser?.registrationNo}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        registrationNo: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="contact">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Phone No</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Phone No"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={selectedUser?.phoneNo}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        phoneNo: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row
          align="middle"
          justify="center"
          gutter={40}
          hidden={!selectedUser?.id}
        >
          {selectedUser?.userType?.includes('STAFF') && (
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',

                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalAttribute2({
                    ...modalAttribute2,
                    isVisible: true,
                    title: 'Staff',
                  })
                }
                size="large"
              >
                Remove
              </Button>
            </Col>
          )}

          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#0CDF1C',
                borderColor: 'transparent',
                width: 160,
                borderRadius: 9,
                color: '#FFF',
              }}
              onClick={async () => {
                try {
                  await API.graphql({
                    query: adminUpdateStaff,
                    variables: {
                      designation: selectedUser?.designation,
                      fullName: selectedUser?.fullName,
                      phoneNo: selectedUser?.phoneNo,
                      registrationNo: selectedUser?.registrationNo,
                      staffCode: selectedUser?.staffCode,
                      staffID: selectedUser?.id,
                      // status: selectedUser?.status,
                    },
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                  })
                  notification['success']({
                    message: 'Success',
                    description: 'Staff data updated!',
                  })
                } catch (error) {
                  notification['error']({
                    message: error?.errors[0]?.message,
                  })
                }
              }}
              size="large"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <AddStaffModal {...{branchID}} />
      <RemoveStaffModal fields={selectedUser} />
    </Space>
  )
}

function AccountManagement() {
  const [companyId, setCompanyId] = React.useState('')
  const [branchID, setBranchID] = React.useState('')
  const [branches, setBranches] = React.useState([])
  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _listBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            companyID: {
              eq: _listUsers.data.listUsers.items[0].branch.company.id,
            },
            deleted: {
              eq: false,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBranches(_listBranches.data.listBranches.items)

      setBranchID(_listUsers.data.listUsers.items[0].branchID)
      setCompanyId(_listUsers.data.listUsers.items[0].branch.company.id)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,

            paddingTop: 33,
            marginLeft: '5%',

            backgroundColor: '#FFF',
          }}
        >
          {companyId && (
            <Branch
              _companyId={companyId}
              {...{setBranchID}}
              refetchBranchesParent={() => init()}
            />
          )}
          {branchID && <Staff {...{branchID}} {...{branches}} />}
          {/* <Staff /> */}
        </Layout>
      </Content>
    </>
  )
}

export default AccountManagement
