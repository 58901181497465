import React from 'react'
import {
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Table,
  Button,
} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card
const columns = [
  {
    title: 'No.',
    dataIndex: 'key',
    defaultSortOrder: 'descend',
    sorter: (a, b) => b.key - a.key,
  },
  {
    title: 'Employment Agency Name',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
  {
    title: 'EA Code',
    render: () => <Input placeholder="EA Code" />,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: (a, b) => a.address - b.address,
  },
  {
    title: 'Actions',

    render: () => (
      <Row justify="space-between">
        <Col>
          <Button
            type="primary"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 150,
            }}
            onClick={() => true}
          >
            Approve
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              backgroundColor: '#707070',
              borderColor: 'transparent',
              width: 150,
            }}
            onClick={() => true}
          >
            Reject
          </Button>
        </Col>
      </Row>
    ),
  },
]
const columns2 = [
  {
    title: 'No.',
    dataIndex: 'key',
    defaultSortOrder: 'descend',
    sorter: (a, b) => b.key - a.key,
  },
  {
    title: 'Employment Agency',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
  {
    title: 'Supplier Code',
    dataIndex: 'code',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.code - b.code,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: (a, b) => a.address - b.address,
  },
  {
    title: 'Details',

    render: () => (
      <Button
        type="primary"
        htmlType="submit"
        style={{
          backgroundColor: '#8893F9',
          borderColor: 'transparent',
          width: 150,
        }}
        onClick={() => true}
      >
        Details
      </Button>
    ),
  },
]

const data = [
  {
    key: '1',
    name: 'Aplha',
    code: 32,
    address: '51 Fairways Drive',
  },
  {
    key: '2',
    name: 'Beta',
    code: 42,
    address: '8 Rodyk St',
  },
]

const data2 = [
  {
    key: '1',
    name: 'Theta',
    code: 32,
    address: '51 Fairways Drive',
    status: 'Active',
  },
  {
    key: '2',
    name: 'Tezoz',
    code: 42,
    address: '8 Rodyk St',
    status: 'Rejected',
  },
]
function EmploymentAgency() {
  return (
    <>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            paddingLeft: 15,
            paddingTop: 33,
            marginLeft: '5%',
            backgroundColor: '#FFF',
          }}
        >
          <Space direction="vertical" size={50}>
            <Row>
              <Col>
                <Input addonBefore={<SearchOutlined />} placeholder="Search" />
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={data}
              bordered
              style={{marginRight: 15}}
            />
            <Table
              columns={columns2}
              dataSource={data2}
              bordered
              style={{marginRight: 15}}
            />
          </Space>
        </Layout>
      </Content>
    </>
  )
}

export default EmploymentAgency
