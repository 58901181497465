import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import App from './App'
import ErrorBoundary from './utils/ErrorBoundary'
import reportWebVitals from './reportWebVitals'
import {SupplierProvider} from './contexts/SupplierProvider'
import Amplify from '@aws-amplify/core'
import {Auth} from '@aws-amplify/auth'
import awsMobile from './aws-exports'

Amplify.configure({
  ...awsMobile,
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken(),
      }
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <SupplierProvider>
        <App />
      </SupplierProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
