import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {error: '', errorInfo: ''}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    return {error}
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const {error} = this.state
    if (error) {
      return (
        <h4 style={{color: 'red'}}>
          Something went wrong. <a href="/">Click here to back to main menu.</a>
        </h4>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
