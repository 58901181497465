import React from 'react'
import {
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Input,
  Space,
  Select,
  Avatar,
  PageHeader,
  Radio,
  Table,
  Divider,
  notification,
} from 'antd'
import {
  CaretDownFilled,
  UserOutlined,
  UserAddOutlined,
  DashboardOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import {getSignedInUserDetails} from '../../../utils/checkValidity'
import {listUsers, getMigrant} from '../../../backend/graphql/queries'
import Slider from 'react-slick'
import {capitalize} from 'lodash'

import {Auth} from '@aws-amplify/auth'
import {useReactToPrint} from 'react-to-print'
import moment from 'moment'

const {Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select

const convertReligion = (text) => {
  switch (text) {
    case 'FREE_THINKER':
      return 'Free Thinker'
    case 'CATHOLIC':
      return 'Catholic'
    case 'MUSLIM':
      return 'Muslim'
    case 'SIKH':
      return 'Sikh'
    case 'CHRISTIAN':
      return 'Christian'
    case 'BUDDHIST':
      return 'Buddhist'
    case 'HINDU':
      return 'Hindu'
    case 'OTHERS':
      return 'Others'
    default:
      return 'Others'
  }
}

const convertRegion = (text) => {
  switch (text) {
    case 'ACEH':
      return 'Aceh'
    case 'NORTH_SUMATRA':
      return 'Sumatera Utara'
    case 'SOUTH_SUMATERA':
      return 'Sumatera Selatan'
    case 'WEST_SUMATRA':
      return 'Sumatera Barat'
    case 'RIAU':
      return 'Riau'
    case 'RIAU_ISLANDS':
      return 'Kepulauan Riau'
    case 'JAMBI':
      return 'Jambi'
    case 'BANGKA_BELITUNG_ISLAND':
      return 'Kepulauan Bangka Belitung'
    case 'BENGKULU':
      return 'Bengkulu'
    case 'LAMPUNG':
      return 'Lampung'
    case 'CAPITAL_SPECIAL_REGION_OF_JAKARTA':
      return 'DKI Jakarta'
    case 'BANTEN':
      return 'Banten'
    case 'WEST_JAVA':
      return 'Jawa Barat'
    case 'CENTRAL_JAVA':
      return 'Jawa Tengah'
    case 'EAST_JAVA':
      return 'Jawa Timur'
    case 'SPECIAL_REGION_OF_YOGYAKARTA':
      return 'DI Yogyakarta'
    case 'BALI':
      return 'Denpasar'
    case 'WEST_NUSA_TENGGARA':
      return 'Nusa Tenggara Barat'
    case 'SOUTH_KALIMANTAN':
      return 'Kalimantan Selatan'
    case 'EAST_KALIMANTAN':
      return 'Kalimantan Timur'
    case 'NORTH_KALIMANTAN':
      return 'Kalimantan Utara'
    case 'WEST_KALIMANTAN':
      return 'Kalimantan Barat'
    case 'CENTRAL_KALIMANTAN':
      return 'Kalimantan Tengah'
    case 'CENTRAL_SULAWESI':
      return 'Sulawesi Tengah'
    case 'WEST_SULAWESI':
      return 'Sulawesi Barat'
    case 'SOUTH_SULAWESI':
      return 'Sulawesi Selatan'
    case 'SOUTHEAST_SULAWESI':
      return 'Sulawesi Tenggara'
    case 'MALUKU':
      return 'Maluku'
    case 'NORTH_MALUKU':
      return 'Maluku Utara'
    case 'PAPUA':
      return 'Papua'
    case 'WEST_PAPUA':
      return 'Papua Barat'
    case 'GORONTALO':
      return 'Gorontalo'
    default:
      return 'Aceh'
  }
}

const convertEducationLevel = (text) => {
  switch (text) {
    case 'COLLEGE':
      return 'College'
    case 'SECONDARY_SCHOOL':
      return 'Secondary School'
    case 'HIGH_SCHOOL':
      return 'High School'
    default:
      return 'High School'
  }
}

const convertMaritalStatus = (text) => {
  switch (text) {
    case 'SINGLE':
      return 'Single'
    case 'WIDOWED':
      return 'Widowed'
    case 'MARRIED':
      return 'Married'
    case 'DIVORCED':
      return 'Divorced'
    case 'SEPARATED':
      return 'Separated'
    case 'SINGLE_PARENT':
      return 'Single Parents'
    case 'NO_PREFERENCE':
      return 'No Preferences'
    default:
      return 'No Preferences'
  }
}
const MainMenuSider = ({setActiveSider}) => {
  const history = useHistory()
  return (
    <Sider style={{backgroundColor: '#FFF'}}>
      <Menu
        style={{width: 256, height: '100%'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
      >
        <Menu.Item
          icon={<DashboardOutlined />}
          onClick={() => {
            // setActiveSider(0)
            history.push('/ea/dashboard')
          }}
        >
          Dashboard
        </Menu.Item>
        {getSignedInUserDetails().UserAttributes.find(
          (userAttribute) => userAttribute.Name === 'custom:userType',
        ) && (
          <Menu.Item
            icon={<UserAddOutlined />}
            onClick={() => {
              history.push({
                pathname: '/ea/dashboard',
                state: {
                  activeSider: 3,
                },
              })
            }}
          >
            EA Account Mgt
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}

const Wrapper = ({children, setActiveSider, user}) => {
  return (
    <div style={{backgroundColor: '#FAFAFA'}}>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          borderBottom: '1px solid #ecf0f1',
        }}
        className="site-page-header"
        onBack={() => null}
        backIcon={false}
        title=" "
        subTitle=" "
        avatar={{
          src: 'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/our.png',
          size: 65,
        }}
        extra={[<DropdownMenu {...{user}} />]}
      />
      <Layout style={{backgroundColor: '#FFF'}}>
        <MainMenuSider {...{setActiveSider}} />

        {children}
      </Layout>
    </div>
  )
}

const DropdownMenu = ({user}) => {
  const history = useHistory()

  const MenuToolbar = () => {
    return (
      <Menu>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => history.push('/ea/profile')}
          >
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          {/* <a rel="noopener noreferrer" onClick={() => history.push('/')}> */}
          <a
            rel="noopener noreferrer"
            onClick={async () => {
              try {
                await Auth.signOut()
                history.push('/')
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error.name,
                })
              }
            }}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown key="more" overlay={MenuToolbar}>
      <Button
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        <Row align="middle" justify="center" style={{marginTop: 15}}>
          <Col>
            <Avatar size={32} icon={<UserOutlined />} />
          </Col>

          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Text strong>{user?.fullName || user?.email}</Text>

            <Text type="secondary">{user?.branch?.company?.companyName}</Text>
          </Col>

          <Col>
            <CaretDownFilled />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}

const RenderPicture = ({s3Key, type, needRefetch}) => {
  const [picture, setPicture] = React.useState('')

  const init = async () => {
    try {
      const _picture = await Storage.get(s3Key)

      setPicture(_picture)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [needRefetch])

  const _renderPicture = (type) => {
    switch (type) {
      case 'PHOTO':
        return (
          <a href={picture} target="_blank">
            <img src={picture} style={{height: 400, width: 300}} alt="Photo" />
          </a>
        )
      case 'ADDITIONAL_PHOTO':
        return (
          <a href={picture} target="_blank">
            <img
              src={picture}
              style={{height: 62, width: 62}}
              alt="Additional Photo"
            />
          </a>
        )
      case 'ADDITIONAL_VIDEO':
        return (
          <a href={picture} target="_blank">
            <video src={picture} style={{height: 62, width: 62}}>
              Your browser does not support the video tag.
            </video>
          </a>
        )
      case 'SUPPORTING_DOCUMENT':
        return (
          <a href={picture} target="_blank" style={{height: 62, width: 62}}>
            <FilePdfOutlined style={{fontSize: 30}} />
          </a>
        )
      default:
        return (
          <img src={picture} style={{height: 400, width: 300}} alt="Photo" />
        )
    }
  }

  return <>{_renderPicture(type)}</>
}

const MultipleRenderWrapper = ({item, type, data}) => {
  const [needRefetch, setNeedRefetch] = React.useState(false)
  return (
    <Space direction="vertical">
      <RenderPicture s3Key={item} {...{type}} {...{needRefetch}} />
    </Space>
  )
}

const ComponentToPrint = React.forwardRef((props, ref) => {
  const {bioData, sliderSettings} = props
  const employeerFeedbackDataTable = [
    {
      no: '1',
      employer: '',
      feedback: '',
    },
    {
      no: '2',
      employer: '',
      feedback: '',
    },
    {
      no: '3',
      employer: '',
      feedback: '',
    },
  ]

  return (
    <div ref={ref} style={{margin: '0', padding: '0'}}>
      {/* Page 1 */}
      <Space direction="vertical">
        <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

        <Text className="content-print-font">
          *Please ensure that you run through the information within the biodata
          as it is an important document to help you select a suitable MDW
        </Text>
      </Space>
      <Title style={{color: '#8893F9', marginTop: 40}} level={5}>
        A.1. Personal Information
        {/* {JSON.stringify(supplierContext.migrant)} */}
      </Title>
      <Space direction="vertical" style={{width: '100%'}}>
        <Row align="top" justify="space-between">
          <Col span={12}>
            <table
              style={{width: '100%', borderColor: '#707070'}}
              className="plain"
              cellPadding={10}
            >
              <tr className="plain">
                <td className="plain content-print-font">Name</td>
                <td className="plain content-print-font" align="right">
                  {bioData.name}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Date of Birth</td>
                <td className="plain content-print-font" align="right">
                  {bioData.dateOfBirth}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Place of birth</td>
                <td className="plain content-print-font" align="right">
                  {bioData.placeOfBirth}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Height & Weight</td>
                <td className="plain content-print-font" align="right">
                  {bioData.height}/{bioData.weight}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Nationality</td>
                <td className="plain content-print-font" align="right">
                  {capitalize(bioData.nationality)}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">
                  Residential address in home country
                </td>
                <td className="plain content-print-font" align="right">
                  {bioData.residentialAddress}
                </td>
              </tr>
              {bioData.nationality === 'INDONESIA' && (
                <tr className="plain">
                  <td className="plain content-print-font">Region</td>
                  <td className="plain content-print-font" align="right">
                    {convertRegion(bioData.region)}
                  </td>
                </tr>
              )}
              <tr className="plain">
                <td className="plain content-print-font">National ID</td>
                <td className="plain content-print-font" align="right">
                  {bioData.nationalID}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Passport Number</td>
                <td className="plain content-print-font" align="right">
                  {bioData.passportNumber}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Salary Range</td>
                <td className="plain content-print-font" align="right">
                  {bioData.salaryRange === 'RANGE_1'
                    ? '400 - 500 SGD'
                    : bioData.salaryRange === 'RANGE_2'
                    ? '501 - 600 SGD'
                    : '601 - Above'}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">
                  Name of port / airport to be repatriated to
                </td>
                <td className="plain content-print-font" align="right">
                  {bioData.nameOfPort}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">
                  Contact number in home country
                </td>
                <td className="plain content-print-font" align="right">
                  {bioData.contactNumber}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Next of Kin</td>
                <td className="plain content-print-font" align="right">
                  {bioData.nextOfKin}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">
                  Phone Number (Next of Kin)
                </td>
                <td className="plain content-print-font" align="right">
                  {bioData.nextOfKinContactNumber}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Religion</td>
                <td className="plain content-print-font" align="right">
                  {convertReligion(bioData.religion)}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Education level</td>
                <td className="plain content-print-font" align="right">
                  {convertEducationLevel(bioData.educationLevel)}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Number of Siblings</td>
                <td className="plain content-print-font" align="right">
                  {bioData.numberOfSiblings}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Marital status</td>
                <td className="plain content-print-font" align="right">
                  {convertMaritalStatus(bioData.maritalStatus)}
                </td>
              </tr>
              <tr className="plain">
                <td className="plain content-print-font">Number of Children</td>
                <td className="plain content-print-font" align="right">
                  {bioData.childrenNumber}
                </td>
              </tr>
            </table>
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Space direction="vertical">
              <RenderPicture
                s3Key={bioData.photoKey}
                type="PHOTO"
                needRefetch={false}
              />
              {(function () {
                let additionalPhoto = JSON.parse(bioData.additionalPhoto)
                return (
                  <Slider {...sliderSettings} style={{width: 250}}>
                    {additionalPhoto.map((item) => (
                      <MultipleRenderWrapper
                        item={item}
                        type="ADDITIONAL_PHOTO"
                        data={additionalPhoto}
                      />
                    ))}
                  </Slider>
                )
              })()}
              {/*  <Row gutter={4} align="middle">
                      <Col>
                        <div
                          style={{
                            border: '1px solid #000',
                            borderRadius: 5,
                            height: 120,
                            width: 90,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Text>Additional 1</Text>
                        </div>
                      </Col>
                     
                    </Row> */}
            </Space>
          </Col>
        </Row>
      </Space>
      {/* <div className="page-break" /> */}
      <div style={{pageBreakAfter: 'always'}} />

      {/* Page 2 */}
      <Space direction="vertical">
        <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

        <Text className="content-print-font">
          *Please ensure that you run through the information within the biodata
          as it is an important document to help you select a suitable MDW
        </Text>
      </Space>
      <Title style={{color: '#8893F9', marginTop: 20}} level={5}>
        A2. Medical History/Dietary Restrictions
      </Title>
      <Row justify="space-between" align="middle" style={{width: '50%'}}>
        <Col span={10}>
          <Typography className="content-print-font">
            14. Allergies (if any)<sup>++</sup>
          </Typography>
        </Col>
        <Col span={14} className="content-print-font">
          {bioData.allergies}
          {/*  <Input.TextArea
            style={{width: 300}}
            type="text"
            size="large"
            className="buttonBorderRadius content-print-font"
            multiple={true}
            maxLength={100}
            value={bioData.allergies}
          /> */}
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{marginTop: 5}}>
        <Col>
          <Typography className="content-print-font">
            15. Past and existing illnesses (including chronic ailments and
            illnesses requiring medication)*
          </Typography>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">
            i. Mental illness{bioData.illness}
          </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.illness} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">ii. Epilepsy</Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.epilepsy} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">iii. Asthma </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.asthma} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">iv. Diabetes </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.diabetes} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">
            v. Hypertension{' '}
          </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.hypertension} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">
            vi. Tuberculosis{' '}
          </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.tuberculosis} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">
            vii. Heart disease{' '}
          </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.heart_disease} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">viii. Malaria </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.malaria} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">
            ix. Operations{' '}
          </Typography>
        </Col>
        <Col span={8}>
          <Radio.Group defaultValue={bioData.operations} disabled>
            <Radio value={true}>Yes </Radio>
            <Radio value={false} style={{marginLeft: 20}}>
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={8}>
          <Typography className="content-print-font">x. Others </Typography>
        </Col>
        <Col className="content-print-font">
          {bioData.physicalDisabilities}
          {/*  <Input.TextArea
            style={{width: 300}}
            size="large"
            className="buttonBorderRadius content-print-font"
            multiple={true}
            maxLength={100}
            value={bioData.others}
            disabled
          /> */}
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{marginTop: 25, width: '70%', marginBottom: 25}}
      >
        <Col span={8}>
          <Typography className="content-print-font">
            16. Physical disabilities
          </Typography>
        </Col>
        <Col className="content-print-font">
          {bioData.physicalDisabilities}
          {/* <Input.TextArea
            style={{width: 300}}
            size="large"
            className="buttonBorderRadius content-print-font"
            multiple={true}
            maxLength={100}
            value={bioData.physicalDisabilities}
            disabled
          /> */}
        </Col>
      </Row>

      {/* Table */}
      <>
        <Row
          align="middle"
          justify="space-between"
          style={{
            backgroundColor: '#D8E2FF',
            padding: 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <Col span={2}>
            <Row>
              <Text align="center" className="content-print-font">
                S/N.
              </Text>
            </Row>
          </Col>
          <Col span={5}>
            <Row>
              <Text align="center" className="content-print-font">
                Areas of Work
              </Text>
            </Row>
          </Col>
          <Col span={3} style={{display: 'flex', justifyContent: 'center'}}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">Willingness</Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  (Yes/No)
                </Text>
              </Space>
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">Experience</Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  (Yes/No)
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  If yes, state the no. of years
                </Text>
              </Space>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">
                  Assessment/Observation
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  Please state qualitative observations of MDW and/or rate the
                  MDW (indicate N.A. of no evaluation was done) Poor
                  ……………………Excellent...N.A
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  1 2 3 4 5 N.A
                </Text>
              </Space>
            </Row>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">1</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">
                Care of infants/children
              </Text>
              <Text>{bioData.careOfInfantsDetailA2}</Text>
              {/* <Text>Please specify age range:</Text>
                        <Input
                          style={{width: 150}}
                          placeholder="0-00"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*                       <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.careOfInfantsWillingnessA2 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}

            {bioData.careOfInfantsHasExperienceYearA2 ? 'Yes' : 'No'}
            {bioData.careOfInfantsExperienceYearA2 &&
              `, ${bioData.careOfInfantsExperienceYearA2}`}
          </Col>
          <Col
            span={10}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {bioData.careOfInfantsAssesmentA2}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">2</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Care of elderly</Text>
              <Text className="content-print-font">
                {bioData.careOfElderlyDetailA2}
              </Text>
              {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.careOfElderlyWillingnessA2 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.careOfElderlyHasExperienceYearA2 ? 'Yes' : 'No'}
            {bioData.careOfElderlyExperienceYearA2 &&
              `, ${bioData.careOfElderlyExperienceYearA2}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {bioData.careOfElderlyAssesmentA2}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">3</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Care of disabled</Text>
              <Text className="content-print-font">
                {bioData.careOfDisabledDetailA2}
              </Text>
              {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.careOfDisabledWillingnessA2 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.careOfDisabledHasExperienceYearA2 ? 'Yes' : 'No'}
            {bioData.careOfDisabledExperienceYearA2 &&
              `, ${bioData.careOfDisabledExperienceYearA2}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {bioData.careOfDisabledAssesmentA2}
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">4</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">General housework</Text>
              <Text className="content-print-font">
                {bioData.generalHouseworkDetailA2}
              </Text>
              {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.generalHouseworkWillingnessA2 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.generalHouseworkHasExperienceYearA2 ? 'Yes' : 'No'}
            {bioData.generalHouseworkExperienceYearA2 &&
              `, ${bioData.generalHouseworkExperienceYearA2}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {bioData.generalHouseworkAssesmentA2}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">5</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Cooking</Text>
              <Text className="content-print-font">
                {bioData.cookingDetailA2}
              </Text>
              {/*  <Text>Please specify cuisines:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.cookingWillingnessA2 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.cookingHasExperienceYearA2 ? 'Yes' : 'No'}
            {bioData.cookingExperienceYearA2 &&
              `, ${bioData.cookingExperienceYearA2}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.cookingAssesmentA2}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">6</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">
                Language abilities (spoken)
              </Text>
              <Text className="content-print-font">
                {bioData.languageDetailA2}
              </Text>
              {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col span={3}></Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.languageExperienceHasYearA2 ? 'Yes' : 'No'}
            {bioData.languageExperienceYearA2 !== 'false'
              ? `, ${bioData.languageExperienceYearA2}`
              : ''}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.languageAssesmentA2}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">7</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Other skills, if any</Text>
              <Text className="content-print-font">
                {bioData.otherSkillsDetailA2}
              </Text>
              {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col span={3}></Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {bioData.otherSkillsHasExperienceYearA2 ? 'Yes' : 'No'}
            {bioData.otherSkillsExperienceYearA2 &&
              `, ${bioData.otherSkillsExperienceYearA2}`}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.otherSkillsAssesmentA2}
          </Col>
        </Row>
        {/* <Divider className="no-margin" /> */}
      </>
      {/* End of Table */}
      <div style={{pageBreakAfter: 'always'}} />
      {/* Page 3 */}
      <Space direction="vertical">
        <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

        <Text className="content-print-font">
          *Please ensure that you run through the information within the biodata
          as it is an important document to help you select a suitable MDW
        </Text>
      </Space>

      <Row
        justify="space-between"
        align="middle"
        style={{width: '100%', marginBottom: 25, marginTop: 40}}
      >
        <Col span={8}>
          <Typography className="content-print-font">
            17. Dietary Restriction
          </Typography>
        </Col>
        <Col className="content-print-font">
          {bioData.dietaryRestriction}
          {/*  <Input.TextArea
            style={{width: 300}}
            size="large"
            className="buttonBorderRadius"
            multiple={true}
            maxLength={100}
            value={bioData.dietaryRestriction}
            disabled
          /> */}
        </Col>
      </Row>
      <Row align="middle" style={{marginTop: 20}}>
        <Col span={5}>
          <Typography className="content-print-font">
            18. Food handling preferences:
          </Typography>
        </Col>
        <Col className="content-print-font">
          {bioData.foodHandlingPreferences}
          {/*  <Input.TextArea
            type="text"
            size="middle"
            className="buttonBorderRadius"
            multiple={true}
            maxLength={200}
            style={{width: 300}}
            disabled
            readOnly
            value={bioData.foodHandlingPreferences}
          /> */}
        </Col>
      </Row>
      {/* Table */}
      <>
        <Row
          align="middle"
          justify="space-between"
          style={{
            backgroundColor: '#D8E2FF',
            padding: 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <Col span={2}>
            <Row>
              <Text align="center" className="content-print-font">
                S/N.
              </Text>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">Core Fundamentals</Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  (Assessment and Observation to select candidates based on
                  these strict criteria including IQ and EQ dimensions)
                </Text>
              </Space>
            </Row>
          </Col>
          <Col span={5}>
            <Row align="center">
              <Col>
                <Space direction="vertical" align="center">
                  <Text className="content-print-font">MDW Competent</Text>
                  <Text type="secondary" style={{fontSize: 12}}>
                    (Yes/No)
                  </Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">MDW Need Training</Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  (Yes/No)
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  If yes, state the type of training needed
                </Text>
              </Space>
            </Row>
          </Col>
          <Col span={5}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">
                  Assessment/Observation
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  Please state qualitative observations of MDW and/or rate the
                  MDW (indicate N.A. of no evaluation was done) Poor
                  ……………………Excellent...N.A
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  1 2 3 4 5 N.A
                </Text>
              </Space>
            </Row>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">1</Text>
          </Col>
          <Col span={22}>
            <Space direction="vertical">
              <Text className="content-print-font">Core Values:</Text>
              <Text className="content-print-font">
                MDWS sensitised and trained in
                <br /> the fundamentals of:
              </Text>
            </Space>
          </Col>
        </Row>
        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text></Text>
          </Col>
          <Col span={6} className="content-print-font">
            <Text>a. Hygiene</Text>
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {bioData.hygieneCompetent ? 'Yes' : 'No'}
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
            }}
            className="content-print-font"
          >
            {/*  <Select value={bioData.hygieneNeedTraining} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {bioData.hygieneNeedTraining ? 'Yes' : 'No'}
            {/*  <Input.TextArea
              style={{marginLeft: 10, height: 120}}
              type="text"
              size="middle"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={200}
              disabled
              readOnly
              value={bioData.hygieneTraining}
            /> */}
            {bioData.hygieneTraining}
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {bioData.hygineAssesment == 0 ? 'N/A' : bioData.hygineAssesment}
          </Col>
        </Row>
        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text></Text>
          </Col>
          <Col span={6}>
            <Text className="content-print-font">b. Adaptibility</Text>
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {bioData.adaptabilityCompetent ? 'Yes' : 'No'}
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
            }}
            className="content-print-font"
          >
            {bioData.adaptabilityNeedTraining ? 'Yes' : 'No'}
            {/* <Select value={bioData.adaptabilityNeedTraining} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {/*  <Input.TextArea
              style={{marginLeft: 10, height: 120}}
              type="text"
              size="middle"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={200}
              disabled
              readOnly
              value={bioData.adaptabilityTraining}
            /> */}
            {bioData.adaptabilityTraining}
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select value={bioData.adaptabilityAssesment} disabled>
              <Option value={''} disabled></Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="0">N.A</Option>
            </Select> */}
            {bioData.adaptabilityAssesment == 0
              ? 'N/A'
              : bioData.hygineAssesment}
          </Col>
        </Row>
        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text></Text>
          </Col>
          <Col span={6} className="content-print-font">
            <Text>c. Discipline</Text>
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select value={bioData.disciplineCompetent} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {bioData.disciplineCompetent ? 'Yes' : 'No'}
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
            }}
            className="content-print-font"
          >
            {/* <Select value={bioData.disciplineNeedTraining} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {bioData.disciplineNeedTraining ? 'Yes' : 'No'}
            {/*  <Input.TextArea
              style={{marginLeft: 10, height: 120}}
              type="text"
              size="middle"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={200}
              disabled
              readOnly
              value={bioData.disciplineTraining}
            /> */}
            {bioData.disciplineTraining}
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select value={bioData.disciplineAssesment} disabled>
              <Option value={''} disabled></Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="0">N.A</Option>
            </Select> */}
            {bioData.disciplineAssesment == 0
              ? 'N/A'
              : bioData.disciplineAssesment}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">2</Text>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <Text className="content-print-font">Core Skills:</Text>
              <Text className="content-print-font">
                Refer to Areas of Work for details
              </Text>
            </Space>
          </Col>
          <Col
            span={16}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*  <Input.TextArea
              style={{width: '100%', marginLeft: 10}}
              type="text"
              size="middle"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={100}
              disabled
              readOnly
              value={bioData.coreSkills}
            /> */}
            {bioData.coreSkills}
          </Col>
        </Row>

        <Divider className="no-margin" />

        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">3</Text>
          </Col>
          <Col span={22}>
            <Space direction="vertical">
              <Text className="content-print-font">Core Fitness:</Text>
              <Text className="content-print-font">Emphasis on:</Text>
            </Space>
          </Col>
        </Row>
        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text></Text>
          </Col>
          <Col span={6} className="content-print-font">
            <Text>a. Mental fitness</Text>
          </Col>
          <Col
            span={5}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*  <Select value={bioData.coreFitnessMentalCompetent} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {bioData.coreFitnessMentalCompetent ? 'Yes' : 'No'}
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
            }}
            className="content-print-font"
          >
            {/* <Select value={bioData.coreFitnessMentalNeedTraining} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {bioData.coreFitnessMentalNeedTraining ? 'Yes' : 'No'}
            {/*  <Input.TextArea
              style={{marginLeft: 10, height: 120}}
              type="text"
              size="middle"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={200}
              disabled
              readOnly
              value={bioData.coreFitnessMentalTraining}
            /> */}
            {bioData.coreFitnessMentalTraining}
          </Col>
          <Col span={5} style={{display: 'flex', justifyContent: 'center'}}>
            {bioData.coreFitnessMentalAssesment == 0
              ? 'N/A'
              : bioData.coreFitnessMentalAssesment}
            {/* <Select value={bioData.coreFitnessMentalAssesment} disabled>
              <Option value={''} disabled></Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="0">N.A</Option>
            </Select> */}
          </Col>
        </Row>
        <Row
          align="top"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text></Text>
          </Col>
          <Col span={6}>
            <Text className="content-print-font">b. Physical fitness</Text>
          </Col>
          <Col
            span={5}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {bioData.coreFitnessPhysicalCompetent ? 'Yes' : 'No'}
            {/*   <Select value={bioData.coreFitnessPhysicalCompetent} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
            }}
            className="content-print-font"
          >
            {bioData.coreFitnessPhysicalNeedTraining ? 'Yes' : 'No'}
            {/* <Select value={bioData.coreFitnessPhysicalNeedTraining} disabled>
              <Option value="" disabled></Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select> */}
            {/*             <Input.TextArea
              style={{marginLeft: 10, height: 120}}
              type="text"
              size="middle"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={200}
              disabled
              readOnly
              value={bioData.coreFitnessPhysicalTraining}
            /> */}
            {bioData.coreFitnessPhysicalTraining}
          </Col>
          <Col
            span={5}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select value={bioData.coreFitnessPhysicalAssesment} disabled>
              <Option value={''} disabled></Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="0">N.A</Option>
            </Select> */}
            {bioData.coreFitnessPhysicalAssesment == 0
              ? 'N/A'
              : bioData.coreFitnessPhysicalAssesment}
          </Col>
        </Row>
        <Divider className="no-margin" />
      </>
      {/* End of Table */}
      <div style={{pageBreakAfter: 'always'}} />
      {/* Page 4 */}
      <Space direction="vertical">
        <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

        <Text>
          *Please ensure that you run through the information within the biodata
          as it is an important document to help you select a suitable MDW
        </Text>
      </Space>
      <Title style={{color: '#8893F9', marginTop: 40}} level={5}>
        A3. Others
      </Title>
      <Row justify="space-between" align="middle" style={{width: '70%'}}>
        <Col span={10}>
          <Typography className="content-print-font">
            19. Preference for rest day:
          </Typography>
        </Col>
        <Col span={3} className="content-print-font">
          {/*  <Input.TextArea
            type="text"
            size="middle"
            className="buttonBorderRadius"
            multiple={true}
            maxLength={200}
            style={{width: 300}}
            disabled
            readOnly
            value={bioData.restDaysPerMonth}
          /> */}
          {bioData.restDaysPerMonth}
        </Col>
        <Col span={11}>
          <Typography className="content-print-font">
            rest day(s) per month.*
          </Typography>
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{
          width: '50%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Col span={10}>
          <Typography className="content-print-font">
            20. Any other remarks:
          </Typography>
        </Col>
        <Col span={14} className="content-print-font">
          {/*  <Input.TextArea
            style={{width: 300}}
            placeholder="Any other remarks"
            type="text"
            size="large"
            className="buttonBorderRadius"
            multiple={true}
            maxLength={100}
            value={bioData.otherRemarks}
            disabled
          /> */}
          {bioData.otherRemarks}
        </Col>
      </Row>
      <Title style={{color: '#8893F9', marginTop: 40}} level={5}>
        B1. Method of Evaluation of Skills
      </Title>
      <Row
        justify="space-between"
        align="middle"
        style={{width: '70%', marginBottom: 20}}
      >
        <Col className="content-print-font">
          <Typography className="content-print-font">
            Method(s) used to evaluate the MDW’s skills:
          </Typography>
          {JSON.parse(bioData.methodOfEvaluationSkills).toString()}
          {/* {bioData.methodOfEvaluationSkills.toString()} */}
          {/*  <Input.TextArea
            style={{width: 300}}
            type="text"
            size="large"
            className="buttonBorderRadius"
            multiple={true}
            maxLength={100}
            value={bioData.methodOfEvaluationSkills}
            disabled
          /> */}
        </Col>
      </Row>
      {/* Table */}
      <>
        <Row
          align="middle"
          justify="space-between"
          style={{
            backgroundColor: '#D8E2FF',
            padding: 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}
        >
          <Col span={2}>
            <Row>
              <Text align="center" className="content-print-font">
                S/N.
              </Text>
            </Row>
          </Col>
          <Col span={5}>
            <Row>
              <Text align="center" className="content-print-font">
                Areas of Work
              </Text>
            </Row>
          </Col>
          <Col span={3} style={{display: 'flex', justifyContent: 'center'}}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">Willingness</Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  (Yes/No)
                </Text>
              </Space>
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">Experience</Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  (Yes/No)
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  If yes, state the no. of years
                </Text>
              </Space>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Space direction="vertical" align="center">
                <Text className="content-print-font">
                  Assessment/Observation
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  Please state qualitative observations of MDW and/or rate the
                  MDW (indicate N.A. of no evaluation was done) Poor
                  ……………………Excellent...N.A
                </Text>
                <Text type="secondary" style={{fontSize: 12}}>
                  1 2 3 4 5 N.A
                </Text>
              </Space>
            </Row>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">1</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">
                Care of infants/children
              </Text>
              <Text>{bioData.careOfInfantsDetailB1}</Text>
              {/* <Text>Please specify age range:</Text>
                        <Input
                          style={{width: 150}}
                          placeholder="0-00"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*                       <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.careOfInfantsWillingnessB1 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}

            {bioData.careOfInfantsHasExperienceYearB1 ? 'Yes' : 'No'}
            {bioData.careOfInfantsExperienceYearB1 &&
              `, ${bioData.careOfInfantsExperienceYearB1}`}
          </Col>
          <Col
            span={10}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {bioData.careOfInfantsAssesmentB1}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">2</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Care of elderly</Text>
              <Text className="content-print-font">
                {bioData.careOfElderlyDetailB1}
              </Text>
              {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.careOfElderlyWillingnessB1 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.careOfElderlyHasExperienceYearB1 ? 'Yes' : 'No'}
            {bioData.careOfElderlyExperienceYearB1 &&
              `, ${bioData.careOfElderlyExperienceYearB1}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {bioData.careOfElderlyAssesmentB1}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">3</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Care of disabled</Text>
              <Text className="content-print-font">
                {bioData.careOfDisabledDetailB1}
              </Text>
              {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.careOfDisabledWillingnessB1 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.careOfDisabledHasExperienceYearB1 ? 'Yes' : 'No'}
            {bioData.careOfDisabledExperienceYearB1 &&
              `, ${bioData.careOfDisabledExperienceYearB1}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {bioData.careOfDisabledAssesmentB1}
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">4</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">General housework</Text>
              <Text className="content-print-font">
                {bioData.generalHouseworkDetailB1}
              </Text>
              {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.generalHouseworkWillingnessB1 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.generalHouseworkHasExperienceYearB1 ? 'Yes' : 'No'}
            {bioData.generalHouseworkExperienceYearB1 &&
              `, ${bioData.generalHouseworkExperienceYearB1}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {bioData.generalHouseworkAssesmentB1}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">5</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Cooking</Text>
              <Text className="content-print-font">
                {bioData.cookingDetailB1}
              </Text>
              {/*  <Text>Please specify cuisines:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col
            span={3}
            style={{display: 'flex', justifyContent: 'center'}}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
            {bioData.cookingWillingnessB1 ? 'Yes' : 'No'}
          </Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.cookingHasExperienceYearB1 ? 'Yes' : 'No'}
            {bioData.cookingExperienceYearB1 &&
              `, ${bioData.cookingExperienceYearB1}`}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.cookingAssesmentB1}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">6</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">
                Language abilities (spoken)
              </Text>
              <Text className="content-print-font">
                {bioData.languageDetailB1}
              </Text>
              {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col span={3}></Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.languageExperienceHasYearB1 ? 'Yes' : 'No'}
            {bioData.languageExperienceYearB1 !== 'false'
              ? `, ${bioData.languageExperienceYearB1}`
              : ''}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.languageAssesmentB1}
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: 10,
          }}
        >
          <Col span={2}>
            <Text className="content-print-font">7</Text>
          </Col>
          <Col span={5}>
            <Space direction="vertical">
              <Text className="content-print-font">Other skills, if any</Text>
              <Text className="content-print-font">
                {bioData.otherSkillsDetailB1}
              </Text>
              {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
            </Space>
          </Col>
          <Col span={3}></Col>
          <Col
            span={4}
            className="content-print-font"
            style={{display: 'flex', justifyContent: 'center'}}
          >
            {bioData.otherSkillsHasExperienceYearB1 ? 'Yes' : 'No'}
            {bioData.otherSkillsExperienceYearB1 &&
              `, ${bioData.otherSkillsExperienceYearB1}`}
            {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            className="content-print-font"
          >
            {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
            {bioData.otherSkillsAssesmentB1}
          </Col>
        </Row>
        <Divider className="no-margin" />
      </>
      {/* End of Table */}
      <div style={{pageBreakAfter: 'always'}} />
      {/* page 5 */}
      <Space direction="vertical">
        <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

        <Text className="content-print-font">
          *Please ensure that you run through the information within the biodata
          as it is an important document to help you select a suitable MDW
        </Text>
      </Space>
      <Title style={{color: '#8893F9', marginTop: 40}} level={5}>
        (C) EMPLOYMENT HISTORY OF THE MDW
      </Title>
      <Title style={{color: '#8893F9', marginTop: 10}} level={5}>
        C1 Employment History Overseas
      </Title>
      <Space direction="vertical" style={{width: '100%'}}>
        <Table
          dataSource={[{no: 1}, {no: 2}, {no: 3}, {no: 4}, {no: 5}]}
          pagination={false}
          size="small"
        >
          <Table.ColumnGroup title="Date">
            <Table.Column
              title="From"
              dataIndex="dateFrom"
              key="dateFrom"
              render={(no) => (
                <>
                  <Text className="content-print-font">
                    {bioData[`employmentHistoryOverseasDateFrom${no}`]}
                  </Text>
                </>
              )}
            />
            <Table.Column
              title="To"
              dataIndex="dateTo"
              key="dateTo"
              render={(no) => (
                <>
                  <Text className="content-print-font">
                    {bioData[`employmentHistoryOverseasDateTo${no}`]}
                  </Text>
                </>
              )}
            />
          </Table.ColumnGroup>
          <Table.Column
            title="Country (including
MDW’s home country)"
            dataIndex="country"
            key="country"
            render={(no) => (
              <>
                <Text className="content-print-font">
                  {bioData[`employmentHistoryCountry${no}`]}
                </Text>
              </>
            )}
          />
          <Table.Column
            title="Employer"
            dataIndex="employer"
            key="employer"
            render={(no) => (
              <>
                <Text className="content-print-font">
                  {bioData[`employmentHistoryEmployer${no}`]}
                </Text>
              </>
            )}
          />
          <Table.Column
            title="Work Duties"
            dataIndex="workDuties"
            key="workDuties"
            render={(no) => (
              <>
                <Text className="content-print-font">
                  {bioData[`employmentHistoryWorkDuties${no}`]}
                </Text>
              </>
            )}
          />
          <Table.Column
            title="Remarks"
            dataIndex="remarks"
            key="remarks"
            render={(no) => (
              <>
                <Text className="content-print-font">
                  {bioData[`employmentHistoryWorkDutiesRemarks${no}`]}
                </Text>
              </>
            )}
          />
        </Table>
        <Row
          justify="space-between"
          align="middle"
          style={{
            marginTop: 30,
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
          }}
        >
          <Col span={12}>
            <Typography className="content-print-font">
              Interviewed by overseas training centre / EA (Please state name of
              foreign training centre / EA)
            </Typography>
          </Col>
          <Col span={12} className="content-print-font">
            {/*  <Input.TextArea
              style={{width: 300}}
              value={bioData.nameOfForeignTrainingCentre}
              type="text"
              size="large"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={100}
              disabled
              readOnly
            /> */}
            {bioData.nameOfForeignTrainingCentre}
          </Col>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
          }}
        >
          <Col span={12}>
            <Typography className="content-print-font">
              State if the third party is certified (e.g. ISO9001) or audited
              periodically by the EA:
            </Typography>
          </Col>
          <Col span={12} className="content-print-font">
            {bioData.stateIfThirdPartyIsCertifiedSpecify}
            {/*  <Input.TextArea
              style={{width: 300}}
              value={bioData.stateIfThirdPartyIsCertifiedSpecify}
              type="text"
              size="large"
              className="buttonBorderRadius"
              multiple={true}
              maxLength={100}
              disabled
              readOnly
            /> */}
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            <Radio.Group value={bioData.stateIfThirdPartyIsCertified} disabled>
              <Space direction="vertical">
                <Radio value={'INTERVIEWED_VIA_TELEPHONE'}>
                  Interviewed via telephone/teleconference
                </Radio>
                <Radio value={'INTERVIEWED_VIA_VIDEO_CONFERENCE'}>
                  Interviewed via video conference
                </Radio>
                <Radio value={'INTERVIEWED_IN_PERSON'}>
                  Interviewed in person
                </Radio>
                <Radio value={'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION'}>
                  Interviewed in person and also made observation of MDW in the
                  areas of work listed in table
                </Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </Space>
      <Title style={{color: '#8893F9', marginTop: 10}} level={5}>
        C2 Employment History in Singapore
      </Title>
      <Row align="middle">
        <Col span="6">
          <Typography className="content-print-font">
            Previous working experience in Singapore*{' '}
          </Typography>
        </Col>
        <Col className="content-print-font">
          {bioData.hasWorkingExperienceInSingapore}
          {/*  <Input
            className="buttonBorderRadius"
            value={bioData.hasWorkingExperienceInSingapore ? 'Yes' : 'No'}
            disabled
          /> */}
        </Col>
      </Row>
      <Title style={{color: '#8893F9', marginTop: 10}} level={5}>
        C3 Feedback from previous employers in Singapore
      </Title>
      <Space direction="vertical" style={{width: '100%'}}>
        <Row align="middle">
          <Col>
            <Typography className="content-print-font">Feedback </Typography>
          </Col>
          <Col
            style={{marginLeft: 10, marginRight: 10}}
            className="content-print-font"
          >
            {/*             <Input
              className="buttonBorderRadius"
              value={bioData.wasFeedbackFromEA ? 'Yes' : 'No'}
              disabled
            /> */}
            {bioData.wasFeedbackFromEA ? 'was yes' : 'was no'}
          </Col>
          <Col>
            <Typography className="content-print-font">
              obtained by the EA from the previous employers.
            </Typography>
          </Col>
        </Row>
        <Row align="middle">
          <Col>
            <Typography className="content-print-font">
              If feedback was obtained (attach testimonial if possible), please
              indicate the feedback in the table below:
            </Typography>
          </Col>
        </Row>
      </Space>
      <Table
        dataSource={employeerFeedbackDataTable}
        pagination={false}
        style={{marginTop: 15}}
        size="small"
      >
        <Table.Column
          title="Employer"
          dataIndex="no"
          key="no"
          render={(no) => (
            <>
              {/*   <Input
              size="large"
              className="buttonBorderRadius"
              value={bioData[`previousEmployer${no}`]}
              disabled
            /> */}
              <Text className="content-print-font">
                {bioData[`previousEmployer${no}`]}
              </Text>
            </>
          )}
        />
        <Table.Column
          title="Feedback"
          dataIndex="no"
          key="no"
          render={(no) => (
            <>
              {/* <Input
              

              size="large"
              className="buttonBorderRadius"
              value={bioData[`previousEmployerFeedback${no}`]}
              disabled
            /> */}
              <Text className="content-print-font">
                {bioData[`previousEmployerFeedback${no}`]}
              </Text>
            </>
          )}
        />
      </Table>
      <Space direction="vertical" style={{width: '100%', marginTop: 20}}>
        <Typography className="content-print-font">
          AVAILABILITY OF MDW TO BE INTERVIEWED BY PROSPECTIVE EMPLOYER
        </Typography>
        <Space direction="vertical" className="content-print-font">
          {bioData.availabilityOfMDWToBeInterviewed}
          {/*  <Input
            className="buttonBorderRadius"
            value={bioData.availabilityOfMDWToBeInterviewed}
            disabled
          /> */}
        </Space>
      </Space>
      <div style={{pageBreakAfter: 'always'}} />
      {/* page 6 */}
      <Space direction="vertical">
        <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

        <Text className="content-print-font">
          *Please ensure that you run through the information within the biodata
          as it is an important document to help you select a suitable MDW
        </Text>
      </Space>
      <Row
        justify="space-between"
        align="middle"
        style={{
          width: '50%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
          marginTop: 20,
        }}
      >
        <Col span={10}>
          <Typography className="content-print-font">Other remarks:</Typography>
        </Col>
        <Col span={14} className="content-print-font">
          {bioData.otherRemarksC3}
          {/*  <Input.TextArea
            style={{width: 300}}
            type="text"
            size="large"
            className="buttonBorderRadius"
            multiple={true}
            maxLength={100}
            value={bioData.otherRemarksC3}
            disabled
          /> */}
        </Col>
      </Row>
      <Row justify="space-around" style={{paddingTop: 110}}>
        <Col>
          <Space direction="vertical" align="center">
            <Divider className="no-margin" style={{width: 200}} />
            <Text strong className="content-print-font">
              MDW Name and Signature
            </Text>
            <Text strong className="content-print-font">
              Date:
            </Text>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center">
            {/* <Text className="content-print-font">
              EA Personnel Name{`     `}Registration Number
            </Text> */}
            <Divider className="no-margin" style={{width: 200}} />
            <Text strong className="content-print-font">
              EA Personnel Name and Registration Number
            </Text>
            <Text strong className="content-print-font">
              Date:
            </Text>
          </Space>
        </Col>
      </Row>
      <Row justify="center" align="center" style={{marginTop: 110}}>
        <Col className="content-print-font">
          I have gone through the 4 page biodata of this FDW and confirm that I
          would like to employ her
        </Col>
      </Row>
      <Row justify="center" style={{marginTop: 110}}>
        <Space direction="vertical" align="center">
          <Divider className="no-margin" style={{width: 200}} />
          <Text strong className="content-print-font">
            Employer Name and NRIC No.
          </Text>
          <Text strong className="content-print-font">
            Date:
          </Text>
        </Space>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
          marginTop: 80,
        }}
      >
        <Col span={3}>
          <Typography className="content-print-font">Note:</Typography>
        </Col>
        <Col span={21}>
          <ul>
            <li className="content-print-font">
              Do consider asking for an MDW who is able to communicate in a
              language you require, and interview her (in
              person/phone/videoconference) to ensure that she can communicate
              adequately.
            </li>
            <li className="content-print-font">
              Do consider requesting for an MDW who has a proven ability to
              perform the chores you require, for example, performing household
              chores (especially if she is required to hang laundry from a
              high-rise unit), cooking and caring for young children or the
              elderly.
            </li>
            <li className="content-print-font">
              Do work together with the EA to ensure that a suitable MDW is
              matched to you according to your needs and requirements. You may
              wish to pay special attention to your prospective MDW’s employment
              history and feedback from the MDW’s previous employer(s) before
              employing her.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  )
})

function BioForm(props) {
  const {match} = props
  const [bioData, setBioData] = React.useState('')
  const [user, setUser] = React.useState('')

  const componentRef = React.useRef()
  const history = useHistory()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    /* ,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {})
    },
    onAfterPrint: () => true, */
  })

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
  }

  const _getMigrant = async () => {
    try {
      //GET BIO DETAIL
      const __getMigrant = await API.graphql({
        query: getMigrant,
        variables: {
          id: match.params.id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBioData(__getMigrant.data.getMigrant)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    const init = async () => {
      try {
        //GET CURRENT LOGIN
        const _listUsers2 = await API.graphql({
          query: listUsers,
          variables: {
            filter: {
              email: {
                eq: getSignedInUserDetails().UserAttributes.find(
                  (userAttribute) => userAttribute.Name === 'email',
                ).Value,
              },
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })

        setUser(_listUsers2.data.listUsers.items[0])
      } catch (error) {
        return notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }

    init()

    _getMigrant()
  }, [])

  if (!bioData) {
    return true
  }

  return (
    <Wrapper {...{user}}>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            padding: 15,
            marginLeft: '6%',
            backgroundColor: '#FFF',
          }}
        >
          <Space direction="vertical" size={30}>
            <Text style={{fontSize: 37, color: '#707070'}}>MDW Profile</Text>
            <Space direction="vertical" style={{width: '100%'}}>
              <Row justify="space-between">
                <Col span={12}>
                  <table
                    style={{width: '100%', borderColor: '#707070'}}
                    className="plain"
                    cellPadding={10}
                  >
                    <tr className="plain">
                      <td className="plain">Name</td>
                      <td className="plain" align="right">
                        {bioData.name}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Date of Birth</td>
                      <td className="plain" align="right">
                        {bioData.dateOfBirth} / Age:{' '}
                        {moment().diff(bioData.dateOfBirth, 'years')}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Place of birth</td>
                      <td className="plain" align="right">
                        {bioData.placeOfBirth}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Height & Weight</td>
                      <td className="plain" align="right">
                        {bioData.height}/{bioData.weight}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Nationality</td>
                      <td className="plain" align="right">
                        {capitalize(bioData.nationality)}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">
                        Residential address in home country
                      </td>
                      <td className="plain" align="right">
                        {bioData.residentialAddress}
                      </td>
                    </tr>
                    {bioData.nationality === 'INDONESIA' && (
                      <tr className="plain">
                        <td className="plain">Region</td>
                        <td className="plain" align="right">
                          {convertRegion(bioData.region)}
                        </td>
                      </tr>
                    )}
                    <tr className="plain">
                      <td className="plain">National ID</td>
                      <td className="plain" align="right">
                        {bioData.nationalID}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Passport Number</td>
                      <td className="plain" align="right">
                        {bioData.passportNumber}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Salary Range</td>
                      <td className="plain" align="right">
                        {bioData.salaryRange === 'RANGE_1'
                          ? '400 - 500 SGD'
                          : bioData.salaryRange === 'RANGE_2'
                          ? '501 - 600 SGD'
                          : '601 - Above'}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">
                        Name of port / airport to be repatriated to
                      </td>
                      <td className="plain" align="right">
                        {bioData.nameOfPort}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Contact number in home country</td>
                      <td className="plain" align="right">
                        {bioData.contactNumber}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Next of Kin</td>
                      <td className="plain" align="right">
                        {bioData.nextOfKin}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Phone Number (Next of Kin)</td>
                      <td className="plain" align="right">
                        {bioData.nextOfKinContactNumber}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Religion</td>
                      <td className="plain" align="right">
                        {convertReligion(bioData.religion)}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Education level</td>
                      <td className="plain" align="right">
                        {convertEducationLevel(bioData.educationLevel)}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Number of Siblings</td>
                      <td className="plain" align="right">
                        {bioData.numberOfSiblings}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Marital status</td>
                      <td className="plain" align="right">
                        {convertMaritalStatus(bioData.maritalStatus)}
                      </td>
                    </tr>
                    <tr className="plain">
                      <td className="plain">Number of Children</td>
                      <td className="plain" align="right">
                        {bioData.childrenNumber}
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Space direction="vertical">
                    <RenderPicture
                      s3Key={bioData.photoKey}
                      type="PHOTO"
                      needRefetch={false}
                    />
                    {(function () {
                      let additionalPhoto = JSON.parse(bioData.additionalPhoto)
                      return (
                        <Slider {...sliderSettings} style={{width: 250}}>
                          {additionalPhoto.map((item) => (
                            <MultipleRenderWrapper
                              item={item}
                              type="ADDITIONAL_PHOTO"
                              data={additionalPhoto}
                            />
                          ))}
                        </Slider>
                      )
                    })()}
                    {/*  <Row gutter={4} align="middle">
                      <Col>
                        <div
                          style={{
                            border: '1px solid #000',
                            borderRadius: 5,
                            height: 120,
                            width: 90,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Text>Additional 1</Text>
                        </div>
                      </Col>
                     
                    </Row> */}
                  </Space>
                </Col>
              </Row>
            </Space>

            <Title level={5} style={{marginTop: 74}}>
              Price Code
            </Title>
            <Space
              direction="horizontal"
              style={{justifyContent: 'space-between', width: '90%'}}
            >
              <Space direction="vertical">
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Country Code</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Country Code"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      disabled
                      readOnly
                      value={bioData?.priceCode?.split(',')[0]}
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Supplier Price Code</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Supplier Price Code"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      disabled
                      readOnly
                      value={bioData?.priceCode?.split(',')[1]}
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>MDW Deployment Country</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="MDW Deployment Country"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      disabled
                      readOnly
                      value={bioData?.priceCode?.split(',')[2]}
                    />
                  </Col>
                </Row>
              </Space>
              <Space direction="vertical">
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Supplier Name</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Supplier Name"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      disabled
                      readOnly
                      value={bioData.branchSupplier?.company?.companyName}
                    />
                  </Col>
                </Row>

                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>EC / No EC</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="EC / No EC"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      disabled
                      readOnly
                      value={bioData.isEC ? 'EC' : 'No EC'}
                    />
                  </Col>
                </Row>
              </Space>
            </Space>
            <Tabs defaultActiveKey="1" style={{marginTop: 108}}>
              <TabPane tab="Medical History" key="1">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Typography style={{color: '#8893F9'}}>
                      Past and existing illnesses (including chronic ailments
                      and illnesses requiring medication)
                    </Typography>
                  </Col>
                </Row>
                <table
                  style={{
                    width: '100%',
                    borderColor: '#707070',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  className="plain"
                  cellPadding={10}
                >
                  <tr className="plain">
                    <th className="plain">Illness</th>
                    <th className="plain">Yes/No</th>
                    <th className="plain">Illness</th>
                    <th className="plain">Yes/No</th>
                  </tr>
                  <tr className="plain">
                    <td className="plain">Mental illness</td>
                    <td className="plain">{bioData.illness ? 'Yes' : 'No'}</td>
                    <td className="plain">Tuberculosis</td>
                    <td className="plain">
                      {bioData.tuberculosis ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr className="plain">
                    <td className="plain">Epilepsy</td>
                    <td className="plain">{bioData.epilepsy ? 'Yes' : 'No'}</td>
                    <td className="plain">Heart disease</td>
                    <td className="plain">
                      {bioData.heart_disease ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr className="plain">
                    <td className="plain">Asthma</td>
                    <td className="plain">{bioData.asthma ? 'Yes' : 'No'}</td>
                    <td className="plain">Malaria</td>
                    <td className="plain">{bioData.malaria ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr className="plain">
                    <td className="plain">Diabetes</td>
                    <td className="plain">{bioData.diabetes ? 'Yes' : 'No'}</td>
                    <td className="plain">Operations</td>
                    <td className="plain">
                      {bioData.operations ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr className="plain">
                    <td className="plain">Hypertension</td>
                    <td className="plain">
                      {bioData.hypertension ? 'Yes' : 'No'}
                    </td>
                    <td className="plain">Operations</td>
                    <td className="plain">
                      {bioData.operations ? 'Yes' : 'No'}
                    </td>
                  </tr>
                </table>
                <Space
                  direction="horizontal"
                  align="start"
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 40,
                  }}
                >
                  <Space direction="vertical">
                    <Row align="middle">
                      <Col span={7} style={{paddingRight: 19}}>
                        <Typography>Others </Typography>
                      </Col>
                      <Col span={17}>
                        <Input.TextArea
                          style={{width: 300}}
                          size="large"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={100}
                          disabled
                          readOnly
                          value={bioData.others}
                        />
                      </Col>
                    </Row>
                    {/*  <Row align="middle">
                      <Col span={7}>
                        <Typography>Physical disabilities</Typography>
                      </Col>
                      <Col span={17}>
                        <Input.TextArea
                          style={{width: 300}}
                          size="large"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={100}
                          disabled
                          readOnly
                          value={bioData.physicalDisabilities}
                        />
                      </Col>
                    </Row> */}
                    <Row
                      style={{width: '100%', marginTop: 38, marginBottom: 38}}
                    >
                      <Col span={7} style={{paddingRight: 19}}>
                        <Typography>Dietary Restriction</Typography>
                      </Col>
                      <Col span={17}>
                        <Input.TextArea
                          style={{width: 300}}
                          size="large"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={100}
                          disabled
                          readOnly
                          value={bioData.dietaryRestriction}
                        />
                      </Col>
                    </Row>
                  </Space>
                  <Space direction="vertical">
                    <Row justify="space-between" align="middle">
                      <Col span={7} style={{paddingRight: 19}}>
                        <Typography>Physical Disabilities </Typography>
                      </Col>
                      <Col span={17}>
                        <Input.TextArea
                          style={{width: 300}}
                          size="large"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={100}
                          disabled
                          readOnly
                          value={bioData.physicalDisabilities}
                        />
                      </Col>
                    </Row>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{
                        marginTop: 38,
                        marginBottom: 38,
                        paddingRight: 19,
                      }}
                    >
                      <Col span={7}>
                        <Typography>Allergies (if any) </Typography>
                      </Col>
                      <Col span={17}>
                        <Input.TextArea
                          style={{width: 300}}
                          size="large"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={100}
                          disabled
                          readOnly
                          value={bioData.allergies}
                        />
                      </Col>
                    </Row>
                  </Space>
                </Space>
                {/*  <Row justify="space-between">
                  <Row align="middle">
                    <Col span={7}>
                      <Typography>Others </Typography>
                    </Col>
                    <Col span={17}>
                      <Input.TextArea
                        style={{width: 300}}
                        placeholder="Please type..."
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                      />
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Col span={7}>
                      <Typography>Physical Disabilities </Typography>
                    </Col>
                    <Col span={17}>
                      <Input.TextArea
                        style={{width: 300}}
                        placeholder="Physical disabilities..."
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                      />
                    </Col>
                  </Row>
                </Row>
                <Row justify="space-between">
                  <Row align="middle">
                    <Col span={7}>
                      <Typography>Physical disabilities</Typography>
                    </Col>
                    <Col span={17}>
                      <Input.TextArea
                        style={{width: 300}}
                        placeholder="Please type..."
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                      />
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Col span={7}>
                      <Typography>Allergies (if any) </Typography>
                    </Col>
                    <Col span={17}>
                      <Input.TextArea
                        style={{width: 300}}
                        placeholder="Physical disabilities..."
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                      />
                    </Col>
                  </Row>
                </Row>

                <Row style={{width: '100%'}}>
                  <Col span={2}>
                    <Typography>Dietary Restriction</Typography>
                  </Col>
                  <Col span={22}>
                    <Input.TextArea
                      style={{width: 300}}
                      placeholder="Please type..."
                      size="large"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={100}
                    />
                  </Col>
                </Row> */}

                <Row align="middle" style={{marginTop: 20}}>
                  <Col span={5}>
                    <Typography>Food handling preferences:</Typography>
                  </Col>
                  <Col>
                    <Radio.Group
                      defaultValue={bioData.foodHandlingPreferences}
                      disabled
                    >
                      <Radio value={'No Pork'}>No Pork</Radio>
                      <Radio value={'No Beef'}>No Beef</Radio>
                      <Radio value={'Others'}>
                        <Row align="middle" gutter={5}>
                          <Col>Others</Col>
                          <Col>
                            {' '}
                            <Input.TextArea
                              type="text"
                              size="middle"
                              className="buttonBorderRadius"
                              multiple={true}
                              maxLength={200}
                              style={{width: 300}}
                              disabled
                              readOnly
                              value={bioData.foodHandlingPreferencesOthers}
                            />
                          </Col>
                        </Row>
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Skill of MDW" key="2">
                {/* Table */}
                <>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      backgroundColor: '#D8E2FF',
                      padding: 10,
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                    }}
                  >
                    <Col span={4}>
                      <Row>
                        <Text align="center">S/N.</Text>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Text align="center">Areas of Work</Text>
                      </Row>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Willingness</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            (Yes/No)
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Experience</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            (Yes/No)
                          </Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            If yes, state the no. of years
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Assessment/Observation</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            Please state qualitative observations of MDW and/or
                            rate the MDW (indicate N.A. of no evaluation was
                            done) Poor ……………………Excellent...N.A
                          </Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            1 2 3 4 5 N.A
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 15,
                    }}
                  >
                    <Col span={4}>
                      <Text>1</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Care of infants/children</Text>
                        <Text>{bioData.careOfInfantsDetailA2}</Text>
                        {/* <Text>Please specify age range:</Text>
                        <Input
                          style={{width: 150}}
                          placeholder="0-00"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/*                       <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.careOfInfantsWillingnessA2 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}

                      {bioData.careOfInfantsHasExperienceYearA2 ? 'Yes' : 'No'}
                      {bioData.careOfInfantsExperienceYearA2 &&
                        `, ${bioData.careOfInfantsExperienceYearA2}`}
                    </Col>
                    <Col
                      span={5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {bioData.careOfInfantsAssesmentA2}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 15,
                    }}
                  >
                    <Col span={4}>
                      <Text>2</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Care of elderly</Text>
                        <Text className="content-print-font">
                          {bioData.careOfElderlyDetailA2}
                        </Text>
                        {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.careOfElderlyWillingnessA2 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.careOfElderlyHasExperienceYearA2 ? 'Yes' : 'No'}
                      {bioData.careOfElderlyExperienceYearA2 &&
                        `, ${bioData.careOfElderlyExperienceYearA2}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {bioData.careOfElderlyAssesmentA2}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 15,
                    }}
                  >
                    <Col span={4}>
                      <Text>3</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Care of disabled</Text>
                        <Text className="content-print-font">
                          {bioData.careOfDisabledDetailA2}
                        </Text>
                        {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.careOfDisabledWillingnessA2 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.careOfDisabledHasExperienceYearA2 ? 'Yes' : 'No'}
                      {bioData.careOfDisabledExperienceYearA2 &&
                        `, ${bioData.careOfDisabledExperienceYearA2}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {bioData.careOfDisabledAssesmentA2}
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>4</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>General housework</Text>
                        <Text>{bioData.generalHouseworkDetailA2}</Text>
                        {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.generalHouseworkWillingnessA2 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.generalHouseworkHasExperienceYearA2
                        ? 'Yes'
                        : 'No'}
                      {bioData.generalHouseworkExperienceYearA2 &&
                        `, ${bioData.generalHouseworkExperienceYearA2}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {bioData.generalHouseworkAssesmentA2}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>5</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Cooking</Text>
                        <Text>{bioData.cookingDetailA2}</Text>
                        {/*  <Text>Please specify cuisines:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.cookingWillingnessA2 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.cookingHasExperienceYearA2 ? 'Yes' : 'No'}
                      {bioData.cookingExperienceYearA2 &&
                        `, ${bioData.cookingExperienceYearA2}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.cookingAssesmentA2}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>6</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Language abilities (spoken)</Text>
                        <Text>{bioData.languageDetailA2}</Text>
                        {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.languageExperienceHasYearA2 ? 'Yes' : 'No'}
                      {bioData.languageExperienceYearA2 !== 'false'
                        ? `, ${bioData.languageExperienceYearA2}`
                        : ''}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.languageAssesmentA2}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>7</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Other skills, if any</Text>
                        <Text>{bioData.otherSkillsDetailA2}</Text>
                        {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={5}>
                      {bioData.otherSkillsHasExperienceYearA2 ? 'Yes' : 'No'}
                      {bioData.otherSkillsExperienceYearA2 &&
                        `, ${bioData.otherSkillsExperienceYearA2}`}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.otherSkillsAssesmentA2}
                    </Col>
                  </Row>
                  <Divider />
                </>
                {/* End of Table */}
                {/* Table */}
                <>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      backgroundColor: '#D8E2FF',
                      padding: 10,
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                    }}
                  >
                    <Col span={4}>
                      <Row>
                        <Text align="center">S/N.</Text>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Core Fundamentals</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            (Assessment and Observation to select candidates
                            based on these strict criteria including IQ and EQ
                            dimensions)
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row align="center">
                        <Col>
                          <Space direction="vertical" align="center">
                            <Text>MDW Competent</Text>
                            <Text type="secondary" style={{fontSize: 12}}>
                              (Yes/No)
                            </Text>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>MDW Need Training</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            (Yes/No)
                          </Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            If yes, state the type of training needed
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Assessment/Observation</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            Please state qualitative observations of MDW and/or
                            rate the MDW (indicate N.A. of no evaluation was
                            done) Poor ……………………Excellent...N.A
                          </Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            1 2 3 4 5 N.A
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>1</Text>
                    </Col>
                    <Col span={20}>
                      <Space direction="vertical">
                        <Text>Core Values:</Text>
                        <Text>
                          MDWS sensitised and trained in
                          <br /> the fundamentals of:
                        </Text>
                      </Space>
                    </Col>
                  </Row>
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text></Text>
                    </Col>
                    <Col span={5}>
                      <Text>a. Hygiene</Text>
                    </Col>
                    <Col
                      span={5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select value={bioData.hygieneCompetent} disabled>
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                      }}
                    >
                      <Select value={bioData.hygieneNeedTraining} disabled>
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                      <Input.TextArea
                        style={{marginLeft: 10, height: 120}}
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        disabled
                        readOnly
                        value={bioData.hygieneTraining}
                      />
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select value={bioData.hygineAssesment} disabled>
                        <Option value={''} disabled></Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="0">N.A</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text></Text>
                    </Col>
                    <Col span={5}>
                      <Text>b. Adaptibility</Text>
                    </Col>
                    <Col
                      span={5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select value={bioData.adaptabilityCompetent} disabled>
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                      }}
                    >
                      <Select value={bioData.adaptabilityNeedTraining} disabled>
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                      <Input.TextArea
                        style={{marginLeft: 10, height: 120}}
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        disabled
                        readOnly
                        value={bioData.adaptabilityTraining}
                      />
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select value={bioData.adaptabilityAssesment} disabled>
                        <Option value={''} disabled></Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="0">N.A</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text></Text>
                    </Col>
                    <Col span={5}>
                      <Text>c. Discipline</Text>
                    </Col>
                    <Col
                      span={5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select value={bioData.disciplineCompetent} disabled>
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                      }}
                    >
                      <Select value={bioData.disciplineNeedTraining} disabled>
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                      <Input.TextArea
                        style={{marginLeft: 10, height: 120}}
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        disabled
                        readOnly
                        value={bioData.disciplineTraining}
                      />
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select value={bioData.disciplineAssesment} disabled>
                        <Option value={''} disabled></Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="0">N.A</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>2</Text>
                    </Col>
                    <Col span={5}>
                      <Space direction="vertical">
                        <Text>Core Skills:</Text>
                        <Text>Refer to Areas of Work for details</Text>
                      </Space>
                    </Col>
                    <Col span={15}>
                      <Input.TextArea
                        style={{width: '100%', marginLeft: 10}}
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        disabled
                        readOnly
                        value={bioData.coreSkills}
                      />
                    </Col>
                  </Row>

                  <Divider />
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>3</Text>
                    </Col>
                    <Col span={20}>
                      <Space direction="vertical">
                        <Text>Core Fitness:</Text>
                        <Text>Emphasis on:</Text>
                      </Space>
                    </Col>
                  </Row>
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text></Text>
                    </Col>
                    <Col span={5}>
                      <Text>a. Mental fitness</Text>
                    </Col>
                    <Col span={5}>
                      <Select
                        value={bioData.coreFitnessMentalCompetent}
                        disabled
                      >
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                      }}
                    >
                      <Select
                        value={bioData.coreFitnessMentalNeedTraining}
                        disabled
                      >
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                      <Input.TextArea
                        style={{marginLeft: 10, height: 120}}
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        disabled
                        readOnly
                        value={bioData.coreFitnessMentalTraining}
                      />
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select
                        value={bioData.coreFitnessMentalAssesment}
                        disabled
                      >
                        <Option value={''} disabled></Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="0">N.A</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row
                    align="top"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text></Text>
                    </Col>
                    <Col span={5}>
                      <Text>b. Physical fitness</Text>
                    </Col>
                    <Col span={5}>
                      <Select
                        value={bioData.coreFitnessPhysicalCompetent}
                        disabled
                      >
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                      }}
                    >
                      <Select
                        value={bioData.coreFitnessPhysicalNeedTraining}
                        disabled
                      >
                        <Option value="" disabled></Option>
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                      <Input.TextArea
                        style={{marginLeft: 10, height: 120}}
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        disabled
                        readOnly
                        value={bioData.coreFitnessPhysicalTraining}
                      />
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Select
                        value={bioData.coreFitnessPhysicalAssesment}
                        disabled
                      >
                        <Option value={''} disabled></Option>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="0">N.A</Option>
                      </Select>
                    </Col>
                  </Row>
                  <Divider />
                </>
                {/* End of Table */}
              </TabPane>
              <TabPane tab="Method of Evaluation of Skills" key="3">
                {/* Table */}
                <>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      backgroundColor: '#D8E2FF',
                      padding: 10,
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                    }}
                  >
                    <Col span={4}>
                      <Row>
                        <Text align="center">S/N.</Text>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Row>
                        <Text align="center">Areas of Work</Text>
                      </Row>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Willingness</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            (Yes/No)
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Experience</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            (Yes/No)
                          </Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            If yes, state the no. of years
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row>
                        <Space direction="vertical" align="center">
                          <Text>Assessment/Observation</Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            Please state qualitative observations of MDW and/or
                            rate the MDW (indicate N.A. of no evaluation was
                            done) Poor ……………………Excellent...N.A
                          </Text>
                          <Text type="secondary" style={{fontSize: 12}}>
                            1 2 3 4 5 N.A
                          </Text>
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 15,
                    }}
                  >
                    <Col span={4}>
                      <Text>1</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Care of infants/children</Text>
                        <Text>{bioData.careOfInfantsDetailB1}</Text>
                        {/* <Text>Please specify age range:</Text>
                        <Input
                          style={{width: 150}}
                          placeholder="0-00"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/*                       <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.careOfInfantsWillingnessB1 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}

                      {bioData.careOfInfantsHasExperienceYearB1 ? 'Yes' : 'No'}
                      {bioData.careOfInfantsExperienceYearB1 &&
                        `, ${bioData.careOfInfantsExperienceYearB1}`}
                    </Col>
                    <Col
                      span={5}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {bioData.careOfInfantsAssesmentB1}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 15,
                    }}
                  >
                    <Col span={4}>
                      <Text>2</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Care of elderly</Text>
                        <Text className="content-print-font">
                          {bioData.careOfElderlyDetailB1}
                        </Text>
                        {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.careOfElderlyWillingnessB1 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.careOfElderlyHasExperienceYearB1 ? 'Yes' : 'No'}
                      {bioData.careOfElderlyExperienceYearB1 &&
                        `, ${bioData.careOfElderlyExperienceYearB1}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {bioData.careOfElderlyAssesmentB1}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 15,
                    }}
                  >
                    <Col span={4}>
                      <Text>3</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Care of disabled</Text>
                        <Text className="content-print-font">
                          {bioData.careOfDisabledDetailB1}
                        </Text>
                        {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.careOfDisabledWillingnessB1 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.careOfDisabledHasExperienceYearB1 ? 'Yes' : 'No'}
                      {bioData.careOfDisabledExperienceYearB1 &&
                        `, ${bioData.careOfDisabledExperienceYearB1}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {bioData.careOfDisabledAssesmentB1}
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>4</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>General housework</Text>
                        <Text>{bioData.generalHouseworkDetailB1}</Text>
                        {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.generalHouseworkWillingnessB1 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.generalHouseworkHasExperienceYearB1
                        ? 'Yes'
                        : 'No'}
                      {bioData.generalHouseworkExperienceYearB1 &&
                        `, ${bioData.generalHouseworkExperienceYearB1}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {bioData.generalHouseworkAssesmentB1}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>5</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Cooking</Text>
                        <Text>{bioData.cookingDetailB1}</Text>
                        {/*  <Text>Please specify cuisines:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col
                      span={4}
                      style={{display: 'flex', justifyContent: 'center'}}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select> */}
                      {bioData.cookingWillingnessB1 ? 'Yes' : 'No'}
                    </Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.cookingHasExperienceYearB1 ? 'Yes' : 'No'}
                      {bioData.cookingExperienceYearB1 &&
                        `, ${bioData.cookingExperienceYearB1}`}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.cookingAssesmentB1}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>6</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Language abilities (spoken)</Text>
                        <Text className="content-print-font">
                          {bioData.languageDetailB1}
                        </Text>
                        {/*  <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={5}>
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.languageExperienceHasYearB1 ? 'Yes' : 'No'}
                      {bioData.languageExperienceYearB1 !== 'false'
                        ? `, ${bioData.languageExperienceYearB1}`
                        : ''}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.languageAssesmentB1}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    align="middle"
                    justify="space-between"
                    style={{
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      <Text>7</Text>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text>Other skills, if any</Text>
                        <Text>{bioData.otherSkillsDetailB1}</Text>
                        {/* <Text>Please specify:</Text>
                        <Input.TextArea
                          style={{height: 120, width: 275}}
                          placeholder="Note..."
                          type="text"
                          size="middle"
                          className="buttonBorderRadius"
                          multiple={true}
                          maxLength={200}
                        /> */}
                      </Space>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={5}>
                      {bioData.otherSkillsHasExperienceYearB1 ? 'Yes' : 'No'}
                      {bioData.otherSkillsExperienceYearB1 &&
                        `, ${bioData.otherSkillsExperienceYearB1}`}
                      {/*  <Select defaultValue="default">
                        <Option value="default" disabled>
                          Yes
                        </Option>
                        <Option value="no">No</Option>
                      </Select>
                      <Select defaultValue="default" style={{marginLeft: 15}}>
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                    </Col>
                    <Col
                      span={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Select defaultValue="default">
                        <Option value="default" disabled>
                          1
                        </Option>
                        <Option value="2">2</Option>
                      </Select> */}
                      {bioData.otherSkillsAssesmentB1}
                    </Col>
                  </Row>
                  <Divider />
                </>
                {/* End of Table */}
              </TabPane>
              <TabPane tab="Employment History Overseas" key="4">
                <Space direction="vertical" style={{width: '100%'}}>
                  <Table
                    dataSource={[
                      {
                        no: '1',
                        dateFrom: '',
                        dateTo: '',
                        country: '',
                        employer: '',
                        workDuties: '',
                        remarks: '',
                      },
                      {
                        no: '2',
                        dateFrom: '',
                        dateTo: '',
                        country: '',
                        employer: '',
                        workDuties: '',
                        remarks: '',
                      },
                      {
                        no: '3',
                        dateFrom: '',
                        dateTo: '',
                        country: '',
                        employer: '',
                        workDuties: '',
                        remarks: '',
                      },
                      {
                        no: '4',
                        dateFrom: '',
                        dateTo: '',
                        country: '',
                        employer: '',
                        workDuties: '',
                        remarks: '',
                      },
                      {
                        no: '5',
                        dateFrom: '',
                        dateTo: '',
                        country: '',
                        employer: '',
                        workDuties: '',
                        remarks: '',
                      },
                    ]}
                    pagination={false}
                  >
                    <Table.ColumnGroup title="Date">
                      <Table.Column
                        title="From"
                        dataIndex="no"
                        key="no"
                        render={(no) => (
                          <>
                            <Text>
                              {
                                bioData[
                                  `employmentHistoryOverseasDateFrom${no}`
                                ]
                              }
                            </Text>
                          </>
                        )}
                      />
                      <Table.Column
                        title="To"
                        dataIndex="no"
                        key="no"
                        render={(no) => (
                          <>
                            <Text>
                              {bioData[`employmentHistoryOverseasDateTo${no}`]}
                            </Text>
                          </>
                        )}
                      />
                    </Table.ColumnGroup>
                    <Table.Column
                      title="Country (including
MDW’s home country)"
                      dataIndex="no"
                      key="no"
                      render={(no) => (
                        <>
                          <Text>
                            {bioData[`employmentHistoryCountry${no}`]}
                          </Text>
                        </>
                      )}
                    />
                    <Table.Column
                      title="Employer"
                      dataIndex="no"
                      key="no"
                      render={(no) => (
                        <>
                          <Text>
                            {bioData[`employmentHistoryEmployer${no}`]}
                          </Text>
                        </>
                      )}
                    />
                    <Table.Column
                      title="Work Duties"
                      dataIndex="no"
                      key="no"
                      render={(no) => (
                        <>
                          <Text>
                            {bioData[`employmentHistoryWorkDuties${no}`]}
                          </Text>
                        </>
                      )}
                    />
                    <Table.Column
                      title="Remarks"
                      dataIndex="no"
                      key="no"
                      render={(no) => (
                        <>
                          <Text>
                            {bioData[`employmentHistoryWorkDutiesRemarks${no}`]}
                          </Text>
                        </>
                      )}
                    />
                  </Table>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      marginTop: 30,
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Col span={12}>
                      <Typography>
                        Interviewed by overseas training centre / EA (Please
                        state name of foreign training centre / EA)
                      </Typography>
                    </Col>
                    <Col span={12}>
                      <Input.TextArea
                        style={{width: 300}}
                        value={bioData.nameOfForeignTrainingCentre}
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Row>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Col span={12}>
                      <Typography>
                        State if the third party is certified (e.g. ISO9001) or
                        audited periodically by the EA:
                      </Typography>
                    </Col>
                    <Col span={12}>
                      <Input.TextArea
                        style={{width: 300}}
                        value={bioData.stateIfThirdPartyIsCertifiedSpecify}
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Radio.Group
                        value={bioData.stateIfThirdPartyIsCertified}
                        disabled
                      >
                        <Space direction="vertical">
                          <Radio value={'INTERVIEWED_VIA_TELEPHONE'}>
                            Interviewed via telephone/teleconference
                          </Radio>
                          <Radio value={'INTERVIEWED_VIA_VIDEO_CONFERENCE'}>
                            Interviewed via video conference
                          </Radio>
                          <Radio value={'INTERVIEWED_IN_PERSON'}>
                            Interviewed in person
                          </Radio>
                          <Radio
                            value={'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION'}
                          >
                            Interviewed in person and also made observation of
                            MDW in the areas of work listed in table
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Space>
                <Space
                  direction="vertical"
                  style={{width: '100%', marginTop: 35}}
                >
                  <Row align="middle">
                    <Col span="7">
                      <Typography>
                        Previous working experience in Singapore:{' '}
                      </Typography>
                    </Col>
                    <Col>
                      {bioData.hasWorkingExperienceInSingapore ? 'Yes' : 'No'}
                    </Col>
                  </Row>
                </Space>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    marginTop: 20,
                  }}
                >
                  <Col span={10}>
                    <Typography>Other remarks:</Typography>
                  </Col>
                  <Col span={14}>
                    <Input.TextArea
                      style={{width: 300}}
                      value={bioData.otherRemarksC3}
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={100}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            <Row
              justify="space-between"
              align="middle"
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                marginTop: 20,
              }}
            >
              <Col span={3}>
                <Typography>Note:</Typography>
              </Col>
              <Col span={21}>
                <ol type="a">
                  <li>
                    EA to ensure MDW meet MOM requirements for age, medical
                    fitness etc before selection and deployment.
                  </li>
                  <li>
                    EA to ensure deployment and management of MDW is in
                    accordance to requirements by MOM.
                  </li>
                  <li>
                    EA is responsible for accuracy and completeness of data
                    provided at all times to employers and suppliers.
                  </li>
                  <li>
                    EA is to provide honest and fair comments of their MDW to
                    EAs and employers .
                  </li>
                  <li>
                    EA is responsible for the choice of MDW selected for
                    deployment with their emplopyers.
                  </li>
                  <li>
                    EA to ensure they comply with the requirements of PDPA in
                    the handling of all data and biodata obtained from OUR SG.
                  </li>
                  <li>
                    EA must follow the deduction plan as specified in the price
                    sheet without additional amount to be placed on the MDW.
                  </li>
                </ol>
              </Col>
            </Row>
            <div style={{display: 'none'}}>
              <ComponentToPrint
                ref={componentRef}
                {...{bioData}}
                {...{sliderSettings}}
              />
            </div>

            <Space
              align="center"
              direction="vertical"
              style={{width: '100%', marginTop: 40}}
              size={10}
            >
              <Space direction="horizontal">
                <>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#8893F9',
                      borderColor: 'transparent',
                      width: 150,
                    }}
                    onClick={() => {
                      // handlePrint()
                      history.push(`/print/bio/${match.params.id}`)
                    }}
                  >
                    Print PDF
                  </Button>
                </>
              </Space>
            </Space>
          </Space>
        </Layout>
      </Content>
    </Wrapper>
  )
}

export default BioForm
