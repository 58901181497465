/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsers2 = /* GraphQL */ `
  query ListUsers2 {
    listUsers2 {
      id
      email
      phoneNo
      fullName
      userType
      branchID
      branch {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      staffCode
      registrationNo
      designation
      deleted
      deletedOn
      role
      status
      gender
      createdAt
      updatedAt
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      phoneNo
      fullName
      userType
      branchID
      branch {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        # type
        deleted
        deletedOn
        createdAt
        updatedAt
      }
      staffCode
      registrationNo
      designation
      deleted
      deletedOn
      role
      status
      gender
      profilePictureKey
      createdAt
      updatedAt
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        phoneNo
        mobileNo
        fullName
        userType
        branchID
        branch {
          isMainBranch
          address
          code
          company {
            id
            companyName
          }
        }
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        profilePictureKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      userID
      user {
        id
        email
        phoneNo
        fullName
        userType
        branchID
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        createdAt
        updatedAt
        # owner
      }
      companyName
      licenseNo
      licenseName
      type

      createdAt
      updatedAt
      # owner
    }
  }
`
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        companyName
        licenseNo
        licenseName
        type
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`
export const getBranch = /* GraphQL */ `
  query GetBranch($id: ID!) {
    getBranch(id: $id) {
      id
      companyID
      company {
        id
        userID
        user {
          email
          fullName
          phoneNo
          mobileNo
          registrationNo
        }
        companyName
        licenseNo
        licenseName
        # type
        createdAt
        updatedAt
        # owner
      }
      branchName
      code
      status
      phone
      address
      remarks
      isMainBranch
      type
      deleted
      deletedOn
      createdAt
      updatedAt
      # owner
    }
  }
`
export const getBranchWtType = /* GraphQL */ `
  query GetBranch($id: ID!) {
    getBranch(id: $id) {
      id
      companyID
      company {
        id
        userID
        user {
          email
          fullName
          phoneNo
          mobileNo
          registrationNo
        }
        companyName
        licenseNo
        licenseName
        # type
        createdAt
        updatedAt
        # owner
      }
      branchName
      code
      status
      phone
      address
      remarks
      isMainBranch
      # type
      deleted
      deletedOn
      createdAt
      updatedAt
      # owner
    }
  }
`
export const listBranches = /* GraphQL */ `
  query ListBranches(
    $id: ID
    $filter: ModelBranchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBranches(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        companyID
        branchName
        code
        status
        company {
          companyName
          user {
            id
            email
          }
        }
        phone
        address
        remarks
        isMainBranch
        # type
        deleted
        deletedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getStatistics = /* GraphQL */ `
  query GetStatistics($id: ID!) {
    getStatistics(id: $id) {
      id
      outProNumber
      totalConfirmedNumber
      totalReservedNumber
      createdAt
      updatedAt
      # owner
    }
  }
`
export const listStatistics = /* GraphQL */ `
  query ListStatistics(
    $id: ID
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStatistics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        outProNumber
        totalConfirmedNumber
        totalReservedNumber
        createdAt
        updatedAt
        # owner
      }
      nextToken
    }
  }
`
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`
export const listStaff = /* GraphQL */ `
  query ListStaff(
    $id: ID
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaff(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getMigrant = /* GraphQL */ `
  query GetMigrant($id: ID!) {
    getMigrant(id: $id) {
      id
      reservedBy
      branchEA {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      suppliedBy
      branchSupplier {
        id
        companyID
        company {
          companyName
        }
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      educationLevel
      salaryRange
      maritalStatus
      religion
      nationality
      status
      supplierCode
      name
      age
      dateOfBirth
      placeOfBirth
      height
      weight
      residentialAddress
      region
      nationalID
      passportNumber
      nameOfPort
      contactNumber
      additionalContactNumber
      nextOfKin
      nextOfKinContactNumber
      numberOfSiblings
      languages
      experience
      childrenNumber
      agesOfChildren
      photoKey
      additionalPhoto
      additionalVideoKey
      supportingDocuments
      allergies
      illness
      epilepsy
      asthma
      diabetes
      hypertension
      tuberculosis
      heart_disease
      malaria
      operations
      others
      physicalDisabilities
      dietaryRestriction
      foodHandlingPreferences
      foodHandlingPreferencesOthers
      restDaysPerMonth
      otherRemarks
      otherRemarksC3
      inactiveMessage
      methodOfEvaluationSkills
      nameOfForeignTrainingCentre
      stateIfThirdPartyIsCertified
      stateIfThirdPartyIsCertifiedSpecify
      interviewedByOverseasTrainingCentre
      employmentHistoryOverseasDateFrom1
      employmentHistoryOverseasDateTo1
      employmentHistoryCountry1
      employmentHistoryEmployer1
      employmentHistoryWorkDuties1
      employmentHistoryWorkDutiesRemarks1
      employmentHistoryOverseasDateFrom2
      employmentHistoryOverseasDateTo2
      employmentHistoryCountry2
      employmentHistoryEmployer2
      employmentHistoryWorkDuties2
      employmentHistoryWorkDutiesRemarks2
      employmentHistoryOverseasDateFrom3
      employmentHistoryOverseasDateTo3
      employmentHistoryCountry3
      employmentHistoryEmployer3
      employmentHistoryWorkDuties3
      employmentHistoryWorkDutiesRemarks3
      employmentHistoryOverseasDateFrom4
      employmentHistoryOverseasDateTo4
      employmentHistoryCountry4
      employmentHistoryEmployer4
      employmentHistoryWorkDuties4
      employmentHistoryWorkDutiesRemarks4
      employmentHistoryOverseasDateFrom5
      employmentHistoryOverseasDateTo5
      employmentHistoryCountry5
      employmentHistoryEmployer5
      employmentHistoryWorkDuties5
      employmentHistoryWorkDutiesRemarks5
      careOfInfantsDetailA2
      careOfInfantsWillingnessA2
      careOfInfantsHasExperienceYearA2
      careOfInfantsExperienceYearA2
      careOfInfantsAssesmentA2
      careOfElderlyDetailA2
      careOfElderlyWillingnessA2
      careOfElderlyHasExperienceYearA2
      careOfElderlyExperienceYearA2
      careOfElderlyAssesmentA2
      careOfDisabledDetailA2
      careOfDisabledWillingnessA2
      careOfDisabledHasExperienceYearA2
      careOfDisabledExperienceYearA2
      careOfDisabledAssesmentA2
      generalHouseworkDetailA2
      generalHouseworkWillingnessA2
      generalHouseworkHasExperienceYearA2
      generalHouseworkExperienceYearA2
      generalHouseworkAssesmentA2
      cookingDetailA2
      cookingWillingnessA2
      cookingHasExperienceYearA2
      cookingExperienceYearA2
      cookingAssesmentA2
      languageDetailA2
      languageExperienceHasYearA2
      languageExperienceYearA2
      languageAssesmentA2
      otherSkillsDetailA2
      otherSkillsHasExperienceYearA2
      otherSkillsExperienceYearA2
      otherSkillsAssesmentA2
      careOfInfantsDetailB1
      careOfInfantsWillingnessB1
      careOfInfantsHasExperienceYearB1
      careOfInfantsExperienceYearB1
      careOfInfantsAssesmentB1
      careOfElderlyDetailB1
      careOfElderlyWillingnessB1
      careOfElderlyHasExperienceYearB1
      careOfElderlyExperienceYearB1
      careOfElderlyAssesmentB1
      careOfDisabledDetailB1
      careOfDisabledWillingnessB1
      careOfDisabledHasExperienceYearB1
      careOfDisabledExperienceYearB1
      careOfDisabledAssesmentB1
      generalHouseworkDetailB1
      generalHouseworkWillingnessB1
      generalHouseworkHasExperienceYearB1
      generalHouseworkExperienceYearB1
      generalHouseworkAssesmentB1
      cookingDetailB1
      cookingWillingnessB1
      cookingHasExperienceYearB1
      cookingExperienceYearB1
      cookingAssesmentB1
      languageDetailB1
      languageExperienceHasYearB1
      languageExperienceYearB1
      languageAssesmentB1
      otherSkillsDetailB1
      otherSkillsHasExperienceYearB1
      otherSkillsExperienceYearB1
      otherSkillsAssesmentB1
      hygieneCompetent
      hygieneNeedTraining
      hygieneTraining
      hygineAssesment
      adaptabilityCompetent
      adaptabilityNeedTraining
      adaptabilityTraining
      adaptabilityAssesment
      disciplineCompetent
      disciplineNeedTraining
      disciplineTraining
      disciplineAssesment
      coreSkills
      coreFitnessMentalCompetent
      coreFitnessMentalNeedTraining
      coreFitnessMentalTraining
      coreFitnessMentalAssesment
      coreFitnessPhysicalCompetent
      coreFitnessPhysicalNeedTraining
      coreFitnessPhysicalTraining
      coreFitnessPhysicalAssesment
      mentalillnessCompetent
      mentalillnessTraining
      mentalillnessAssesment
      physicalFitnessCompetent
      physicalFitnessTraining
      physicalFitnessAssesment
      certifiedOrAudityDescription
      certifiedOrAudityInterviewMethod
      hasWorkingExperienceInSingapore
      previousEmployer1
      previousEmployerFeedback1
      previousEmployer2
      previousEmployerFeedback2
      previousEmployer3
      previousEmployerFeedback3
      availabilityOfMDWToBeInterviewed
      wasFeedbackFromEA
      availablityToInterviewDetail
      isInActive
      priceCode
      priceCodeMDWDeploymentCountry
      referenceCode
      outProOn
      createdOn
      deletedOn
      updatedOn
      deleted
      createdAt
      updatedAt
      # owner
      inInactiveByUser {
        branch {
          branchName
          company {
            companyName
          }
        }
      }
    }
  }
`
export const getMigrantSuperAdmin = /* GraphQL */ `
  query GetMigrant($id: ID!) {
    getMigrant(id: $id) {
      id
      confirmedBy
      reservedBy
      branchEA {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      suppliedBy
      branchSupplier {
        id
        companyID
        company {
          companyName
        }
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      educationLevel
      salaryRange
      maritalStatus
      religion
      nationality
      status
      supplierCode
      name
      age
      dateOfBirth
      placeOfBirth
      height
      weight
      residentialAddress
      region
      nationalID
      passportNumber
      nameOfPort
      contactNumber
      additionalContactNumber
      nextOfKin
      nextOfKinContactNumber
      numberOfSiblings
      languages
      experience
      childrenNumber
      agesOfChildren
      photoKey
      additionalPhoto
      additionalVideoKey
      supportingDocuments
      allergies
      illness
      epilepsy
      asthma
      diabetes
      hypertension
      tuberculosis
      heart_disease
      malaria
      operations
      others
      physicalDisabilities
      dietaryRestriction
      foodHandlingPreferences
      foodHandlingPreferencesOthers
      restDaysPerMonth
      otherRemarks
      otherRemarksC3
      inactiveMessage
      methodOfEvaluationSkills
      nameOfForeignTrainingCentre
      stateIfThirdPartyIsCertified
      stateIfThirdPartyIsCertifiedSpecify
      interviewedByOverseasTrainingCentre
      employmentHistoryOverseasDateFrom1
      employmentHistoryOverseasDateTo1
      employmentHistoryCountry1
      employmentHistoryEmployer1
      employmentHistoryWorkDuties1
      employmentHistoryWorkDutiesRemarks1
      employmentHistoryOverseasDateFrom2
      employmentHistoryOverseasDateTo2
      employmentHistoryCountry2
      employmentHistoryEmployer2
      employmentHistoryWorkDuties2
      employmentHistoryWorkDutiesRemarks2
      employmentHistoryOverseasDateFrom3
      employmentHistoryOverseasDateTo3
      employmentHistoryCountry3
      employmentHistoryEmployer3
      employmentHistoryWorkDuties3
      employmentHistoryWorkDutiesRemarks3
      employmentHistoryOverseasDateFrom4
      employmentHistoryOverseasDateTo4
      employmentHistoryCountry4
      employmentHistoryEmployer4
      employmentHistoryWorkDuties4
      employmentHistoryWorkDutiesRemarks4
      employmentHistoryOverseasDateFrom5
      employmentHistoryOverseasDateTo5
      employmentHistoryCountry5
      employmentHistoryEmployer5
      employmentHistoryWorkDuties5
      employmentHistoryWorkDutiesRemarks5
      careOfInfantsDetailA2
      careOfInfantsWillingnessA2
      careOfInfantsHasExperienceYearA2
      careOfInfantsExperienceYearA2
      careOfInfantsAssesmentA2
      careOfElderlyDetailA2
      careOfElderlyWillingnessA2
      careOfElderlyHasExperienceYearA2
      careOfElderlyExperienceYearA2
      careOfElderlyAssesmentA2
      careOfDisabledDetailA2
      careOfDisabledWillingnessA2
      careOfDisabledHasExperienceYearA2
      careOfDisabledExperienceYearA2
      careOfDisabledAssesmentA2
      generalHouseworkDetailA2
      generalHouseworkWillingnessA2
      generalHouseworkHasExperienceYearA2
      generalHouseworkExperienceYearA2
      generalHouseworkAssesmentA2
      cookingDetailA2
      cookingWillingnessA2
      cookingHasExperienceYearA2
      cookingExperienceYearA2
      cookingAssesmentA2
      languageDetailA2
      languageExperienceHasYearA2
      languageExperienceYearA2
      languageAssesmentA2
      otherSkillsDetailA2
      otherSkillsHasExperienceYearA2
      otherSkillsExperienceYearA2
      otherSkillsAssesmentA2
      careOfInfantsDetailB1
      careOfInfantsWillingnessB1
      careOfInfantsHasExperienceYearB1
      careOfInfantsExperienceYearB1
      careOfInfantsAssesmentB1
      careOfElderlyDetailB1
      careOfElderlyWillingnessB1
      careOfElderlyHasExperienceYearB1
      careOfElderlyExperienceYearB1
      careOfElderlyAssesmentB1
      careOfDisabledDetailB1
      careOfDisabledWillingnessB1
      careOfDisabledHasExperienceYearB1
      careOfDisabledExperienceYearB1
      careOfDisabledAssesmentB1
      generalHouseworkDetailB1
      generalHouseworkWillingnessB1
      generalHouseworkHasExperienceYearB1
      generalHouseworkExperienceYearB1
      generalHouseworkAssesmentB1
      cookingDetailB1
      cookingWillingnessB1
      cookingHasExperienceYearB1
      cookingExperienceYearB1
      cookingAssesmentB1
      languageDetailB1
      languageExperienceHasYearB1
      languageExperienceYearB1
      languageAssesmentB1
      otherSkillsDetailB1
      otherSkillsHasExperienceYearB1
      otherSkillsExperienceYearB1
      otherSkillsAssesmentB1
      hygieneCompetent
      hygieneNeedTraining
      hygieneTraining
      hygineAssesment
      adaptabilityCompetent
      adaptabilityNeedTraining
      adaptabilityTraining
      adaptabilityAssesment
      disciplineCompetent
      disciplineNeedTraining
      disciplineTraining
      disciplineAssesment
      coreSkills
      coreFitnessMentalCompetent
      coreFitnessMentalNeedTraining
      coreFitnessMentalTraining
      coreFitnessMentalAssesment
      coreFitnessPhysicalCompetent
      coreFitnessPhysicalNeedTraining
      coreFitnessPhysicalTraining
      coreFitnessPhysicalAssesment
      mentalillnessCompetent
      mentalillnessTraining
      mentalillnessAssesment
      physicalFitnessCompetent
      physicalFitnessTraining
      physicalFitnessAssesment
      certifiedOrAudityDescription
      certifiedOrAudityInterviewMethod
      hasWorkingExperienceInSingapore
      previousEmployer1
      previousEmployerFeedback1
      previousEmployer2
      previousEmployerFeedback2
      previousEmployer3
      previousEmployerFeedback3
      availabilityOfMDWToBeInterviewed
      wasFeedbackFromEA
      availablityToInterviewDetail
      isInActive
      priceCode
      priceCodeMDWDeploymentCountry
      referenceCode
      outProOn
      createdOn
      deletedOn
      updatedOn
      deleted
      createdAt
      updatedAt
      # owner
      inInactiveByUser {
        branch {
          branchName
          company {
            companyName
          }
        }
      }
    }
  }
`

export const listMigrants = /* GraphQL */ `
  query ListMigrants(
    $id: ID
    $filter: ModelMigrantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMigrants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        reservedBy
        reserveExpiryOn
        suppliedBy
        educationLevel
        salaryRange
        maritalStatus
        religion
        nationality
        status
        supplierCode
        name
        age
        dateOfBirth
        placeOfBirth
        height
        weight
        residentialAddress
        region
        nationalID
        passportNumber
        nameOfPort
        contactNumber
        additionalContactNumber
        nextOfKin
        nextOfKinContactNumber
        numberOfSiblings
        languages
        experience
        childrenNumber
        agesOfChildren
        photoKey
        additionalPhoto
        additionalVideoKey
        supportingDocuments
        allergies
        illness
        epilepsy
        asthma
        diabetes
        hypertension
        tuberculosis
        heart_disease
        malaria
        operations
        others
        physicalDisabilities
        dietaryRestriction
        foodHandlingPreferences
        foodHandlingPreferencesOthers
        restDaysPerMonth
        otherRemarks
        otherRemarksC3
        inactiveMessage
        methodOfEvaluationSkills
        nameOfForeignTrainingCentre
        stateIfThirdPartyIsCertified
        stateIfThirdPartyIsCertifiedSpecify
        interviewedByOverseasTrainingCentre
        employmentHistoryOverseasDateFrom1
        employmentHistoryOverseasDateTo1
        employmentHistoryCountry1
        employmentHistoryEmployer1
        employmentHistoryWorkDuties1
        employmentHistoryWorkDutiesRemarks1
        employmentHistoryOverseasDateFrom2
        employmentHistoryOverseasDateTo2
        employmentHistoryCountry2
        employmentHistoryEmployer2
        employmentHistoryWorkDuties2
        employmentHistoryWorkDutiesRemarks2
        employmentHistoryOverseasDateFrom3
        employmentHistoryOverseasDateTo3
        employmentHistoryCountry3
        employmentHistoryEmployer3
        employmentHistoryWorkDuties3
        employmentHistoryWorkDutiesRemarks3
        employmentHistoryOverseasDateFrom4
        employmentHistoryOverseasDateTo4
        employmentHistoryCountry4
        employmentHistoryEmployer4
        employmentHistoryWorkDuties4
        employmentHistoryWorkDutiesRemarks4
        employmentHistoryOverseasDateFrom5
        employmentHistoryOverseasDateTo5
        employmentHistoryCountry5
        employmentHistoryEmployer5
        employmentHistoryWorkDuties5
        employmentHistoryWorkDutiesRemarks5
        careOfInfantsDetailA2
        careOfInfantsWillingnessA2
        careOfInfantsHasExperienceYearA2
        careOfInfantsExperienceYearA2
        careOfInfantsAssesmentA2
        careOfElderlyDetailA2
        careOfElderlyWillingnessA2
        careOfElderlyHasExperienceYearA2
        careOfElderlyExperienceYearA2
        careOfElderlyAssesmentA2
        careOfDisabledDetailA2
        careOfDisabledWillingnessA2
        careOfDisabledHasExperienceYearA2
        careOfDisabledExperienceYearA2
        careOfDisabledAssesmentA2
        generalHouseworkDetailA2
        generalHouseworkWillingnessA2
        generalHouseworkHasExperienceYearA2
        generalHouseworkExperienceYearA2
        generalHouseworkAssesmentA2
        cookingDetailA2
        cookingWillingnessA2
        cookingHasExperienceYearA2
        cookingExperienceYearA2
        cookingAssesmentA2
        languageDetailA2
        languageExperienceHasYearA2
        languageExperienceYearA2
        languageAssesmentA2
        otherSkillsDetailA2
        otherSkillsHasExperienceYearA2
        otherSkillsExperienceYearA2
        otherSkillsAssesmentA2
        careOfInfantsDetailB1
        careOfInfantsWillingnessB1
        careOfInfantsHasExperienceYearB1
        careOfInfantsExperienceYearB1
        careOfInfantsAssesmentB1
        careOfElderlyDetailB1
        careOfElderlyWillingnessB1
        careOfElderlyHasExperienceYearB1
        careOfElderlyExperienceYearB1
        careOfElderlyAssesmentB1
        careOfDisabledDetailB1
        careOfDisabledWillingnessB1
        careOfDisabledHasExperienceYearB1
        careOfDisabledExperienceYearB1
        careOfDisabledAssesmentB1
        generalHouseworkDetailB1
        generalHouseworkWillingnessB1
        generalHouseworkHasExperienceYearB1
        generalHouseworkExperienceYearB1
        generalHouseworkAssesmentB1
        cookingDetailB1
        cookingWillingnessB1
        cookingHasExperienceYearB1
        cookingExperienceYearB1
        cookingAssesmentB1
        languageDetailB1
        languageExperienceHasYearB1
        languageExperienceYearB1
        languageAssesmentB1
        otherSkillsDetailB1
        otherSkillsHasExperienceYearB1
        otherSkillsExperienceYearB1
        otherSkillsAssesmentB1
        hygieneCompetent
        hygieneNeedTraining
        hygieneTraining
        hygineAssesment
        adaptabilityCompetent
        adaptabilityNeedTraining
        adaptabilityTraining
        adaptabilityAssesment
        disciplineCompetent
        disciplineNeedTraining
        disciplineTraining
        disciplineAssesment
        coreSkills
        coreFitnessMentalCompetent
        coreFitnessMentalNeedTraining
        coreFitnessMentalTraining
        coreFitnessMentalAssesment
        coreFitnessPhysicalCompetent
        coreFitnessPhysicalNeedTraining
        coreFitnessPhysicalTraining
        coreFitnessPhysicalAssesment
        mentalillnessCompetent
        mentalillnessTraining
        mentalillnessAssesment
        physicalFitnessCompetent
        physicalFitnessTraining
        physicalFitnessAssesment
        certifiedOrAudityDescription
        certifiedOrAudityInterviewMethod
        hasWorkingExperienceInSingapore
        previousEmployer1
        previousEmployerFeedback1
        previousEmployer2
        previousEmployerFeedback2
        previousEmployer3
        previousEmployerFeedback3
        availabilityOfMDWToBeInterviewed
        wasFeedbackFromEA
        availablityToInterviewDetail
        isInActive
        priceCode
        priceCodeMDWDeploymentCountry
        referenceCode
        outProOn
        createdOn
        deletedOn
        updatedOn
        deleted
        createdAt
        updatedAt
        isExtended
      }
      nextToken
    }
  }
`

export const listMigrantBySupplierBranchByStatus = /* GraphQL */ `
  query ListMigrantBySupplierBranchByStatus(
    $suppliedBy: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMigrantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrantBySupplierBranchByStatus(
      suppliedBy: $suppliedBy
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reservedBy
        reserveExpiryOn
        suppliedBy
        educationLevel
        salaryRange
        maritalStatus
        religion
        nationality
        status
        supplierCode
        name
        age
        dateOfBirth
        placeOfBirth
        height
        weight
        residentialAddress
        region
        nationalID
        passportNumber
        nameOfPort
        contactNumber
        additionalContactNumber
        nextOfKin
        nextOfKinContactNumber
        numberOfSiblings
        languages
        experience
        childrenNumber
        agesOfChildren
        photoKey
        additionalPhoto
        additionalVideoKey
        supportingDocuments
        allergies
        illness
        epilepsy
        asthma
        diabetes
        hypertension
        tuberculosis
        heart_disease
        malaria
        operations
        others
        physicalDisabilities
        dietaryRestriction
        foodHandlingPreferences
        foodHandlingPreferencesOthers
        restDaysPerMonth
        otherRemarks
        otherRemarksC3
        inactiveMessage
        methodOfEvaluationSkills
        nameOfForeignTrainingCentre
        stateIfThirdPartyIsCertified
        stateIfThirdPartyIsCertifiedSpecify
        interviewedByOverseasTrainingCentre
        employmentHistoryOverseasDateFrom1
        employmentHistoryOverseasDateTo1
        employmentHistoryCountry1
        employmentHistoryEmployer1
        employmentHistoryWorkDuties1
        employmentHistoryWorkDutiesRemarks1
        employmentHistoryOverseasDateFrom2
        employmentHistoryOverseasDateTo2
        employmentHistoryCountry2
        employmentHistoryEmployer2
        employmentHistoryWorkDuties2
        employmentHistoryWorkDutiesRemarks2
        employmentHistoryOverseasDateFrom3
        employmentHistoryOverseasDateTo3
        employmentHistoryCountry3
        employmentHistoryEmployer3
        employmentHistoryWorkDuties3
        employmentHistoryWorkDutiesRemarks3
        employmentHistoryOverseasDateFrom4
        employmentHistoryOverseasDateTo4
        employmentHistoryCountry4
        employmentHistoryEmployer4
        employmentHistoryWorkDuties4
        employmentHistoryWorkDutiesRemarks4
        employmentHistoryOverseasDateFrom5
        employmentHistoryOverseasDateTo5
        employmentHistoryCountry5
        employmentHistoryEmployer5
        employmentHistoryWorkDuties5
        employmentHistoryWorkDutiesRemarks5
        careOfInfantsDetailA2
        careOfInfantsWillingnessA2
        careOfInfantsHasExperienceYearA2
        careOfInfantsExperienceYearA2
        careOfInfantsAssesmentA2
        careOfElderlyDetailA2
        careOfElderlyWillingnessA2
        careOfElderlyHasExperienceYearA2
        careOfElderlyExperienceYearA2
        careOfElderlyAssesmentA2
        careOfDisabledDetailA2
        careOfDisabledWillingnessA2
        careOfDisabledHasExperienceYearA2
        careOfDisabledExperienceYearA2
        careOfDisabledAssesmentA2
        generalHouseworkDetailA2
        generalHouseworkWillingnessA2
        generalHouseworkHasExperienceYearA2
        generalHouseworkExperienceYearA2
        generalHouseworkAssesmentA2
        cookingDetailA2
        cookingWillingnessA2
        cookingHasExperienceYearA2
        cookingExperienceYearA2
        cookingAssesmentA2
        languageDetailA2
        languageExperienceHasYearA2
        languageExperienceYearA2
        languageAssesmentA2
        otherSkillsDetailA2
        otherSkillsHasExperienceYearA2
        otherSkillsExperienceYearA2
        otherSkillsAssesmentA2
        careOfInfantsDetailB1
        careOfInfantsWillingnessB1
        careOfInfantsHasExperienceYearB1
        careOfInfantsExperienceYearB1
        careOfInfantsAssesmentB1
        careOfElderlyDetailB1
        careOfElderlyWillingnessB1
        careOfElderlyHasExperienceYearB1
        careOfElderlyExperienceYearB1
        careOfElderlyAssesmentB1
        careOfDisabledDetailB1
        careOfDisabledWillingnessB1
        careOfDisabledHasExperienceYearB1
        careOfDisabledExperienceYearB1
        careOfDisabledAssesmentB1
        generalHouseworkDetailB1
        generalHouseworkWillingnessB1
        generalHouseworkHasExperienceYearB1
        generalHouseworkExperienceYearB1
        generalHouseworkAssesmentB1
        cookingDetailB1
        cookingWillingnessB1
        cookingHasExperienceYearB1
        cookingExperienceYearB1
        cookingAssesmentB1
        languageDetailB1
        languageExperienceHasYearB1
        languageExperienceYearB1
        languageAssesmentB1
        otherSkillsDetailB1
        otherSkillsHasExperienceYearB1
        otherSkillsExperienceYearB1
        otherSkillsAssesmentB1
        hygieneCompetent
        hygieneNeedTraining
        hygieneTraining
        hygineAssesment
        adaptabilityCompetent
        adaptabilityNeedTraining
        adaptabilityTraining
        adaptabilityAssesment
        disciplineCompetent
        disciplineNeedTraining
        disciplineTraining
        disciplineAssesment
        coreSkills
        coreFitnessMentalCompetent
        coreFitnessMentalNeedTraining
        coreFitnessMentalTraining
        coreFitnessMentalAssesment
        coreFitnessPhysicalCompetent
        coreFitnessPhysicalNeedTraining
        coreFitnessPhysicalTraining
        coreFitnessPhysicalAssesment
        mentalillnessCompetent
        mentalillnessTraining
        mentalillnessAssesment
        physicalFitnessCompetent
        physicalFitnessTraining
        physicalFitnessAssesment
        certifiedOrAudityDescription
        certifiedOrAudityInterviewMethod
        hasWorkingExperienceInSingapore
        previousEmployer1
        previousEmployerFeedback1
        previousEmployer2
        previousEmployerFeedback2
        previousEmployer3
        previousEmployerFeedback3
        availabilityOfMDWToBeInterviewed
        wasFeedbackFromEA
        availablityToInterviewDetail
        isInActive
        priceCode
        priceCodeMDWDeploymentCountry
        referenceCode
        outProOn
        createdOn
        deletedOn
        updatedOn
        deleted
        createdAt
        updatedAt
        # owner
        isExtended
      }
      nextToken
    }
  }
`
export const listMigrantByStatus = /* GraphQL */ `
  query ListMigrantByStatus(
    $status: MigrantStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelMigrantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrantByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reservedBy
        reservedByUserID
        reserveExpiryOn
        suppliedBy
        educationLevel
        salaryRange
        maritalStatus
        religion
        nationality
        status
        supplierCode
        name
        age
        dateOfBirth
        placeOfBirth
        height
        weight
        residentialAddress
        region
        nationalID
        passportNumber
        nameOfPort
        contactNumber
        additionalContactNumber
        nextOfKin
        nextOfKinContactNumber
        numberOfSiblings
        languages
        experience
        childrenNumber
        agesOfChildren
        photoKey
        additionalPhoto
        additionalVideoKey
        supportingDocuments
        allergies
        illness
        epilepsy
        asthma
        diabetes
        hypertension
        tuberculosis
        heart_disease
        malaria
        operations
        others
        physicalDisabilities
        dietaryRestriction
        foodHandlingPreferences
        foodHandlingPreferencesOthers
        restDaysPerMonth
        otherRemarks
        otherRemarksC3
        inactiveMessage
        methodOfEvaluationSkills
        nameOfForeignTrainingCentre
        stateIfThirdPartyIsCertified
        stateIfThirdPartyIsCertifiedSpecify
        interviewedByOverseasTrainingCentre
        employmentHistoryOverseasDateFrom1
        employmentHistoryOverseasDateTo1
        employmentHistoryCountry1
        employmentHistoryEmployer1
        employmentHistoryWorkDuties1
        employmentHistoryWorkDutiesRemarks1
        employmentHistoryOverseasDateFrom2
        employmentHistoryOverseasDateTo2
        employmentHistoryCountry2
        employmentHistoryEmployer2
        employmentHistoryWorkDuties2
        employmentHistoryWorkDutiesRemarks2
        employmentHistoryOverseasDateFrom3
        employmentHistoryOverseasDateTo3
        employmentHistoryCountry3
        employmentHistoryEmployer3
        employmentHistoryWorkDuties3
        employmentHistoryWorkDutiesRemarks3
        employmentHistoryOverseasDateFrom4
        employmentHistoryOverseasDateTo4
        employmentHistoryCountry4
        employmentHistoryEmployer4
        employmentHistoryWorkDuties4
        employmentHistoryWorkDutiesRemarks4
        employmentHistoryOverseasDateFrom5
        employmentHistoryOverseasDateTo5
        employmentHistoryCountry5
        employmentHistoryEmployer5
        employmentHistoryWorkDuties5
        employmentHistoryWorkDutiesRemarks5
        careOfInfantsDetailA2
        careOfInfantsWillingnessA2
        careOfInfantsHasExperienceYearA2
        careOfInfantsExperienceYearA2
        careOfInfantsAssesmentA2
        careOfElderlyDetailA2
        careOfElderlyWillingnessA2
        careOfElderlyHasExperienceYearA2
        careOfElderlyExperienceYearA2
        careOfElderlyAssesmentA2
        careOfDisabledDetailA2
        careOfDisabledWillingnessA2
        careOfDisabledHasExperienceYearA2
        careOfDisabledExperienceYearA2
        careOfDisabledAssesmentA2
        generalHouseworkDetailA2
        generalHouseworkWillingnessA2
        generalHouseworkHasExperienceYearA2
        generalHouseworkExperienceYearA2
        generalHouseworkAssesmentA2
        cookingDetailA2
        cookingWillingnessA2
        cookingHasExperienceYearA2
        cookingExperienceYearA2
        cookingAssesmentA2
        languageDetailA2
        languageExperienceHasYearA2
        languageExperienceYearA2
        languageAssesmentA2
        otherSkillsDetailA2
        otherSkillsHasExperienceYearA2
        otherSkillsExperienceYearA2
        otherSkillsAssesmentA2
        careOfInfantsDetailB1
        careOfInfantsWillingnessB1
        careOfInfantsHasExperienceYearB1
        careOfInfantsExperienceYearB1
        careOfInfantsAssesmentB1
        careOfElderlyDetailB1
        careOfElderlyWillingnessB1
        careOfElderlyHasExperienceYearB1
        careOfElderlyExperienceYearB1
        careOfElderlyAssesmentB1
        careOfDisabledDetailB1
        careOfDisabledWillingnessB1
        careOfDisabledHasExperienceYearB1
        careOfDisabledExperienceYearB1
        careOfDisabledAssesmentB1
        generalHouseworkDetailB1
        generalHouseworkWillingnessB1
        generalHouseworkHasExperienceYearB1
        generalHouseworkExperienceYearB1
        generalHouseworkAssesmentB1
        cookingDetailB1
        cookingWillingnessB1
        cookingHasExperienceYearB1
        cookingExperienceYearB1
        cookingAssesmentB1
        languageDetailB1
        languageExperienceHasYearB1
        languageExperienceYearB1
        languageAssesmentB1
        otherSkillsDetailB1
        otherSkillsHasExperienceYearB1
        otherSkillsExperienceYearB1
        otherSkillsAssesmentB1
        hygieneCompetent
        hygieneNeedTraining
        hygieneTraining
        hygineAssesment
        adaptabilityCompetent
        adaptabilityNeedTraining
        adaptabilityTraining
        adaptabilityAssesment
        disciplineCompetent
        disciplineNeedTraining
        disciplineTraining
        disciplineAssesment
        coreSkills
        coreFitnessMentalCompetent
        coreFitnessMentalNeedTraining
        coreFitnessMentalTraining
        coreFitnessMentalAssesment
        coreFitnessPhysicalCompetent
        coreFitnessPhysicalNeedTraining
        coreFitnessPhysicalTraining
        coreFitnessPhysicalAssesment
        mentalillnessCompetent
        mentalillnessTraining
        mentalillnessAssesment
        physicalFitnessCompetent
        physicalFitnessTraining
        physicalFitnessAssesment
        certifiedOrAudityDescription
        certifiedOrAudityInterviewMethod
        hasWorkingExperienceInSingapore
        previousEmployer1
        previousEmployerFeedback1
        previousEmployer2
        previousEmployerFeedback2
        previousEmployer3
        previousEmployerFeedback3
        availabilityOfMDWToBeInterviewed
        wasFeedbackFromEA
        availablityToInterviewDetail
        isInActive
        priceCode
        priceCodeMDWDeploymentCountry
        referenceCode
        outProOn
        createdOn
        deletedOn
        updatedOn
        deleted
        # owner
        isExtended
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPriceCodeCountryCode = /* GraphQL */ `
  query GetPriceCodeCountryCode($id: ID!) {
    getPriceCodeCountryCode(id: $id) {
      id
      countryCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const listPriceCodeCountryCodes = /* GraphQL */ `
  query ListPriceCodeCountryCodes(
    $id: ID
    $filter: ModelPriceCodeCountryCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPriceCodeCountryCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        countryCode
        createdAt
        updatedAt
        # owner
      }
      nextToken
    }
  }
`
export const getPriceCodeSupplierPriceCode = /* GraphQL */ `
  query GetPriceCodeSupplierPriceCode($id: ID!) {
    getPriceCodeSupplierPriceCode(id: $id) {
      id
      supplierPriceCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const listPriceCodeSupplierPriceCodes = /* GraphQL */ `
  query ListPriceCodeSupplierPriceCodes(
    $id: ID
    $filter: ModelPriceCodeSupplierPriceCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPriceCodeSupplierPriceCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        supplierPriceCode
        createdAt
        updatedAt
        # owner
      }
      nextToken
    }
  }
`
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      user {
        id
        email
        phoneNo
        fullName
        userType
        branchID
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        createdAt
        updatedAt
        # owner
      }
      description
      isRead
      readOn
      createdAt
      updatedAt
    }
  }
`
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        description
        isRead
        readOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listStatisticsByCompanyID = /* GraphQL */ `
  query ListStatisticsByCompanyID(
    $companyID: ID!
    # $referenceID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatisticsByCompanyID(
      companyID: $companyID
      # referenceID: $referenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        branch {
          branchName
          code
        }
        reserved {
          nationality
          priceCodeMDWDeploymentCountry
          id
        }
        confirmed {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
        outProcessed {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
        referenceName
        companyID
        company {
          companyName
        }
        type
      }
      nextToken
    }
  }
`
export const listStatisticsByBranchID = /* GraphQL */ `
  query ListStatisticsByBranchID(
    $branchID: ID!
    # $referenceID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatisticsByBranchID(
      branchID: $branchID
      # referenceID: $referenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        branch {
          branchName
        }
        company {
          companyName
        }
        confirmed {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
        outProcessed {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
        reserved {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
      }
    }
  }
`
export const listStatisticsByType = /* GraphQL */ `
  query ListStatisticsByType(
    $type: StatisticsType!
    $sortDirection: ModelSortDirection
    $filter: ModelStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatisticsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companyID
        confirmed {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
        outProcessed {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
        referenceID
        referenceName
        reserved {
          id
          nationality
          priceCodeMDWDeploymentCountry
        }
      }
      nextToken
    }
  }
`
