import React from 'react'
import {
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Button,
  Form,
  Modal,
  notification,
} from 'antd'
import {UserAddOutlined, UsergroupAddOutlined} from '@ant-design/icons'
import {API} from '@aws-amplify/api'
import {
  listBranches,
  getBranch,
  listUsers,
  getUser,
} from '../../../backend/graphql/queries'
import {
  updateUser,
  updateBranch,
  updateCompany,
  createBranch,
  createStaff,
  deleteStaff,
  updateStaff,
} from '../../../backend/graphql/mutations'
import {getSignedInUserDetails} from '../../../utils/checkValidity'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card
const {TextArea} = Input

const Branch = ({_companyId, setBranchID, refetchBranchesParent}) => {
  const [branches, setBranches] = React.useState([])

  const [selectedBranch, setSelectedBranch] = React.useState('')
  const [selectedBranchStaffNumber, setSelectedBranchStaffNumber] =
    React.useState('')

  const [modalAttribute, setModalAttribute] = React.useState({
    isVisible: false,
    title: '',
  })
  const [modalAttribute2, setModalAttribute2] = React.useState({
    isVisible: false,
    title: '',
  })

  const [fields, setFields] = React.useState({
    branchId: '',
    branchName: '',
    branchCode: '',

    contactNo: '',
    contactInfo: '',

    address: '',
    remarks: '',

    isMainBranch: '',
    status: '',

    companyId: '',
  })

  const [chooseBranch, setChooseBranch] = React.useState('')

  const AddBranchModal = ({companyId, refetchBranches}) => {
    const [addBranchFields, setAddBranchFields] = React.useState({
      branchName: '',
      branchCode: '',
      status: '',
      mobileNo: '',
      telephoneNo: '',
      address: '',
      remarks: '',
    })

    return (
      <Modal
        title={`Add ${modalAttribute.title}`}
        visible={modalAttribute.isVisible}
        width={950}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={async () => {
              try {
                await API.graphql({
                  query: createBranch,
                  variables: {
                    input: {
                      companyID: _companyId,
                      branchName: addBranchFields.branchName,
                      code: addBranchFields.branchCode,
                      status: 'ACTIVE',
                      phone: addBranchFields.telephoneNo,
                      address: addBranchFields.address,
                      isMainBranch: false,
                      type: 'SUPPLIER',
                      deleted: false,
                      remarks: addBranchFields.remarks,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                notification['success']({
                  message: 'Success',
                  description: 'Branch added!',
                })
                refetchBranches()
                refetchBranchesParent()
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: JSON.stringify(error),
                })
              } finally {
                setModalAttribute({
                  ...modalAttribute,
                  isVisible: false,
                  title: '',
                })
              }
            }}
            size="large"
          >
            Submit
          </Button>,
        ]}
        onOk={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
      >
        <RegistrationForm
          title={modalAttribute.title}
          {...{addBranchFields}}
          {...{setAddBranchFields}}
          companyId={fields.companyId}
        />
      </Modal>
    )
  }

  const RemoveBranchModal = ({companyId}) => {
    return (
      <Modal
        title=""
        visible={modalAttribute2.isVisible}
        width={855}
        footer={null}
        /*  footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Remove
          </Button>,
        ]} */
        onOk={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
      >
        <Space direction="vertical" align="center" style={{width: '100%'}}>
          {selectedBranchStaffNumber > 0 && (
            <Text style={{color: 'red', fontSize: 31}}>
              This Branch have {selectedBranchStaffNumber} Staff
            </Text>
          )}
          <Text style={{fontSize: 26}}>
            Are you sure wanna remove this Branch ?
          </Text>
          <img
            alt="example"
            src={require('../../../assets/image/warning.png')}
            style={{width: 170, height: 155}}
          />

          <Row
            style={{marginTop: 50, width: 500}}
            justify="space-around"
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#CECECE',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalAttribute2({
                    ...modalAttribute2,
                    isVisible: false,
                    title: '',
                  })
                }
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={async () => {
                  try {
                    /*   await API.graphql({
                      query: updateUser,
                      variables: {
                        input: {
                          id: 'avc',
                          branchID: selectedBranch.id,
                          deleted: true,
                        },
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    }) */

                    await API.graphql({
                      query: updateBranch,
                      variables: {
                        input: {
                          id: selectedBranch.id,
                          deleted: true,
                        },
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })

                    notification['success']({
                      message: 'Success',
                      description: 'Branch deleted!',
                    })
                  } catch (error) {
                    notification['error']({
                      message: 'Error',
                      description: JSON.stringify(error),
                    })
                  } finally {
                    setChooseBranch('')
                    init()
                    setFields({
                      branchId: '',
                      branchName: '',
                      branchCode: '',

                      contactNo: '',
                      contactInfo: '',

                      address: '',
                      remarks: '',

                      isMainBranch: '',
                      status: '',

                      companyId: '',
                    })
                    setModalAttribute2({
                      ...modalAttribute2,
                      isVisible: false,
                      title: '',
                    })
                  }
                }}
                size="large"
              >
                Remove
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    )
  }

  const RegistrationForm = ({title, addBranchFields, setAddBranchFields}) => {
    const [form] = Form.useForm()

    return (
      <Form layout="inline" form={form}>
        <Space direction="horizontal" size={50} align="start">
          <Space direction="vertical">
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>{title} Name*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder={`${title} Name`}
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.branchName}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            branchName: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Branch Code</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Branch Code"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.branchCode}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            branchCode: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Status</Typography>
                    </Col>
                    <Col span={16}>
                      <Select
                        defaultValue={''}
                        style={{width: 300, marginLeft: 10}}
                        onChange={(value) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            status: value,
                          })
                        }
                      >
                        <Option value="default" disabled>
                          Select Status
                        </Option>
                        <Option value="ACTIVE">Active</Option>
                        <Option value="PENDING">Pending</Option>
                        <Option value="REJECTED">Rejected</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row> */}
            <Row hidden>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Mobile No.</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Mobile No."
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.mobileNo}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            mobileNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <Space direction="vertical">
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Phone No.*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Phone No."
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addBranchFields.telephoneNo}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            telephoneNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Address</Typography>
                    </Col>
                    <Col span={16}>
                      <Input.TextArea
                        style={{width: 380}}
                        placeholder="Address"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        value={addBranchFields.address}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            address: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography>Remarks</Typography>
                    </Col>
                    <Col span={16}>
                      <Input.TextArea
                        style={{width: 380}}
                        placeholder="Remarks"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        value={addBranchFields.remarks}
                        onChange={(e) =>
                          setAddBranchFields({
                            ...addBranchFields,
                            remarks: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Space>
        <Space direction="horizontal"></Space>
      </Form>
    )
  }

  const init = async () => {
    try {
      const _listBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            companyID: {
              eq: _companyId,
            },

            deleted: {
              eq: false,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBranches(_listBranches.data.listBranches.items)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _getBranch = async (branchId) => {
    try {
      const __getBranch = await API.graphql({
        query: getBranch,
        variables: {
          id: branchId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setSelectedBranch(__getBranch.data.getBranch)
      setFields({
        branchId: __getBranch.data.getBranch.id,
        branchName: __getBranch.data.getBranch.branchName,
        branchCode: __getBranch.data.getBranch.code,
        contactNo: __getBranch.data.getBranch.company?.user?.phoneNo,
        contactInfo: __getBranch.data.getBranch.phone,
        address: __getBranch.data.getBranch.address,
        remarks: __getBranch.data.getBranch.remarks,
        isMainBranch: __getBranch.data.getBranch.isMainBranch,
        status: __getBranch.data.getBranch.status,
        companyId: __getBranch.data.getBranch.companyID,
      })
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    // if (_companyId) {
    init()
    // }
  }, [])

  React.useEffect(() => {
    const countStaffNumber = async () => {
      try {
        const _listUsers = await API.graphql({
          query: listUsers,
          variables: {
            filter: {
              branchID: {
                eq: selectedBranch.id,
              },
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })

        setSelectedBranchStaffNumber(_listUsers.data.listUsers.items.length)
      } catch (error) {
        return notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }
    if (selectedBranch) {
      countStaffNumber()
    }
  }, [selectedBranch])

  return (
    <Space direction="vertical" size={50}>
      <Row
        align="middle"
        justify="space-between"
        style={{backgroundColor: '#D8E2FF'}}
      >
        <Col>
          <Title style={{marginLeft: 15}}>BRANCH</Title>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text>CHOOSE BRANCH</Text>
            <Select
              defaultValue=""
              value={chooseBranch}
              style={{width: 200}}
              onChange={(value) => {
                setBranchID(value)
                setChooseBranch(value)
                _getBranch(value)
              }}
            >
              <Option value="" disabled></Option>
              {branches.map((item) => (
                <Option value={item.id}>
                  {' '}
                  {item.branchName === 'Main Branch'
                    ? item.code
                    : item.branchName}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center" style={{marginTop: 15}}>
            <Text>TOTAL BRANCH</Text>
            <Title>{branches.length}</Title>
          </Space>
        </Col>
        <Col>
          <Button
            type="text"
            htmlType="submit"
            style={{
              backgroundColor: '#34C217',
              borderColor: 'transparent',
              color: '#FFF',
              width: 150,
              marginRight: 15,
              borderRadius: 9,
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: true,
                title: 'Branch',
              })
            }
            icon={<UsergroupAddOutlined />}
          >
            Add Branch
          </Button>
        </Col>
        <AddBranchModal
          companyId={fields.companyId}
          refetchBranches={() => init()}
        />
        <RemoveBranchModal companyId={fields.companyId} />
      </Row>
      <Form name="branch_detail">
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="branchName">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Branch Name</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Branch Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.branchName}
                    onChange={(e) =>
                      setFields({...fields, branchName: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="contactInfo">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Phone No</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Phone No"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.contactInfo}
                    onChange={(e) =>
                      setFields({...fields, contactInfo: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="branchCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Branch Code</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Branch Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.branchCode}
                    onChange={(e) =>
                      setFields({...fields, branchCode: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="address">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Address</Typography>
                </Col>
                <Col span={16}>
                  <TextArea
                    style={{width: 300}}
                    placeholder="Address"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    rows={4}
                    value={fields.address}
                    onChange={(e) =>
                      setFields({...fields, address: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" align="top">
          <Col span={10} style={{visibility: 'hidden'}}>
            <Form.Item name="status">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Status</Typography>
                </Col>
                <Col span={5}>
                  {!fields.isMainBranch ? (
                    <Select
                      defaultValue={fields.status}
                      style={{width: 250}}
                      onChange={(value) =>
                        setFields({
                          ...fields,
                          status: value,
                        })
                      }
                    >
                      <Option value="default" disabled>
                        Select Status
                      </Option>
                      <Option value="ACTIVE">Active</Option>
                      <Option value="PENDING">Pending</Option>
                      <Option value="REJECTED">Rejected</Option>
                    </Select>
                  ) : (
                    <Input
                      style={{width: 300}}
                      placeholder="Status"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={selectedBranch?.status}
                      readOnly
                    />
                  )}
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item name="remarks">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Remarks</Typography>
                </Col>
                <Col span={16}>
                  <TextArea
                    style={{width: 300}}
                    placeholder="Remarks"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    rows={4}
                    value={fields.remarks}
                    onChange={(e) =>
                      setFields({...fields, remarks: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" hidden>
          <Col span={10}>
            <Form.Item name="status">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Contact No</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact No"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.contactNo}
                    onChange={(e) =>
                      setFields({...fields, contactNo: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="remarks">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Remarks</Typography>
                </Col>
                <Col span={16}>
                  <TextArea
                    style={{width: 300}}
                    placeholder="Remarks"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    rows={4}
                    value={fields.remarks}
                    onChange={(e) =>
                      setFields({...fields, remarks: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row
        align="middle"
        justify="center"
        gutter={40}
        hidden={!fields.branchId}
      >
        {!fields.isMainBranch && (
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#8893F9',

                borderColor: 'transparent',
                width: 160,
                borderRadius: 9,
                color: '#FFF',
              }}
              onClick={() =>
                setModalAttribute2({
                  ...modalAttribute2,
                  isVisible: true,
                  title: 'Staff',
                })
              }
              size="large"
            >
              Remove
            </Button>
          </Col>
        )}

        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#34C217',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={async () => {
              try {
                await API.graphql({
                  query: updateBranch,
                  variables: {
                    input: {
                      id: fields.branchId,
                      branchName: fields.branchName,
                      code: fields.branchCode,
                      phone: fields.contactInfo,
                      address: fields.address,
                      remarks: fields.remarks,
                      status: fields.status,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })
                notification['success']({
                  message: 'Success',
                  description: 'Data Updated!',
                })
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: JSON.stringify(error),
                })
              }
            }}
            size="large"
          >
            Save
          </Button>
        </Col>
      </Row>
      <br />
    </Space>
  )
}

const Staff = ({_companyId, branchID, branches}) => {
  const [users, setUsers] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState('')
  const [fields, setFields] = React.useState({
    id: '',
    fullName: '',
    staffCode: '',
    idNumber: '',

    regNo: '',
    branchCode: '',
    branchName: '',

    designation: '',
    email: '',

    contact: '',
    branchId: '',
    userType: '',
    status: '',
  })

  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          filter: {
            branchID: {
              eq: branchID,
            },
          },
        },
      })

      setUsers(_listUsers.data.listUsers.items)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _getUser = async (userId) => {
    try {
      const __getUser = await API.graphql({
        query: getUser,
        variables: {
          id: userId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setSelectedUser(__getUser.data.getUser)

      setFields({
        id: __getUser.data.getUser.id,
        fullName: __getUser.data.getUser.fullName,
        staffCode: __getUser.data.getUser.staffCode,
        regNo: __getUser.data.getUser.registrationNo,
        designation: __getUser.data.getUser.designation,
        email: __getUser.data.getUser.email,
        branchCode: __getUser.data.getUser.branch.code,
        branchName: __getUser.data.getUser.branch.branchName,
        // contact: __getUser.data.getUser.branch.phoneNo,
        contact: __getUser.data.getUser.phoneNo,
        userPhoneNo: __getUser.data.getUser.phoneNo,
        userType: __getUser.data.getUser.userType,
        status: __getUser.data.getUser.status,
      })
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [branchID])

  const [modalAttribute, setModalAttribute] = React.useState({
    isVisible: false,
    title: '',
  })
  const [modalAttribute2, setModalAttribute2] = React.useState({
    isVisible: false,
    title: '',
  })
  const [chooseStaff, setChooseStaff] = React.useState('')

  const AddStaffModal = ({branchID}) => {
    const [addStaffFields, setAddStaffFields] = React.useState({
      fullName: '',
      staffCode: '',
      idNumber: '',
      regNo: 0,
      branchCode: '',
      designation: '',
      email: '',
      mobileNo: '',
      telephoneNo: '',
      role: '',
      branchID: branches[0].id,
    })

    return (
      <Modal
        title={`Add ${modalAttribute.title}`}
        visible={modalAttribute.isVisible}
        width={855}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={async () => {
              try {
                await API.graphql({
                  query: createStaff,
                  variables: {
                    branchID: addStaffFields.branchID,
                    phoneNo: addStaffFields.telephoneNo,
                    email: addStaffFields.email,
                    designation: addStaffFields.designation,
                    fullName: addStaffFields.fullName,
                    mobileNo: addStaffFields.mobileNo,
                    registrationNo: addStaffFields.regNo,
                    staffCode: addStaffFields.staffCode,
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })
                init()
                // get staff id to update others data
                /* const getStaffID = await API.graphql({
                  query: listUsers,
                  variables: {
                    filter: {
                      email: {
                        eq: addStaffFields.email,
                      },
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                await API.graphql({
                  query: updateUser,
                  variables: {
                    input: {
                      id: getStaffID.data.listUsers.items[0].id,
                      fullName: addStaffFields.fullName,
                      staffCode: addStaffFields.staffCode,
                      registrationNo: addStaffFields.regNo,
                      designation: addStaffFields.designation,
                      mobileNo: addStaffFields.mobileNo,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                }) */

                notification['success']({
                  message: 'Success',
                  description: 'Staff added!',
                })
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error?.errors[0]?.message,
                })
              } finally {
                setModalAttribute({
                  ...modalAttribute,
                  isVisible: false,
                  title: '',
                })
              }
            }}
            size="large"
          >
            Submit
          </Button>,
        ]}
        onOk={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute({
            ...modalAttribute,
            isVisible: false,
            title: '',
          })
        }
      >
        <RegistrationForm
          title={modalAttribute.title}
          {...{branchID}}
          {...{addStaffFields}}
          {...{setAddStaffFields}}
        />
      </Modal>
    )
  }

  const RemoveStaffModal = ({fields}) => {
    return (
      <Modal
        title=""
        visible={modalAttribute2.isVisible}
        width={855}
        footer={null}
        /* footer={[
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#CECECE',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={() =>
              setModalAttribute2({
                ...modalAttribute2,
                isVisible: false,
                title: '',
              })
            }
            size="large"
          >
            Submit
          </Button>,
        ]} */
        onOk={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
        onCancel={() =>
          setModalAttribute2({
            ...modalAttribute2,
            isVisible: false,
            title: '',
          })
        }
      >
        <Space direction="vertical" align="center" style={{width: '100%'}}>
          <Text style={{fontSize: 26}}>
            Are you sure wanna remove this Staff ?
          </Text>
          <img
            alt="example"
            src={require('../../../assets/image/warning.png')}
            style={{width: 170, height: 155}}
          />
          <Row
            style={{marginTop: 50, width: 500}}
            justify="space-around"
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#34C217',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalAttribute2({
                    ...modalAttribute2,
                    isVisible: false,
                    title: '',
                  })
                }
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={async () => {
                  try {
                    await API.graphql({
                      query: deleteStaff,
                      variables: {
                        staffID: fields.id,
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })
                  } catch (error) {
                    return notification['error']({
                      message: 'Error',
                      description: JSON.stringify(error),
                    })
                  } finally {
                    setChooseStaff('')
                    init()
                    setModalAttribute2({
                      ...modalAttribute2,
                      isVisible: false,
                      title: '',
                    })
                  }
                }}
                size="large"
              >
                Remove
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    )
  }

  const RegistrationForm = ({
    title,
    branchID,
    addStaffFields,
    setAddStaffFields,
  }) => {
    const [form] = Form.useForm()

    return (
      <Form layout="inline" form={form}>
        <Space direction="horizontal" size={50}>
          <Space direction="vertical">
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Full Name*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Full Name"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.fullName}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            fullName: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Staff Code</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Staff Code"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.staffCode}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            staffCode: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {/*  <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>ID Number</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="ID Number"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.idNumber}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            idNumber: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>
                        Reg No.{`  `}
                      </Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Registration No."
                        type="number"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.regNo}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            regNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <Space direction="vertical" style={{paddingTop: 50}}>
            {/*   <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Branch Code</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Branch Code"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.branchCode}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            branchCode: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Designation</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Designation"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.designation}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            designation: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>E-mail*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="E-mail"
                        type="email"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.email}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            email: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{visibility: 'hidden'}}>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Mobile No.</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Mobile No."
                        type="email"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.mobileNo}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            mobileNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Row>
                    <Col span={8}>
                      <Typography style={{width: 120}}>Phone No.*</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 250}}
                        placeholder="Phone No."
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={addStaffFields.telephoneNo}
                        onChange={(e) =>
                          setAddStaffFields({
                            ...addStaffFields,
                            telephoneNo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Space>
        <Space direction="vertical">
          <Text strong>Branch</Text>
          <Row>
            <Col>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <Typography style={{width: 120}}>Branch Name</Typography>
                  </Col>
                  <Col span={16}>
                    <Select
                      defaultValue="selectStatus"
                      className="buttonBorderRadius"
                      style={{width: 250}}
                      value={addStaffFields.branchID}
                      onChange={(e) => {
                        setAddStaffFields({
                          ...addStaffFields,
                          branchID: e,
                        })
                      }}
                    >
                      <Option value="selectStatus" disabled>
                        Select Branch Name
                      </Option>
                      {branches.map((branch) => (
                        <Option value={branch.id}>{branch.branchName}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <Typography style={{width: 120}}>Status</Typography>
                  </Col>
                  <Col span={16}>
                    <Select defaultValue="default" style={{width: 250}}>
                      <Option value="default" disabled>
                        Select Status
                      </Option>
                      <Option value="active">Active</Option>
                      <Option value="inactive">Inactive</Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row> */}
        </Space>
      </Form>
    )
  }

  return (
    <Space direction="vertical" size={50}>
      <Row
        align="middle"
        justify="space-between"
        style={{backgroundColor: '#D8E2FF'}}
      >
        <Col>
          <Title style={{marginLeft: 15}}>STAFF</Title>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text>CHOOSE STAFF</Text>
            <Select
              defaultValue=""
              style={{width: 200}}
              value={chooseStaff}
              onChange={(value) => {
                setChooseStaff(value)
                _getUser(value)
              }}
            >
              <Option value=""></Option>
              {users.map((item) => (
                <Option value={item.id}>{item.fullName || item.email}</Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center" style={{marginTop: 15}}>
            <Text>TOTAL STAFF</Text>
            <Title>{users.length}</Title>
          </Space>
        </Col>
        <Col>
          <Button
            type="text"
            htmlType="submit"
            style={{
              backgroundColor: '#34C217',
              borderColor: 'transparent',
              color: '#FFF',
              width: 150,
              marginRight: 15,
              borderRadius: 9,
            }}
            onClick={() =>
              setModalAttribute({
                ...modalAttribute,
                isVisible: true,
                title: 'Staff',
              })
            }
            icon={<UserAddOutlined />}
          >
            Add Staff
          </Button>
        </Col>
      </Row>
      <Form name="branch_detail">
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="branchName">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Full Name</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Full Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.fullName}
                    onChange={(e) =>
                      setFields({...fields, fullName: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="branchCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Email</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Email"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    disabled
                    value={fields.email}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="branchCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Staff Code</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Staff Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.staffCode}
                    onChange={(e) =>
                      setFields({...fields, staffCode: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="address">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Designation</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Designation"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.designation}
                    onChange={(e) =>
                      setFields({...fields, designation: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        {/*    <Row justify="space-around" align="top">
          <Col span={10}>
            <Form.Item name="username">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Username</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Status"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="email">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Email</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Email"
                    type="email"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row> */}
        {/*         <Row justify="space-around">
          <Col span={10}>
            <Form.Item name="idNumber">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>ID Number</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="ID Number"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="contact">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Contact</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact"
                    type="number"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row> */}
        <Row justify="space-around">
          <Col span={10}>
            <Form.Item name="registrationNo">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Reg No</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Registration No"
                    type="number"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.regNo}
                    onChange={(e) =>
                      setFields({...fields, regNo: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="registrationNo">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Phone No</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Phone No"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.contact}
                    onChange={(e) =>
                      setFields({...fields, contact: e.target.value})
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" hidden>
          <Col span={10}>
            <Form.Item name="registrationNo">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Branch Name</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Branch Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    value={fields.branchName}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}></Col>
        </Row>
      </Form>
      <Row align="middle" justify="center" gutter={40} hidden={!fields.id}>
        {fields.userType.includes('STAFF') && (
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#8893F9',

                borderColor: 'transparent',
                width: 160,
                borderRadius: 9,
                color: '#FFF',
              }}
              onClick={() =>
                setModalAttribute2({
                  ...modalAttribute2,
                  isVisible: true,
                  title: 'Staff',
                })
              }
              size="large"
            >
              Remove
            </Button>
          </Col>
        )}

        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#0CDF1C',
              borderColor: 'transparent',
              width: 160,
              borderRadius: 9,
              color: '#FFF',
            }}
            onClick={async () => {
              try {
                await API.graphql({
                  query: updateStaff,
                  variables: {
                    staffID: fields.id,
                    staffCode: fields.staffCode,
                    registrationNo: fields.regNo,
                    phoneNo: fields.contact,
                    fullName: fields.fullName,
                    designation: fields.designation,
                    status: fields.status,
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })
                notification['success']({
                  message: 'Success',
                  description: 'Staff data updated!',
                })
              } catch (error) {
                notification['error']({
                  message: error?.errors[0]?.message,
                })
              }
            }}
            size="large"
          >
            Save
          </Button>
        </Col>
      </Row>
      <br />
      <AddStaffModal {...{branchID}} />
      <RemoveStaffModal {...{fields}} />
    </Space>
  )
}

const FormDetail = ({setCompanyId}) => {
  const [selectedBranch, setSelectedBranch] = React.useState({
    branchName: '',
    adminName: '',
    adminEmail: '',
    address: '',
    remarks: '',
    regNo: '',
    mobileNo: '',
    telephoneNo: '',
    status: '',
    userId: '',
    companyId: '',
  })

  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const __getBranch = await API.graphql({
        query: getBranch,
        variables: {
          id: _listUsers.data.listUsers.items[0].branchID,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      // setSelectedBranch(__getBranch.data.getBranch)
      setSelectedBranch({
        branchName: __getBranch.data.getBranch.company.companyName,
        adminName: __getBranch.data.getBranch.company.user.fullName,
        adminEmail: __getBranch.data.getBranch.company.user.email,
        address: __getBranch.data.getBranch.address,
        remarks: __getBranch.data.getBranch.remarks,
        regNo: __getBranch.data.getBranch.company.user.registrationNo,
        mobileNo: '',
        telephoneNo: __getBranch.data.getBranch.company.user.phoneNo,
        mobileNo: __getBranch.data.getBranch.company.user.mobileNo,
        status: __getBranch.data.getBranch.status,
        userId: __getBranch.data.getBranch.company.userID,
        companyId: __getBranch.data.getBranch.companyID,
      })
      setCompanyId(__getBranch.data.getBranch.companyID)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _editSupplier = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: selectedBranch.companyId,
            companyName: selectedBranch.branchName,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: updateBranch,
        variables: {
          input: {
            id: _listUsers.data.listUsers.items[0].branchID,
            address: selectedBranch.address,
            remarks: selectedBranch.remarks,
            status: selectedBranch.status,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: selectedBranch.userId,
            fullName: selectedBranch.adminName,
            registrationNo: selectedBranch.regNo,
            phoneNo: selectedBranch.telephoneNo,
            mobileNo: selectedBranch.mobileNo,
            status:
              selectedBranch.status === 'ACTIVE'
                ? 'APPROVED'
                : selectedBranch.status === 'PENDING'
                ? 'PENDING'
                : 'REJECTED',
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      notification['success']({
        message: 'Success',
        description: 'Data Updated!',
      })
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Space
        direction="horizontal"
        style={{
          justifyContent: 'space-around',

          // width: '90%',
          alignItems: 'start',
          marginBottom: 20,
        }}
      >
        <Space direction="vertical">
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Supplier Name</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Supplier Name"
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.branchName}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    branchName: e.target.value,
                  })
                }
                disabled
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Admin Name</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Admin Name"
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.adminName}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    adminName: e.target.value,
                  })
                }
                disabled
              />
            </Col>
          </Row>

          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Admin Email</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Admin Email"
                type="email"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.adminEmail}
                readOnly
                disabled
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Address</Typography>
            </Col>
            <Col span={14}>
              <Input.TextArea
                style={{width: 380}}
                placeholder="Address"
                type="text"
                size="large"
                className="buttonBorderRadius"
                multiple={true}
                maxLength={100}
                value={selectedBranch.address}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    address: e.target.value,
                  })
                }
                disabled
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Remarks</Typography>
            </Col>
            <Col span={14}>
              <Input.TextArea
                style={{width: 250}}
                placeholder="Remarks"
                type="text"
                size="large"
                className="buttonBorderRadius"
                multiple={true}
                maxLength={100}
                value={selectedBranch.remarks}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    remarks: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </Space>
        <Space direction="vertical" size={10}>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Supplier Reg No</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Supplier Reg. No."
                type="number"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.regNo}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    regNo: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Mobile No.</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Mobile No."
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.mobileNo}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    mobileNo: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Telephone No.</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Telephone No."
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.telephoneNo}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    telephoneNo: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          {/* {selectedBranch.status && (
            <Row justify="space-between" align="middle">
              <Col span={10}>
                <Typography>Status</Typography>
              </Col>
              <Col span={14}>
                <Select
                  defaultValue={selectedBranch.status}
                  style={{width: 250}}
                  onChange={(value) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      status: value,
                    })
                  }
                >
                  <Option value="default" disabled>
                    Select Status
                  </Option>
                  <Option value="ACTIVE">Active</Option>
                  <Option value="PENDING">Pending</Option>
                  <Option value="REJECTED">Rejected</Option>
                </Select>
              </Col>
            </Row>
          )} */}
        </Space>
      </Space>

      <Row align="middle" justify="center">
        <Col>
          <Button
            onClick={() => _editSupplier()}
            style={{
              borderColor: 'transparent',
              width: 150,
              backgroundColor: '#34C217',
              color: '#FFF',
              borderRadius: 9,
              marginBottom: 20,
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  )
}

function AccountManagement() {
  const [companyId, setCompanyId] = React.useState('')
  const [branchID, setBranchID] = React.useState('')
  const [branches, setBranches] = React.useState([])
  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _listBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            companyID: {
              eq: _listUsers.data.listUsers.items[0].branch.company.id,
            },
            deleted: {
              eq: false,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBranches(_listBranches.data.listBranches.items)
      setBranchID(_listUsers.data.listUsers.items[0].branchID)
      setCompanyId(_listUsers.data.listUsers.items[0].branch.company.id)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,

            paddingTop: 33,
            marginLeft: '5%',

            backgroundColor: '#FFF',
          }}
        >
          <FormDetail {...{setCompanyId}} />
          {companyId && (
            <Branch
              _companyId={companyId}
              {...{setBranchID}}
              refetchBranchesParent={() => init()}
            />
          )}
          {branchID && <Staff {...{branchID}} {...{branches}} />}
        </Layout>
      </Content>
    </>
  )
}

export default AccountManagement
