import React from 'react'
import {
  Layout,
  Col,
  Row,
  Form,
  Input,
  Button,
  Typography,
  Space,
  notification,
} from 'antd'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {Formik} from 'formik'
import ErrorText from '../../components/ErrorText'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons'
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {
  createCompany,
  createBranch,
  createUser,
  updateUser,
} from '../../backend/graphql/mutations'

const {Title} = Typography

function SetPassword(props) {
  console.log('SetPassword props', props)
  const {Content} = Layout

  const history = useHistory()
  const [formInput, setFormInput] = React.useState({
    pwd: '',
    rePwd: '',
    isValid: false,
  })
  const [isResendEmail, setIsResendEmail] = React.useState(false)
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must at least 8 characters')

      .matches(/[a-z]{1,}/, 'Password must at least contain one lower case')
      .matches(/[A-Z]{1,}/, 'Password must at least contain one upper case')
      .matches(/\d{1,}/, 'Password must at least contain one digit')
      .matches(
        /[!@#$&]{1,}/,
        'Contain one digit • Contain one special character of one of the following (!@#$%)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  const chara = /.{8,}/
  const lowercase = /[a-z]{1,}/
  const uppercase = /[A-Z]{1,}/
  const digit = /\d{1,}/
  const special = /[!@#$&]{1,}/

  return (
    <>
      <Layout
        className="bg-white"
        style={{
          backgroundImage: `url(${require('../../assets/image/set_password_bg.png')})`,
        }}
      >
        <Content>
          <Row justify="center" align="middle">
            <Col>
              <Space
                direction="vertical"
                size={10}
                align="center"
                style={{
                  height: '100vh',
                  justifyContent: 'center',
                }}
              >
                <Title style={{color: '#8893F9'}}>Create Password</Title>

                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      const urlParams = new URLSearchParams(
                        props.location.search,
                      )
                      const encoded = urlParams.get('data')
                      const decoded = JSON.parse(atob(encoded))
                      const code = urlParams.get('code')

                      // console.log('decoded', decoded)
                      // console.log('props.location', props.location)
                      // console.log('code', code.slice(0, 6))

                      // TO SIMULATE:
                      /* await Auth.resendSignUp(decoded.userName)
                      return notification['info']({
                        message: 'Info',
                        description:
                          'Please check your email, we already send an update set password link.',
                      }) */

                      await Auth.confirmSignUp(
                        decoded.userName,
                        code.slice(0, 6),
                      )

                      const user = await Auth.signIn(
                        decoded.userName,
                        '1234qwer',
                      )

                      await Auth.changePassword(
                        user,
                        '1234qwer',
                        values.password,
                      )

                      // TODO: CREATE USER => CREATE COMPANY => CREATE BRANCH => UPDATE USER TO BRANCH
                      /*  const _createUser = await API.graphql({
                        query: createUser,
                        variables: {
                          input: {
                            email: user.attributes.email,
                            fullName: user.attributes['custom:companyName'],
                            userType: user.attributes['custom:userType'],
                            status: 'PENDING',
                            phoneNo: user.attributes['custom:phoneNo'],
                            // branchID: _createBranch.data.createBranch.id,
                            deleted: false,
                          },
                        },
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                      })

                      const _createCompany = await API.graphql({
                        query: createCompany,
                        variables: {
                          input: {
                            userID: _createUser.data.createUser.id,
                            companyName: user.attributes['custom:companyName'],
                            type:
                              user.attributes['custom:userType'] ===
                                'SUPPLIER_ADMIN' ||
                              user.attributes['custom:userType'] ===
                                'SUPPLIER_STAFF'
                                ? 'SUPPLIER'
                                : 'EA',
                          },
                        },
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                      })

                      console.log('_createCompany', _createCompany)

                      const _createBranch = await API.graphql({
                        query: createBranch,
                        variables: {
                          input: {
                            companyID: _createCompany.data.createCompany.id,
                            branchName: 'Main Branch',
                            code: '',
                            status: 'PENDING',
                            phone: user.attributes['custom:phoneNo'],
                            address: user.attributes['custom:address'],
                            isMainBranch: true,
                            type:
                              user.attributes['custom:userType'] ===
                                'SUPPLIER_ADMIN' ||
                              user.attributes['custom:userType'] ===
                                'SUPPLIER_STAFF'
                                ? 'SUPPLIER'
                                : 'EA',
                            deleted: false,
                          },
                        },
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                      })
                      console.log('_createBranch', _createBranch)
                      await API.graphql({
                        query: updateUser,
                        variables: {
                          input: {
                            branchID: _createBranch.data.createBranch.id,
                            id: _createUser.data.createUser.id,
                          },
                        },
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                      }) */

                      await Auth.signOut()

                      notification['success']({
                        message: 'Success',
                        description: 'Password Successfully Created!',
                      })

                      history.push('/')
                    } catch (error) {
                      if (
                        error?.name === 'ExpiredCodeException' ||
                        error?.code === 'ExpiredCodeException'
                      ) {
                        const urlParams = new URLSearchParams(
                          props.location.search,
                        )

                        const encoded = urlParams.get('data')
                        const decoded = JSON.parse(atob(encoded))

                        Auth.resendSignUp(decoded.userName)

                        return notification['info']({
                          message: 'Info',
                          description:
                            'Please check your email, we already send an update set password link.',
                        })
                      } else if (
                        error?.name === 'NotAuthorizedException' ||
                        error?.code === 'NotAuthorizedException'
                      ) {
                        return notification['error']({
                          key: 'notification-error-notauthorizedexception',
                          message: 'Error',
                          duration: 0,
                          description:
                            'You have already created password previously, please login to continue or forgot password.',
                          btn: (
                            <Row>
                              <Col>
                                <Button
                                  type="primary"
                                  size="small"
                                  onClick={() => {
                                    notification.close(
                                      'notification-error-notauthorizedexception',
                                    )
                                    history.push('/')
                                  }}
                                >
                                  Login
                                </Button>
                              </Col>
                              <Col style={{paddingLeft: 25}}>
                                <Button
                                  type="primary"
                                  size="small"
                                  onClick={() => {
                                    notification.close(
                                      'notification-error-notauthorizedexception',
                                    )
                                    history.push('/forgot-password')
                                  }}
                                >
                                  Forgot Password
                                </Button>
                              </Col>
                            </Row>
                          ),
                        })
                      } else if (
                        error?.name === 'LimitExceededException' ||
                        error?.code === 'LimitExceededException'
                      ) {
                        return notification['error']({
                          message: 'Error',
                          duration: 0,
                          description:
                            'You have tried many times, please try again later.',
                        })
                      }

                      notification['error']({
                        message: 'Error',
                        duration: 0,
                        description: error?.name,
                      })
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                  }) => {
                    return (
                      <>
                        {!values.password.match(chara) ||
                        !values.password.match(lowercase) ||
                        !values.password.match(uppercase) ||
                        !values.password.match(digit) ? (
                          <Title level={4}>Password Must :</Title>
                        ) : (
                          true
                        )}

                        <ul>
                          {!values.password.match(chara) && (
                            <li>Password must at least 8 characters</li>
                          )}
                          {!values.password.match(lowercase) && (
                            <li>Include Lowercase character</li>
                          )}
                          {!values.password.match(uppercase) && (
                            <li>Include Uppercase character</li>
                          )}
                          {!values.password.match(digit) && (
                            <li>Include Number</li>
                          )}
                        </ul>

                        <Form className="flexColCenter">
                          <Form.Item name="password">
                            <Typography>New Password</Typography>
                            <Input.Password
                              style={{width: 300}}
                              placeholder="New Password"
                              type="password"
                              size="large"
                              className="buttonBorderRadius"
                              value={values.password}
                              onChange={(e) =>
                                setFieldValue('password', e.target.value)
                              }
                              maxLength={50}
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                            <br />
                            {errors.password && (
                              <ErrorText>{errors.password}</ErrorText>
                            )}
                          </Form.Item>

                          <Form.Item
                            name="rePassword"
                            rules={[
                              {
                                required: true,
                                message: 'Confirm Password is required',
                              },
                            ]}
                          >
                            <Typography>Retype New Password</Typography>
                            <Input.Password
                              style={{width: 300}}
                              placeholder="Retype New Password"
                              type="password"
                              size="large"
                              className="buttonBorderRadius"
                              value={values.confirmPassword}
                              onChange={(e) =>
                                setFieldValue('confirmPassword', e.target.value)
                              }
                              maxLength={50}
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                            <br />
                            {errors.confirmPassword && (
                              <ErrorText>{errors.confirmPassword}</ErrorText>
                            )}
                          </Form.Item>
                          <Form.Item
                            label=" "
                            colon={false}
                            className="marginTop15"
                          >
                            <Button
                              type="primary"
                              style={{
                                // backgroundColor: '#8893F9',
                                backgroundColor:
                                  !values.password.match(chara) ||
                                  !values.password.match(lowercase) ||
                                  !values.password.match(uppercase) ||
                                  !values.password.match(digit) ||
                                  !values.confirmPassword.match(chara) ||
                                  !values.confirmPassword.match(lowercase) ||
                                  !values.confirmPassword.match(uppercase) ||
                                  !values.confirmPassword.match(digit) ||
                                  isSubmitting
                                    ? 'gray'
                                    : '#8893F9',
                                borderColor: 'transparent',
                                borderRadius: 9,
                                color: '#FFF',
                              }}
                              size="large"
                              onClick={() =>
                                !values.password.match(chara) ||
                                !values.password.match(lowercase) ||
                                !values.password.match(uppercase) ||
                                !values.password.match(digit) ||
                                !values.confirmPassword.match(chara) ||
                                !values.confirmPassword.match(lowercase) ||
                                !values.confirmPassword.match(uppercase) ||
                                !values.confirmPassword.match(digit) ||
                                isSubmitting
                                  ? console.log(false)
                                  : handleSubmit()
                              }
                              disabled={
                                !values.password.match(chara) ||
                                !values.password.match(chara) ||
                                !values.password.match(lowercase) ||
                                !values.password.match(uppercase) ||
                                !values.password.match(digit) ||
                                !values.confirmPassword.match(chara) ||
                                !values.confirmPassword.match(chara) ||
                                !values.confirmPassword.match(lowercase) ||
                                !values.confirmPassword.match(uppercase) ||
                                !values.confirmPassword.match(digit) ||
                                isSubmitting
                              }
                            >
                              CREATE PASSWORD
                            </Button>
                          </Form.Item>
                        </Form>
                      </>
                    )
                  }}
                </Formik>
              </Space>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  )
}

export default SetPassword
