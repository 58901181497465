import 'antd/dist/antd.css'
import './styles/App.css'
import RoutePath from './utils/RoutePath'
import {BrowserRouter} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <RoutePath />
      </BrowserRouter>
    </div>
  )
}

export default App
