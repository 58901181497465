import React from 'react'
import {
  Row,
  Col,
  Typography,
  Layout,
  Input,
  Space,
  Table,
  Button,
  notification,
  Modal,
} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {API} from '@aws-amplify/api'
import {listBranches} from '../../../backend/graphql/queries'
import {
  updateBranch,
  updateUser,
  updateCompany,
  confirmSupplier,
  adminDeleteUser,
} from '../../../backend/graphql/mutations'
import {capitalize, filter} from 'lodash'
const {Content} = Layout
const {Text} = Typography

function Supplier() {
  const [pendingCompanies, setPendingCompanies] = React.useState([])
  const [tempPendingCompanies, setTempPendingCompanies] = React.useState([])
  const [rejectedActiveCompanies, setRejectedActiveCompanies] = React.useState(
    [],
  )
  const [tempRejectedActiveCompanies, setTempRejectedActiveCompanies] =
    React.useState([])
  const [modalDeleteUser, setModalDeleteUser] = React.useState({
    companyName: '',
    hasShown: false,
    userID: '',
    branchID: '',
  })
  // const [deleteUserParam, setDeleteUserParam] = React.useState({userID:'', branchID: ''})

  const init = async () => {
    try {
      const _listPendingBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            deleted: {
              eq: false,
            },
            isMainBranch: {
              eq: true,
            },
            status: {
              eq: 'PENDING',
            },
            type: {
              eq: 'SUPPLIER',
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setTempPendingCompanies(_listPendingBranches.data.listBranches.items)
      setPendingCompanies(_listPendingBranches.data.listBranches.items)

      const _listActiveRejectedBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            deleted: {
              eq: false,
            },
            isMainBranch: {
              eq: true,
            },
            or: [
              {
                status: {eq: 'REJECTED'},
              },
              {
                status: {eq: 'ACTIVE'},
              },
            ],
            and: [
              {
                id: {ne: '0fe53d5f-486e-4973-a8f8-e405f7fc8ccb'},
              },
              {
                id: {ne: '9e450bae-e509-45ae-96c3-95abc07dea1b'},
              },
              {
                id: {ne: 'bfada97e-ca15-4966-87e6-086d3dd704eb'},
              },
              {
                id: {ne: '755c2bd7-f1e4-46dc-8a90-20bc6c977aa3'},
              },
              {
                id: {ne: '9a43ccf1-771d-48b2-9ed7-0a9cf04637cb'},
              },
            ],

            type: {
              eq: 'SUPPLIER',
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setTempRejectedActiveCompanies(
        _listActiveRejectedBranches.data.listBranches.items,
      )
      setRejectedActiveCompanies(
        _listActiveRejectedBranches.data.listBranches.items,
      )
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  const PendingRow = (props) => {
    const [code, setCode] = React.useState('')
    const onClickAction = async (status) => {
      if (!code && status === 'ACTIVE') {
        return notification['error']({
          message: 'Error',
          description: "Code can't be empty",
        })
      }
      try {
        if (status === 'ACTIVE') {
          await API.graphql({
            query: confirmSupplier,
            variables: {
              supplierID: props.company.user.id,
              supplierCode: code,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
        } else if (status === 'REJECTED') {
          await API.graphql({
            query: updateBranch,
            variables: {
              input: {
                id: props.id,
                status: 'REJECTED',
                code,
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
          await API.graphql({
            query: updateCompany,
            variables: {
              input: {
                id: props.companyID,
                // status,
                code,
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
          await API.graphql({
            query: updateUser,
            variables: {
              input: {
                id: props.company.user.id,
                status: 'REJECTED',
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
        }
        /* await API.graphql({
        query: updateBranch,
        variables: {
          input: {
            id: props.id,
            status,
            code,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      await API.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: props.companyID,
            // status,
            code,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: props.company.user.id,
            status: status === 'ACTIVE' ? 'APPROVED' : 'REJECTED',
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }) */

        props.init()
        notification['success']({
          message: 'Success',
          description: 'Status Updated.',
        })
      } catch (error) {
        notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      } finally {
        setCode('')
      }
    }
    return (
      <Row align="middle" justify="space-between">
        <Col span={4} align="center">
          {props.index + 1}
        </Col>
        <Col span={5}>{props.company.companyName}</Col>
        <Col span={5} align="center">
          <Input
            placeholder="Supplier Code"
            style={{width: 150}}
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </Col>
        <Col span={5}>{props.address}</Col>
        <Col span={5} align="center">
          <Space direction="vertical">
            <Button
              type="primary"
              style={{
                backgroundColor: '#8893F9',
                borderColor: 'transparent',
                width: 100,
              }}
              onClick={() => onClickAction('ACTIVE')}
            >
              Approve
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#707070',
                borderColor: 'transparent',
                width: 100,
              }}
              onClick={() => onClickAction('REJECTED')}
            >
              Reject
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: '#FF2B2B',
                borderColor: 'transparent',
                width: 100,
              }}
              onClick={() => {
                setModalDeleteUser({
                  ...modalDeleteUser,
                  hasShown: true,
                  companyName: props.company?.companyName,
                  userID: props.company?.user?.id,
                  branchID: props.id,
                })
              }}
            >
              Delete
            </Button>
          </Space>
          {/*   <Row justify="space-between">
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row> */}
        </Col>
      </Row>
    )
  }

  const RejectedActiveRow = (props) => {
    const history = useHistory()

    return (
      <Row align="middle" justify="space-between">
        <Col span={2} align="center">
          {props.index + 1}
        </Col>
        <Col span={5}>{props.company.companyName}</Col>
        <Col span={5} align="center">
          {props.code}
        </Col>
        <Col span={5}>{props.address}</Col>
        <Col span={2}>{capitalize(props.status)}</Col>
        <Col span={5} align="center">
          <Row justify="space-between" align="center">
            <Col align="center">
              <Button
                type="primary"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 100,
                }}
                onClick={() => history.push(`/admin/supplier/${props.id}`)}
              >
                Details
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const DeleteUserModal = () => {
    return (
      <Modal
        visible={modalDeleteUser.hasShown}
        width={855}
        footer={null}
        onOk={() => setModalDeleteUser({...modalDeleteUser, hasShown: false})}
        onCancel={() =>
          setModalDeleteUser({...modalDeleteUser, hasShown: false})
        }
      >
        <Space direction="vertical" align="center" style={{width: '100%'}}>
          <Text style={{fontSize: 26}}>
            Are you sure wanna to delete{' '}
            <Text style={{color: 'red'}}>{modalDeleteUser.companyName}</Text> ?
          </Text>
          <img
            alt="example"
            src={require('../../..//assets/image/warning.png')}
            style={{width: 170, height: 155}}
          />

          <Row
            style={{marginTop: 50, width: 500}}
            justify="space-around"
            align="middle"
          >
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#CECECE',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalDeleteUser({...modalDeleteUser, hasShown: false})
                }
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={async () => {
                  try {
                    await API.graphql({
                      query: adminDeleteUser,
                      variables: {
                        userID: modalDeleteUser.userID,
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })

                    await API.graphql({
                      query: updateBranch,
                      variables: {
                        input: {
                          id: modalDeleteUser.branchID,
                          deleted: true,
                        },
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })
                    init()
                    notification['success']({
                      message: 'Success',
                      description: 'User deleted!',
                    })
                  } catch (error) {
                    notification['error']({
                      message: 'Error',
                      description: JSON.stringify(error),
                    })
                  } finally {
                    setModalDeleteUser({...modalDeleteUser, hasShown: false})
                  }
                }}
                size="large"
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    )
  }

  return (
    <>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 33,
            marginLeft: '5%',
            backgroundColor: '#FFF',
          }}
        >
          <Space direction="vertical" size={50}>
            <Row>
              <Col>
                <Input
                  addonBefore={<SearchOutlined />}
                  placeholder="Search"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setPendingCompanies(tempPendingCompanies)
                      return setRejectedActiveCompanies(
                        tempRejectedActiveCompanies,
                      )
                    }
                    var resultsTempPendingCompanies = filter(
                      tempPendingCompanies,
                      function (item) {
                        return (
                          item.company.companyName
                            .toLowerCase()
                            .indexOf(e.target.value.toLowerCase()) > -1
                        )
                      },
                    )
                    var resultsTempRejectedActiveCompanies = filter(
                      tempRejectedActiveCompanies,
                      function (item) {
                        return (
                          item.company.companyName
                            .toLowerCase()
                            .indexOf(e.target.value.toLowerCase()) > -1
                        )
                      },
                    )
                    setPendingCompanies(resultsTempPendingCompanies)
                    setRejectedActiveCompanies(
                      resultsTempRejectedActiveCompanies,
                    )
                  }}
                />
              </Col>
            </Row>
            {/* <Table
              columns={columns}
              dataSource={pendingCompanies}
              pagination={false}
              bordered
              style={{marginRight: 15}}
            /> */}

            <>
              <Row
                align="middle"
                justify="space-between"
                style={{
                  backgroundColor: '#D8E2FF',
                  padding: 10,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                }}
              >
                <Col span={4}>
                  <Row align="center">
                    <Text align="center">No</Text>
                  </Row>
                </Col>
                <Col span={5} align="center">
                  <Row align="center">
                    <Text align="center">Supplier Name</Text>
                  </Row>
                </Col>
                <Col
                  span={5}
                  style={{display: 'flex', justifyContent: 'center'}}
                  align="center"
                >
                  <Row>
                    <Space direction="vertical" align="center">
                      <Text>Supplier Code</Text>
                    </Space>
                  </Row>
                </Col>
                <Col span={5} align="center">
                  <Row align="center">
                    <Space direction="vertical" align="center">
                      <Text>Address</Text>
                    </Space>
                  </Row>
                </Col>
                <Col span={5} align="center">
                  <Row align="center">
                    <Space direction="vertical" align="center">
                      <Text>Actions</Text>
                    </Space>
                  </Row>
                </Col>
              </Row>
              {pendingCompanies.map((item, index) => (
                <PendingRow {...item} {...{index}} {...{init}} />
              ))}
            </>

            {/* <Table
              columns={columns2}
              dataSource={rejectedActiveCompanies}
              pagination={false}
              bordered
              style={{marginRight: 15}}
            /> */}

            <>
              <Row
                align="middle"
                justify="space-between"
                style={{
                  backgroundColor: '#D8E2FF',
                  padding: 10,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                }}
              >
                <Col span={2}>
                  <Row align="center">
                    <Text align="center">No</Text>
                  </Row>
                </Col>
                <Col span={5} align="center">
                  <Row align="center">
                    <Text align="center">Supplier Name</Text>
                  </Row>
                </Col>
                <Col
                  span={5}
                  style={{display: 'flex', justifyContent: 'center'}}
                  align="center"
                >
                  <Row>
                    <Space direction="vertical" align="center">
                      <Text>Supplier Code</Text>
                    </Space>
                  </Row>
                </Col>
                <Col span={5} align="center">
                  <Row align="center">
                    <Space direction="vertical" align="center">
                      <Text>Address</Text>
                    </Space>
                  </Row>
                </Col>
                <Col span={2} align="center">
                  <Row align="center">
                    <Space direction="vertical" align="center">
                      <Text>Status</Text>
                    </Space>
                  </Row>
                </Col>
                <Col span={5} align="center">
                  <Row align="center">
                    <Space direction="vertical" align="center">
                      <Text>Actions</Text>
                    </Space>
                  </Row>
                </Col>
              </Row>
              {rejectedActiveCompanies.map((item, index) => (
                <RejectedActiveRow {...item} {...{index}} />
              ))}
            </>
            <DeleteUserModal />
          </Space>
        </Layout>
      </Content>
    </>
  )
}

export default Supplier
