/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const changePassword = /* GraphQL */ `
  mutation ChangePassword(
    $username: String!
    $password: String!
    $random: Int
  ) {
    changePassword(username: $username, password: $password, random: $random)
  }
`
export const addToEAGroup = /* GraphQL */ `
  mutation AddToEAGroup($email: String!) {
    addToEAGroup(email: $email)
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      phoneNo
      fullName
      userType
      branchID
      branch {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      staffCode
      registrationNo
      designation
      deleted
      deletedOn
      role
      status
      gender
      createdAt
      updatedAt
      # owner
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      phoneNo
      mobileNo
      fullName
      userType
      branchID
      branch {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        # type
        deleted
        deletedOn
        createdAt
        updatedAt
      }
      staffCode
      registrationNo
      designation
      deleted
      deletedOn
      role
      status
      gender
      profilePictureKey
      createdAt
      updatedAt
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      phoneNo
      fullName
      userType
      branchID
      branch {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      staffCode
      registrationNo
      designation
      deleted
      deletedOn
      role
      status
      gender
      createdAt
      updatedAt
      # owner
    }
  }
`
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        phoneNo
        fullName
        userType
        branchID
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        createdAt
        updatedAt
        # owner
      }
      companyName
      licenseNo
      licenseName
      type

      createdAt
      updatedAt
      owner
    }
  }
`
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        phoneNo
        fullName
        userType
        branchID
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        createdAt
        updatedAt
        # owner
      }
      companyName
      licenseNo
      licenseName
      type
      # statistics {
      #   id
      #   outProNumber
      #   totalConfirmedNumber
      #   totalReservedNumber
      #   createdAt
      #   updatedAt
      #   owner
      # }
      createdAt
      updatedAt
      # owner
    }
  }
`
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        phoneNo
        fullName
        userType
        branchID
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        createdAt
        updatedAt
        # owner
      }
      companyName
      licenseNo
      licenseName
      type
      statistics {
        id
        outProNumber
        totalConfirmedNumber
        totalReservedNumber
        createdAt
        updatedAt
        # owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`
export const createBranch = /* GraphQL */ `
  mutation CreateBranch(
    $input: CreateBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    createBranch(input: $input, condition: $condition) {
      id
      companyID
      company {
        id
        userID
        companyName
        licenseNo
        licenseName
        type
        createdAt
        updatedAt
        # owner
      }
      branchName
      code
      status
      phone
      address
      remarks
      isMainBranch
      type
      deleted
      deletedOn
      createdAt
      updatedAt
      # owner
    }
  }
`

export const createBranchWtType = /* GraphQL */ `
  mutation CreateBranch(
    $input: CreateBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    createBranch(input: $input, condition: $condition) {
      id
      companyID
      company {
        id
        userID
        companyName
        licenseNo
        licenseName
        # type
        createdAt
        updatedAt
        # owner
      }
      branchName
      code
      status
      phone
      address
      remarks
      isMainBranch
      type
      deleted
      deletedOn
      createdAt
      updatedAt
      # owner
    }
  }
`

export const updateBranch = /* GraphQL */ `
  mutation UpdateBranch(
    $input: UpdateBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    updateBranch(input: $input, condition: $condition) {
      id
      companyID
      company {
        id
        userID
        companyName
        licenseNo
        licenseName
        # type
        createdAt
        updatedAt
        # owner
      }
      branchName
      code
      status
      phone
      address
      remarks
      isMainBranch
      # type
      deleted
      deletedOn
      createdAt
      updatedAt
      # owner
    }
  }
`
export const deleteBranch = /* GraphQL */ `
  mutation DeleteBranch(
    $input: DeleteBranchInput!
    $condition: ModelBranchConditionInput
  ) {
    deleteBranch(input: $input, condition: $condition) {
      id
      companyID
      company {
        id
        userID
        companyName
        licenseNo
        licenseName
        type
        createdAt
        updatedAt
        owner
      }
      branchName
      code
      status
      phone
      address
      remarks
      isMainBranch
      type
      deleted
      deletedOn
      createdAt
      updatedAt
      # owner
    }
  }
`
export const createStatistics = /* GraphQL */ `
  mutation CreateStatistics(
    $input: CreateStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    createStatistics(input: $input, condition: $condition) {
      id
      outProNumber
      totalConfirmedNumber
      totalReservedNumber
      createdAt
      updatedAt
      # owner
    }
  }
`
export const updateStatistics = /* GraphQL */ `
  mutation UpdateStatistics(
    $input: UpdateStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    updateStatistics(input: $input, condition: $condition) {
      id
      outProNumber
      totalConfirmedNumber
      totalReservedNumber
      createdAt
      updatedAt
      # owner
    }
  }
`
export const deleteStatistics = /* GraphQL */ `
  mutation DeleteStatistics(
    $input: DeleteStatisticsInput!
    $condition: ModelStatisticsConditionInput
  ) {
    deleteStatistics(input: $input, condition: $condition) {
      id
      outProNumber
      totalConfirmedNumber
      totalReservedNumber
      createdAt
      updatedAt
      # owner
    }
  }
`

export const createMigrant = /* GraphQL */ `
  mutation CreateMigrant(
    $input: CreateMigrantInput!
    $condition: ModelMigrantConditionInput
  ) {
    createMigrant(input: $input, condition: $condition) {
      id
      reservedBy
      branchEA {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      suppliedBy
      # branchSupplier {
      #   id
      #   companyID
      #   branchName
      #   code
      #   status
      #   phone
      #   address
      #   remarks
      #   isMainBranch
      #   type
      #   deleted
      #   deletedOn
      #   createdAt
      #   updatedAt
      #   owner
      # }
      educationLevel
      salaryRange
      maritalStatus
      religion
      nationality
      status
      supplierCode
      name
      age
      dateOfBirth
      placeOfBirth
      height
      weight
      residentialAddress
      region
      nationalID
      passportNumber
      nameOfPort
      contactNumber
      additionalContactNumber
      nextOfKin
      nextOfKinContactNumber
      numberOfSiblings
      languages
      experience
      childrenNumber
      agesOfChildren
      photoKey
      additionalPhoto
      additionalVideoKey
      supportingDocuments
      allergies
      illness
      epilepsy
      asthma
      diabetes
      hypertension
      tuberculosis
      heart_disease
      malaria
      operations
      others
      physicalDisabilities
      dietaryRestriction
      foodHandlingPreferences
      foodHandlingPreferencesOthers
      restDaysPerMonth
      otherRemarks
      otherRemarksC3
      inactiveMessage
      methodOfEvaluationSkills
      nameOfForeignTrainingCentre
      stateIfThirdPartyIsCertified
      stateIfThirdPartyIsCertifiedSpecify
      interviewedByOverseasTrainingCentre
      employmentHistoryOverseasDateFrom1
      employmentHistoryOverseasDateTo1
      employmentHistoryCountry1
      employmentHistoryEmployer1
      employmentHistoryWorkDuties1
      employmentHistoryWorkDutiesRemarks1
      employmentHistoryOverseasDateFrom2
      employmentHistoryOverseasDateTo2
      employmentHistoryCountry2
      employmentHistoryEmployer2
      employmentHistoryWorkDuties2
      employmentHistoryWorkDutiesRemarks2
      employmentHistoryOverseasDateFrom3
      employmentHistoryOverseasDateTo3
      employmentHistoryCountry3
      employmentHistoryEmployer3
      employmentHistoryWorkDuties3
      employmentHistoryWorkDutiesRemarks3
      employmentHistoryOverseasDateFrom4
      employmentHistoryOverseasDateTo4
      employmentHistoryCountry4
      employmentHistoryEmployer4
      employmentHistoryWorkDuties4
      employmentHistoryWorkDutiesRemarks4
      employmentHistoryOverseasDateFrom5
      employmentHistoryOverseasDateTo5
      employmentHistoryCountry5
      employmentHistoryEmployer5
      employmentHistoryWorkDuties5
      employmentHistoryWorkDutiesRemarks5
      careOfInfantsDetailA2
      careOfInfantsWillingnessA2
      careOfInfantsHasExperienceYearA2
      careOfInfantsExperienceYearA2
      careOfInfantsAssesmentA2
      careOfElderlyDetailA2
      careOfElderlyWillingnessA2
      careOfElderlyHasExperienceYearA2
      careOfElderlyExperienceYearA2
      careOfElderlyAssesmentA2
      careOfDisabledDetailA2
      careOfDisabledWillingnessA2
      careOfDisabledHasExperienceYearA2
      careOfDisabledExperienceYearA2
      careOfDisabledAssesmentA2
      generalHouseworkDetailA2
      generalHouseworkWillingnessA2
      generalHouseworkHasExperienceYearA2
      generalHouseworkExperienceYearA2
      generalHouseworkAssesmentA2
      cookingDetailA2
      cookingWillingnessA2
      cookingHasExperienceYearA2
      cookingExperienceYearA2
      cookingAssesmentA2
      languageDetailA2
      languageExperienceHasYearA2
      languageExperienceYearA2
      languageAssesmentA2
      otherSkillsDetailA2
      otherSkillsHasExperienceYearA2
      otherSkillsExperienceYearA2
      otherSkillsAssesmentA2
      careOfInfantsDetailB1
      careOfInfantsWillingnessB1
      careOfInfantsHasExperienceYearB1
      careOfInfantsExperienceYearB1
      careOfInfantsAssesmentB1
      careOfElderlyDetailB1
      careOfElderlyWillingnessB1
      careOfElderlyHasExperienceYearB1
      careOfElderlyExperienceYearB1
      careOfElderlyAssesmentB1
      careOfDisabledDetailB1
      careOfDisabledWillingnessB1
      careOfDisabledHasExperienceYearB1
      careOfDisabledExperienceYearB1
      careOfDisabledAssesmentB1
      generalHouseworkDetailB1
      generalHouseworkWillingnessB1
      generalHouseworkHasExperienceYearB1
      generalHouseworkExperienceYearB1
      generalHouseworkAssesmentB1
      cookingDetailB1
      cookingWillingnessB1
      cookingHasExperienceYearB1
      cookingExperienceYearB1
      cookingAssesmentB1
      languageDetailB1
      languageExperienceHasYearB1
      languageExperienceYearB1
      languageAssesmentB1
      otherSkillsDetailB1
      otherSkillsHasExperienceYearB1
      otherSkillsExperienceYearB1
      otherSkillsAssesmentB1
      hygieneCompetent
      hygieneNeedTraining
      hygieneTraining
      hygineAssesment
      adaptabilityCompetent
      adaptabilityNeedTraining
      adaptabilityTraining
      adaptabilityAssesment
      disciplineCompetent
      disciplineNeedTraining
      disciplineTraining
      disciplineAssesment
      coreSkills
      coreFitnessMentalCompetent
      coreFitnessMentalNeedTraining
      coreFitnessMentalTraining
      coreFitnessMentalAssesment
      coreFitnessPhysicalCompetent
      coreFitnessPhysicalNeedTraining
      coreFitnessPhysicalTraining
      coreFitnessPhysicalAssesment
      mentalillnessCompetent
      mentalillnessTraining
      mentalillnessAssesment
      physicalFitnessCompetent
      physicalFitnessTraining
      physicalFitnessAssesment
      certifiedOrAudityDescription
      certifiedOrAudityInterviewMethod
      hasWorkingExperienceInSingapore
      previousEmployer1
      previousEmployerFeedback1
      previousEmployer2
      previousEmployerFeedback2
      previousEmployer3
      previousEmployerFeedback3
      availabilityOfMDWToBeInterviewed
      wasFeedbackFromEA
      availablityToInterviewDetail
      isInActive
      priceCode
      priceCodeMDWDeploymentCountry
      referenceCode
      outProOn
      createdOn
      deletedOn
      updatedOn
      deleted
      createdAt
      updatedAt
      # owner
    }
  }
`
export const updateMigrant = /* GraphQL */ `
  mutation UpdateMigrant(
    $input: UpdateMigrantInput!
    $condition: ModelMigrantConditionInput
  ) {
    updateMigrant(input: $input, condition: $condition) {
      id
      reservedBy
      branchEA {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      suppliedBy
      branchSupplier {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      educationLevel
      salaryRange
      maritalStatus
      religion
      nationality
      status
      supplierCode
      name
      age
      dateOfBirth
      placeOfBirth
      height
      weight
      residentialAddress
      region
      nationalID
      passportNumber
      nameOfPort
      contactNumber
      additionalContactNumber
      nextOfKin
      nextOfKinContactNumber
      numberOfSiblings
      languages
      experience
      childrenNumber
      agesOfChildren
      photoKey
      additionalPhoto
      additionalVideoKey
      supportingDocuments
      allergies
      illness
      epilepsy
      asthma
      diabetes
      hypertension
      tuberculosis
      heart_disease
      malaria
      operations
      others
      physicalDisabilities
      dietaryRestriction
      foodHandlingPreferences
      foodHandlingPreferencesOthers
      restDaysPerMonth
      otherRemarks
      otherRemarksC3
      inactiveMessage
      methodOfEvaluationSkills
      nameOfForeignTrainingCentre
      stateIfThirdPartyIsCertified
      stateIfThirdPartyIsCertifiedSpecify
      interviewedByOverseasTrainingCentre
      employmentHistoryOverseasDateFrom1
      employmentHistoryOverseasDateTo1
      employmentHistoryCountry1
      employmentHistoryEmployer1
      employmentHistoryWorkDuties1
      employmentHistoryWorkDutiesRemarks1
      employmentHistoryOverseasDateFrom2
      employmentHistoryOverseasDateTo2
      employmentHistoryCountry2
      employmentHistoryEmployer2
      employmentHistoryWorkDuties2
      employmentHistoryWorkDutiesRemarks2
      employmentHistoryOverseasDateFrom3
      employmentHistoryOverseasDateTo3
      employmentHistoryCountry3
      employmentHistoryEmployer3
      employmentHistoryWorkDuties3
      employmentHistoryWorkDutiesRemarks3
      employmentHistoryOverseasDateFrom4
      employmentHistoryOverseasDateTo4
      employmentHistoryCountry4
      employmentHistoryEmployer4
      employmentHistoryWorkDuties4
      employmentHistoryWorkDutiesRemarks4
      employmentHistoryOverseasDateFrom5
      employmentHistoryOverseasDateTo5
      employmentHistoryCountry5
      employmentHistoryEmployer5
      employmentHistoryWorkDuties5
      employmentHistoryWorkDutiesRemarks5
      careOfInfantsDetailA2
      careOfInfantsWillingnessA2
      careOfInfantsHasExperienceYearA2
      careOfInfantsExperienceYearA2
      careOfInfantsAssesmentA2
      careOfElderlyDetailA2
      careOfElderlyWillingnessA2
      careOfElderlyHasExperienceYearA2
      careOfElderlyExperienceYearA2
      careOfElderlyAssesmentA2
      careOfDisabledDetailA2
      careOfDisabledWillingnessA2
      careOfDisabledHasExperienceYearA2
      careOfDisabledExperienceYearA2
      careOfDisabledAssesmentA2
      generalHouseworkDetailA2
      generalHouseworkWillingnessA2
      generalHouseworkHasExperienceYearA2
      generalHouseworkExperienceYearA2
      generalHouseworkAssesmentA2
      cookingDetailA2
      cookingWillingnessA2
      cookingHasExperienceYearA2
      cookingExperienceYearA2
      cookingAssesmentA2
      languageDetailA2
      languageExperienceHasYearA2
      languageExperienceYearA2
      languageAssesmentA2
      otherSkillsDetailA2
      otherSkillsHasExperienceYearA2
      otherSkillsExperienceYearA2
      otherSkillsAssesmentA2
      careOfInfantsDetailB1
      careOfInfantsWillingnessB1
      careOfInfantsHasExperienceYearB1
      careOfInfantsExperienceYearB1
      careOfInfantsAssesmentB1
      careOfElderlyDetailB1
      careOfElderlyWillingnessB1
      careOfElderlyHasExperienceYearB1
      careOfElderlyExperienceYearB1
      careOfElderlyAssesmentB1
      careOfDisabledDetailB1
      careOfDisabledWillingnessB1
      careOfDisabledHasExperienceYearB1
      careOfDisabledExperienceYearB1
      careOfDisabledAssesmentB1
      generalHouseworkDetailB1
      generalHouseworkWillingnessB1
      generalHouseworkHasExperienceYearB1
      generalHouseworkExperienceYearB1
      generalHouseworkAssesmentB1
      cookingDetailB1
      cookingWillingnessB1
      cookingHasExperienceYearB1
      cookingExperienceYearB1
      cookingAssesmentB1
      languageDetailB1
      languageExperienceHasYearB1
      languageExperienceYearB1
      languageAssesmentB1
      otherSkillsDetailB1
      otherSkillsHasExperienceYearB1
      otherSkillsExperienceYearB1
      otherSkillsAssesmentB1
      hygieneCompetent
      hygieneNeedTraining
      hygieneTraining
      hygineAssesment
      adaptabilityCompetent
      adaptabilityNeedTraining
      adaptabilityTraining
      adaptabilityAssesment
      disciplineCompetent
      disciplineNeedTraining
      disciplineTraining
      disciplineAssesment
      coreSkills
      coreFitnessMentalCompetent
      coreFitnessMentalNeedTraining
      coreFitnessMentalTraining
      coreFitnessMentalAssesment
      coreFitnessPhysicalCompetent
      coreFitnessPhysicalNeedTraining
      coreFitnessPhysicalTraining
      coreFitnessPhysicalAssesment
      mentalillnessCompetent
      mentalillnessTraining
      mentalillnessAssesment
      physicalFitnessCompetent
      physicalFitnessTraining
      physicalFitnessAssesment
      certifiedOrAudityDescription
      certifiedOrAudityInterviewMethod
      hasWorkingExperienceInSingapore
      previousEmployer1
      previousEmployerFeedback1
      previousEmployer2
      previousEmployerFeedback2
      previousEmployer3
      previousEmployerFeedback3
      availabilityOfMDWToBeInterviewed
      wasFeedbackFromEA
      availablityToInterviewDetail
      isInActive
      priceCode
      priceCodeMDWDeploymentCountry
      referenceCode
      outProOn
      createdOn
      deletedOn
      updatedOn
      deleted
      createdAt
      updatedAt
      # owner
    }
  }
`
export const deleteMigrant = /* GraphQL */ `
  mutation DeleteMigrant(
    $input: DeleteMigrantInput!
    $condition: ModelMigrantConditionInput
  ) {
    deleteMigrant(input: $input, condition: $condition) {
      id
      reservedBy
      branchEA {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      suppliedBy
      branchSupplier {
        id
        companyID
        branchName
        code
        status
        phone
        address
        remarks
        isMainBranch
        type
        deleted
        deletedOn
        createdAt
        updatedAt
        # owner
      }
      educationLevel
      salaryRange
      maritalStatus
      religion
      nationality
      status
      supplierCode
      name
      age
      dateOfBirth
      placeOfBirth
      height
      weight
      residentialAddress
      region
      nationalID
      passportNumber
      nameOfPort
      contactNumber
      additionalContactNumber
      nextOfKin
      nextOfKinContactNumber
      numberOfSiblings
      languages
      experience
      childrenNumber
      agesOfChildren
      photoKey
      additionalPhoto
      additionalVideoKey
      supportingDocuments
      allergies
      illness
      epilepsy
      asthma
      diabetes
      hypertension
      tuberculosis
      heart_disease
      malaria
      operations
      others
      physicalDisabilities
      dietaryRestriction
      foodHandlingPreferences
      foodHandlingPreferencesOthers
      restDaysPerMonth
      otherRemarks
      otherRemarksC3
      inactiveMessage
      methodOfEvaluationSkills
      nameOfForeignTrainingCentre
      stateIfThirdPartyIsCertified
      stateIfThirdPartyIsCertifiedSpecify
      interviewedByOverseasTrainingCentre
      employmentHistoryOverseasDateFrom1
      employmentHistoryOverseasDateTo1
      employmentHistoryCountry1
      employmentHistoryEmployer1
      employmentHistoryWorkDuties1
      employmentHistoryWorkDutiesRemarks1
      employmentHistoryOverseasDateFrom2
      employmentHistoryOverseasDateTo2
      employmentHistoryCountry2
      employmentHistoryEmployer2
      employmentHistoryWorkDuties2
      employmentHistoryWorkDutiesRemarks2
      employmentHistoryOverseasDateFrom3
      employmentHistoryOverseasDateTo3
      employmentHistoryCountry3
      employmentHistoryEmployer3
      employmentHistoryWorkDuties3
      employmentHistoryWorkDutiesRemarks3
      employmentHistoryOverseasDateFrom4
      employmentHistoryOverseasDateTo4
      employmentHistoryCountry4
      employmentHistoryEmployer4
      employmentHistoryWorkDuties4
      employmentHistoryWorkDutiesRemarks4
      employmentHistoryOverseasDateFrom5
      employmentHistoryOverseasDateTo5
      employmentHistoryCountry5
      employmentHistoryEmployer5
      employmentHistoryWorkDuties5
      employmentHistoryWorkDutiesRemarks5
      careOfInfantsDetailA2
      careOfInfantsWillingnessA2
      careOfInfantsHasExperienceYearA2
      careOfInfantsExperienceYearA2
      careOfInfantsAssesmentA2
      careOfElderlyDetailA2
      careOfElderlyWillingnessA2
      careOfElderlyHasExperienceYearA2
      careOfElderlyExperienceYearA2
      careOfElderlyAssesmentA2
      careOfDisabledDetailA2
      careOfDisabledWillingnessA2
      careOfDisabledHasExperienceYearA2
      careOfDisabledExperienceYearA2
      careOfDisabledAssesmentA2
      generalHouseworkDetailA2
      generalHouseworkWillingnessA2
      generalHouseworkHasExperienceYearA2
      generalHouseworkExperienceYearA2
      generalHouseworkAssesmentA2
      cookingDetailA2
      cookingWillingnessA2
      cookingHasExperienceYearA2
      cookingExperienceYearA2
      cookingAssesmentA2
      languageDetailA2
      languageExperienceHasYearA2
      languageExperienceYearA2
      languageAssesmentA2
      otherSkillsDetailA2
      otherSkillsHasExperienceYearA2
      otherSkillsExperienceYearA2
      otherSkillsAssesmentA2
      careOfInfantsDetailB1
      careOfInfantsWillingnessB1
      careOfInfantsHasExperienceYearB1
      careOfInfantsExperienceYearB1
      careOfInfantsAssesmentB1
      careOfElderlyDetailB1
      careOfElderlyWillingnessB1
      careOfElderlyHasExperienceYearB1
      careOfElderlyExperienceYearB1
      careOfElderlyAssesmentB1
      careOfDisabledDetailB1
      careOfDisabledWillingnessB1
      careOfDisabledHasExperienceYearB1
      careOfDisabledExperienceYearB1
      careOfDisabledAssesmentB1
      generalHouseworkDetailB1
      generalHouseworkWillingnessB1
      generalHouseworkHasExperienceYearB1
      generalHouseworkExperienceYearB1
      generalHouseworkAssesmentB1
      cookingDetailB1
      cookingWillingnessB1
      cookingHasExperienceYearB1
      cookingExperienceYearB1
      cookingAssesmentB1
      languageDetailB1
      languageExperienceHasYearB1
      languageExperienceYearB1
      languageAssesmentB1
      otherSkillsDetailB1
      otherSkillsHasExperienceYearB1
      otherSkillsExperienceYearB1
      otherSkillsAssesmentB1
      hygieneCompetent
      hygieneNeedTraining
      hygieneTraining
      hygineAssesment
      adaptabilityCompetent
      adaptabilityNeedTraining
      adaptabilityTraining
      adaptabilityAssesment
      disciplineCompetent
      disciplineNeedTraining
      disciplineTraining
      disciplineAssesment
      coreSkills
      coreFitnessMentalCompetent
      coreFitnessMentalNeedTraining
      coreFitnessMentalTraining
      coreFitnessMentalAssesment
      coreFitnessPhysicalCompetent
      coreFitnessPhysicalNeedTraining
      coreFitnessPhysicalTraining
      coreFitnessPhysicalAssesment
      mentalillnessCompetent
      mentalillnessTraining
      mentalillnessAssesment
      physicalFitnessCompetent
      physicalFitnessTraining
      physicalFitnessAssesment
      certifiedOrAudityDescription
      certifiedOrAudityInterviewMethod
      hasWorkingExperienceInSingapore
      previousEmployer1
      previousEmployerFeedback1
      previousEmployer2
      previousEmployerFeedback2
      previousEmployer3
      previousEmployerFeedback3
      availabilityOfMDWToBeInterviewed
      wasFeedbackFromEA
      availablityToInterviewDetail
      isInActive
      priceCode
      priceCodeMDWDeploymentCountry
      referenceCode
      outProOn
      createdOn
      deletedOn
      updatedOn
      deleted
      createdAt
      updatedAt
      # owner
    }
  }
`
export const createPriceCodeCountryCode = /* GraphQL */ `
  mutation CreatePriceCodeCountryCode(
    $input: CreatePriceCodeCountryCodeInput!
    $condition: ModelPriceCodeCountryCodeConditionInput
  ) {
    createPriceCodeCountryCode(input: $input, condition: $condition) {
      id
      countryCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const updatePriceCodeCountryCode = /* GraphQL */ `
  mutation UpdatePriceCodeCountryCode(
    $input: UpdatePriceCodeCountryCodeInput!
    $condition: ModelPriceCodeCountryCodeConditionInput
  ) {
    updatePriceCodeCountryCode(input: $input, condition: $condition) {
      id
      countryCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const deletePriceCodeCountryCode = /* GraphQL */ `
  mutation DeletePriceCodeCountryCode(
    $input: DeletePriceCodeCountryCodeInput!
    $condition: ModelPriceCodeCountryCodeConditionInput
  ) {
    deletePriceCodeCountryCode(input: $input, condition: $condition) {
      id
      countryCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const createPriceCodeSupplierPriceCode = /* GraphQL */ `
  mutation CreatePriceCodeSupplierPriceCode(
    $input: CreatePriceCodeSupplierPriceCodeInput!
    $condition: ModelPriceCodeSupplierPriceCodeConditionInput
  ) {
    createPriceCodeSupplierPriceCode(input: $input, condition: $condition) {
      id
      supplierPriceCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const updatePriceCodeSupplierPriceCode = /* GraphQL */ `
  mutation UpdatePriceCodeSupplierPriceCode(
    $input: UpdatePriceCodeSupplierPriceCodeInput!
    $condition: ModelPriceCodeSupplierPriceCodeConditionInput
  ) {
    updatePriceCodeSupplierPriceCode(input: $input, condition: $condition) {
      id
      supplierPriceCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const deletePriceCodeSupplierPriceCode = /* GraphQL */ `
  mutation DeletePriceCodeSupplierPriceCode(
    $input: DeletePriceCodeSupplierPriceCodeInput!
    $condition: ModelPriceCodeSupplierPriceCodeConditionInput
  ) {
    deletePriceCodeSupplierPriceCode(input: $input, condition: $condition) {
      id
      supplierPriceCode
      createdAt
      updatedAt
      # owner
    }
  }
`
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      createdAt
    }
  }
`
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      # userID
      # user {
      #   id
      #   email
      #   phoneNo
      #   fullName
      #   userType
      #   branchID
      #   staffCode
      #   registrationNo
      #   designation
      #   deleted
      #   deletedOn
      #   role
      #   status
      #   gender
      #   createdAt
      #   updatedAt
      #   owner
      # }
      # description
      # isRead
      # readOn
      # createdAt
      updatedAt
    }
  }
`
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        phoneNo
        fullName
        userType
        branchID
        staffCode
        registrationNo
        designation
        deleted
        deletedOn
        role
        status
        gender
        createdAt
        updatedAt
        owner
      }
      description
      isRead
      readOn
      createdAt
      updatedAt
    }
  }
`
export const reserveMDWForEA = /* GraphQL */ `
  mutation ReserveMDWForEA($mdwID: String!, $eaID: String!) {
    reserveMDWForEA(mdwID: $mdwID, eaID: $eaID)
  }
`
export const confirmMDWForEA = /* GraphQL */ `
  mutation ConfirmMDWForEA($mdwID: String!, $eaID: String!) {
    confirmMDWForEA(mdwID: $mdwID, eaID: $eaID)
  }
`
export const confirmSupplier = /* GraphQL */ `
  mutation ConfirmSupplier($supplierID: String!, $supplierCode: String!) {
    confirmSupplier(supplierID: $supplierID, supplierCode: $supplierCode)
  }
`
export const confirmEA = /* GraphQL */ `
  mutation ConfirmEA($eaID: String!, $eaCode: String!) {
    confirmEA(eaID: $eaID, eaCode: $eaCode)
  }
`
export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $branchID: ID!
    $email: AWSEmail!
    $phoneNo: String!
    $fullName: String
    $staffCode: String
    $registrationNo: String
    $designation: String
    $mobileNo: String
  ) {
    createStaff(
      branchID: $branchID
      email: $email
      phoneNo: $phoneNo
      fullName: $fullName
      staffCode: $staffCode
      registrationNo: $registrationNo
      designation: $designation
      mobileNo: $mobileNo
    )
  }
`
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $staffID: ID!
    $staffCode: String
    $registrationNo: Int
    $phoneNo: String
    $fullName: String
    $designation: String
    $status: StaffStatus
  ) {
    updateStaff(
      staffID: $staffID
      staffCode: $staffCode
      registrationNo: $registrationNo
      phoneNo: $phoneNo
      fullName: $fullName
      designation: $designation
      status: $status
    )
  }
`
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff($staffID: ID!) {
    deleteStaff(staffID: $staffID)
  }
`
export const adminDeleteUser = /* GraphQL */ `
  mutation AdminDeleteUser($userID: ID!) {
    adminDeleteUser(userID: $userID)
  }
`
export const adminCreateStaff = /* GraphQL */ `
  mutation AdminCreateStaff(
    $branchID: ID!
    $designation: String
    $email: AWSEmail!
    $fullName: String
    $mobileNo: String
    $phoneNo: String!
    $registrationNo: String
    $staffCode: String
    $userType: UserType!
  ) {
    adminCreateStaff(
      branchID: $branchID
      designation: $designation
      email: $email
      fullName: $fullName
      mobileNo: $mobileNo
      phoneNo: $phoneNo
      registrationNo: $registrationNo
      staffCode: $staffCode
      userType: $userType
    )
  }
`
export const adminUpdateStaff = /* GraphQL */ `
  mutation AdminUpdateStaff(
    $designation: String
    $fullName: String
    $phoneNo: String
    $registrationNo: Int
    $staffCode: String
    $staffID: ID!
    $status: StaffStatus
  ) {
    adminUpdateStaff(
      designation: $designation
      fullName: $fullName
      phoneNo: $phoneNo
      registrationNo: $registrationNo
      staffCode: $staffCode
      staffID: $staffID
      status: $status
    )
  }
`
export const adminDeleteStaff = /* GraphQL */ `
  mutation AdminDeleteStaff($staffID: ID!) {
    adminDeleteStaff(staffID: $staffID)
  }
`
