import React from 'react'
import {
  Layout,
  Col,
  Row,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Modal,
  Space,
  notification,
} from 'antd'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {Auth} from '@aws-amplify/auth'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons'
import ErrorText from '../../components/ErrorText'

const {Title, Text} = Typography

const {TextArea} = Input

function ForgotPassword() {
  const {Content} = Layout

  const history = useHistory()
  const [forgotPasswordSubmitEmail, setForgotPasswordSubmitEmail] =
    React.useState('')
  const [isResetPassword, setIsResetPassword] = React.useState(false)

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must at least 8 characters')

      .matches(/[a-z]{1,}/, 'Password must at least contain one lower case')
      .matches(/[A-Z]{1,}/, 'Password must at least contain one upper case')
      .matches(/\d{1,}/, 'Password must at least contain one digit')
      .matches(
        /[!@#$&]{1,}/,
        'Contain one digit • Contain one special character of one of the following (!@#$%)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  const chara = /.{8,}/
  const lowercase = /[a-z]{1,}/
  const uppercase = /[A-Z]{1,}/
  const digit = /\d{1,}/
  const special = /[!@#$&]{1,}/
  if (!isResetPassword) {
    return (
      <>
        <Layout
          className="bg-white"
          style={{
            backgroundImage: `url(${require('../../assets/image/set_password_bg.png')})`,
          }}
        >
          <Content>
            <Row justify="center" align="middle">
              <Col>
                <Space
                  direction="vertical"
                  size={10}
                  align="center"
                  style={{
                    height: '100vh',
                    justifyContent: 'center',
                  }}
                >
                  <Title style={{color: '#8893F9'}}>Forgot Password</Title>
                  <Text>Enter your email address and</Text>
                  <Text>we'll sent you a link to reset your password.</Text>
                  <Formik
                    style={{marginTop: 25}}
                    initialValues={{
                      email: '',
                    }}
                    onSubmit={async (values) => {
                      try {
                        const _forgotPassword = await Auth.forgotPassword(
                          values.email,
                        )

                        notification['success']({
                          message: 'Success',
                          description: _forgotPassword.CodeDeliveryDetails
                            .Destination
                            ? 'Verification code sent to email!'
                            : '',
                        })
                        setForgotPasswordSubmitEmail(values.email)
                        setIsResetPassword(true)
                        values.email = ''
                      } catch (error) {
                        notification['error']({
                          message: 'Error',
                          description: 'Email not found',
                        })
                      }
                    }}
                  >
                    {({
                      handleSubmit,
                      values,
                      errors,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form className="flexColCenter">
                          <Form.Item
                            name="email"
                            rules={[
                              {required: true, message: 'Email is required'},
                            ]}
                          >
                            <Typography>Email</Typography>
                            <Input
                              style={{width: 300}}
                              placeholder="Enter your email address"
                              type="email"
                              size="large"
                              className="buttonBorderRadius"
                              value={values.email}
                              onChange={(e) =>
                                setFieldValue('email', e.target.value)
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            label=" "
                            colon={false}
                            className="marginTop15"
                          >
                            <Button
                              style={{
                                backgroundColor: '#8893F9',
                                borderColor: 'transparent',
                                width: 200,
                                borderRadius: 9,
                                color: '#FFF',
                              }}
                              onClick={handleSubmit}
                              size="large"
                            >
                              SEND
                            </Button>
                          </Form.Item>
                        </Form>
                      )
                    }}
                  </Formik>
                  <Row style={{marginTop: 50}}>
                    <Col>
                      <Text>Do you remember your password? </Text>
                    </Col>
                    <Col>
                      <Text
                        style={{
                          color: '#2134E3',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => history.push('/')}
                      >
                        {' '}
                        Try Login here
                      </Text>
                    </Col>
                  </Row>
                </Space>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
    )
  } else {
    return (
      <>
        <Layout
          className="bg-white"
          style={{
            backgroundImage: `url(${require('../../assets/image/set_password_bg.png')})`,
          }}
        >
          <Content>
            <Row justify="center" align="middle">
              <Col>
                <Space
                  direction="vertical"
                  size={10}
                  align="center"
                  style={{
                    height: '100vh',
                    justifyContent: 'center',
                  }}
                >
                  <Title style={{color: '#8893F9'}}>Reset Password</Title>

                  <Formik
                    initialValues={{
                      code: '',

                      password: '',
                      confirmPassword: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      try {
                        await Auth.forgotPasswordSubmit(
                          forgotPasswordSubmitEmail,
                          values.code,
                          values.password,
                        )

                        notification['success']({
                          message: 'Success',
                          description: 'Password Successfully Changed!',
                        })

                        history.push('/')
                      } catch (error) {
                        notification['error']({
                          message: 'Error',
                          description: error.name || error,
                        })
                        console.log(error)
                      }
                    }}
                  >
                    {({
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      isSubmitting,
                    }) => {
                      return (
                        <>
                          {!values.password.match(chara) ||
                          !values.password.match(lowercase) ||
                          !values.password.match(uppercase) ||
                          !values.password.match(digit) ? (
                            <Title level={4}>Password Must :</Title>
                          ) : (
                            true
                          )}

                          <ul>
                            {!values.password.match(chara) && (
                              <li>Password must at least 8 characters</li>
                            )}
                            {!values.password.match(lowercase) && (
                              <li>Include Lowercase character</li>
                            )}
                            {!values.password.match(uppercase) && (
                              <li>Include Uppercase character</li>
                            )}
                            {!values.password.match(digit) && (
                              <li>Include Number</li>
                            )}
                          </ul>

                          <Form className="flexColCenter">
                            <Form.Item name="code">
                              <Typography>Verification Code</Typography>
                              <Input
                                style={{width: 300}}
                                placeholder="Verification Code"
                                type="number"
                                size="large"
                                className="buttonBorderRadius"
                                value={values.code}
                                onChange={(e) =>
                                  setFieldValue('code', e.target.value)
                                }
                                maxLength={50}
                              />
                              <br />
                            </Form.Item>

                            <Form.Item name="password">
                              <Typography>New Password</Typography>
                              <Input.Password
                                style={{width: 300}}
                                placeholder="New Password"
                                type="password"
                                size="large"
                                className="buttonBorderRadius"
                                value={values.password}
                                onChange={(e) =>
                                  setFieldValue('password', e.target.value)
                                }
                                maxLength={50}
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                              />
                              <br />
                              {errors.password && (
                                <ErrorText>{errors.password}</ErrorText>
                              )}
                            </Form.Item>

                            <Form.Item
                              name="rePassword"
                              rules={[
                                {
                                  required: true,
                                  message: 'Confirm Password is required',
                                },
                              ]}
                            >
                              <Typography>Retype New Password</Typography>
                              <Input.Password
                                style={{width: 300}}
                                placeholder="Retype New Password"
                                type="password"
                                size="large"
                                className="buttonBorderRadius"
                                value={values.confirmPassword}
                                onChange={(e) =>
                                  setFieldValue(
                                    'confirmPassword',
                                    e.target.value,
                                  )
                                }
                                maxLength={50}
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                              />
                              <br />
                              {errors.confirmPassword && (
                                <ErrorText>{errors.confirmPassword}</ErrorText>
                              )}
                            </Form.Item>
                            <Form.Item
                              label=" "
                              colon={false}
                              className="marginTop15"
                            >
                              <Button
                                type="primary"
                                style={{
                                  // backgroundColor: '#8893F9',
                                  backgroundColor:
                                    !values.password.match(chara) ||
                                    !values.password.match(lowercase) ||
                                    !values.password.match(uppercase) ||
                                    !values.password.match(digit) ||
                                    !values.confirmPassword.match(chara) ||
                                    !values.confirmPassword.match(lowercase) ||
                                    !values.confirmPassword.match(uppercase) ||
                                    !values.confirmPassword.match(digit)
                                      ? 'gray'
                                      : '#8893F9',
                                  borderColor: 'transparent',
                                  borderRadius: 9,
                                  color: '#FFF',
                                }}
                                size="large"
                                onClick={() =>
                                  !values.password.match(chara) ||
                                  !values.password.match(lowercase) ||
                                  !values.password.match(uppercase) ||
                                  !values.password.match(digit) ||
                                  !values.confirmPassword.match(chara) ||
                                  !values.confirmPassword.match(lowercase) ||
                                  !values.confirmPassword.match(uppercase) ||
                                  !values.confirmPassword.match(digit)
                                    ? console.log(false)
                                    : handleSubmit()
                                }
                                disabled={
                                  !values.password.match(chara) ||
                                  !values.password.match(chara) ||
                                  !values.password.match(lowercase) ||
                                  !values.password.match(uppercase) ||
                                  !values.password.match(digit) ||
                                  !values.confirmPassword.match(chara) ||
                                  !values.confirmPassword.match(chara) ||
                                  !values.confirmPassword.match(lowercase) ||
                                  !values.confirmPassword.match(uppercase) ||
                                  !values.confirmPassword.match(digit)
                                }
                              >
                                RESET PASSWORD
                              </Button>
                            </Form.Item>
                          </Form>
                        </>
                      )
                    }}
                  </Formik>
                </Space>
              </Col>
            </Row>
          </Content>
        </Layout>
      </>
    )
  }
}

export default ForgotPassword
