import * as React from 'react'

export const SupplierContext = React.createContext()

export function SupplierProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const addMigrant = async (data) => {
    try {
      dispatch({
        type: 'ADD_MIGRANT',
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const editMigrant = async (data) => {
    try {
      dispatch({
        type: 'EDIT_MIGRANT',
        payload: data,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getMigrant = async (id) => {
    try {
      dispatch({
        type: 'GET_MIGRANT',
        payload: id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const clearMigrant = async () => {
    try {
      dispatch({
        type: 'CLEAR_MIGRANT',
        payload: true,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <SupplierContext.Provider
      value={{
        migrants: state.migrants,
        migrant: state.migrant,
        addMigrant,
        editMigrant,
        getMigrant,
        clearMigrant,
      }}
    >
      {props.children}
    </SupplierContext.Provider>
  )
}

const reducer = (state, action) => {
  const {payload, type} = action

  switch (type) {
    case 'ADD_MIGRANT':
      return {
        ...state,
        migrants: [...state.migrants, payload],
        migrant: '',
      }
    case 'EDIT_MIGRANT':
      const _migrantIndex = state.migrants.findIndex(
        (migrant) => migrant.id === state.migrant.id,
      )
      let _migrants = state.migrants

      _migrants[_migrantIndex] = payload
      console.log('MM', _migrants)
      return {
        ...state,
        migrants: _migrants,
        migrant: '',
      }
    case 'GET_MIGRANT':
      return {
        ...state,
        migrant: state.migrants.find((migrant) => migrant.id === payload),
      }
    case 'CLEAR_MIGRANT':
      return {
        ...state,
        migrant: '',
      }
    default:
      return state
  }
}

const initialState = {
  migrant: '',
  migrants: [
    {
      name: 'Champo Benu',
      dateOfBirth: '2022-01-28',
      placeOfBirth: 'Jakarta',
      height: '160',
      weight: '55',
      nationalityID: '0',
      residentialAddress: '',
      region: '',
      nationalID: '',
      passportNumber: '',
      salaryRangeID: '',
      nameOfPort: '',
      referenceCode: 'A31',
      status: 'AVAILABLE',
      religionID: '',
      maritalStatusID: '',
      id: 1,
      picture:
        'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/migrant1.png',
    },
    {
      name: 'Htay Hla ',
      dateOfBirth: '2022-01-28',
      placeOfBirth: 'Jakarta',
      height: '160',
      weight: '55',
      nationalityID: '0',
      residentialAddress: '',
      region: '',
      nationalID: '',
      passportNumber: '',
      salaryRangeID: '',
      nameOfPort: '',
      referenceCode: 'A31',
      status: 'RESERVED',
      religionID: '',
      maritalStatusID: '',
      id: 2,
      picture:
        'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/migrant2.png',
    },
    {
      name: 'Lili Pali',
      dateOfBirth: '2022-01-28',
      placeOfBirth: 'Jakarta',
      height: '160',
      weight: '55',
      nationalityID: '0',
      residentialAddress: '',
      region: '',
      nationalID: '',
      passportNumber: '',
      salaryRangeID: '',
      nameOfPort: '',
      referenceCode: 'A31',
      status: 'OUT PROCESSED',
      religionID: '',
      maritalStatusID: '',
      id: 3,
      picture:
        'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/migrant3.png',
    },
    {
      name: 'Alisa Hernawan',
      dateOfBirth: '2022-01-28',
      placeOfBirth: 'Jakarta',
      height: '160',
      weight: '55',
      nationalityID: '0',
      residentialAddress: '',
      region: '',
      nationalID: '',
      passportNumber: '',
      salaryRangeID: '',
      nameOfPort: '',
      referenceCode: 'A31',
      status: 'CONFIRMED',
      religionID: '',
      maritalStatusID: '',
      id: 4,
      picture:
        'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/migrant4.png',
    },
    {
      name: 'Indah',
      dateOfBirth: '2022-01-28',
      placeOfBirth: 'Jakarta',
      height: '160',
      weight: '55',
      nationalityID: '0',
      residentialAddress: '',
      region: '',
      nationalID: '',
      passportNumber: '',
      salaryRangeID: '',
      nameOfPort: '',
      referenceCode: 'A31',
      status: 'RESERVED',
      religionID: '',
      maritalStatusID: '',
      id: 5,
      picture:
        'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/migrant5.png',
    },
  ],
}
