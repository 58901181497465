import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {checkLoginUser} from './checkValidity'
import {Auth} from '@aws-amplify/auth'

export const PrivateRoute = ({component: Component, ...rest}) => {
  const [userGroup, setUserGroup] = React.useState('')

  React.useEffect(() => {
    const init = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser()
        setUserGroup(
          userData.signInUserSession.accessToken.payload['cognito:groups'][0],
        )
      } catch (error) {
        console.error(error)
      }
    }
    init()
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (checkLoginUser()) {
          switch (userGroup) {
            case 'admin':
              if (
                rest.path !== '/admin/dashboard' &&
                rest.path !== '/admin/bio/:id' &&
                rest.path !== '/admin/supplier/:id' &&
                rest.path !== '/admin/ea/:id' &&
                rest.path !== '/admin/profile'
              ) {
                return <Redirect to={'/not-found'} />
              }
              return <Component {...props} />

            case 'supplier':
              if (
                rest.path !== '/supplier/dashboard' &&
                rest.path !== '/supplier/add-new-bio' &&
                rest.path !== '/supplier/profile' &&
                rest.path !== '/bio/:id'
              ) {
                return <Redirect to={'/not-found'} />
              }
              return <Component {...props} />
            case 'ea':
              if (
                rest.path !== '/ea/dashboard' &&
                rest.path !== '/ea/profile' &&
                rest.path !== '/ea/bio/:id' &&
                rest.path !== '/print/bio/:id'
              ) {
                return <Redirect to={'/not-found'} />
              }
              return <Component {...props} />

            default:
              return <Component {...props} />
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
        }
      }}
    />
  )
}
/* export const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={(props) =>
      checkLoginUser() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    }
  />
)
 */
