import React from 'react'
import {
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Popover,
  Checkbox,
  List,
  Badge,
  notification,
  Pagination,
} from 'antd'
import {
  CaretDownFilled,
  BellOutlined,
  CalendarOutlined,
  IdcardOutlined,
  SearchOutlined,
  FilterOutlined,
  RightCircleTwoTone,
  DownCircleTwoTone,
  ReconciliationTwoTone,
  BookTwoTone,
  SmileTwoTone,
  MehTwoTone,
  IdcardTwoTone,
  HighlightTwoTone,
} from '@ant-design/icons'
import Bio from '../../../components/Bio'
import VirtualList from 'rc-virtual-list'
import moment from 'moment'
import {SupplierContext} from '../../../contexts/SupplierProvider'
import {useHistory} from 'react-router-dom'
import {API} from '@aws-amplify/api'
// import {Storage} from '@aws-amplify/storage'
import {
  updateMigrant,
  createNotification,
  updateNotification,
} from '../../../backend/graphql/mutations'
import {
  listMigrants,
  listNotifications,
  listMigrantByStatus,
  getBranch,
  listStatisticsByType,
} from '../../../backend/graphql/queries'
import {capitalize, filter, orderBy, includes, concat, uniqBy} from 'lodash'
import {getSignedInUserDetails} from '../../../utils/checkValidity'
import awsmobile from '../../../backend/aws-exports'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card

const NotificationPopOver = () => {
  const [notifications, setNotifications] = React.useState([])
  const init = async () => {
    try {
      const _listNotifications = await API.graphql({
        query: listNotifications,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _listNotificationsOrderByCreatedAt = orderBy(
        _listNotifications.data.listNotifications.items,
        ['createdAt'],
        ['desc'],
      )

      setNotifications(_listNotificationsOrderByCreatedAt)
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <List>
      <VirtualList
        data={notifications}
        height={230}
        itemHeight={47}
        itemKey="id"
        // onScroll={onScroll}
      >
        {(item) => (
          <List.Item
            key={item.id}
            style={{cursor: 'pointer'}}
            onClick={async () => {
              try {
                await API.graphql({
                  query: updateNotification,
                  variables: {
                    input: {
                      id: item.id,
                      isRead: true,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                init()
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: JSON.stringify(error),
                })
              }
            }}
          >
            <Row>
              <Col span={23}>
                <Space direction="vertical">
                  <Text type="secondary" style={{width: 50, fontSize: 13}}>
                    {item.description}
                  </Text>
                  <Text style={{color: '#8893F9', fontSize: 11}}>
                    {moment(item.createdAt).fromNow()}
                  </Text>
                </Space>
              </Col>
              {!item.isRead && (
                <Col span={1}>
                  <Badge count={1} size="default" color={'#8893F9'} />
                </Col>
              )}
            </Row>
          </List.Item>
        )}
      </VirtualList>
    </List>
  )
}

const PerformanceSalesPopOver = ({statisticsSummaryItems, type}) => {
  const [data, setData] = React.useState([])
  const [tempData, setTempData] = React.useState([])

  React.useEffect(() => {
    let _data = []
    if (type == 'OutPro') {
      statisticsSummaryItems.filter((item) => {
        if (item.outProcessed.length > 0) {
          _data.push({
            companyName: item.referenceName,
            number: item.outProcessed.length,
          })
        }
      })

      setData(_data)
      setTempData(_data)
    } else if (type == 'Confirmed') {
      statisticsSummaryItems.filter((item) => {
        if (item.confirmed.length > 0) {
          _data.push({
            companyName: item.referenceName,
            number: item.confirmed.length,
          })
        }
      })
      setData(_data)
      setTempData(_data)
    } else if (type == 'Reserved') {
      statisticsSummaryItems.filter((item) => {
        if (item.reserved.length > 0) {
          _data.push({
            companyName: item.referenceName,
            number: item.reserved.length,
          })
        }
      })
      setData(_data)
      setTempData(_data)
    }
  }, [])

  return (
    <Space direction="vertical">
      <Input
        // style={{width: 300}}
        placeholder="Search"
        type="text"
        size="middle"
        addonAfter={<SearchOutlined />}
        onChange={(e) => {
          let filtered = filter(tempData, function (item) {
            return (
              item.companyName
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) > -1
            )
          })

          setData(filtered)
        }}
      />
      <List>
        <VirtualList
          data={data}
          height={150}
          itemHeight={30}
          itemKey="id"
          // onScroll={onScroll}
        >
          {(item) => (
            <List.Item key={item.id} style={{cursor: 'pointer'}}>
              <Row justify="space-between" style={{width: 200}}>
                <Col style={{width: 190}}>
                  <Text style={{fontSize: 13}}>{item.companyName}</Text>
                </Col>
                <Col>
                  <Text style={{color: '#8893F9', fontSize: 11}}>
                    {item.number}
                  </Text>
                </Col>
              </Row>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </Space>
  )
}

const CurrentMDWStatusSider = () => {
  const [migrants, setMigrants] = React.useState([])

  const [nextToken, setNextToken] = React.useState('')

  const init = async (nextToken = '') => {
    try {
      const variables = {
        nextToken,
      }

      if (!nextToken) {
        delete variables.nextToken
      }

      const _listMigrants = await API.graphql({
        query: listMigrants,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        filter: {
          deleted: {
            eq: 0,
          },
        },
        variables,
      })

      const _migrants = migrants.concat(_listMigrants.data.listMigrants.items)
      setMigrants(_migrants)

      if (_listMigrants.data.listMigrants.nextToken) {
        setNextToken(_listMigrants.data.listMigrants.nextToken)
      } else {
        setNextToken('')
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    if (nextToken) {
      init(nextToken)
    }
  }, [nextToken])

  return (
    <Sider
      width="270"
      style={{
        backgroundColor: '#FFF',
        padding: 10,
      }}
    >
      <Popover
        content={<NotificationPopOver />}
        overlayStyle={{
          width: 250,
        }}
        trigger="hover"
        placement="bottomLeft"
      >
        <Row
          align="middle"
          justify="space-between"
          style={{
            border: '1px solid #8893F9',

            borderRadius: 5,
            padding: 8,
          }}
        >
          <Col>Notifications</Col>
          <Col>
            <BellOutlined style={{color: '#8893F9', fontSize: 25}} />
          </Col>
        </Row>
      </Popover>
      <Space
        style={{
          backgroundColor: '#8893F9',
          borderRadius: 5,
          padding: 15,
          marginTop: 25,
          width: 250,
        }}
        direction="vertical"
        size={20}
      >
        <Text strong style={{color: '#FFF', fontSize: 18}}>
          Current MDW Status
        </Text>

        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totSupplied.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total MDW in Database&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {migrants.filter((migrant) => migrant.deleted === 0).length}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totPending.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Pending&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter(
                  (migrant) =>
                    migrant.status === 'PENDING' && migrant.deleted === 0,
                ).length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totPending.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Submitted&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'SUBMITTED')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totAvailable.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Available&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'AVAILABLE')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totInactive.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Inactive&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter(
                  (migrant) =>
                    migrant.status === 'INACTIVE' && migrant.deleted === 0,
                ).length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totReserved.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Reserved
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'RESERVED')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totConfirm.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Confirm
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'CONFIRMED')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totSubmitted.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Out Processed&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {migrants.filter((migrant) => migrant.status === 'OUTPRO').length}
            </Text>
          </Col>
        </Row>
      </Space>
    </Sider>
  )
}
const migrants = [
  {
    id: 1,
    migrantPicture: require('../../../assets/image/migrant1.png'),
    migrantName: 'Htay La',
    migrantNationality: 'Indonesia',
    migrantReligion: 'Islam',
    migrantMaritalStatus: 'Marriage',
    migrantRefCode: 39393939,
    migrantDueDate: '13/4/2022',
    migrantStatus: 'SUBMITTED',
  },
  {
    id: 2,
    migrantPicture: require('../../../assets/image/migrant2.png'),
    migrantName: 'Mayang',
    migrantNationality: 'Indonesia',
    migrantReligion: 'Islam',
    migrantMaritalStatus: 'Marriage',
    migrantRefCode: 39393939,
    migrantDueDate: '13/4/2022',
    migrantStatus: 'AVAILABLE',
  },
  {
    id: 3,
    migrantPicture: require('../../../assets/image/migrant3.png'),
    migrantName: 'Larasati',
    migrantNationality: 'Indonesia',
    migrantReligion: 'Islam',
    migrantMaritalStatus: 'Marriage',
    migrantRefCode: 39393939,
    migrantDueDate: '13/4/2022',
    migrantStatus: 'EXTENDED',
  },
]

const Migrant = ({
  id,
  picture,
  name,
  status,
  nationalityID,
  nationality,
  maritalStatus,
  religion,
  referenceCode,
  dueDate,
  setSelectedMDW,
  priceCode,
  photoKey,
  updatedAt,
  suppliedBy,
  migrant,
}) => {
  const [_status, setStatus] = React.useState(status)
  const [_picture, _setPicture] = React.useState('')
  const supplierContext = React.useContext(SupplierContext)
  /* React.useEffect(() => {
    const init = async () => {
      try {
        const __picture = await Storage.get(photoKey)
        _setPicture(__picture)
      } catch (error) {
        console.error(error)
      }
    }

    if (photoKey) {
      init()
    }
  }, []) */
  const history = useHistory()

  const _updateMigrant = async (_status) => {
    try {
      console.log('[Received status]', _status)
      let extendedDate = new Date(
        new Date(migrant.reserveExpiryOn) + 5 * 86400000,
      )

      extendedDate.setDate(extendedDate.getDate() + 5)

      let _variables =
        _status !== 'INACTIVE'
          ? {
              input: {
                id,
                status: 'RESERVED',
                reserveExpiryOn: extendedDate,
                isExtended: true,
              },
            }
          : {
              input: {
                id,
                status: _status,
                isInActive: true,
                inInactiveByUserID:
                  getSignedInUserDetails().UserAttributes.find(
                    (userAttribute) => userAttribute.Name === 'sub',
                  ).Value,
              },
            }
      await API.graphql({
        query: updateMigrant,
        variables: _variables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      const _getBranch = await API.graphql({
        query: getBranch,
        variables: {
          id: suppliedBy,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: createNotification,
        variables: {
          input: {
            userID: _getBranch.data.getBranch.company.userID,
            description: `${name} status already ${capitalize(_status)}`,
            isRead: false,
            readOn: moment().format(),
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      return notification['success']({
        message: 'Success',
        description: 'MDW Bio status updated!',
      })
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const DropDownStatus = ({migrantId}) => (
    <Menu>
      {/* <Menu.Item onClick={() => setStatus('SUBMITTED')}>SUBMITTED</Menu.Item> */}
      {/*  <Menu.Item
        onClick={() => {
          setStatus('AVAILABLE')
          _updateMigrant()
        }}
      >
        AVAILABLE
      </Menu.Item> */}
      {/*  <Menu.Item
        onClick={() => {
          setStatus('RESERVED')
          _updateMigrant('RESERVED')
        }}
      >
        RESERVED
      </Menu.Item> */}
      {/*  <Menu.Item
        onClick={() => {
          setStatus('CONFIRMED')
          _updateMigrant('CONFIRMED')
        }}
      >
        CONFIRMED
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          setStatus('INACTIVE')
          _updateMigrant('INACTIVE')
        }}
      >
        INACTIVE
      </Menu.Item>
      {/*  <Menu.Item
        onClick={() => {
          setStatus('OUT PROCESSED')
          _updateMigrant('OUTPRO')
        }}
      >
        OUT PROCESSED
      </Menu.Item> */}
      {status === 'RESERVED' && (
        <Menu.Item
          onClick={() => {
            setStatus('EXTENDED')
            _updateMigrant('EXTENDED')
          }}
        >
          EXTENDED
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <Card
      style={{
        borderRadius: 15,
        overflow: 'hidden',
      }}
      bordered
      hoverable
      cover={
        <>
          <img
            alt={`mdw-${id}-photo`}
            src={
              photoKey
                ? `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_appsync_region}.amazonaws.com/public/${photoKey}`
                : require('../../../assets/image/profilePicture.png')
            }
            // onClick={() => setSelectedMDW(name)}
            onClick={() => {
              supplierContext.getMigrant(id)
              history.push(`/admin/bio/${id}`)
            }}
            style={{
              height: 135,
              objectFit: 'contain',
            }}
          />
          {_status === 'CONFIRMED' ||
          _status === 'AVAILABLE' ||
          _status === 'OUT PROCESSED' ||
          _status === 'RESERVED' ||
          // (_status === 'SUBMITTED' && priceCode) ||
          _status === 'SUBMITTED' ||
          _status === 'INACTIVE' ||
          _status === 'AVAILABLE' ||
          _status === 'EXTENDED' ? (
            <Dropdown
              key="more"
              overlay={<DropDownStatus migrantId={id} status={_status} />}
            >
              <Row
                align="middle"
                justify="space-between"
                style={{
                  backgroundColor:
                    _status === 'AVAILABLE'
                      ? '#D8E2FF'
                      : _status === 'SUBMITTED'
                      ? '#FDA732'
                      : _status === 'OUT PROCESSED'
                      ? '#CA75FA'
                      : _status === 'CONFIRMED'
                      ? '#AEFA96'
                      : _status === 'EXTENDED' || _status === 'RESERVED'
                      ? '#ECFF42'
                      : '#FCF889',
                }}
              >
                <Col>
                  <Text></Text>
                </Col>
                <Col style={{paddingLeft: 25}}>
                  <Text>{_status}</Text>
                </Col>
                <Col style={{paddingRight: 5}}>
                  <CaretDownFilled />
                </Col>
              </Row>
            </Dropdown>
          ) : (
            <Row
              align="middle"
              justify="center"
              style={{
                backgroundColor:
                  _status === 'AVAILABLE'
                    ? '#D8E2FF'
                    : _status === 'SUBMITTED'
                    ? '#FDA732'
                    : _status === 'OUT PROCESSED'
                    ? '#CA75FA'
                    : _status === 'CONFIRMED'
                    ? '#AEFA96'
                    : _status === 'EXTENDED' || _status === 'RESERVED'
                    ? '#ECFF42'
                    : '#FCF889',
              }}
            >
              <Col>
                <Text>{_status}</Text>
              </Col>
            </Row>
          )}
        </>
      }
      actions={
        _status === 'RESERVED' && migrant.reserveExpiryOn
          ? [
              <Row justify="center" gutter={10}>
                <Col>
                  <Text style={{fontSize: 11, color: 'red'}}>
                    {migrant.isExtended && 'Extended '}Due Date
                  </Text>
                </Col>
                <Col>
                  <Text style={{fontSize: 11, color: 'red'}}>
                    {moment(migrant.reserveExpiryOn).format('DD/MM/YYYY')}
                  </Text>
                </Col>
              </Row>,
            ]
          : []
      }
      bodyStyle={{
        backgroundColor:
          migrant.status === 'RESERVED'
            ? moment(migrant.reserveExpiryOn).diff(moment(), 'days') >= 4
              ? '#FFF'
              : moment(migrant.reserveExpiryOn).diff(moment(), 'days') > 1 &&
                moment(migrant.reserveExpiryOn).diff(moment(), 'days') <= 3
              ? '#F0F789'
              : '#F1B0B0'
            : '#FFF',
      }}
    >
      <Space
        direction="vertical"
        onClick={() => history.push(`/admin/bio/${id}`)}
        style={{width: '100%'}}
      >
        <Title level={4}>{name}</Title>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Nationality</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>{capitalize(nationality)}</Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Age</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {moment().diff(migrant.dateOfBirth, 'years')}
            </Text>
            {/* <Text style={{fontSize: 11}}>
              {nationalityID === 1 ? 'Cambodia' : 'Indonesia'}
            </Text> */}
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Religion</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {religion
                ? religion === 'FREE_THINKER'
                  ? 'Free Thinker'
                  : capitalize(religion)
                : '-'}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Marital Status</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {maritalStatus
                ? maritalStatus === 'SINGLE_PARENT'
                  ? 'Single Parent '
                  : capitalize(maritalStatus)
                : '-'}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Ref. Code</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>{referenceCode}</Text>
          </Col>
        </Row>
      </Space>
    </Card>
  )
}

const SearchFilterContent = ({
  filterParams,
  setFilterParams,
  migrants,
  setMigrants,
  tempMigrants,
  setIsPopOverShown,
}) => {
  const defaultAllParams = {
    name: '',
    nationality: [],
    region: '',
    religion: [],
    maritalStatus: [],
    typeOfMaid: [],
    languageAbility: [],
    offDayPreferences: [],
    responsibility: [],
    agePreferences: [],
    educationLevel: [],
    experience: [],
    salaryRanges: [],
  }

  const [allParams, setAllParams] = React.useState(defaultAllParams)

  React.useEffect(() => {
    let filterLoadashResult = []

    // Handler filter by name
    filterLoadashResult = filter(tempMigrants, function (item) {
      return item.name.toLowerCase().indexOf(allParams.name.toLowerCase()) > -1
    })

    // Handler filter by nationality
    if (allParams.nationality.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.nationality, item.nationality)
      })
    }

    // Handler filter by Indonesia region
    if (allParams.region) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          allParams.region === item.region && item.nationality === 'INDONESIA'
        )
      })
    }

    // Handler filter by religion
    if (allParams.religion.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.religion, item.religion)
      })
    }

    // Handler filter by marital status
    if (allParams.maritalStatus.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.maritalStatus, item.maritalStatus)
      })
    }

    // Handler filter by type of maid
    if (allParams.typeOfMaid.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.typeOfMaid.includes('NEW') &&
            !item.employmentHistoryCountry1 &&
            !item.employmentHistoryCountry2 &&
            !item.employmentHistoryCountry3 &&
            !item.employmentHistoryCountry4 &&
            !item.employmentHistoryCountry5) ||
          (allParams.typeOfMaid.includes('EXPERIENCED') &&
            (item.employmentHistoryCountry1 ||
              item.employmentHistoryCountry2 ||
              item.employmentHistoryCountry3 ||
              item.employmentHistoryCountry4 ||
              item.employmentHistoryCountry5))
        )
      })

      /*  if (allParams.typeOfMaid.includes('EX-OVERSEAS')) {
        filterLoadashResult = filter(filterLoadashResult, function (item) {
          return (
            item.employmentHistoryOverseasDateFrom1 ||
            item.employmentHistoryOverseasDateTo1 ||
            item.employmentHistoryCountry1
          )
        })
      } */
    }

    // Handler filter by language abilities
    if (allParams.languageAbility.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.languageAbility.includes('ENGLISH') &&
            (item.languageDetailA2.toUpperCase().includes('ENGLISH') ||
              item.languageDetailB1.toUpperCase().includes('ENGLISH'))) ||
          (allParams.languageAbility.includes('INDONESIAN') &&
            (item.languageDetailA2.toUpperCase().includes('INDONESIAN') ||
              item.languageDetailB1.toUpperCase().includes('INDONESIAN') ||
              item.languageDetailA2.toUpperCase().includes('BAHASA') ||
              item.languageDetailB1.toUpperCase().includes('BAHASA') ||
              item.languageDetailA2.toUpperCase().includes('MALAYU') ||
              item.languageDetailB1.toUpperCase().includes('MALAYU') ||
              item.languageDetailA2.toUpperCase().includes('MELAYU') ||
              item.languageDetailB1.toUpperCase().includes('MALAYU'))) ||
          (allParams.languageAbility.includes('MANDARIN') &&
            (item.languageDetailA2.toUpperCase().includes('MANDARIN') ||
              item.languageDetailB1.toUpperCase().includes('MANDARIN')))
        )
      })
    }

    // Handler filter by off day preference
    if (allParams.offDayPreferences.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(
          allParams.offDayPreferences,
          String(item.restDaysPerMonth),
        )
      })
    }

    // Handler filter by type responsibility
    if (allParams.responsibility.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.responsibility.includes('CAREGIVER') &&
            (item.careOfInfantsHasExperienceYearA2 ||
              item.careOfInfantsDetailA2 ||
              item.careOfInfantsHasExperienceYearB1 ||
              item.careOfInfantsDetailB1)) ||
          (allParams.responsibility.includes('CARE_OF_ELDERLY') &&
            (item.careOfElderlyHasExperienceYearA2 ||
              item.careOfElderlyDetailA2 ||
              item.careOfElderlyHasExperienceYearB1 ||
              item.careOfElderlyDetailB1)) ||
          (allParams.responsibility.includes('CARE_FOR_INFANT') &&
            (item.careOfInfantsHasExperienceYearA2 ||
              item.careOfInfantsDetailA2 ||
              item.careOfInfantsHasExperienceYearB1 ||
              item.careOfInfantsDetailB1)) ||
          (allParams.responsibility.includes('CARE_FOR_DISABLED') &&
            (item.careOfDisabledHasExperienceYearA2 ||
              item.careOfDisabledDetailA2 ||
              item.careOfDisabledHasExperienceYearB1 ||
              item.careOfDisabledDetailB1)) ||
          (allParams.responsibility.includes('COOKING') &&
            (item.cookingHasExperienceYearA2 ||
              item.cookingDetailA2 ||
              item.cookingHasExperienceYearB1 ||
              item.cookingDetailB1)) ||
          (allParams.responsibility.includes('GENERAL_HOUSE_WORK') &&
            (item.generalHouseworkHasExperienceYearA2 ||
              item.generalHouseworkDetailA2 ||
              item.generalHouseworkHasExperienceYearB1 ||
              item.generalHouseworkDetailB1))
        )
      })
    }

    // Handler filter by age preferences
    if (allParams.agePreferences.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        let age = moment().diff(item.dateOfBirth, 'years')
        return (
          (allParams.agePreferences.includes('23-25') &&
            age >= 23 &&
            age <= 25) ||
          (allParams.agePreferences.includes('26-30') &&
            age >= 26 &&
            age <= 30) ||
          (allParams.agePreferences.includes('31-35') &&
            age >= 31 &&
            age <= 35) ||
          (allParams.agePreferences.includes('36-40') &&
            age >= 36 &&
            age <= 40) ||
          (allParams.agePreferences.includes('41-45') && age >= 41 && age <= 45)
        )
      })
    }

    // Handler filter by education level
    if (allParams.educationLevel.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.educationLevel, item.educationLevel)
      })
    }

    // Handler filter by exoeriece / country
    if (allParams.experience.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.experience.includes('HOME_COUNTRY') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes(item.nationality) ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes(item.nationality) ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes(item.nationality) ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes(item.nationality) ||
              item.employmentHistoryCountry5
                ?.toUpperCase()
                ?.includes(item.nationality))) ||
          (allParams.experience.includes('SINGAPORE') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('SINGAPORE'))) ||
          (allParams.experience.includes('MALAYSIA') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('MALAYSIA'))) ||
          (allParams.experience.includes('TAIWAN') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('TAIWAN') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('TAIWAN') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('TAIWAN') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('TAIWAN') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('TAIWAN'))) ||
          (allParams.experience.includes('HONGKONG') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('HONGKONG') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('HONGKONG') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('HONGKONG') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('HONGKONG') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('HONGKONG'))) ||
          (allParams.experience.includes('MIDDLE_EAST') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('MIDDLE_EAST'))) ||
          (allParams.experience.includes('BRUNEI') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('BRUNEI') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('BRUNEI') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('BRUNEI') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('BRUNEI') ||
              item.employmentHistoryCountry5.toUpperCase().includes('BRUNEI')))
        )
      })
    }

    // Handler filter by salary range
    if (allParams.salaryRanges.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.salaryRanges.includes('RANGE_1') &&
            item.salaryRange.includes('RANGE_1')) ||
          (allParams.salaryRanges.includes('RANGE_2') &&
            item.salaryRange.includes('RANGE_2')) ||
          (allParams.salaryRanges.includes('RANGE_3') &&
            item.salaryRange.includes('RANGE_3'))
        )
      })
    }

    setMigrants(filterLoadashResult)
  }, [allParams])

  return (
    <Space direction="vertical" size={5}>
      <Title level={5}></Title>
      <Row>
        <Col>
          <Input
            style={{width: 300}}
            placeholder="Search by name"
            type="text"
            size="middle"
            addonAfter={
              <SearchOutlined onClick={() => setIsPopOverShown(false)} />
            }
            value={allParams.name}
            onChange={(e) => setAllParams({...allParams, name: e.target.value})}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={15}>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Nationality</Text>
            <Checkbox
              value="INDONESIA"
              onChange={(e) => {
                if (!allParams.nationality.includes('INDONESIA')) {
                  setAllParams({
                    ...allParams,
                    nationality: [...allParams.nationality, e.target.value],
                  })
                } else {
                  const _nationality = allParams.nationality.filter(
                    (item) => item !== 'INDONESIA',
                  )

                  setAllParams({
                    ...allParams,
                    nationality: _nationality,
                  })
                }
              }}
            >
              Indonesia
            </Checkbox>
            <Select
              defaultValue="default"
              style={{width: 200}}
              onChange={(e) => {
                setAllParams({
                  ...allParams,
                  region: e,
                })
              }}
            >
              <Option value="default" disabled>
                Select Region
              </Option>
              <Option value="">All Region</Option>
              <Option value="ACEH">Aceh</Option>
              <Option value="BALI">Bali</Option>
              <Option value="BANGKA_BELITUNG_ISLAND">
                Bangka Belitung Island
              </Option>
              <Option value="BANTEN">Banten</Option>
              <Option value="BENGKULU">Bengkulu</Option>
              <Option value="CENTRAL_JAVA">Central Java</Option>
              <Option value="CENTRAL_KALIMANTAN">Central Kalimantan</Option>
              <Option value="CENTRAL_SULAWESI">Central Sulawesi</Option>
              <Option value="EAST_JAVA">East Java</Option>
              <Option value="EAST_KALIMANTAN">East Kalimantan</Option>
              <Option value="EAST_NUSA_TENGGARA">East Nusa Tenggara</Option>
              <Option value="GORONTALO">Gorontalo</Option>
              <Option value="CAPITAL_SPECIAL_REGION_OF_JAKARTA">
                Capital Special Region of Jakarta
              </Option>
              <Option value="JAMBI">Jambi</Option>
              <Option value="LAMPUNG">Lampung</Option>
              <Option value="MALUKU">Maluku</Option>
              <Option value="NORTH_KALIMANTAN">North Kalimantan</Option>
              <Option value="NORTH_MALUKU">North Maluku</Option>
              <Option value="NORTH_SULAWESI">North Sulawesi</Option>
              <Option value="NORTH_SUMATRA">North Sumatera</Option>
              <Option value="PAPUA">Papua</Option>
              <Option value="RIAU">Riau</Option>
              <Option value="RIAU_ISLANDS">Riau Islands</Option>
              <Option value="SOUTHEAST_SULAWESI">Southeast Sulawesi</Option>
              <Option value="SOUTH_KALIMANTAN">Southeast Kalimantan</Option>
              <Option value="SOUTH_SULAWESI">South Sulawesi</Option>
              <Option value="SOUTH_SUMATERA">South Sumatera</Option>
              <Option value="WEST_JAVA">West Java</Option>
              <Option value="WEST_KALIMANTAN">West Kalimantan</Option>
              <Option value="WEST_NUSA_TENGGARA">West Nusa Tenggara</Option>
              <Option value="WEST_PAPUA">West Papua</Option>
              <Option value="WEST_SULAWESI">West Sulawesi</Option>
              <Option value="WEST_SUMATRA">West Sumatera</Option>
              <Option value="SPECIAL_REGION_OF_YOGYAKARTA">
                Special Region of Yogyakarta
              </Option>
            </Select>
            <Checkbox
              value="CAMBODIA"
              onChange={(e) => {
                if (!allParams.nationality.includes('CAMBODIA')) {
                  setAllParams({
                    ...allParams,
                    nationality: [...allParams.nationality, e.target.value],
                  })
                } else {
                  const _nationality = allParams.nationality.filter(
                    (item) => item !== 'CAMBODIA',
                  )

                  setAllParams({
                    ...allParams,
                    nationality: _nationality,
                  })
                }
              }}
            >
              Cambodia
            </Checkbox>
            <Checkbox
              value="MYANMAR"
              onChange={(e) => {
                if (!allParams.nationality.includes('MYANMAR')) {
                  setAllParams({
                    ...allParams,
                    nationality: [...allParams.nationality, e.target.value],
                  })
                } else {
                  const _nationality = allParams.nationality.filter(
                    (item) => item !== 'MYANMAR',
                  )

                  setAllParams({
                    ...allParams,
                    nationality: _nationality,
                  })
                }
              }}
            >
              Myanmar
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Religion</Text>
            <Checkbox
              value="FREE_THINKER"
              onChange={(e) => {
                if (!allParams.religion.includes('FREE_THINKER')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'FREE_THINKER',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Free Thinker
            </Checkbox>
            <Checkbox
              value="CATHOLIC"
              onChange={(e) => {
                if (!allParams.religion.includes('CATHOLIC')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'CATHOLIC',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Catholic
            </Checkbox>
            <Checkbox
              value="MUSLIM"
              onChange={(e) => {
                if (!allParams.religion.includes('MUSLIM')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'MUSLIM',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Muslim
            </Checkbox>
            <Checkbox
              value="SIKH"
              onChange={(e) => {
                if (!allParams.religion.includes('SIKH')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'SIKH',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Sikh
            </Checkbox>
            <Checkbox
              value="CHRISTIAN"
              onChange={(e) => {
                if (!allParams.religion.includes('CHRISTIAN')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'CHRISTIAN',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Christian
            </Checkbox>
            <Checkbox
              value="BUDDHIST"
              onChange={(e) => {
                if (!allParams.religion.includes('BUDDHIST')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'BUDDHIST',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Buddhist
            </Checkbox>
            <Checkbox
              value="HINDU"
              onChange={(e) => {
                if (!allParams.religion.includes('HINDU')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'HINDU',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Hindu
            </Checkbox>
            <Checkbox
              value="OTHERS"
              onChange={(e) => {
                if (!allParams.religion.includes('OTHERS')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'OTHERS',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Others
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Marital Status</Text>
            <Checkbox
              value="SINGLE"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('SINGLE')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'SINGLE',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Single
            </Checkbox>
            <Checkbox
              value="WIDOWED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('WIDOWED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'WIDOWED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Widowed
            </Checkbox>
            <Checkbox
              value="MARRIED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('MARRIED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'MARRIED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Married
            </Checkbox>
            <Checkbox
              value="DIVORCED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('DIVORCED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'DIVORCED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Divorced
            </Checkbox>
            <Checkbox
              value="SEPARATED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('SEPARATED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'SEPARATED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Separated
            </Checkbox>
            <Checkbox
              value="SINGLE_PARENT"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('SINGLE_PARENT')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'SINGLE_PARENT',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Single Parent
            </Checkbox>
            {/* <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Type of maid</Text>
            <Checkbox
              value="NEW"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('NEW')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'NEW',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              New
            </Checkbox>
            {/*             <Checkbox
              value="EX-OVERSEAS"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('EX-OVERSEAS')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'EX-OVERSEAS',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              Ex-Overseas
            </Checkbox> */}
            {/* <Checkbox value="" >Transfer</Checkbox> */}
            <Checkbox
              value="EXPERIENCED"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('EXPERIENCED')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'EXPERIENCED',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              Experienced
            </Checkbox>
            {/*  <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Language ability</Text>
            <Checkbox
              value="ENGLISH"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('ENGLISH')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'ENGLISH',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              English
            </Checkbox>
            <Checkbox
              value="BAHASA"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('INDONESIAN')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      'INDONESIAN',
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'INDONESIAN',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              Bahasa Indonesia
            </Checkbox>
            <Checkbox
              value="MANDARIN"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('MANDARIN')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'MANDARIN',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              Mandarin
            </Checkbox>
            {/* <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>
              Off days preferences
            </Text>
            <Checkbox
              value="0"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('0')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '0',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              0
            </Checkbox>
            <Checkbox
              value="1"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('1')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '1',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              1
            </Checkbox>
            <Checkbox
              value="2"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('2')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '2',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              2
            </Checkbox>
            <Checkbox
              value="3"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('3')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '3',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              3
            </Checkbox>
            <Checkbox
              value="4"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('4')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '4',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              4
            </Checkbox>
          </Space>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Responsibility</Text>
            <Checkbox
              value="CAREGIVER"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CAREGIVER')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CAREGIVER',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Caregiver
            </Checkbox>
            <Checkbox
              value="CARE_OF_ELDERLY"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CARE_OF_ELDERLY')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CARE_OF_ELDERLY',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Care of Elderly
            </Checkbox>
            <Checkbox
              value="GENERAL_HOUSE_WORK"
              onChange={(e) => {
                if (!allParams.responsibility.includes('GENERAL_HOUSE_WORK')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'GENERAL_HOUSE_WORK',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              General Housework
            </Checkbox>
            <Checkbox
              value="CARE_FOR_INFANT"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CARE_FOR_INFANT')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CARE_FOR_INFANT',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Care for Infant/Children
            </Checkbox>
            <Checkbox
              value="CARE_FOR_DISABLED"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CARE_FOR_DISABLED')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CARE_FOR_DISABLED',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Care for Disabled
            </Checkbox>
            <Checkbox
              value="COOKING"
              onChange={(e) => {
                if (!allParams.responsibility.includes('C00KING')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'COOKING',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Cooking
            </Checkbox>
            {/*             <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.responsibility.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Age Preference</Text>
            <Checkbox
              value="23-25"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('23-25')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '23-25',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              23 to 25
            </Checkbox>
            <Checkbox
              value="26-30"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('26-30')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '26-30',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              26 to 30
            </Checkbox>
            <Checkbox
              value="31-35"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('31-35')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '31-35',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              31 - 35
            </Checkbox>
            <Checkbox
              value="36-40"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('36-40')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '36-40',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              36 - 40
            </Checkbox>
            <Checkbox
              value="41-45"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('41-45')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '41-45',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              41 - 45
            </Checkbox>

            {/* <Checkbox value="">No Preference</Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Education Level</Text>
            <Checkbox
              value="COLLEGE"
              onChange={(e) => {
                if (!allParams.educationLevel.includes('COLLEGE')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'COLLEGE',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }}
            >
              College/Degree(&gt;=13yrs)
            </Checkbox>
            <Checkbox
              value="SECONDARY_SCHOOL"
              onChange={(e) => {
                if (!allParams.educationLevel.includes('SECONDARY_SCHOOL')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'SECONDARY_SCHOOL',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }}
            >
              Secondary School(8-9yrs)
            </Checkbox>
            <Checkbox
              value="HIGH_SCHOOL"
              onChange={(e) => {
                if (!allParams.educationLevel.includes('HIGH_SCHOOL')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'HIGH_SCHOOL',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }}
            >
              High School(10-12yrs)
            </Checkbox>
            <Checkbox
              value="NOT_IMPORTANT"
              /*  onChange={(e) => {
                if (!allParams.educationLevel.includes('NOT_IMPORTANT')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'NOT_IMPORTANT',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }} */
            >
              Not Important
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Experience</Text>
            <Checkbox
              value="HOME_COUNTRY"
              onChange={(e) => {
                if (!allParams.experience.includes('HOME_COUNTRY')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'HOME_COUNTRY',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Home Country
            </Checkbox>
            <Checkbox
              value="SINGAPORE"
              onChange={(e) => {
                if (!allParams.experience.includes('SINGAPORE')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'SINGAPORE',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Singapore
            </Checkbox>
            <Checkbox
              value="MALAYSIA"
              onChange={(e) => {
                if (!allParams.experience.includes('MALAYSIA')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'MALAYSIA',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Malaysia
            </Checkbox>
            <Checkbox
              value="TAIWAN"
              onChange={(e) => {
                if (!allParams.experience.includes('TAIWAN')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'TAIWAN',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Taiwan
            </Checkbox>
            <Checkbox
              value="HONGKONG"
              onChange={(e) => {
                if (!allParams.experience.includes('HONGKONG')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'HONGKONG',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Hongkong
            </Checkbox>
            <Checkbox
              value="MIDDLE_EAST"
              onChange={(e) => {
                if (!allParams.experience.includes('MIDDLE_EAST')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'MIDDLE_EAST',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Middle East
            </Checkbox>
            <Checkbox
              value="BRUNEI"
              onChange={(e) => {
                if (!allParams.experience.includes('BRUNEI')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'BRUNEI',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Brunei
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Price Range</Text>
            <Checkbox
              value="RANGE_1"
              onChange={(e) => {
                if (!allParams.salaryRanges.includes('RANGE_1')) {
                  setAllParams({
                    ...allParams,
                    salaryRanges: [...allParams.salaryRanges, e.target.value],
                  })
                } else {
                  const _salaryRanges = allParams.salaryRanges.filter(
                    (item) => item !== 'RANGE_1',
                  )

                  setAllParams({
                    ...allParams,
                    salaryRanges: _salaryRanges,
                  })
                }
              }}
            >
              400 - 500 SGD
            </Checkbox>
            <Checkbox
              value="RANGE_2"
              onChange={(e) => {
                if (!allParams.salaryRanges.includes('RANGE_2')) {
                  setAllParams({
                    ...allParams,
                    salaryRanges: [...allParams.salaryRanges, e.target.value],
                  })
                } else {
                  const _salaryRanges = allParams.salaryRanges.filter(
                    (item) => item !== 'RANGE_2',
                  )

                  setAllParams({
                    ...allParams,
                    salaryRanges: _salaryRanges,
                  })
                }
              }}
            >
              501 - 600 SGD
            </Checkbox>
            <Checkbox
              value="RANGE_3"
              onChange={(e) => {
                if (!allParams.salaryRanges.includes('RANGE_3')) {
                  setAllParams({
                    ...allParams,
                    salaryRanges: [...allParams.salaryRanges, e.target.value],
                  })
                } else {
                  const _salaryRanges = allParams.salaryRanges.filter(
                    (item) => item !== 'RANGE_3',
                  )

                  setAllParams({
                    ...allParams,
                    salaryRanges: _salaryRanges,
                  })
                }
              }}
            >
              601 - Above
            </Checkbox>
          </Space>
        </Col>
      </Row>
      <Row justify="center" align="middle" gutter={10}>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: 'gray',
              borderColor: 'transparent',
              width: 150,
              borderRadius: 9,
              color: '#FFF',
            }}
            size="large"
            // onClick={() => setAllParams(defaultAllParams)}
            onClick={() => window.location.reload()}
          >
            Clear All
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 150,
              borderRadius: 9,
              color: '#FFF',
            }}
            size="large"
            onClick={() => setIsPopOverShown(false)}
          >
            Search
          </Button>
        </Col>
      </Row>
    </Space>
  )
}

const pageSize = 48
const MDWBio = ({setSelectedMDW}) => {
  const [migrants, setMigrants] = React.useState([])
  const [pagination, setPagination] = React.useState({
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  })
  const [tempMigrants, setTempMigrants] = React.useState([])
  // const [filterParams, setFilterParams] = React.useState({})
  const [filterParams, setFilterParams] = React.useState({
    filter: {
      status: {
        ne: 'PENDING',
      },
    },
  })
  const [isPopOverShown, setIsPopOverShown] = React.useState(false)
  const [nextToken, setNextToken] = React.useState('')

  const handleChangePagination = (page) => {
    return setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    })
  }

  React.useEffect(() => {
    if (filterParams.filter.status.eq) {
      const refetchListMigrants = async () => {
        try {
          if (filterParams.filter.status.eq === 'ALL') {
            return initMigrantsData()
          }
          const _listMigrants = await API.graphql({
            query: listMigrantByStatus,
            variables: {
              status: filterParams.filter.status.eq,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })

          setMigrants(_listMigrants.data.listMigrantByStatus.items)
          setTempMigrants(_listMigrants.data.listMigrantByStatus.items)
          setPagination({
            current: 1,
            minIndex: 0,
            maxIndex: pageSize,
          })
          // setUser(_listMigrants.data.listMigrants.items)
        } catch (error) {
          notification['error']({
            message: 'Error',
            description: JSON.stringify(error),
          })
        }
      }

      refetchListMigrants()
    }
  }, [filterParams])

  const initMigrantsData = async (nextToken = '') => {
    const variables = {
      filter: {
        status: {
          ne: 'PENDING',
        },
      },
      nextToken,
    }

    if (!nextToken) {
      delete variables.nextToken
    }

    try {
      const _listMigrants = await API.graphql({
        query: listMigrants,
        variables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      // TODO: REORDER
      const _sortMigrantByCreatedOn =
        _listMigrants.data.listMigrants.items.sort(function (a, b) {
          return new Date(b.updatedAt) - new Date(a.updatedAt)
        })

      const _sortMigrantBySubmittedFirst = _sortMigrantByCreatedOn.sort(
        function (a, b) {
          if (a.status.toLowerCase() > b.status.toLowerCase()) return -1
          if (a.status.toLowerCase() > b.status.toLowerCase()) return 1
          return 0
        },
      )

      /*       const _getMigrantOnlyInactive = _sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'INACTIVE',
      )

      const _getMigrantApartFromInactive = _sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status !== 'INACTIVE',
      )

      const _migrants = migrants.concat(
        _getMigrantApartFromInactive.concat(_getMigrantOnlyInactive),
      ) */

      const _getMigrantOnlyConfirmed = _sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'CONFIRMED',
      )
      const _getMigrantOnlyReserved = _sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'RESERVED',
      )

      const _getMigrantOnlyOutpro = _sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'OUTPRO',
      )

      const _getMigrantOnlyInactive = _sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'INACTIVE' && migrant.deleted === 0,
      )

      const _getMigrantApartFromInactiveReservedConfirmed =
        _sortMigrantBySubmittedFirst.filter(
          (migrant) =>
            migrant.status !== 'INACTIVE' &&
            migrant.status !== 'RESERVED' &&
            migrant.status !== 'CONFIRMED' &&
            migrant.status !== 'OUTPRO',
        )

      /* const _migrants = migrants.concat(
        _getMigrantOnlyConfirmed.concat(
          _getMigrantOnlyReserved.concat(
            _getMigrantApartFromInactiveReservedConfirmed.concat(
              _getMigrantOnlyInactive,
            ),
          ),
        ),
      )  */

      const _migrants = uniqBy(
        concat(
          migrants,
          _getMigrantOnlyConfirmed,
          _getMigrantOnlyReserved,
          _getMigrantApartFromInactiveReservedConfirmed,
          _getMigrantOnlyOutpro,
          _getMigrantOnlyInactive,
        ),
        'id',
      )

      // TODO: REORDER
      const __sortMigrantByCreatedOn = _migrants.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })

      const __sortMigrantBySubmittedFirst = __sortMigrantByCreatedOn.sort(
        function (a, b) {
          if (a.status.toLowerCase() > b.status.toLowerCase()) return -1
          if (a.status.toLowerCase() > b.status.toLowerCase()) return 1
          return 0
        },
      )

      /*  const __getMigrantOnlyInactive = __sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'INACTIVE',
      )

      const __getMigrantApartFromInactive =
        __sortMigrantBySubmittedFirst.filter(
          (migrant) => migrant.status !== 'INACTIVE',
        )  */

      const __getMigrantOnlyOutpro = __sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'OUTPRO',
      )

      const __getMigrantOnlyInactive = __sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'INACTIVE' && migrant.deleted === 0,
      )

      const __getMigrantOnlyReserved = __sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'RESERVED',
      )

      const __getMigrantOnlyConfirmed = __sortMigrantBySubmittedFirst.filter(
        (migrant) => migrant.status === 'CONFIRMED',
      )

      const __getMigrantApartFromInactiveReservedConfirmed =
        __sortMigrantBySubmittedFirst.filter(
          (migrant) =>
            migrant.status !== 'INACTIVE' &&
            migrant.status !== 'OUTPRO' &&
            migrant.status !== 'RESERVED' &&
            migrant.status !== 'CONFIRMED',
        )

      setMigrants(
        uniqBy(
          concat(
            __getMigrantOnlyConfirmed,
            __getMigrantOnlyReserved,
            __getMigrantApartFromInactiveReservedConfirmed,
            __getMigrantOnlyOutpro,
            __getMigrantOnlyInactive,
          ),
          'id',
        ),
      )
      setTempMigrants(
        uniqBy(
          concat(
            __getMigrantOnlyConfirmed,
            __getMigrantOnlyReserved,
            __getMigrantApartFromInactiveReservedConfirmed,
            __getMigrantOnlyOutpro,
            __getMigrantOnlyInactive,
          ),
          'id',
        ),
      )
      setPagination({...pagination, maxIndex: pageSize})
      /*  setMigrants(
        __getMigrantOnlyConfirmed.concat(
          __getMigrantOnlyReserved.concat(
            __getMigrantApartFromInactiveReservedConfirmed.concat(
              __getMigrantOnlyInactive,
            ),
          ),
        ),
      )
      setTempMigrants(
        __getMigrantOnlyConfirmed.concat(
          __getMigrantOnlyReserved.concat(
            __getMigrantApartFromInactiveReservedConfirmed.concat(
              __getMigrantOnlyInactive,
            ),
          ),
        ),
      ) */

      /*       setMigrants(
        __getMigrantApartFromInactive.concat(__getMigrantOnlyInactive),
      )
      setTempMigrants(
        __getMigrantApartFromInactive.concat(__getMigrantOnlyInactive),
      ) */

      if (_listMigrants.data.listMigrants.nextToken) {
        setNextToken(_listMigrants.data.listMigrants.nextToken)
      } else {
        setNextToken('')
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }
  React.useEffect(() => {
    initMigrantsData()
  }, [])
  React.useEffect(() => {
    if (nextToken) {
      initMigrantsData(nextToken)
    }
  }, [nextToken])

  return (
    <Space
      direction="vertical"
      size={20}
      style={{backgroundColor: '#FFF', padding: '20px 10px 0 10px'}}
    >
      <Row gutter={8}>
        <Col span="8">
          <Popover
            content={
              <SearchFilterContent
                {...{filterParams}}
                {...{setFilterParams}}
                {...{migrants}}
                {...{setMigrants}}
                {...{tempMigrants}}
                {...{setIsPopOverShown}}
              />
            }
            title={
              <Row justify="space-between">
                <Col>Search MDW</Col>
                <Col>
                  <Typography.Link
                    style={{color: 'red'}}
                    onClick={() => setIsPopOverShown(false)}
                  >
                    Close
                  </Typography.Link>
                </Col>
              </Row>
            }
            trigger="click"
            placement="topLeft"
            visible={isPopOverShown}
          >
            <Input
              addonAfter={
                <FilterOutlined onClick={() => setIsPopOverShown(true)} />
              }
              placeholder="Search &amp; Filter"
              readOnly
              onClick={() => setIsPopOverShown(true)}
            />
          </Popover>
        </Col>
        <Col span="8">
          {/*           <Input
            addonBefore={<SearchOutlined />}
            addonAfter={<CaretDownFilled />}
            placeholder="Status"
          /> */}
          <Select
            defaultValue="selectStatus"
            style={{width: 300}}
            value={filterParams?.status?.eq}
            onChange={(e) => {
              setFilterParams({
                filter: {
                  status: {
                    eq: e,
                  },
                },
              })
              /* if (e) {
                setFilterParams({
                  filter: {
                    status: {
                      eq: e,
                    },
                  },
                })
              } else {
                setFilterParams({
                  filter: {
                    status: {
                      ne: 'PENDING',
                    },
                  },
                })
              } */
            }}
          >
            <Option value="selectStatus" disabled>
              Select Status
            </Option>
            <Option value="ALL">All Status</Option>
            <Option value="AVAILABLE">Available</Option>
            <Option value="SUBMITTED">Submitted</Option>
            <Option value="RESERVED">Reserved</Option>
            <Option value="CONFIRMED">Confirmed</Option>
            <Option value="OUTPRO">Out Processed</Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={2} justify="end">
        <Col>
          <Pagination
            pageSize={pageSize}
            current={pagination.current}
            total={migrants.length}
            onChange={handleChangePagination}
            showSizeChanger={false}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        {migrants?.map(
          (migrant, index) =>
            index >= pagination.minIndex &&
            index < pagination.maxIndex && (
              <Col
                span={6}
                key={migrant.id}
                style={{marginTop: 10, marginBottom: 10}}
              >
                <Migrant {...migrant} {...{migrant}} {...{setSelectedMDW}} />
              </Col>
            ),
        )}
      </Row>
    </Space>
  )
}

const PerformanceSales = () => {
  const [expandable, setExpandable] = React.useState({
    outPro: false,
    confirmed: false,
    reserved: false,
  })
  const [statisticsSummaryNumber, setStatisticsSummaryNumber] = React.useState({
    outProcessed: [],
    confirmed: [],
    reserved: [],
  })
  const [statisticsSummaryItems, setStatisticsSummaryItems] = React.useState('')
  const [type, setType] = React.useState('EA')
  const [performanceSalesCountry, setPerformanceSalesCountry] =
    React.useState('')

  const init = async () => {
    try {
      const statistics = await API.graphql({
        query: listStatisticsByType,
        variables: {
          type,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      let totalOutpro = 0
      let totalReserved = 0
      let totalConfirmed = 0

      let summaryItems = []

      statistics.data.listStatisticsByType.items.filter((item) => {
        totalOutpro += item.outProcessed.length
        totalReserved += item.reserved.length
        totalConfirmed += item.confirmed.length

        summaryItems = [...summaryItems, item]
      })

      setStatisticsSummaryNumber({
        ...statisticsSummaryNumber,
        outProcessed: totalOutpro,
        confirmed: totalConfirmed,
        reserved: totalReserved,
      })

      setStatisticsSummaryItems(summaryItems)
    } catch (error) {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [type])

  React.useEffect(() => {
    if (performanceSalesCountry && statisticsSummaryItems) {
      let _statisticsSummaryItems = statisticsSummaryItems
      const outproFilter = statisticsSummaryItems.map((item) =>
        item.outProcessed.filter(
          (item) => item.nationality === performanceSalesCountry,
        ),
      )
      const confirmedFilter = statisticsSummaryItems.map((item) =>
        item.confirmed.filter(
          (item) => item.nationality === performanceSalesCountry,
        ),
      )
      const reservedFilter = statisticsSummaryItems.map((item) =>
        item.reserved.filter(
          (item) => item.nationality === performanceSalesCountry,
        ),
      )

      _statisticsSummaryItems.outProcessed = outproFilter
      _statisticsSummaryItems.confirmed = confirmedFilter
      _statisticsSummaryItems.reserved = reservedFilter

      setStatisticsSummaryNumber({
        ...statisticsSummaryNumber,
        outProcessed: outproFilter[0].length,
        confirmed: confirmedFilter[0].length,
        reserved: reservedFilter[0].length,
      })
      setStatisticsSummaryItems(_statisticsSummaryItems)
    } else if (!performanceSalesCountry && statisticsSummaryItems) {
      let totalOutpro = 0
      let totalReserved = 0
      let totalConfirmed = 0

      let summaryItems = []

      let _statisticsSummaryItems = statisticsSummaryItems
      _statisticsSummaryItems.filter((item) => {
        totalOutpro += item.outProcessed.length
        totalReserved += item.reserved.length
        totalConfirmed += item.confirmed.length

        summaryItems = [...summaryItems, item]
      })

      setStatisticsSummaryNumber({
        ...statisticsSummaryNumber,
        outProcessed: totalOutpro,
        confirmed: totalConfirmed,
        reserved: totalReserved,
      })

      setStatisticsSummaryItems(summaryItems)
    }
  }, [performanceSalesCountry])

  return (
    <Space
      direction="vertical"
      size={70}
      style={{backgroundColor: '#FFF', padding: '20px 10px 0 10px'}}
    >
      <Row gutter={5}>
        <Col>
          {/* <Input
            addonBefore={<SearchOutlined />}
            addonAfter={selectAfter} //<CaretDownFilled />
            placeholder="Criteria"
          /> */}
          <Select
            style={{width: 250}}
            value={type}
            onChange={(e) => setType(e)}
          >
            <Option value="" disabled>
              Select Criteria
            </Option>
            <Option value="EA">Employment Agency</Option>
            <Option value="SUPPLIER">Supplier</Option>
          </Select>
        </Col>
        <Col style={{marginLeft: 10}}>
          <Select
            style={{width: 250}}
            value={performanceSalesCountry}
            onChange={(e) => setPerformanceSalesCountry(e)}
          >
            <Option value="" disabled>
              Select Country
            </Option>
            {/* <Option value="SINGAPORET">Singapore</Option> */}
            <Option value="">All Countries</Option>
            <Option value="INDONESIA">Indonesia</Option>
            <Option value="CAMBODIA">Cambodia</Option>
            <Option value="MYANMAR">Myanmar</Option>
          </Select>
        </Col>
        <Col style={{marginLeft: 10}}>
          <Button
            type="primary"
            style={{
              backgroundColor: '#10DF1D',
              borderColor: 'transparent',
              width: 160,
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span="8">
          <Card
            style={{
              width: 240,
              borderRadius: 15,
              overflow: 'hidden',
              backgroundColor: '#DCE0FF',
            }}
            bordered={true}
            cover={
              <Space
                direction="vertical"
                align="center"
                style={{paddingTop: 25}}
              >
                <Space direction="horizontal" align="end">
                  <SearchOutlined style={{color: '#8893F9', fontSize: 48}} />

                  <Space direction="vertical" align="start">
                    <Text style={{color: '#8893F9', fontSize: 13}}>
                      OUT PROCESSED
                    </Text>
                    <Text style={{color: '#8893F9', fontSize: 25}} strong>
                      {statisticsSummaryNumber.outProcessed}
                    </Text>
                  </Space>
                </Space>
              </Space>
            }
            actions={
              statisticsSummaryNumber.outProcessed > 0
                ? [
                    <Popover
                      content={
                        <PerformanceSalesPopOver
                          {...{statisticsSummaryItems}}
                          type="OutPro"
                        />
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Row
                        align="middle"
                        justify="center"
                        onClick={() =>
                          setExpandable({
                            ...expandable,
                            outPro: !expandable.outPro,
                          })
                        }
                      >
                        <Col>
                          <Text style={{color: '#8893F9'}}>
                            More Details&nbsp;
                          </Text>
                          {!expandable.outPro ? (
                            <RightCircleTwoTone twoToneColor="#8893F9" />
                          ) : (
                            <DownCircleTwoTone twoToneColor="#8893F9" />
                          )}
                        </Col>
                      </Row>
                    </Popover>,
                  ]
                : []
            }
          ></Card>
        </Col>
        <Col span="8">
          <Card
            style={{
              width: 240,
              borderRadius: 15,
              overflow: 'hidden',
              backgroundColor: '#DCE0FF',
            }}
            bordered={true}
            cover={
              <Space
                direction="vertical"
                align="center"
                style={{paddingTop: 25}}
              >
                <Space direction="horizontal" align="end">
                  <IdcardOutlined style={{color: '#8893F9', fontSize: 48}} />

                  <Space direction="vertical" align="start">
                    <Text style={{color: '#8893F9', fontSize: 13}}>
                      TOTAL CONFIRMED
                    </Text>
                    <Text style={{color: '#8893F9', fontSize: 25}} strong>
                      {statisticsSummaryNumber.confirmed}
                    </Text>
                  </Space>
                </Space>
              </Space>
            }
            actions={
              statisticsSummaryNumber.confirmed > 0
                ? [
                    <Popover
                      content={
                        <PerformanceSalesPopOver
                          {...{statisticsSummaryItems}}
                          type="Confirmed"
                        />
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Row
                        align="middle"
                        justify="center"
                        onClick={() =>
                          setExpandable({
                            ...expandable,
                            confirmed: !expandable.confirmed,
                          })
                        }
                      >
                        <Col>
                          <Text style={{color: '#8893F9'}}>
                            More Details&nbsp;
                          </Text>
                          {!expandable.confirmed ? (
                            <RightCircleTwoTone twoToneColor="#8893F9" />
                          ) : (
                            <DownCircleTwoTone twoToneColor="#8893F9" />
                          )}
                        </Col>
                      </Row>
                    </Popover>,
                  ]
                : []
            }
          ></Card>
        </Col>
        <Col span="8">
          <Card
            style={{
              width: 240,
              borderRadius: 15,
              overflow: 'hidden',
              backgroundColor: '#DCE0FF',
            }}
            bordered={true}
            cover={
              <Space
                direction="vertical"
                align="center"
                style={{paddingTop: 25}}
              >
                <Space direction="horizontal" align="end">
                  <CalendarOutlined style={{color: '#8893F9', fontSize: 48}} />

                  <Space direction="vertical" align="start">
                    <Text style={{color: '#8893F9', fontSize: 13}}>
                      TOTAL RESERVED
                    </Text>
                    <Text style={{color: '#8893F9', fontSize: 25}} strong>
                      {statisticsSummaryNumber.reserved}
                    </Text>
                  </Space>
                </Space>
              </Space>
            }
            actions={
              statisticsSummaryNumber.reserved > 0
                ? [
                    <Popover
                      content={
                        <PerformanceSalesPopOver
                          {...{statisticsSummaryItems}}
                          type="Reserved"
                        />
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Row
                        align="middle"
                        justify="center"
                        onClick={() =>
                          setExpandable({
                            ...expandable,
                            reserved: !expandable.reserved,
                          })
                        }
                      >
                        <Col>
                          <Text style={{color: '#8893F9'}}>
                            More Details&nbsp;
                          </Text>
                          {!expandable.reserved ? (
                            <RightCircleTwoTone twoToneColor="#8893F9" />
                          ) : (
                            <DownCircleTwoTone twoToneColor="#8893F9" />
                          )}
                        </Col>
                      </Row>
                    </Popover>,
                  ]
                : []
            }
          ></Card>
        </Col>
      </Row>
    </Space>
  )
}

function Dashboard() {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
  const [selectedMDW, setSelectedMDW] = React.useState('')
  const selectedTabStyle = {
    backgroundColor: '#8893F9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingTop: 10,
  }
  const unSelectTabStyle = {
    borderBottom: '1px solid #8893F9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingTop: 10,
  }

  if (selectedMDW) {
    return <Bio {...{selectedMDW}} />
  }

  return (
    <>
      <Content
        style={{
          backgroundColor: '#FFF',
          border: '1px solid #8893F9',
          borderRadius: 5,
          marginLeft: '5%',

          marginTop: 10,
        }}
      >
        {/* <Content style={{backgroundColor: '#FFF', padding: 10}}> */}
        {/*         <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            padding: 15,
            marginLeft: '6%',
            backgroundColor: '#FFF',
          }}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="MDW Bio" key="1">
              <MDWBio {...{setSelectedMDW}} />
            </TabPane>
            <TabPane tab="Performance Sales" key="2">
              <PerformanceSales />
            </TabPane>
          </Tabs>
        </Layout> */}
        <Space direction="vertical" style={{width: '100%'}}>
          <Row>
            <Col
              style={
                selectedTabIndex === 0 ? selectedTabStyle : unSelectTabStyle
              }
              span={12}
              onClick={() => setSelectedTabIndex(0)}
            >
              <Title
                level={3}
                style={{
                  color: selectedTabIndex !== 0 ? '#8893F9' : '#FFF',
                }}
              >
                MDW Bio
              </Title>
            </Col>
            <Col
              style={
                selectedTabIndex === 1 ? selectedTabStyle : unSelectTabStyle
              }
              span={12}
              onClick={() => setSelectedTabIndex(1)}
            >
              <Title
                level={3}
                style={{
                  color: selectedTabIndex !== 1 ? '#8893F9' : '#FFF',
                }}
              >
                Performance Sales
              </Title>
            </Col>
          </Row>
          <Layout>
            {selectedTabIndex === 0 ? (
              <MDWBio {...{setSelectedMDW}} />
            ) : (
              <PerformanceSales />
            )}
          </Layout>
        </Space>
      </Content>
      <CurrentMDWStatusSider />
    </>
  )
}

export default Dashboard
