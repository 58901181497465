import React from 'react'
import {
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Avatar,
  PageHeader,
  Radio,
  Table,
  Divider,
  Checkbox,
  notification,
  Switch,
} from 'antd'
import {
  CaretDownFilled,
  UserOutlined,
  UserAddOutlined,
  DashboardOutlined,
  FileImageTwoTone,
  FilePdfOutlined,
  VideoCameraTwoTone,
  FileTwoTone,
  UserSwitchOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {SupplierContext} from '../../../contexts/SupplierProvider'
import {Auth} from '@aws-amplify/auth'
import {Formik} from 'formik'
import {getSignedInUserDetails} from '../../../utils/checkValidity'
import {API} from '@aws-amplify/api'
import {
  listUsers,
  getMigrantSuperAdmin,
  listPriceCodeCountryCodes,
  listPriceCodeSupplierPriceCodes,
  getCompany,
  getBranch,
} from '../../../backend/graphql/queries'
import {
  createMigrant,
  updateMigrant,
  createPriceCodeCountryCode,
  createPriceCodeSupplierPriceCode,
} from '../../../backend/graphql/mutations'
import moment from 'moment'
import {Storage} from '@aws-amplify/storage'
import awsMobile from '../../../backend/aws-exports'
import Slider from 'react-slick'
import {orderBy, capitalize} from 'lodash'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card

const MainMenuSider = ({setActiveSider}) => {
  const history = useHistory()
  return (
    <Sider style={{backgroundColor: '#FFF'}}>
      <Menu
        style={{width: 256, height: '100%'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
      >
        <Menu.Item
          icon={<DashboardOutlined />}
          onClick={() => {
            history.push({
              pathname: '/admin/dashboard',
            })
          }}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          icon={<UserSwitchOutlined />}
          onClick={() =>
            history.push({
              pathname: '/admin/dashboard',
              state: {
                activeSider: 1,
              },
            })
          }
        >
          Supplier
        </Menu.Item>
        <Menu.Item
          icon={<FormOutlined />}
          onClick={() =>
            history.push({
              pathname: '/admin/dashboard',
              state: {
                activeSider: 2,
              },
            })
          }
        >
          Employment Agencies
        </Menu.Item>
        {getSignedInUserDetails().UserAttributes.find(
          (userAttribute) => userAttribute.Name === 'custom:userType',
        ) && (
          <Menu.Item
            icon={<UserAddOutlined />}
            onClick={() => {
              history.push({
                pathname: '/admin/dashboard',
                state: {
                  activeSider: 3,
                },
              })
            }}
          >
            OUR Account Mgt
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}

const Wrapper = ({children, setActiveSider, user}) => {
  return (
    <div style={{backgroundColor: '#FAFAFA'}}>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          borderBottom: '1px solid #ecf0f1',
        }}
        className="site-page-header"
        onBack={() => null}
        backIcon={false}
        title=" "
        subTitle=" "
        avatar={{
          src: 'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/our.png',
          size: 65,
        }}
        extra={[<DropdownMenu {...{user}} />]}
      />
      <Layout style={{backgroundColor: '#FFF'}}>
        <MainMenuSider {...{setActiveSider}} />

        {children}
      </Layout>
    </div>
  )
}

const DropdownMenu = ({user}) => {
  const history = useHistory()

  const MenuToolbar = () => {
    return (
      <Menu>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => history.push('/supplier/profile')}
          >
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          {/* <a rel="noopener noreferrer" onClick={() => history.push('/')}> */}
          <a
            rel="noopener noreferrer"
            onClick={async () => {
              try {
                await Auth.signOut()
                history.push('/')
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error.name,
                })
              }
            }}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown key="more" overlay={MenuToolbar}>
      <Button
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        <Row align="middle" justify="center" style={{marginTop: 15}}>
          <Col>
            <Avatar size={32} icon={<UserOutlined />} />
          </Col>

          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Text strong>{user?.fullName || user?.email}</Text>

            <Text type="secondary">{user?.email}</Text>
          </Col>

          <Col>
            <CaretDownFilled />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}

const RenderPicture = ({s3Key, type, needRefetch}) => {
  const [picture, setPicture] = React.useState('')

  const init = async () => {
    try {
      const _picture = await Storage.get(s3Key)

      setPicture(_picture)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [needRefetch])

  const _renderPicture = (type) => {
    switch (type) {
      case 'PHOTO':
        return (
          <a href={picture} target="_blank">
            <img src={picture} style={{height: 400, width: 300}} alt="Photo" />
          </a>
        )
      case 'ADDITIONAL_PHOTO':
        return (
          <a href={picture} target="_blank">
            <img
              src={picture}
              style={{height: 62, width: 62}}
              alt="Additional Photo"
            />
          </a>
        )
      case 'ADDITIONAL_VIDEO':
        return (
          <a href={picture} target="_blank">
            <video src={picture} style={{height: 62, width: 62}}>
              Your browser does not support the video tag.
            </video>
          </a>
        )
      case 'SUPPORTING_DOCUMENT':
        return (
          <a href={picture} target="_blank" style={{height: 62, width: 62}}>
            <FilePdfOutlined style={{fontSize: 30}} />
          </a>
        )
      default:
        return (
          <img src={picture} style={{height: 400, width: 300}} alt="Photo" />
        )
    }
  }

  return <>{_renderPicture(type)}</>
}

const MultipleRenderWrapper = ({item, type, data, setFieldValue}) => {
  const [needRefetch, setNeedRefetch] = React.useState(false)

  return (
    <Space
      direction="vertical"
      style={
        type === 'SUPPORTING_DOCUMENT'
          ? {border: '1px solid #000', borderRadius: 5, padding: 5}
          : {}
      }
    >
      <RenderPicture s3Key={item} {...{type}} {...{needRefetch}} />
      {/* {type === 'SUPPORTING_DOCUMENT' && (
        <Text style={{fontSize: 8}}>{item}</Text>
      )} */}
      <Text
        style={{cursor: 'pointer', color: 'red'}}
        onClick={() => {
          if (type === 'ADDITIONAL_PHOTO') {
            const _additionalPhoto = data.filter((item2) => item2 !== item)
            setFieldValue('additionalPhoto', JSON.stringify(_additionalPhoto))
            setNeedRefetch(true)
          } else if (type === 'ADDITIONAL_VIDEO') {
            const _additionalVideo = data.filter((item2) => item2 !== item)
            setFieldValue(
              'additionalVideoKey',
              JSON.stringify(_additionalVideo),
            )
            setNeedRefetch(true)
          } else if (type === 'SUPPORTING_DOCUMENT') {
            const _supportingDocument = data.filter((item2) => item2 !== item)
            setFieldValue(
              'supportingDocuments',
              JSON.stringify(_supportingDocument),
            )
            setNeedRefetch(true)
          }
        }}
      >
        x remove
      </Text>
    </Space>
  )
}

function SampleNextArrow() {
  return <Text>&gt;</Text>
}
function SamplePrevArrow() {
  return <Text>&lt;</Text>
}
function BioForm(props) {
  const {match} = props
  const [pageForm, setPageForm] = React.useState(1)
  const [user, setUser] = React.useState('') //to get current user logged in
  const supplierContext = React.useContext(SupplierContext)

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
  }
  const initFormValue = {
    name: '',
    dateOfBirth: '',
    placeOfBirth: '',
    height: '',
    weight: '',
    nationalityID: '',
    residentialAddress: '',
    region: '',
    nationalID: '',
    passportNumber: '',
    salaryRangeID: '',
    nameOfPort: '',
    referenceCode: '',
    status: '',
    religionID: '',
    maritalStatusID: '',
    picture: '',
  }
  const [form, setForm] = React.useState(
    supplierContext.migrant || initFormValue,
  )
  const [eaCompanyName, setEACompanyName] = React.useState('')
  const [eaBranchName, setEABranchName] = React.useState('')

  const [priceCoding, setPriceCoding] = React.useState({
    countryCode: '',
    supplierPriceCode: '',
    mdwDeploymentCountry: 'SINGAPORE',
    isEc: '',
  })

  const _initFormValues = {
    suppliedBy: user.branchID,
    educationLevel: 'COLLEGE',
    salaryRange: 'RANGE_1',
    maritalStatus: 'SINGLE',
    religion: 'FREE_THINKER',
    nationality: 'CAMBODIA',
    status: '',
    supplierCode: '',
    name: '',
    // age: '',
    dateOfBirth: moment().format('YYYY-MM-DD'),
    placeOfBirth: '',
    height: 0,
    weight: 0,
    residentialAddress: '',
    region: 'ACEH',
    nationalID: '',
    passportNumber: '',
    nameOfPort: '',
    contactNumber: '',
    additionalContactNumber: '',
    nextOfKin: '',
    nextOfKinContactNumber: '',
    numberOfSiblings: 0,
    languages: '',
    experience: 'NEW',
    childrenNumber: 0,
    agesOfChildren: '',
    photoKey: '',
    additionalPhoto: JSON.stringify([]),
    additionalVideoKey: JSON.stringify([]),
    supportingDocuments: JSON.stringify([]),
    allergies: '',
    illness: false,
    epilepsy: false,
    asthma: false,
    diabetes: false,
    hypertension: false,
    tuberculosis: false,
    heart_disease: false,
    malaria: false,
    operations: false,
    others: '',
    physicalDisabilities: '',
    dietaryRestriction: '',
    foodHandlingPreferences: '',
    foodHandlingPreferencesOthers: '',
    restDaysPerMonth: 1,
    otherRemarks: '',
    otherRemarksC3: '',
    inactiveMessage: '',
    methodOfEvaluationSkills: JSON.stringify([]),
    nameOfForeignTrainingCentre: '',
    // stateIfThirdPartyIsCertified: 'INTERVIEWED_VIA_TELEPHONE',
    stateIfThirdPartyIsCertified: 'NA',
    stateIfThirdPartyIsCertifiedSpecify: '',
    interviewedByOverseasTrainingCentre: '',
    employmentHistoryOverseasDateFrom1: '',
    employmentHistoryOverseasDateTo1: '',
    employmentHistoryCountry1: '',
    employmentHistoryEmployer1: '',
    employmentHistoryWorkDuties1: '',
    employmentHistoryWorkDutiesRemarks1: '',
    employmentHistoryOverseasDateFrom2: '',
    employmentHistoryOverseasDateTo2: '',
    employmentHistoryCountry2: '',
    employmentHistoryEmployer2: '',
    employmentHistoryWorkDuties2: '',
    employmentHistoryWorkDutiesRemarks2: '',
    employmentHistoryOverseasDateFrom3: '',
    employmentHistoryOverseasDateTo3: '',
    employmentHistoryCountry3: '',
    employmentHistoryEmployer3: '',
    employmentHistoryWorkDuties3: '',
    employmentHistoryWorkDutiesRemarks3: '',
    employmentHistoryOverseasDateFrom4: '',
    employmentHistoryOverseasDateTo4: '',
    employmentHistoryCountry4: '',
    employmentHistoryEmployer4: '',
    employmentHistoryWorkDuties4: '',
    employmentHistoryWorkDutiesRemarks4: '',
    employmentHistoryOverseasDateFrom5: '',
    employmentHistoryOverseasDateTo5: '',
    employmentHistoryCountry5: '',
    employmentHistoryEmployer5: '',
    employmentHistoryWorkDuties5: '',
    employmentHistoryWorkDutiesRemarks5: '',
    careOfInfantsDetailA2: '',
    careOfInfantsWillingnessA2: false,
    careOfInfantsHasExperienceYearA2: false,
    careOfInfantsExperienceYearA2: '',
    careOfInfantsAssesmentA2: 1,
    careOfElderlyDetailA2: '',
    careOfElderlyWillingnessA2: false,
    careOfElderlyHasExperienceYearA2: false,
    careOfElderlyExperienceYearA2: '',
    careOfElderlyAssesmentA2: 1,
    careOfDisabledDetailA2: '',
    careOfDisabledWillingnessA2: false,
    careOfDisabledHasExperienceYearA2: false,
    careOfDisabledExperienceYearA2: '',
    careOfDisabledAssesmentA2: 1,
    generalHouseworkDetailA2: '',
    generalHouseworkWillingnessA2: false,
    generalHouseworkHasExperienceYearA2: false,
    generalHouseworkExperienceYearA2: '',
    generalHouseworkAssesmentA2: 1,
    cookingDetailA2: '',
    cookingWillingnessA2: false,
    cookingHasExperienceYearA2: false,
    cookingExperienceYearA2: '',
    cookingAssesmentA2: 1,
    languageDetailA2: '',
    languageExperienceHasYearA2: false,
    languageExperienceYearA2: '',
    languageAssesmentA2: 1,
    otherSkillsDetailA2: '',
    otherSkillsHasExperienceYearA2: false,
    otherSkillsExperienceYearA2: '',
    otherSkillsAssesmentA2: 1,
    careOfInfantsDetailB1: '',
    careOfInfantsWillingnessB1: false,
    careOfInfantsHasExperienceYearB1: false,
    careOfInfantsExperienceYearB1: '',
    careOfInfantsAssesmentB1: 1,
    careOfElderlyDetailB1: '',
    careOfElderlyWillingnessB1: false,
    careOfElderlyHasExperienceYearB1: false,
    careOfElderlyExperienceYearB1: '',
    careOfElderlyAssesmentB1: 1,
    careOfDisabledDetailB1: '',
    careOfDisabledWillingnessB1: false,
    careOfDisabledHasExperienceYearB1: false,
    careOfDisabledAssesmentB1: 1,
    generalHouseworkDetailB1: '',
    generalHouseworkWillingnessB1: false,
    generalHouseworkHasExperienceYearB1: false,
    generalHouseworkExperienceYearB1: '',
    generalHouseworkAssesmentB1: 1,
    cookingDetailB1: '',
    cookingWillingnessB1: false,
    cookingHasExperienceYearB1: false,
    cookingExperienceYearB1: '',
    cookingAssesmentB1: 1,
    languageDetailB1: '',
    languageExperienceHasYearB1: false,
    languageExperienceYearB1: false,
    languageAssesmentB1: 1,
    otherSkillsDetailB1: '',
    otherSkillsHasExperienceYearB1: false,
    otherSkillsExperienceYearB1: '',
    otherSkillsAssesmentB1: 1,
    hygieneCompetent: false,
    hygieneNeedTraining: false,
    hygieneTraining: '',
    hygineAssesment: 1,
    adaptabilityCompetent: false,
    adaptabilityNeedTraining: false,
    adaptabilityTraining: '',
    adaptabilityAssesment: 1,
    disciplineCompetent: false,
    disciplineNeedTraining: false,
    disciplineTraining: '',
    disciplineAssesment: 1,
    coreSkills: '',
    coreFitnessMentalCompetent: false,
    coreFitnessMentalNeedTraining: false,
    coreFitnessMentalTraining: '',
    coreFitnessMentalAssesment: 1,
    coreFitnessPhysicalCompetent: false,
    coreFitnessPhysicalNeedTraining: false,
    coreFitnessPhysicalTraining: '',
    coreFitnessPhysicalAssesment: 1,
    mentalillnessCompetent: false,
    mentalillnessTraining: '',
    mentalillnessAssesment: 1,
    physicalFitnessCompetent: false,
    physicalFitnessTraining: '',
    physicalFitnessAssesment: 1,
    certifiedOrAudityDescription: '',
    certifiedOrAudityInterviewMethod: '',
    hasWorkingExperienceInSingapore: false,
    previousEmployer1: '',
    previousEmployerFeedback1: '',
    previousEmployer2: '',
    previousEmployerFeedback2: '',
    previousEmployer3: '',
    previousEmployerFeedback3: '',
    availabilityOfMDWToBeInterviewed: 'NA',
    wasFeedbackFromEA: false,
    availablityToInterviewDetail: '',
    isInActive: false,
    referenceCode: '',
    deleted: 0,
  }
  const [initFormValues, setInitFormValues] = React.useState(_initFormValues)

  const [newPriceCodeData, setNewPriceCodeData] = React.useState('')
  const [priceCodeCountries, setPriceCodeCountries] = React.useState([])

  const [newSupplierPriceCodeData, setNewSupplierPriceCodeData] =
    React.useState('')
  const [supplierPriceCodeCountries, setSupplierPriceCodeCountries] =
    React.useState([])

  const history = useHistory()

  const historyOverseasDataTable = [
    {
      no: '1',
      dateFrom: '',
      dateTo: '',
      country: '',
      employer: '',
      workDuties: '',
      remarks: '',
    },
    {
      no: '2',
      dateFrom: '',
      dateTo: '',
      country: '',
      employer: '',
      workDuties: '',
      remarks: '',
    },
    {
      no: '3',
      dateFrom: '',
      dateTo: '',
      country: '',
      employer: '',
      workDuties: '',
      remarks: '',
    },
    {
      no: '4',
      dateFrom: '',
      dateTo: '',
      country: '',
      employer: '',
      workDuties: '',
      remarks: '',
    },
    {
      no: '5',
      dateFrom: '',
      dateTo: '',
      country: '',
      employer: '',
      workDuties: '',
      remarks: '',
    },
  ]

  const employeerFeedbackDataTable = [
    {
      no: '1',
      employer: '',
      feedback: '',
    },
    {
      no: '2',
      employer: '',
      feedback: '',
    },
    {
      no: '3',
      employer: '',
      feedback: '',
    },
  ]

  const _listPriceCodeCountryCodes = async () => {
    try {
      const __listPriceCodeCountryCodes = await API.graphql({
        query: listPriceCodeCountryCodes,

        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      const _countryCodesOrderedByCode = orderBy(
        __listPriceCodeCountryCodes.data.listPriceCodeCountryCodes.items,
        ['countryCode'],
        ['asc'],
      )

      setPriceCodeCountries(_countryCodesOrderedByCode)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _listPriceCodeSupplierPriceCodes = async () => {
    try {
      const __listPriceCodeSupplierPriceCodes = await API.graphql({
        query: listPriceCodeSupplierPriceCodes,

        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _supplierPriceCodeCountriesOrderedBySupplierPriceCode = orderBy(
        __listPriceCodeSupplierPriceCodes.data.listPriceCodeSupplierPriceCodes
          .items,
        ['supplierPriceCode'],
        ['asc'],
      )

      setSupplierPriceCodeCountries(
        _supplierPriceCodeCountriesOrderedBySupplierPriceCode,
      )
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const renderForm = (values, setFieldValue) => {
    const data = [1, 2, 3, 4]

    // eslint-disable-next-line default-case
    switch (pageForm) {
      case 1:
        return (
          <>
            <Title style={{color: '#8893F9'}} level={5}>
              A.1. Personal Information
              {/* {JSON.stringify(supplierContext.migrant)} */}
            </Title>
            <Space
              direction="horizontal"
              style={{
                justifyContent: 'space-between',

                width: '100%',
                alignItems: 'start',
              }}
            >
              <Space direction="vertical">
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>1. Name*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      defaultValue={values.name}
                      placeholder="Full Name"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                  </Col>
                </Row>

                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>2. Date of Birth*</Typography>
                  </Col>
                  <Col span={14}>
                    <Row align="middle">
                      <Input
                        style={{width: 200}}
                        placeholder="Date of Birth"
                        type="date"
                        size="large"
                        className="buttonBorderRadius"
                        value={values.dateOfBirth}
                        onChange={(e) =>
                          setFieldValue('dateOfBirth', e.target.value)
                        }
                      />
                      <Typography style={{marginLeft: 8, marginRight: 5}}>
                        Age
                      </Typography>
                      <Input
                        style={{width: 60}}
                        placeholder="Age"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={moment().diff(values.dateOfBirth, 'years')}
                        readOnly
                        disabled
                      />
                    </Row>
                  </Col>
                </Row>

                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>3. Place of birth*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Place of Birth"
                      size="large"
                      className="buttonBorderRadius"
                      value={values.placeOfBirth}
                      onChange={(e) =>
                        setFieldValue('placeOfBirth', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>4. Height & Weight*</Typography>
                  </Col>
                  <Col span={14}>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{width: 300}}
                    >
                      <Space
                        direction="row"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Input
                          style={{width: 100}}
                          placeholder="Height"
                          size="large"
                          className="buttonBorderRadius"
                          max={3}
                          type="number"
                          value={values.height}
                          onChange={(e) =>
                            setFieldValue(
                              'height',
                              e.target.value.length > 3
                                ? e.target.value.slice(0, 3)
                                : e.target.value,
                            )
                          }
                        />
                        <Text>cm</Text>
                      </Space>
                      <Space
                        direction="row"
                        align="middle"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Input
                          style={{width: 100}}
                          placeholder="Weight"
                          size="large"
                          className="buttonBorderRadius"
                          max={3}
                          type="number"
                          value={values.weight}
                          onChange={(e) =>
                            setFieldValue(
                              'weight',
                              e.target.value.length > 3
                                ? e.target.value.slice(0, 3)
                                : e.target.value,
                            )
                          }
                        />
                        <Text>kg</Text>
                      </Space>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>5. Nationality*</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={values.nationality}
                      defaultValue={values.nationality}
                      style={{width: 300}}
                      onChange={(e) => setFieldValue('nationality', e)}
                    >
                      <Option value="default" disabled>
                        Select Nationality
                      </Option>
                      <Option value="CAMBODIA">Cambodia</Option>
                      <Option value="INDONESIA">Indonesia</Option>
                      <Option value="MYANMAR">Myanmar</Option>
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>
                      6. Residential address in home country*
                    </Typography>
                  </Col>
                  <Col span={14}>
                    <Input.TextArea
                      style={{width: 300}}
                      placeholder="Residential Address"
                      size="large"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={100}
                      value={values.residentialAddress}
                      onChange={(e) =>
                        setFieldValue('residentialAddress', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                {values.nationality === 'INDONESIA' && (
                  <Row justify="space-between" align="middle">
                    <Col span={10}>
                      <Typography>7. Region*</Typography>
                    </Col>
                    <Col span={14}>
                      <Select
                        value={values.region}
                        defaultValue={values.region}
                        style={{width: 300}}
                        onChange={(e) => setFieldValue('region', e)}
                      >
                        <Option value="default" disabled>
                          Select Region
                        </Option>
                        <Option value="ACEH">Aceh</Option>
                        <Option value="BALI">Bali</Option>
                        <Option value="BANGKA_BELITUNG_ISLAND">
                          Bangka Belitung Island
                        </Option>
                        <Option value="BANTEN">Banten</Option>
                        <Option value="BENGKULU">Bengkulu</Option>
                        <Option value="CENTRAL_JAVA">Central Java</Option>
                        <Option value="CENTRAL_KALIMANTAN">
                          Central Kalimantan
                        </Option>
                        <Option value="CENTRAL_SULAWESI">
                          Central Sulawesi
                        </Option>
                        <Option value="EAST_JAVA">East Java</Option>
                        <Option value="EAST_KALIMANTAN">East Kalimantan</Option>
                        <Option value="EAST_NUSA_TENGGARA">
                          East Nusa Tenggara
                        </Option>
                        <Option value="GORONTALO">Gorontalo</Option>
                        <Option value="CAPITAL_SPECIAL_REGION_OF_JAKARTA">
                          Capital Special Region of Jakarta
                        </Option>
                        <Option value="JAMBI">Jambi</Option>
                        <Option value="LAMPUNG">Lampung</Option>
                        <Option value="MALUKU">Maluku</Option>
                        <Option value="NORTH_KALIMANTAN">
                          North Kalimantan
                        </Option>
                        <Option value="NORTH_MALUKU">North Maluku</Option>
                        <Option value="NORTH_SULAWESI">North Sulawesi</Option>
                        <Option value="NORTH_SUMATRA">North Sumatera</Option>
                        <Option value="PAPUA">Papua</Option>
                        <Option value="RIAU">Riau</Option>
                        <Option value="RIAU_ISLANDS">Riau Islands</Option>
                        <Option value="SOUTHEAST_SULAWESI">
                          Southeast Sulawesi
                        </Option>
                        <Option value="SOUTH_KALIMANTAN">
                          Southeast Kalimantan
                        </Option>
                        <Option value="SOUTH_SULAWESI">South Sulawesi</Option>
                        <Option value="SOUTH_SUMATERA">South Sumatera</Option>
                        <Option value="WEST_JAVA">West Java</Option>
                        <Option value="WEST_KALIMANTAN">West Kalimantan</Option>
                        <Option value="WEST_NUSA_TENGGARA">
                          West Nusa Tenggara
                        </Option>
                        <Option value="WEST_PAPUA">West Papua</Option>
                        <Option value="WEST_SULAWESI">West Sulawesi</Option>
                        <Option value="WEST_SUMATRA">West Sumatera</Option>
                        <Option value="SPECIAL_REGION_OF_YOGYAKARTA">
                          Special Region of Yogyakarta
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                )}
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>8. National ID*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="National ID"
                      size="large"
                      className="buttonBorderRadius"
                      value={values.nationalID}
                      onChange={(e) =>
                        setFieldValue('nationalID', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>9. Passport Number*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Passport Number"
                      size="large"
                      className="buttonBorderRadius"
                      value={values.passportNumber}
                      onChange={(e) =>
                        setFieldValue('passportNumber', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>10. Salary Range*</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={values.salaryRange}
                      defaultValue={values.salaryRange}
                      style={{width: 300}}
                      onChange={(e) => setFieldValue('salaryRange', e)}
                    >
                      <Option value="default" disabled>
                        Select Salary Range
                      </Option>
                      <Option value="RANGE_1">400 - 500 SGD</Option>
                      <Option value="RANGE_2">501 - 600 SGD</Option>
                      <Option value="RANGE_3">601 - Above </Option>
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>
                      11. Name of port/ airport to be repatriated to*
                    </Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Name of port"
                      size="large"
                      className="buttonBorderRadius"
                      value={values.nameOfPort}
                      onChange={(e) =>
                        setFieldValue('nameOfPort', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>12. Contact number in home country*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Contact number in home country"
                      size="large"
                      className="buttonBorderRadius"
                      type="tel"
                      value={values.contactNumber}
                      onChange={(e) =>
                        setFieldValue('contactNumber', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>12 a. Next of Kin*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Next of Kin"
                      size="large"
                      className="buttonBorderRadius"
                      type="text"
                      value={values.nextOfKin}
                      onChange={(e) =>
                        setFieldValue('nextOfKin', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>12 b. Contact Number (Next of Kin)*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Contact number of Kin"
                      size="large"
                      className="buttonBorderRadius"
                      type="tel"
                      value={values.nextOfKinContactNumber}
                      onChange={(e) =>
                        setFieldValue('nextOfKinContactNumber', e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>13. Religion*</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={values.religion}
                      defaultValue={values.religion}
                      style={{width: 300}}
                      onChange={(e) => setFieldValue('religion', e)}
                    >
                      <Option value="default" disabled>
                        Select Religion
                      </Option>
                      <Option value="FREE_THINKER">Free Thinker</Option>
                      <Option value="CATHOLIC">Catholic</Option>
                      <Option value="MUSLIM">Muslim</Option>
                      <Option value="SIKH">Sikh</Option>
                      <Option value="CHRISTIAN">Christian</Option>
                      <Option value="BUDDHIST">Buddhist</Option>
                      <Option value="HINDU">Hindu</Option>
                      <Option value="OTHERS">Others</Option>
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>14. Education Level*</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={values.educationLevel}
                      defaultValue={values.educationLevel}
                      style={{width: 300}}
                      onChange={(e) => setFieldValue('educationLevel', e)}
                    >
                      <Option value="default" disabled>
                        Select Education Level
                      </Option>
                      <Option value="COLLEGE">College</Option>
                      <Option value="SECONDARY_SCHOOL">Secondary School</Option>
                      <Option value="HIGH_SCHOOL">High School</Option>
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>15. Number of Siblings*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Number of Siblings"
                      size="large"
                      className="buttonBorderRadius"
                      type="number"
                      value={values.numberOfSiblings}
                      onChange={(e) =>
                        setFieldValue(
                          'numberOfSiblings',
                          e.target.value.length > 2
                            ? e.target.value.slice(0, 2)
                            : e.target.value,
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>16. Marital Status*</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={values.maritalStatus}
                      defaultValue={values.maritalStatus}
                      style={{width: 300}}
                      onChange={(e) => setFieldValue('maritalStatus', e)}
                    >
                      <Option value="default" disabled>
                        Select Marital Status
                      </Option>
                      <Option value="SINGLE">Single</Option>
                      <Option value="WIDOWED">Widowed</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                      <Option value="SEPARATED">Separated</Option>
                      <Option value="SINGLE_PARENT">Single Parent</Option>
                      <Option value="NO_PREFERENCE">No Preference</Option>
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>17. Number of Children*</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Number of Children"
                      size="large"
                      className="buttonBorderRadius"
                      type="number"
                      value={values.childrenNumber}
                      onChange={(e) =>
                        setFieldValue(
                          'childrenNumber',
                          e.target.value.length > 2
                            ? e.target.value.slice(0, 2)
                            : e.target.value,
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10} style={{visibility: 'hidden'}}>
                    <Typography>17. Number of Children</Typography>
                  </Col>
                  <Col span={14}>
                    <Typography>
                      Age(s) of children (if any)<sup>++</sup>
                    </Typography>
                    <Input
                      style={{width: 300}}
                      placeholder="Age"
                      size="large"
                      className="buttonBorderRadius"
                      type="text"
                      value={values.agesOfChildren}
                      onChange={(e) =>
                        setFieldValue('agesOfChildren', e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Space>
              <Space direction="vertical" size={10}>
                {/*                 <img
                  alt="example"
                  src={require('../assets/image/migrant1full.png')}
                  style={{width: 293, height: 380}}
                /> */}
                {(values?.status === 'RESERVED' ||
                  values?.status === 'CONFIRMED') && (
                  <>
                    {(function () {
                      return (
                        <>
                          <div>
                            <Typography>
                              {capitalize(values?.status)} by
                            </Typography>
                            <Input
                              style={{width: 300}}
                              placeholder="EA Name"
                              size="large"
                              className="buttonBorderRadius"
                              value={
                                eaBranchName
                                  ? eaCompanyName + ' - ' + eaBranchName
                                  : eaCompanyName +
                                    ' - ' +
                                    values?.branchEA?.branchName
                              }
                              readOnly
                              disabled
                            />
                          </div>
                        </>
                      )
                    })()}
                  </>
                )}
                <div>
                  <Typography>Supplier Reference</Typography>
                  <Input
                    style={{width: 300}}
                    placeholder="Reference Code"
                    size="large"
                    className="buttonBorderRadius"
                    value={values.referenceCode}
                    onChange={(e) =>
                      setFieldValue('referenceCode', e.target.value)
                    }
                  />
                </div>
                {!values.photoKey ? (
                  <>
                    <label htmlFor="uploadPhoto">
                      <Space
                        direction="vertical"
                        align="center"
                        style={{
                          border: '1px solid #000',
                          borderRadius: 5,
                          height: 400,
                          width: 300,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 20,
                          cursor: 'pointer',
                        }}
                      >
                        <Text>Photo*</Text>
                        <Text>(half/full bodied and coloured)</Text>
                      </Space>
                      <input
                        hidden
                        type="file"
                        id="uploadPhoto"
                        accept="image/*"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 5000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 5mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `photo-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'image/png',
                              },
                            )

                            setFieldValue('photoKey', _upload.key)
                            // setProfilePictureKey(_upload.key)
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                    </label>
                    <Text style={{color: 'red'}}>
                      Max Photo 5mb, file JPEG & PNG
                    </Text>
                  </>
                ) : (
                  <>
                    <RenderPicture
                      s3Key={values.photoKey}
                      type="PHOTO"
                      needRefetch={false}
                    />

                    <Text
                      style={{cursor: 'pointer', color: 'red'}}
                      onClick={() => setFieldValue('photoKey', '')}
                    >
                      x remove
                    </Text>
                  </>
                )}
                {JSON.parse(values.additionalPhoto).length === 0 ? (
                  <>
                    <label htmlFor="uploadAdditionalPhoto">
                      <Space
                        direction="horizontal"
                        align="center"
                        style={{
                          border: '1px solid #000',
                          borderRadius: 5,
                          height: 100,
                          width: 300,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 50,
                          cursor: 'pointer',
                        }}
                      >
                        <FileImageTwoTone style={{fontSize: 30}} />
                        <Text>Additional Photo</Text>
                      </Space>
                      <input
                        hidden
                        type="file"
                        id="uploadAdditionalPhoto"
                        accept="image/*"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 5000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 5mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `additionalPhoto-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'image/png',
                              },
                            )

                            const _additionalPhoto = JSON.parse(
                              values.additionalPhoto,
                            )
                            const __additionalPhoto = [
                              ..._additionalPhoto,
                              _upload.key,
                            ]
                            setFieldValue(
                              'additionalPhoto',
                              JSON.stringify(__additionalPhoto),
                            )
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                    </label>
                    <Text style={{color: 'red'}}>
                      Max Photo 5mb, file JPEG & PNG
                    </Text>
                  </>
                ) : (
                  <div style={{marginTop: 25}}>
                    {(function () {
                      let additionalPhoto = JSON.parse(values.additionalPhoto)
                      return (
                        <Slider {...sliderSettings} style={{width: 250}}>
                          {additionalPhoto.map((item) => (
                            <MultipleRenderWrapper
                              item={item}
                              type="ADDITIONAL_PHOTO"
                              data={additionalPhoto}
                              {...{setFieldValue}}
                            />
                          ))}
                        </Slider>
                      )
                    })()}
                    <label htmlFor="uploadAdditionalPhoto">
                      <Text
                        style={{
                          color: '#34C217',
                          cursor: 'pointer',
                        }}
                      >
                        Add more Additional Photo
                      </Text>
                      <input
                        hidden
                        type="file"
                        id="uploadAdditionalPhoto"
                        accept="image/*"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 5000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 5mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `additionalPhoto-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'image/png',
                              },
                            )

                            const _additionalPhoto = JSON.parse(
                              values.additionalPhoto,
                            )
                            const __additionalPhoto = [
                              ..._additionalPhoto,
                              _upload.key,
                            ]
                            setFieldValue(
                              'additionalPhoto',
                              JSON.stringify(__additionalPhoto),
                            )
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                    </label>
                  </div>
                )}
                {JSON.parse(values.additionalVideoKey).length === 0 ? (
                  <>
                    <label htmlFor="uploadAdditionalVideo">
                      <Space
                        direction="horizontal"
                        align="center"
                        style={{
                          border: '1px solid #000',
                          borderRadius: 5,
                          height: 100,
                          width: 300,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 50,
                          cursor: 'pointer',
                        }}
                      >
                        <VideoCameraTwoTone style={{fontSize: 30}} />
                        <Text>Add Video</Text>
                      </Space>
                      <input
                        hidden
                        type="file"
                        id="uploadAdditionalVideo"
                        accept="video/*"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 25000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 25mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `additionalVideo-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'video/mpg,video/avi,video/mp4',
                              },
                            )

                            const _additionalVideoKey = JSON.parse(
                              values.additionalVideoKey,
                            )
                            const __additionalVideoKey = [
                              ..._additionalVideoKey,
                              _upload.key,
                            ]
                            setFieldValue(
                              'additionalVideoKey',
                              JSON.stringify(__additionalVideoKey),
                            )
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                      <Text style={{color: 'red'}}>
                        Max Video 25mb, file MPG, MP4, AVI
                      </Text>
                    </label>
                  </>
                ) : (
                  <div style={{marginTop: 25}}>
                    {(function () {
                      console.log(
                        'values.additionalVideoKey',
                        values.additionalVideoKey,
                      )
                      let additionalVideo = JSON.parse(
                        values.additionalVideoKey,
                      )
                      return (
                        <Slider {...sliderSettings} style={{width: 250}}>
                          {additionalVideo.map((item) => (
                            <MultipleRenderWrapper
                              item={item}
                              type="ADDITIONAL_VIDEO"
                              data={additionalVideo}
                              {...{setFieldValue}}
                            />
                          ))}
                        </Slider>
                      )
                    })()}
                    <label htmlFor="uploadAdditionalVideo">
                      <Text
                        style={{
                          color: '#34C217',
                          cursor: 'pointer',
                        }}
                      >
                        Add more Additional Video
                      </Text>
                      <input
                        hidden
                        type="file"
                        id="uploadAdditionalVideo"
                        accept="video/*"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 25000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 25mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `additionalVideo-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'video/mpg,video/avi,video/mp4',
                              },
                            )

                            const _additionalVideo = JSON.parse(
                              values.additionalVideoKey,
                            )
                            const __additionalVideo = [
                              ..._additionalVideo,
                              _upload.key,
                            ]
                            setFieldValue(
                              'additionalVideoKey',
                              JSON.stringify(__additionalVideo),
                            )
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                    </label>
                  </div>
                )}

                {JSON.parse(values.supportingDocuments).length === 0 ? (
                  <>
                    <label htmlFor="uploadSupportingDocument">
                      <Space
                        direction="horizontal"
                        align="center"
                        style={{
                          border: '1px solid #000',
                          borderRadius: 5,
                          height: 100,
                          width: 300,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 50,
                          cursor: 'pointer',
                        }}
                      >
                        <FileTwoTone style={{fontSize: 30}} />
                        <Text>Supporting Document</Text>
                      </Space>
                      <input
                        hidden
                        type="file"
                        id="uploadSupportingDocument"
                        accept=".pdf"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 25000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 5mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `supportingDocument-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'application/pdf',
                              },
                            )

                            const _supportingDocuments = JSON.parse(
                              values.supportingDocuments,
                            )
                            const __supportingDocuments = [
                              ..._supportingDocuments,
                              _upload.key,
                            ]
                            setFieldValue(
                              'supportingDocuments',
                              JSON.stringify(__supportingDocuments),
                            )
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                      <Text style={{color: 'red'}}>Max File 5mb, file PDF</Text>
                    </label>
                  </>
                ) : (
                  <div style={{marginTop: 25}}>
                    {(function () {
                      let supportinDocument = JSON.parse(
                        values.supportingDocuments,
                      )
                      return (
                        <Slider {...sliderSettings} style={{width: 250}}>
                          {supportinDocument.map((item) => (
                            <MultipleRenderWrapper
                              item={item}
                              type="SUPPORTING_DOCUMENT"
                              data={supportinDocument}
                              {...{setFieldValue}}
                            />
                          ))}
                        </Slider>
                      )
                    })()}
                    <label htmlFor="uploadSupportingDocument">
                      <Text
                        style={{
                          color: '#34C217',
                          cursor: 'pointer',
                        }}
                      >
                        Add more Supporting Document
                      </Text>
                      <input
                        hidden
                        type="file"
                        id="uploadSupportingDocument"
                        accept=".pdf"
                        onChange={async (e) => {
                          try {
                            if (e.target.files[0].size > 5000000) {
                              notification['error']({
                                message: 'Error',
                                description: 'Maximum file size is 5mb.',
                              })
                            }
                            const _upload = await Storage.put(
                              `supportingDocument-mdw-${user.branchID}-${(
                                Math.random() * 1e32
                              ).toString(36)}`,
                              e.target.files[0],
                              {
                                contentType: 'video/mpg,video/avi,video/mp4',
                              },
                            )

                            const _supportingDocument = JSON.parse(
                              values.supportingDocuments,
                            )
                            const __supportingDocument = [
                              ..._supportingDocument,
                              _upload.key,
                            ]
                            setFieldValue(
                              'supportingDocuments',
                              JSON.stringify(__supportingDocument),
                            )
                          } catch (error) {
                            notification['error']({
                              message: 'Error',
                              description: JSON.stringify(error),
                            })
                          }
                        }}
                      />
                    </label>
                  </div>
                )}
                {values.status === 'INACTIVE' && (
                  <div
                    style={{
                      marginTop: 25,
                      backgroundColor: '#FDA3AC',
                      borderRadius: 10,
                      padding: 10,
                      /* visibility:
                      values.status === 'INACTIVE' ? 'visible' : 'hidden', */
                    }}
                  >
                    <Typography>Inactive by:</Typography>

                    <Input.TextArea
                      style={{width: 280, marginTop: 15}}
                      placeholder="Note.."
                      size="large"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={150}
                      disabled
                      value={
                        values?.inInactiveByUser?.branch?.company?.companyName +
                        ' - ' +
                        values?.inInactiveByUser?.branch?.branchName
                      }
                    />
                    {values.inactiveMessage && (
                      <>
                        <Typography>Inactive message:</Typography>
                        <Input.TextArea
                          style={{width: 280, marginTop: 15}}
                          placeholder="Note.."
                          size="large"
                          className="buttonBorderRadius"
                          // multiple={true}
                          maxLength={150}
                          disabled
                          value={values.inactiveMessage}
                        />
                      </>
                    )}
                  </div>
                )}
              </Space>
            </Space>

            <Space
              direction="horizontal"
              style={{justifyContent: 'space-between', width: '90%'}}
              align="start"
            >
              <Space direction="vertical" style={{marginBottom: 67}}>
                <Text strong>Price Code</Text>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Country Code</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      style={{width: 300}}
                      placeholder="Select Country Code"
                      value={priceCoding.countryCode}
                      onChange={(e) =>
                        setPriceCoding({...priceCoding, countryCode: e})
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{margin: '8px 0'}} />
                          <Space align="center" style={{padding: '0 8px 4px'}}>
                            <Input
                              placeholder="Enter new data"
                              value={newPriceCodeData}
                              onChange={(e) =>
                                setNewPriceCodeData(e.target.value)
                              }
                            />
                            <Typography.Link
                              onClick={async () => {
                                try {
                                  await API.graphql({
                                    query: createPriceCodeCountryCode,
                                    variables: {
                                      input: {
                                        countryCode: newPriceCodeData,
                                      },
                                    },
                                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                                  })
                                  _listPriceCodeCountryCodes()
                                  setNewPriceCodeData('')
                                } catch (error) {
                                  notification['error']({
                                    message: 'Error',
                                    description: JSON.stringify(error),
                                  })
                                }
                              }}
                              style={{whiteSpace: 'nowrap'}}
                            >
                              <PlusOutlined /> Add new code
                            </Typography.Link>
                          </Space>
                        </>
                      )}
                    >
                      {priceCodeCountries.map((item) => (
                        <Option key={item.countryCode}>
                          {item.countryCode}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Supplier Price Code</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      style={{width: 300}}
                      placeholder="Select Supplier Price Code"
                      value={priceCoding.supplierPriceCode}
                      onChange={(e) =>
                        setPriceCoding({...priceCoding, supplierPriceCode: e})
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{margin: '8px 0'}} />
                          <Space align="center" style={{padding: '0 8px 4px'}}>
                            <Input
                              placeholder="Enter new data"
                              value={newSupplierPriceCodeData}
                              onChange={(e) =>
                                setNewSupplierPriceCodeData(e.target.value)
                              }
                            />
                            <Typography.Link
                              onClick={async () => {
                                try {
                                  await API.graphql({
                                    query: createPriceCodeSupplierPriceCode,
                                    variables: {
                                      input: {
                                        supplierPriceCode:
                                          newSupplierPriceCodeData,
                                      },
                                    },
                                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                                  })
                                  _listPriceCodeSupplierPriceCodes()
                                  setNewSupplierPriceCodeData('')
                                } catch (error) {
                                  notification['error']({
                                    message: 'Error',
                                    description: JSON.stringify(error),
                                  })
                                }
                              }}
                              style={{whiteSpace: 'nowrap'}}
                            >
                              <PlusOutlined /> Add new code
                            </Typography.Link>
                          </Space>
                        </>
                      )}
                    >
                      {supplierPriceCodeCountries.map((item) => (
                        <Option key={item.supplierPriceCode}>
                          {item.supplierPriceCode}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>MDW Deployment Country</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={priceCoding.mdwDeploymentCountry}
                      defaultValue={priceCoding.mdwDeploymentCountry}
                      value={priceCoding.mdwDeploymentCountry}
                      onChange={(e) =>
                        setPriceCoding({
                          ...priceCoding,
                          mdwDeploymentCountry: e,
                        })
                      }
                      style={{width: 300}}
                    >
                      <Option value="default" disabled>
                        Select Country
                      </Option>
                      <Option value="SINGAPORE">Singapore</Option>
                      {/* <Option value="INDONESIA">Indonesia</Option>
                      <Option value="MYANMAR">Myanmar</Option> */}
                    </Select>
                  </Col>
                </Row>
              </Space>
              <Space direction="vertical" style={{paddingTop: 25}}>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Supplier Name</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Supplier Name"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      disabled
                      readOnly
                      value={values?.branchSupplier?.company?.companyName}
                    />
                  </Col>
                </Row>

                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>EC / No EC</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={priceCoding.isEc}
                      defaultValue={priceCoding.isEc}
                      onChange={(e) =>
                        setPriceCoding({...priceCoding, isEc: e})
                      }
                      style={{width: 300}}
                    >
                      <Option value="default" disabled>
                        Select Country Code
                      </Option>
                      <Option value="1">EC</Option>
                      <Option value="2">No EC</Option>
                    </Select>
                  </Col>
                </Row>
              </Space>
            </Space>
          </>
        )
      case 2:
        return (
          <>
            <Title style={{color: '#8893F9'}} level={5}>
              A.2. Medical History/Dietary Restrictions
            </Title>

            <Space direction="vertical" style={{width: '100%'}}>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={10}>
                  <Typography>
                    14. Allergies (if any)<sup>++</sup>
                  </Typography>
                </Col>
                <Col span={14}>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Type Allergies"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.allergies}
                    onChange={(e) => setFieldValue('allergies', e.target.value)}
                  />
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{marginTop: 15, width: '50%'}}
              >
                <Col>
                  <Typography>
                    15. Past and existing illnesses (including chronic ailments
                    and illnesses requiring medication)*
                  </Typography>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>i. Mental illness{values.illness}</Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.illness}
                    onChange={(e) => setFieldValue('illness', e.target.value)}
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>ii. Epilepsy</Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.epilepsy}
                    onChange={(e) => setFieldValue('epilepsy', e.target.value)}
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>iii. Asthma </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.asthma}
                    onChange={(e) => setFieldValue('asthma', e.target.value)}
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>iv. Diabetes </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.diabetes}
                    onChange={(e) => setFieldValue('diabetes', e.target.value)}
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>v. Hypertension </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.hypertension}
                    onChange={(e) =>
                      setFieldValue('hypertension', e.target.value)
                    }
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>vi. Tuberculosis </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.tuberculosis}
                    onChange={(e) =>
                      setFieldValue('tuberculosis', e.target.value)
                    }
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>vii. Heart disease </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.heart_disease}
                    onChange={(e) =>
                      setFieldValue('heart_disease', e.target.value)
                    }
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>viii. Malaria </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.malaria}
                    onChange={(e) => setFieldValue('malaria', e.target.value)}
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>ix. Operations </Typography>
                </Col>
                <Col span={8}>
                  <Radio.Group
                    defaultValue={values.operations}
                    onChange={(e) =>
                      setFieldValue('operations', e.target.value)
                    }
                  >
                    <Radio value={true}>Yes </Radio>
                    <Radio value={false} style={{marginLeft: 20}}>
                      No
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>x. Others </Typography>
                </Col>
                <Col>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Please type..."
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.others}
                    onChange={(e) => setFieldValue('others', e.target.value)}
                  />
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{marginTop: 25, width: '50%'}}
              >
                <Col span={8}>
                  <Typography>16. Physical disabilities</Typography>
                </Col>
                <Col>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Please type..."
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.physicalDisabilities}
                    onChange={(e) =>
                      setFieldValue('physicalDisabilities', e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={8}>
                  <Typography>17. Dietary Restriction</Typography>
                </Col>
                <Col>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Please type..."
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.dietaryRestriction}
                    onChange={(e) =>
                      setFieldValue('dietaryRestriction', e.target.value)
                    }
                  />
                </Col>
              </Row>
              {/* <Table columns={columns} dataSource={data} /> */}
              {/* Table */}
              <>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    backgroundColor: '#D8E2FF',
                    padding: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Col span={4}>
                    <Row>
                      <Text align="center">S/N.</Text>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Text align="center">Areas of Work</Text>
                    </Row>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Willingness</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Yes/No)
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Experience</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Yes/No)
                        </Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          If yes, state the no. of years
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Assessment/Observation</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          Please state qualitative observations of MDW and/or
                          rate the MDW (indicate N.A. of no evaluation was done)
                          Poor ……………………Excellent...N.A
                        </Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          1 2 3 4 5 N.A
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>1</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Care of infants/children</Text>
                      <Text>Please specify age range:</Text>
                      <Input
                        style={{width: 275}}
                        placeholder="0-00"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={values.careOfInfantsDetailA2}
                        onChange={(e) =>
                          setFieldValue('careOfInfantsDetailA2', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfInfantsWillingnessA2}
                      defaultValue={values.careOfInfantsWillingnessA2}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsWillingnessA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.careOfInfantsHasExperienceYearA2}
                      defaultValue={values.careOfInfantsHasExperienceYearA2}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsHasExperienceYearA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.careOfInfantsExperienceYearA2}
                      defaultValue={values.careOfInfantsExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfInfantsAssesmentA2}
                      defaultValue={values.careOfInfantsAssesmentA2}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsAssesmentA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>2</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Care of elderly</Text>
                      <Text>Please specify:</Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.careOfElderlyDetailA2}
                        onChange={(e) =>
                          setFieldValue('careOfElderlyDetailA2', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfElderlyWillingnessA2}
                      defaultValue={values.careOfElderlyWillingnessA2}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyWillingnessA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.careOfElderlyHasExperienceYearA2}
                      defaultValue={values.careOfElderlyHasExperienceYearA2}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyHasExperienceYearA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.careOfElderlyExperienceYearA2}
                      defaultValue={values.careOfElderlyExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.careOfElderlyAssesmentA2}
                      defaultValue={values.careOfElderlyAssesmentA2}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyAssesmentA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>3</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Care of disabled</Text>
                      <Text>Please specify:</Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.careOfDisabledDetailA2}
                        onChange={(e) =>
                          setFieldValue(
                            'careOfDisabledDetailA2',
                            e.target.value,
                          )
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfDisabledWillingnessA2}
                      defaultValue={values.careOfDisabledWillingnessA2}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledWillingnessA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.careOfDisabledHasExperienceYearA2}
                      defaultValue={values.careOfDisabledHasExperienceYearA2}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledHasExperienceYearA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.careOfDisabledExperienceYearA2}
                      defaultValue={values.careOfDisabledExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.careOfDisabledAssesmentA2}
                      defaultValue={values.careOfDisabledAssesmentA2}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledAssesmentA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>4</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>General housework*</Text>
                      <Text>
                        Please specify:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.generalHouseworkDetailA2}
                        onChange={(e) =>
                          setFieldValue(
                            'generalHouseworkDetailA2',
                            e.target.value,
                          )
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.generalHouseworkWillingnessA2}
                      defaultValue={values.generalHouseworkWillingnessA2}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkWillingnessA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.generalHouseworkHasExperienceYearA2}
                      defaultValue={values.generalHouseworkHasExperienceYearA2}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkHasExperienceYearA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.generalHouseworkExperienceYearA2}
                      defaultValue={values.generalHouseworkExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.generalHouseworkAssesmentA2}
                      defaultValue={values.generalHouseworkAssesmentA2}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkAssesmentA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>5</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Cooking*</Text>
                      <Text>
                        Please specify cuisines:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.cookingDetailA2}
                        onChange={(e) =>
                          setFieldValue('cookingDetailA2', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.cookingWillingnessA2}
                      defaultValue={values.cookingWillingnessA2}
                      onChange={(e) => setFieldValue('cookingWillingnessA2', e)}
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.cookingHasExperienceYearA2}
                      defaultValue={values.cookingHasExperienceYearA2}
                      onChange={(e) =>
                        setFieldValue('cookingHasExperienceYearA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.cookingExperienceYearA2}
                      defaultValue={values.cookingExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('cookingExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.cookingAssesmentA2}
                      defaultValue={values.cookingAssesmentA2}
                      onChange={(e) => setFieldValue('cookingAssesmentA2', e)}
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>6</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Language abilities (spoken)</Text>
                      <Text>
                        Please specify:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.languageDetailA2}
                        onChange={(e) =>
                          setFieldValue('languageDetailA2', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col span={4}></Col>
                  <Col span={5}>
                    <Select
                      value={values.languageExperienceHasYearA2}
                      defaultValue={values.languageExperienceHasYearA2}
                      onChange={(e) =>
                        setFieldValue('languageExperienceHasYearA2', e)
                      }
                    >
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.languageExperienceYearA2}
                      defaultValue={values.languageExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('languageExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.languageAssesmentA2}
                      defaultValue={values.languageAssesmentA2}
                      onChange={(e) => setFieldValue('languageAssesmentA2', e)}
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>7</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Other skills, if any</Text>
                      <Text>
                        Please specify:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.otherSkillsDetailA2}
                        onChange={(e) =>
                          setFieldValue('otherSkillsDetailA2', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col span={4}></Col>
                  <Col span={5}>
                    <Select
                      value={values.otherSkillsHasExperienceYearA2}
                      defaultValue={values.otherSkillsHasExperienceYearA2}
                      onChange={(e) =>
                        setFieldValue('otherSkillsHasExperienceYearA2', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.otherSkillsExperienceYearA2}
                      defaultValue={values.otherSkillsExperienceYearA2}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('otherSkillsExperienceYearA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.otherSkillsAssesmentA2}
                      defaultValue={values.otherSkillsAssesmentA2}
                      onChange={(e) =>
                        setFieldValue('otherSkillsAssesmentA2', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
              </>
              {/* End of Table */}
              <Row align="middle">
                <Col span={5}>
                  <Typography>18. Food handling preferences:</Typography>
                </Col>
                <Col>
                  <Radio.Group
                    defaultValue={values.foodHandlingPreferences}
                    onChange={(e) =>
                      setFieldValue('foodHandlingPreferences', e.target.value)
                    }
                  >
                    <Radio value={'No Pork'}>No Pork</Radio>
                    <Radio value={'No Beef'}>No Beef</Radio>
                    <Radio value={'Others'}>
                      <Row align="middle" gutter={5}>
                        <Col>Others</Col>
                        <Col>
                          {' '}
                          <Input.TextArea
                            placeholder="Note..."
                            type="text"
                            size="middle"
                            className="buttonBorderRadius"
                            multiple={true}
                            maxLength={200}
                            style={{width: 300}}
                            value={values.foodHandlingPreferencesOthers}
                            onChange={(e) =>
                              setFieldValue(
                                'foodHandlingPreferencesOthers',
                                e.target.value,
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Radio>
                    {/* <Row>
                      <Col>
                        <Radio value={3}>Others</Radio>
                      </Col>
                      <Col>
                        <Input
                          style={{width: 300}}
                          placeholder="Note.."
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                        />
                      </Col>
                    </Row> */}
                  </Radio.Group>
                </Col>
              </Row>
              {/* Table */}
              <>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    backgroundColor: '#D8E2FF',
                    padding: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Col span={4}>
                    <Row>
                      <Text align="center">S/N.</Text>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Core Fundamentals</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Assessment and Observation to select candidates based
                          on these strict criteria including IQ and EQ
                          dimensions)
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row align="center">
                      <Space direction="vertical" align="center">
                        <Text>MDW Competent</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Yes/No)
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>MDW Need Training</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Yes/No)
                        </Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          If yes, state the type of training needed
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Assessment/Observation</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          Please state qualitative observations of MDW and/or
                          rate the MDW (indicate N.A. of no evaluation was done)
                          Poor ……………………Excellent...N.A
                        </Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          1 2 3 4 5 N.A
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>1</Text>
                  </Col>
                  <Col span={20}>
                    <Space direction="vertical">
                      <Text>Core Values:</Text>
                      <Text>
                        MDWS sensitised and trained in
                        <br /> the fundamentals of:
                      </Text>
                    </Space>
                  </Col>
                </Row>
                <Row
                  align="top"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text></Text>
                  </Col>
                  <Col span={5}>
                    <Text>a. Hygiene</Text>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.hygieneCompetent}
                      defaultValue={values.hygieneCompetent}
                      onChange={(e) => setFieldValue('hygieneCompetent', e)}
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Select
                      value={values.hygieneNeedTraining}
                      defaultValue={values.hygieneNeedTraining}
                      onChange={(e) => setFieldValue('hygieneNeedTraining', e)}
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Input.TextArea
                      style={{marginLeft: 10, height: 120}}
                      placeholder="Note..."
                      type="text"
                      size="middle"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={200}
                      value={values.hygieneTraining}
                      onChange={(e) =>
                        setFieldValue('hygieneTraining', e.target.value)
                      }
                    />
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.hygineAssesment}
                      defaultValue={values.hygineAssesment}
                      onChange={(e) => setFieldValue('hygineAssesment', e)}
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Row
                  align="top"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text></Text>
                  </Col>
                  <Col span={5}>
                    <Text>b. Adaptibility</Text>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.adaptabilityCompetent}
                      defaultValue={values.adaptabilityCompetent}
                      onChange={(e) =>
                        setFieldValue('adaptabilityCompetent', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Select
                      value={values.adaptabilityNeedTraining}
                      defaultValue={values.adaptabilityNeedTraining}
                      onChange={(e) =>
                        setFieldValue('adaptabilityNeedTraining', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Input.TextArea
                      style={{marginLeft: 10, height: 120}}
                      placeholder="Note..."
                      type="text"
                      size="middle"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={200}
                      value={values.adaptabilityTraining}
                      onChange={(e) =>
                        setFieldValue('adaptabilityTraining', e.target.value)
                      }
                    />
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.adaptabilityAssesment}
                      defaultValue={values.adaptabilityAssesment}
                      onChange={(e) =>
                        setFieldValue('adaptabilityAssesment', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Row
                  align="top"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text></Text>
                  </Col>
                  <Col span={5}>
                    <Text>c. Discipline</Text>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.disciplineCompetent}
                      defaultValue={values.disciplineCompetent}
                      onChange={(e) => setFieldValue('disciplineCompetent', e)}
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Select
                      value={values.disciplineNeedTraining}
                      defaultValue={values.disciplineNeedTraining}
                      onChange={(e) =>
                        setFieldValue('disciplineNeedTraining', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Input.TextArea
                      style={{marginLeft: 10, height: 120}}
                      placeholder="Note..."
                      type="text"
                      size="middle"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={200}
                      value={values.disciplineTraining}
                      onChange={(e) =>
                        setFieldValue('disciplineTraining', e.target.value)
                      }
                    />
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.disciplineAssesment}
                      defaultValue={values.disciplineAssesment}
                      onChange={(e) => setFieldValue('disciplineAssesment', e)}
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>2</Text>
                  </Col>
                  <Col span={5}>
                    <Space direction="vertical">
                      <Text>Core Skills:</Text>
                      <Text>Refer to Areas of Work for details</Text>
                    </Space>
                  </Col>
                  <Col span={15}>
                    <Input.TextArea
                      style={{width: '100%', marginLeft: 10}}
                      placeholder="Note..."
                      type="text"
                      size="middle"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={100}
                      value={values.coreSkills}
                      onChange={(e) =>
                        setFieldValue('coreSkills', e.target.value)
                      }
                    />
                  </Col>
                </Row>

                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>3</Text>
                  </Col>
                  <Col span={20}>
                    <Space direction="vertical">
                      <Text>Core Fitness:</Text>
                      <Text>Emphasis on:</Text>
                    </Space>
                  </Col>
                </Row>
                <Row
                  align="top"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text></Text>
                  </Col>
                  <Col span={5}>
                    <Text>a. Mental fitness</Text>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.coreFitnessMentalCompetent}
                      defaultValue={values.coreFitnessMentalCompetent}
                      onChange={(e) =>
                        setFieldValue('coreFitnessMentalCompetent', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Select
                      value={values.coreFitnessMentalNeedTraining}
                      defaultValue={values.coreFitnessMentalNeedTraining}
                      onChange={(e) =>
                        setFieldValue('coreFitnessMentalNeedTraining', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Input.TextArea
                      style={{marginLeft: 10, height: 120}}
                      placeholder="Note..."
                      type="text"
                      size="middle"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={200}
                      value={values.coreFitnessMentalTraining}
                      onChange={(e) =>
                        setFieldValue(
                          'coreFitnessMentalTraining',
                          e.target.value,
                        )
                      }
                    />
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.coreFitnessMentalAssesment}
                      defaultValue={values.coreFitnessMentalAssesment}
                      onChange={(e) =>
                        setFieldValue('coreFitnessMentalAssesment', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Row
                  align="top"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text></Text>
                  </Col>
                  <Col span={5}>
                    <Text>b. Physical fitness</Text>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.coreFitnessPhysicalCompetent}
                      defaultValue={values.coreFitnessPhysicalCompetent}
                      onChange={(e) =>
                        setFieldValue('coreFitnessPhysicalCompetent', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Select
                      value={values.coreFitnessPhysicalNeedTraining}
                      defaultValue={values.coreFitnessPhysicalNeedTraining}
                      onChange={(e) =>
                        setFieldValue(
                          'coreFitnessPhysicalNeedTraining',
                          e.target.value,
                        )
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Input.TextArea
                      style={{marginLeft: 10, height: 120}}
                      placeholder="Note..."
                      type="text"
                      size="middle"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={200}
                      value={values.coreFitnessPhysicalTraining}
                      onChange={(e) =>
                        setFieldValue(
                          'coreFitnessPhysicalTraining',
                          e.target.value,
                        )
                      }
                    />
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.coreFitnessPhysicalAssesment}
                      defaultValue={values.coreFitnessPhysicalAssesment}
                      onChange={(e) =>
                        setFieldValue('coreFitnessPhysicalAssesment', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
              </>
              {/* End of Table */}
            </Space>
          </>
        )
      case 3:
        return (
          <>
            <Title style={{color: '#8893F9'}} level={5}>
              A.3. Others
            </Title>

            <Space direction="vertical" style={{width: '100%'}}>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col span={10}>
                  <Typography>19. Preference for rest day:</Typography>
                </Col>
                <Col span={3}>
                  <Select
                    defaultValue={values.restDaysPerMonth}
                    onChange={(e) => setFieldValue('restDaysPerMonth', e)}
                  >
                    <Option value={''} disabled></Option>
                    {[...new Array(5)].map((item, index) => (
                      <Option value={index}>{index}</Option>
                    ))}
                  </Select>
                </Col>
                <Col span={11}>
                  <Typography>rest day(s) per month.*</Typography>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Col span={10}>
                  <Typography>20. Any other remarks:</Typography>
                </Col>
                <Col span={14}>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Any other remarks"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.otherRemarks}
                    onChange={(e) =>
                      setFieldValue('otherRemarks', e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Space>
            <Title
              style={{color: '#8893F9', marginTop: 82, marginBottom: 40}}
              level={5}
            >
              B.1. Method of Evaluation of Skills
            </Title>
            <Space direction="vertical" style={{width: '100%'}}>
              <Row
                justify="space-between"
                align="middle"
                style={{width: '50%'}}
              >
                <Col>
                  <Typography>
                    Please indicate the method(s) used to evaluate the MDW’s
                    skills (can tick more than one):
                    {/*  {JSON.stringify([
                      ...new Set(values.methodOfEvaluationSkills),
                    ])} */}
                  </Typography>
                </Col>
              </Row>
              <Checkbox
                value="DECLARATION"
                checked={JSON.parse(values.methodOfEvaluationSkills).includes(
                  'DECLARATION',
                )}
                onChange={(e) => {
                  if (
                    !values.methodOfEvaluationSkills.includes('DECLARATION')
                  ) {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills = [
                      ..._methodOfEvaluationSkills,
                      e.target.value,
                    ]

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  } else {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills =
                      _methodOfEvaluationSkills.filter(
                        (item) => item !== 'DECLARATION',
                      )

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  }
                }}
              >
                Based on MDW’s declaration, no evaluation/observation by
                Singapore EA or overseas training centre/EA
              </Checkbox>
              <Checkbox
                value="INTERVIEWED_BY_SG_EA"
                checked={JSON.parse(values.methodOfEvaluationSkills).includes(
                  'INTERVIEWED_BY_SG_EA',
                )}
                onChange={(e) => {
                  if (
                    !values.methodOfEvaluationSkills.includes(
                      'INTERVIEWED_BY_SG_EA',
                    )
                  ) {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills = [
                      ..._methodOfEvaluationSkills,
                      e.target.value,
                    ]

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  } else {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills =
                      _methodOfEvaluationSkills.filter(
                        (item) => item !== 'INTERVIEWED_BY_SG_EA',
                      )

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  }
                }}
              >
                Interviewed by Singapore EA
              </Checkbox>
              <Checkbox
                value="INTERVIEWED_VIA_TELEPHONE"
                checked={JSON.parse(values.methodOfEvaluationSkills).includes(
                  'INTERVIEWED_VIA_TELEPHONE',
                )}
                onChange={(e) => {
                  if (
                    !values.methodOfEvaluationSkills.includes(
                      'INTERVIEWED_VIA_TELEPHONE',
                    )
                  ) {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills = [
                      ..._methodOfEvaluationSkills,
                      e.target.value,
                    ]

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  } else {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills =
                      _methodOfEvaluationSkills.filter(
                        (item) => item !== 'INTERVIEWED_VIA_TELEPHONE',
                      )

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  }
                }}
              >
                Interviewed via telephone/teleconference
              </Checkbox>
              <Checkbox
                value="INTERVIEWED_VIA_VIDEO_CONFERENCE"
                checked={JSON.parse(values.methodOfEvaluationSkills).includes(
                  'INTERVIEWED_VIA_VIDEO_CONFERENCE',
                )}
                onChange={(e) => {
                  if (
                    !values.methodOfEvaluationSkills.includes(
                      'INTERVIEWED_VIA_VIDEO_CONFERENCE',
                    )
                  ) {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills = [
                      ..._methodOfEvaluationSkills,
                      e.target.value,
                    ]

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  } else {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills =
                      _methodOfEvaluationSkills.filter(
                        (item) => item !== 'INTERVIEWED_VIA_VIDEO_CONFERENCE',
                      )

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  }
                }}
              >
                Interviewed via video conference
              </Checkbox>
              <Checkbox
                value="INTERVIEWED_IN_PERSON"
                checked={JSON.parse(values.methodOfEvaluationSkills).includes(
                  'INTERVIEWED_IN_PERSON',
                )}
                onChange={(e) => {
                  if (
                    !values.methodOfEvaluationSkills.includes(
                      'INTERVIEWED_IN_PERSON',
                    )
                  ) {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills = [
                      ..._methodOfEvaluationSkills,
                      e.target.value,
                    ]

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  } else {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills =
                      _methodOfEvaluationSkills.filter(
                        (item) => item !== 'INTERVIEWED_IN_PERSON',
                      )

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  }
                }}
              >
                Interviewed in person
              </Checkbox>
              <Checkbox
                value="INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION"
                checked={JSON.parse(values.methodOfEvaluationSkills).includes(
                  'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION',
                )}
                onChange={(e) => {
                  if (
                    !values.methodOfEvaluationSkills.includes(
                      'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION',
                    )
                  ) {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills = [
                      ..._methodOfEvaluationSkills,
                      e.target.value,
                    ]

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  } else {
                    const _methodOfEvaluationSkills = JSON.parse(
                      values.methodOfEvaluationSkills,
                    )

                    const __methodOfEvaluationSkills =
                      _methodOfEvaluationSkills.filter(
                        (item) =>
                          item !== 'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION',
                      )

                    setFieldValue(
                      'methodOfEvaluationSkills',
                      JSON.stringify(__methodOfEvaluationSkills),
                    )
                  }
                }}
              >
                Interviewed in person and also made observation of MDW in the
                areas of work listed in table
              </Checkbox>
              <Row style={{marginBottom: 48}}>
                <Col>
                  <Checkbox
                    value="INTERVIEWED_BY_EA"
                    checked={JSON.parse(
                      values.methodOfEvaluationSkills,
                    ).includes('INTERVIEWED_BY_EA')}
                    onChange={(e) => {
                      if (
                        !values.methodOfEvaluationSkills.includes(
                          'INTERVIEWED_BY_EA',
                        )
                      ) {
                        const _methodOfEvaluationSkills = JSON.parse(
                          values.methodOfEvaluationSkills,
                        )

                        const __methodOfEvaluationSkills = [
                          ..._methodOfEvaluationSkills,
                          e.target.value,
                        ]

                        setFieldValue(
                          'methodOfEvaluationSkills',
                          JSON.stringify(__methodOfEvaluationSkills),
                        )
                      } else {
                        const _methodOfEvaluationSkills = JSON.parse(
                          values.methodOfEvaluationSkills,
                        )

                        const __methodOfEvaluationSkills =
                          _methodOfEvaluationSkills.filter(
                            (item) => item !== 'INTERVIEWED_BY_EA',
                          )

                        setFieldValue(
                          'methodOfEvaluationSkills',
                          JSON.stringify(__methodOfEvaluationSkills),
                        )
                      }
                    }}
                  >
                    Interviewed by overseas training centre / EA (Please state
                    name of foreign training centre / EA):
                  </Checkbox>
                </Col>
                <Col>
                  <Input
                    style={{width: 200}}
                    type="text"
                    size="small"
                    className="buttonBorderRadius"
                    value={values.nameOfForeignTrainingCentre}
                    onChange={(e) =>
                      setFieldValue(
                        'nameOfForeignTrainingCentre',
                        e.target.value,
                      )
                    }
                  />
                </Col>
              </Row>

              {/* Table */}
              <>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    backgroundColor: '#D8E2FF',
                    padding: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Col span={4}>
                    <Row>
                      <Text align="center">S/N.</Text>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Text align="center">Areas of Work</Text>
                    </Row>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Willingness</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Yes/No)
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Experience</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          (Yes/No)
                        </Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          If yes, state the no. of years
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row>
                      <Space direction="vertical" align="center">
                        <Text>Assessment/Observation</Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          Please state qualitative observations of MDW and/or
                          rate the MDW (indicate N.A. of no evaluation was done)
                          Poor ……………………Excellent...N.A
                        </Text>
                        <Text type="secondary" style={{fontSize: 12}}>
                          1 2 3 4 5 N.A
                        </Text>
                      </Space>
                    </Row>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>1</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Care of infants/children</Text>
                      <Text>Please specify age range:</Text>
                      <Input
                        style={{width: 275}}
                        placeholder="0-00"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={values.careOfInfantsDetailB1}
                        onChange={(e) =>
                          setFieldValue('careOfInfantsDetailB1', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfInfantsWillingnessB1}
                      defaultValue={values.careOfInfantsWillingnessB1}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsWillingnessB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.careOfInfantsHasExperienceYearB1}
                      defaultValue={values.careOfInfantsHasExperienceYearB1}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsHasExperienceYearB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.careOfInfantsExperienceYearB1}
                      defaultValue={values.careOfInfantsExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfInfantsAssesmentB1}
                      defaultValue={values.careOfInfantsAssesmentB1}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsAssesmentB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>2</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Care of elderly</Text>
                      <Text>Please specify:</Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.careOfElderlyDetailB1}
                        onChange={(e) =>
                          setFieldValue('careOfElderlyDetailB1', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfElderlyWillingnessB1}
                      defaultValue={values.careOfElderlyWillingnessB1}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyWillingnessB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.careOfElderlyHasExperienceYearB1}
                      defaultValue={values.careOfElderlyHasExperienceYearB1}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyHasExperienceYearB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.careOfElderlyExperienceYearB1}
                      defaultValue={values.careOfElderlyExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('careOfElderlyExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.careOfInfantsAssesmentB1}
                      defaultValue={values.careOfInfantsAssesmentB1}
                      onChange={(e) =>
                        setFieldValue('careOfInfantsAssesmentB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>3</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Care of disabled</Text>
                      <Text>Please specify:</Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.careOfDisabledDetailB1}
                        onChange={(e) =>
                          setFieldValue(
                            'careOfDisabledDetailB1',
                            e.target.value,
                          )
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.careOfDisabledWillingnessB1}
                      defaultValue={values.careOfDisabledWillingnessB1}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledWillingnessB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.careOfDisabledHasExperienceYearB1}
                      defaultValue={values.careOfDisabledHasExperienceYearB1}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledHasExperienceYearB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.careOfDisabledExperienceYearB1}
                      defaultValue={values.careOfDisabledExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.careOfDisabledAssesmentB1}
                      defaultValue={values.careOfDisabledAssesmentB1}
                      onChange={(e) =>
                        setFieldValue('careOfDisabledAssesmentB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>4</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>General housework</Text>
                      <Text>
                        Please specify:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.generalHouseworkDetailB1}
                        onChange={(e) =>
                          setFieldValue(
                            'generalHouseworkDetailB1',
                            e.target.value,
                          )
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.generalHouseworkWillingnessB1}
                      defaultValue={values.generalHouseworkWillingnessB1}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkWillingnessB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.generalHouseworkHasExperienceYearB1}
                      defaultValue={values.generalHouseworkHasExperienceYearB1}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkHasExperienceYearB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.generalHouseworkExperienceYearB1}
                      defaultValue={values.generalHouseworkExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.generalHouseworkAssesmentB1}
                      defaultValue={values.generalHouseworkAssesmentB1}
                      onChange={(e) =>
                        setFieldValue('generalHouseworkAssesmentB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>5</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Cooking</Text>
                      <Text>
                        Please specify cuisines:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.cookingDetailB1}
                        onChange={(e) =>
                          setFieldValue('cookingDetailB1', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col
                    span={4}
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Select
                      value={values.cookingWillingnessB1}
                      defaultValue={values.cookingWillingnessB1}
                      onChange={(e) => setFieldValue('cookingWillingnessB1', e)}
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                  <Col span={5}>
                    <Select
                      value={values.cookingHasExperienceYearB1}
                      defaultValue={values.cookingHasExperienceYearB1}
                      onChange={(e) =>
                        setFieldValue('cookingHasExperienceYearB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.cookingExperienceYearB1}
                      defaultValue={values.cookingExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('cookingExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.cookingAssesmentB1}
                      defaultValue={values.cookingAssesmentB1}
                      onChange={(e) => setFieldValue('cookingAssesmentB1', e)}
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>6</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Language abilities (spoken)</Text>
                      <Text>
                        Please specify:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.languageDetailB1}
                        onChange={(e) =>
                          setFieldValue('languageDetailB1', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col span={4}></Col>
                  <Col span={5}>
                    <Select
                      value={values.languageExperienceHasYearB1}
                      defaultValue={values.languageExperienceHasYearB1}
                      onChange={(e) =>
                        setFieldValue('languageExperienceHasYearB1', e)
                      }
                    >
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.languageExperienceYearB1}
                      defaultValue={values.languageExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('languageExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.languageAssesmentB1}
                      defaultValue={values.languageAssesmentB1}
                      onChange={(e) => setFieldValue('languageAssesmentB1', e)}
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row
                  align="middle"
                  justify="space-between"
                  style={{
                    padding: 10,
                  }}
                >
                  <Col span={4}>
                    <Text>7</Text>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical">
                      <Text>Other skills, if any</Text>
                      <Text>
                        Please specify:<sup>++</sup>
                      </Text>
                      <Input.TextArea
                        style={{height: 120, width: 275}}
                        placeholder="Note..."
                        type="text"
                        size="middle"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={200}
                        value={values.otherSkillsDetailB1}
                        onChange={(e) =>
                          setFieldValue('otherSkillsDetailB1', e.target.value)
                        }
                      />
                    </Space>
                  </Col>
                  <Col span={4}></Col>
                  <Col span={5}>
                    <Select
                      value={values.otherSkillsHasExperienceYearB1}
                      defaultValue={values.otherSkillsHasExperienceYearB1}
                      onChange={(e) =>
                        setFieldValue('otherSkillsHasExperienceYearB1', e)
                      }
                    >
                      <Option value="" disabled></Option>
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                    <Select
                      value={values.otherSkillsExperienceYearB1}
                      defaultValue={values.otherSkillsExperienceYearB1}
                      style={{marginLeft: 5, width: 150}}
                      onChange={(e) =>
                        setFieldValue('otherSkillsExperienceYearB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value={0}>0</Option>
                      <Option value={0.5}>Less than 1 year</Option>
                      {[...new Array(10)].map((item, index) => (
                        <Option value={index + 1}>{index + 1}</Option>
                      ))}
                      <Option value={11}>More than 10 years</Option>
                    </Select>
                  </Col>
                  <Col
                    span={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Select
                      value={values.otherSkillsAssesmentB1}
                      defaultValue={values.otherSkillsAssesmentB1}
                      onChange={(e) =>
                        setFieldValue('otherSkillsAssesmentB1', e)
                      }
                    >
                      <Option value={''} disabled></Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="0">N.A</Option>
                    </Select>
                  </Col>
                </Row>
                <Divider />
              </>
              {/* End of Table */}
            </Space>
          </>
        )
      case 4:
        return (
          <>
            <Space direction="vertical" style={{width: '100%'}}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Col span={12}>
                  <Typography>
                    State if the third party is certified (e.g. ISO9001) or
                    audited periodically by the EA:
                  </Typography>
                </Col>
                <Col span={12}>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Note..."
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.stateIfThirdPartyIsCertifiedSpecify}
                    onChange={(e) =>
                      setFieldValue(
                        'stateIfThirdPartyIsCertifiedSpecify',
                        e.target.value,
                      )
                    }
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col>
                  <Radio.Group
                    defaultValue={values.stateIfThirdPartyIsCertified}
                    onChange={(e) =>
                      setFieldValue(
                        'stateIfThirdPartyIsCertified',
                        e.target.value,
                      )
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={'INTERVIEWED_VIA_TELEPHONE'}>
                        Interviewed via telephone/teleconference
                      </Radio>
                      <Radio value={'INTERVIEWED_VIA_VIDEO_CONFERENCE'}>
                        Interviewed via video conference
                      </Radio>
                      <Radio value={'INTERVIEWED_IN_PERSON'}>
                        Interviewed in person
                      </Radio>
                      <Radio
                        value={'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION'}
                      >
                        Interviewed in person and also made observation of MDW
                        in the areas of work listed in table
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Col span={12}>
                  <Typography>
                    Interviewed by overseas training centre / EA (Please state
                    name of foreign training centre / EA)
                  </Typography>
                </Col>
                <Col span={12}>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Note..."
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.interviewedByOverseasTrainingCentre}
                    onChange={(e) =>
                      setFieldValue(
                        'interviewedByOverseasTrainingCentre',
                        e.target.value,
                      )
                    }
                  />
                </Col>
              </Row>
            </Space>
            <Title style={{color: '#8893F9'}} level={5}>
              (C) EMPLOYMENT HISTORY OF THE MDW
            </Title>

            <Title style={{color: '#8893F9'}} level={5}>
              C1 Employment History Overseas
            </Title>
            <Table dataSource={historyOverseasDataTable} pagination={false}>
              <Table.ColumnGroup title="Date">
                <Table.Column
                  title="From"
                  dataIndex="no"
                  key="no"
                  render={(no) => (
                    <Input
                      // style={{width: 300}}
                      id="grey-placeholder"
                      placeholder={`Name`}
                      type="date"
                      size="large"
                      className="buttonBorderRadius"
                      value={values[`employmentHistoryOverseasDateFrom${no}`]}
                      onChange={(e) =>
                        setFieldValue(
                          `employmentHistoryOverseasDateFrom${no}`,
                          e.target.value,
                        )
                      }
                    />
                  )}
                />
                <Table.Column
                  title="To"
                  dataIndex="no"
                  key="no"
                  render={(no) => (
                    <Input
                      // style={{width: 300}}
                      id="grey-placeholder"
                      placeholder="Name"
                      type="date"
                      size="large"
                      className="buttonBorderRadius"
                      value={values[`employmentHistoryOverseasDateTo${no}`]}
                      onChange={(e) =>
                        setFieldValue(
                          `employmentHistoryOverseasDateTo${no}`,
                          e.target.value,
                        )
                      }
                    />
                  )}
                />
              </Table.ColumnGroup>
              <Table.Column
                title="Country (including
MDW’s home country)"
                dataIndex="no"
                key="no"
                render={(no) => (
                  <Select
                    value={values[`employmentHistoryCountry${no}`]}
                    defaultValue={values[`employmentHistoryCountry${no}`]}
                    onChange={(e) =>
                      setFieldValue(`employmentHistoryCountry${no}`, e)
                    }
                    id="grey-placeholder"
                    style={{width: 150}}
                  >
                    <Option value="" disabled>
                      Select Country
                    </Option>
                    <Option value="SINGAPORE">Singapore</Option>
                    <Option value="MALAYSIA">Malaysia</Option>
                    <Option value="TAIWAN">Taiwan</Option>
                    <Option value="HONGKONG">Hong Kong</Option>
                    <Option value="BRUNEI">Brunei</Option>
                    <Option value="MIDDLE_EAST">Middle East</Option>
                    <Option value="CAMBODIA">Cambodia</Option>
                    <Option value="INDONESIA">Indonesia</Option>
                    <Option value="MYANMAR">Myanmar</Option>
                    <Option value="OTHERS">Others</Option>
                  </Select>
                )}
              />
              <Table.Column
                title="Employer"
                dataIndex="no"
                key="no"
                render={(no) => (
                  <Input
                    // style={{width: 300}}

                    placeholder={`Employer`}
                    size="large"
                    className="buttonBorderRadius"
                    value={values[`employmentHistoryEmployer${no}`]}
                    onChange={(e) =>
                      setFieldValue(
                        `employmentHistoryEmployer${no}`,
                        e.target.value,
                      )
                    }
                  />
                )}
              />
              <Table.Column
                title="Work Duties"
                dataIndex="no"
                key="no"
                render={(no) => (
                  <Input
                    // style={{width: 300}}
                    placeholder="Work Duties"
                    size="large"
                    className="buttonBorderRadius"
                    value={values[`employmentHistoryWorkDuties${no}`]}
                    onChange={(e) =>
                      setFieldValue(
                        `employmentHistoryWorkDuties${no}`,
                        e.target.value,
                      )
                    }
                  />
                )}
              />
              <Table.Column
                title="Remarks"
                dataIndex="no"
                key="no"
                render={(no) => (
                  <Input.TextArea
                    // style={{width: 300}}
                    placeholder="Remarks"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values[`employmentHistoryWorkDutiesRemarks${no}`]}
                    onChange={(e) =>
                      setFieldValue(
                        `employmentHistoryWorkDutiesRemarks${no}`,
                        e.target.value,
                      )
                    }
                  />
                )}
              />
            </Table>
            <Title style={{color: '#8893F9', marginTop: 38}} level={5}>
              C2 Employment History in Singapore
            </Title>
            <Space
              direction="vertical"
              style={{width: '100%', marginBottom: 39, marginTop: 30}}
            >
              <Row align="middle">
                <Col span="6">
                  <Typography>
                    Previous working experience in Singapore*{' '}
                  </Typography>
                </Col>
                <Col>
                  <Select
                    value={values.hasWorkingExperienceInSingapore}
                    defaultValue={values.hasWorkingExperienceInSingapore}
                    onChange={(e) =>
                      setFieldValue('hasWorkingExperienceInSingapore', e)
                    }
                  >
                    <Option value="" disabled></Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Col>
              </Row>
            </Space>
            <Title style={{color: '#8893F9'}} level={5}>
              C3 Feedback from previous employers in Singapore
            </Title>
            <Space direction="vertical" style={{width: '100%'}}>
              <Row align="middle">
                <Col>
                  <Typography>Feedback </Typography>
                </Col>
                <Col style={{marginLeft: 10, marginRight: 10}}>
                  <Select
                    value={values.wasFeedbackFromEA}
                    defaultValue={values.wasFeedbackFromEA}
                    onChange={(e) => setFieldValue('wasFeedbackFromEA', e)}
                    style={{width: 110}}
                  >
                    <Option value={true}>was yes</Option>
                    <Option value={false}>was not</Option>
                  </Select>
                </Col>
                <Col>
                  <Typography>
                    obtained by the EA from the previous employers.
                  </Typography>
                </Col>
              </Row>
              <Row align="middle">
                <Col>
                  <Typography>
                    If feedback was obtained (attach testimonial if possible),
                    please indicate the feedback in the table below:
                  </Typography>
                </Col>
              </Row>
            </Space>
            <Table
              dataSource={employeerFeedbackDataTable}
              pagination={false}
              style={{marginTop: 15}}
            >
              <Table.Column
                title="Employer"
                dataIndex="no"
                key="no"
                render={(no) => (
                  <Input
                    placeholder={`Employer`}
                    size="large"
                    className="buttonBorderRadius"
                    value={values[`previousEmployer${no}`]}
                    onChange={(e) =>
                      setFieldValue(`previousEmployer${no}`, e.target.value)
                    }
                  />
                )}
              />
              <Table.Column
                title="Feedback"
                dataIndex="no"
                key="no"
                render={(no) => (
                  <Input
                    // style={{width: 300}}
                    placeholder="Feedback"
                    size="large"
                    className="buttonBorderRadius"
                    value={values[`previousEmployerFeedback${no}`]}
                    onChange={(e) =>
                      setFieldValue(
                        `previousEmployerFeedback${no}`,
                        e.target.value,
                      )
                    }
                  />
                )}
              />
            </Table>
            <Space direction="vertical" style={{width: '100%', marginTop: 20}}>
              <Typography>
                AVAILABILITY OF MDW TO BE INTERVIEWED BY PROSPECTIVE EMPLOYER
              </Typography>
              <Space direction="vertical">
                <Radio.Group
                  defaultValue={values.availabilityOfMDWToBeInterviewed}
                  onChange={(e) =>
                    setFieldValue(
                      'availabilityOfMDWToBeInterviewed',
                      e.target.value,
                    )
                  }
                >
                  <Space direction="vertical">
                    <Radio value={'MDW_IS_NOT_AVAILABLE_FOR_INTERVIEW'}>
                      MDW is not available for interview
                    </Radio>
                    <Radio value={'MDW_CAN_BE_INTERVIEWED_BY_PHONE'}>
                      MDW can be interviewed by phone
                    </Radio>
                    <Radio value={'MDW_CAN_BE_INTERVIEWED_BY_VIDEO_CONFERENCE'}>
                      MDW can be interviewed by video-conference
                    </Radio>
                    <Radio value={'MDW_CAN_BE_INTERVIEWED_IN_PERSON'}>
                      MDW can be interviewed in person
                    </Radio>
                  </Space>
                </Radio.Group>
              </Space>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                  marginTop: 20,
                }}
              >
                <Col span={10}>
                  <Typography>Other remarks:</Typography>
                </Col>
                <Col span={14}>
                  <Input.TextArea
                    style={{width: 300}}
                    placeholder="Note.."
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                    value={values.otherRemarksC3}
                    onChange={(e) =>
                      setFieldValue('otherRemarksC3', e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                  marginTop: 20,
                }}
              >
                <Col span={5}>
                  <Typography>Inactive</Typography>
                </Col>
                <Col span={5}>
                  <Switch
                    checked={values.isInActive}
                    defaultChecked={values.isInActive}
                    onChange={(e) =>
                      setFieldValue('isInActive', !values.isInActive)
                    }
                  />
                </Col>
                <Col span={14}>
                  <Input.TextArea
                    value={values.inactiveMessage}
                    onChange={(e) =>
                      setFieldValue('inactiveMessage', e.target.value)
                    }
                    style={{width: 300}}
                    placeholder="Note.."
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    multiple={true}
                    maxLength={100}
                  />
                </Col>
              </Row>
            </Space>
          </>
        )
    }
  }

  const isSubmittedActive = (values) => {
    if (
      values.name &&
      values.dateOfBirth &&
      values.placeOfBirth &&
      values.height &&
      values.weight &&
      values.nationality &&
      values.residentialAddress &&
      values.nationalID &&
      values.passportNumber &&
      values.salaryRange &&
      values.nameOfPort &&
      values.contactNumber &&
      values.nextOfKin &&
      values.nextOfKinContactNumber &&
      values.religion &&
      values.educationLevel &&
      // values.numberOfSiblings &&
      values.maritalStatus &&
      // values.childrenNumber &&
      values.photoKey &&
      // values.careOfInfantsDetailA2 &&
      // values.careOfInfantsDetailB1 &&
      // values.careOfElderlyDetailA2 &&
      // values.careOfElderlyDetailB1 &&
      // values.careOfDisabledDetailA2 &&
      // values.careOfDisabledDetailB1 &&
      values.generalHouseworkDetailA2 &&
      // values.generalHouseworkDetailB1 &&
      values.cookingDetailA2
      // values.cookingDetailB1 &&
      // values.languageDetailA2 &&
      // values.languageDetailB1 &&
      // values.foodHandlingPreferences &&
      // values.coreSkills
      // values.restDaysPerMonth
    ) {
      return true
    }
    return false
  }

  const handleSubmission = async (type, formikProps) => {
    const {values} = formikProps

    let _values
    _values = values
    delete _values.branchEA
    delete _values.branchSupplier
    delete _values.createdAt
    delete _values.updatedAt
    delete _values.owner
    delete _values.createdOn
    delete _values.deletedOn
    delete _values.updatedOn
    delete _values.deleted
    delete _values.suppliedBy
    delete _values.inInactiveByUser

    let chunks = []
    for (const cols = Object.entries(_values); cols.length; )
      chunks.push(cols.splice(0, 52).reduce((o, [k, v]) => ((o[k] = v), o), {}))

    chunks.map(async (item) => {
      try {
        await API.graphql({
          query: updateMigrant,
          variables: {
            input: {
              ...item,
              id: match.params.id,
              status: type || values.status,
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })
      } catch (error) {
        return notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    })

    notification['success']({
      message: 'Success',
      description: `MDW Bio data Updated!`,
    })
    setTimeout(() => {
      window.location.href = '/admin/dashboard'
    }, 1000)
  }

  React.useEffect(() => {
    if (
      priceCoding.countryCode &&
      priceCoding.supplierPriceCode &&
      priceCoding.isEc
    ) {
      setInitFormValues({
        ...initFormValues,
        priceCode:
          priceCoding.countryCode +
          ',' +
          priceCoding.supplierPriceCode +
          ',' +
          priceCoding.isEc,
        priceCodeMDWDeploymentCountry: priceCoding.mdwDeploymentCountry,
      })
    }
  }, [priceCoding])

  React.useEffect(() => {
    const init = async () => {
      try {
        //GET CURRENT LOGIN
        const _listUsers2 = await API.graphql({
          query: listUsers,
          variables: {
            filter: {
              email: {
                eq: getSignedInUserDetails().UserAttributes.find(
                  (userAttribute) => userAttribute.Name === 'email',
                ).Value,
              },
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })

        setUser(_listUsers2.data.listUsers.items[0])
      } catch (error) {
        return notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }

    init()

    const _getMigrant = async () => {
      try {
        //GET BIO DETAIL
        const __getMigrant = await API.graphql({
          query: getMigrantSuperAdmin,
          variables: {
            id: match.params.id,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })

        if (__getMigrant?.data?.getMigrant?.reservedBy) {
          const _getCompany = await API.graphql({
            query: getCompany,
            variables: {
              id: __getMigrant?.data?.getMigrant?.branchEA?.companyID,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
          setEACompanyName(_getCompany.data.getCompany.companyName)
          console.log('_getCompany', _getCompany)
        } else if (__getMigrant?.data?.getMigrant?.confirmedBy) {
          const _getBranch = await API.graphql({
            query: getBranch,
            variables: {
              id: __getMigrant?.data?.getMigrant?.confirmedBy,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })
          setEACompanyName(_getBranch.data.getBranch.company.companyName)
          setEABranchName(_getBranch.data.getBranch.branchName)
          console.log('_getBranch', _getBranch)
        }

        console.log('__getMigrant', __getMigrant.data.getMigrant)
        // setInitFormValues(__getMigrant.data.getMigrant)
        setInitFormValues({
          ...__getMigrant.data.getMigrant,
          isInActive: __getMigrant.data.getMigrant.status === 'INACTIVE',
        })

        if (
          __getMigrant.data.getMigrant.priceCode &&
          __getMigrant.data.getMigrant.priceCodeMDWDeploymentCountry
        ) {
          setPriceCoding({
            countryCode: __getMigrant.data.getMigrant.priceCode.split(',')[0],
            supplierPriceCode:
              __getMigrant.data.getMigrant.priceCode.split(',')[1],
            isEc: __getMigrant.data.getMigrant.priceCode.split(',')[2],
            mdwDeploymentCountry:
              __getMigrant.data.getMigrant.priceCodeMDWDeploymentCountry,
          })
        }
      } catch (error) {
        return notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }

    if (match.params?.id) {
      _getMigrant()
      _listPriceCodeCountryCodes()
      _listPriceCodeSupplierPriceCodes()
    }
  }, [])

  return (
    <Wrapper {...{user}}>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            padding: 15,
            marginLeft: '6%',
            backgroundColor: '#FFF',
          }}
        >
          <Space direction="vertical" size={50}>
            <Space direction="vertical">
              <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

              <Text>
                *Please ensure that you run through the information within the
                biodata as it is an important document to help you select a
                suitable MDW
              </Text>
            </Space>
            <Formik
              enableReinitialize={true}
              initialValues={initFormValues}
              onSubmit={async (values) => {}}
            >
              {(formikProps) => {
                const {values, handleSubmit, setFieldValue} = formikProps
                return (
                  <>
                    {renderForm(values, setFieldValue)}
                    <Space
                      align="center"
                      direction="vertical"
                      style={{width: '100%', marginTop: 80}}
                      size={10}
                    >
                      <Space direction="horizontal">
                        {pageForm !== 1 && (
                          <Button
                            type="primary"
                            style={{
                              backgroundColor: '#8893F9',
                              borderColor: 'transparent',
                              width: 150,
                            }}
                            onClick={() => setPageForm(pageForm - 1)}
                          >
                            Back
                          </Button>
                        )}
                        {pageForm !== 4 ? (
                          <Button
                            type="primary"
                            style={{
                              backgroundColor: '#8893F9',
                              borderColor: 'transparent',
                              width: 150,
                            }}
                            onClick={() => setPageForm(pageForm + 1)}
                          >
                            Continue
                          </Button>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              style={{
                                backgroundColor: '#8893F9',
                                borderColor: 'transparent',
                                width: 150,
                              }}
                              onClick={() => {
                                // handleSubmit('SAVE')
                                // history.push('/supplier/dashboard')
                                handleSubmission(null, formikProps)
                              }}
                            >
                              Save
                            </Button>

                            {!values.isInActive ? (
                              <Button
                                type="primary"
                                style={{
                                  backgroundColor: `${
                                    isSubmittedActive(values) &&
                                    initFormValues.priceCode &&
                                    initFormValues.priceCodeMDWDeploymentCountry &&
                                    (values.status === 'SUBMITTED' ||
                                      JSON.stringify(initFormValues) !==
                                        JSON.stringify(values))
                                      ? '#34C217'
                                      : '#D1D1D1'
                                  }`,
                                  borderColor: 'transparent',
                                  width: 170,
                                }}
                                onClick={() => {
                                  if (
                                    isSubmittedActive(values) &&
                                    initFormValues.priceCode &&
                                    initFormValues.priceCodeMDWDeploymentCountry &&
                                    (values.status === 'SUBMITTED' ||
                                      JSON.stringify(initFormValues) !==
                                        JSON.stringify(values))
                                  ) {
                                    handleSubmission(
                                      values.status === 'RESERVED' ||
                                        values.status === 'CONFIRMED'
                                        ? values.status
                                        : 'AVAILABLE',
                                      formikProps,
                                    )
                                  } else {
                                    return true
                                  }
                                }}
                              >
                                Make MDW Available
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                style={{
                                  backgroundColor: `${
                                    isSubmittedActive(values) &&
                                    initFormValues.priceCode &&
                                    initFormValues.priceCodeMDWDeploymentCountry
                                      ? '#34C217'
                                      : '#D1D1D1'
                                  }`,
                                  borderColor: 'transparent',
                                  width: 170,
                                }}
                                onClick={() => {
                                  if (
                                    isSubmittedActive(values) &&
                                    initFormValues.priceCode &&
                                    initFormValues.priceCodeMDWDeploymentCountry
                                  ) {
                                    handleSubmission('INACTIVE', formikProps)
                                  } else {
                                    return true
                                  }
                                }}
                              >
                                Submit
                              </Button>
                            )}
                          </>
                        )}
                      </Space>
                      {isSubmittedActive(values) &&
                      initFormValues.priceCode &&
                      initFormValues.priceCodeMDWDeploymentCountry ? (
                        <></>
                      ) : (
                        <>
                          {pageForm === 4 && (
                            <Text style={{color: '#FF0707'}}>
                              *Please complete the price code data before make
                              MDW Available
                            </Text>
                          )}
                        </>
                      )}
                      <Text>{pageForm} of 4 form</Text>
                    </Space>
                  </>
                )
              }}
            </Formik>
          </Space>
        </Layout>
      </Content>
    </Wrapper>
  )
}

export default BioForm
