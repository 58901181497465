import awsExports from '../backend/aws-exports'

export const checkLoginUser = () => {
  return localStorage.getItem(
    `CognitoIdentityServiceProvider.${awsExports.aws_user_pools_web_client_id}.LastAuthUser`,
  )
}

export const getSignedInUserDetails = () => {
  return JSON.parse(
    localStorage.getItem(
      `CognitoIdentityServiceProvider.${
        awsExports.aws_user_pools_web_client_id
      }.${localStorage.getItem(
        `CognitoIdentityServiceProvider.${awsExports.aws_user_pools_web_client_id}.LastAuthUser`,
      )}.userData`,
    ),
  )
}
