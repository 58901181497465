import React from 'react'
import {
  Layout,
  Col,
  Row,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Modal,
  Space,
  notification,
} from 'antd'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {Formik} from 'formik'
import {API} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import {listUsers} from '../../backend/graphql/queries'
import {capitalize} from 'lodash'
import {checkLoginUser} from '../../utils/checkValidity'

const RegistrationForm = ({title, modalAttribute, setModalAttribute}) => {
  const [form] = Form.useForm()

  return (
    <Formik
      initialValues={{
        email: '',
        phoneNo: '',
        companyName: '',
        address: '',
      }}
      onSubmit={async (values) => {
        try {
          // TODO: send generated password, via clientMetaData Auth.signUp

          await Auth.signUp({
            username: values.email,
            password: '1234qwer',
            attributes: {
              email: values.email,
              'custom:address': values.address,
              'custom:companyName': values.companyName,
              'custom:phoneNo': values.phoneNo,
              'custom:userType':
                title === 'Supplier' ? 'SUPPLIER_ADMIN' : 'EA_ADMIN',
            },

            /* phoneNo: values.phoneNo,
            fullName: values.companyName, */
          })

          notification['success']({
            message: 'Success',
            description: 'Set password link already sent to the email!',
          })

          values.email = ''
          values.phoneNo = ''
          values.companyName = ''
          values.address = ''
        } catch (error) {
          console.error(error)
          notification['error']({
            message: 'Error',
            description:
              error.name === 'UsernameExistsException'
                ? 'Email already registered'
                : error.name,
          })
        }
      }}
    >
      {({handleSubmit, values, errors, setFieldValue, isSubmitting}) => {
        return (
          <Form layout="inline" form={form} name="horizontal_login">
            <Row style={{width: '100%'}}>
              <Col span={12}>
                <Form.Item
                  name="companyName"
                  rules={[{message: 'Company Name is required'}]}
                >
                  <Row>
                    <Col span={8}>
                      <Typography>{title}/Company Name</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Company Name"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        value={values.companyName}
                        onChange={(e) =>
                          setFieldValue('companyName', e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={12} style={{paddingLeft: 25}}>
                <Form.Item
                  // label="Admin Email"
                  name="email"
                  rules={[{message: 'Email is required'}]}
                >
                  <Row>
                    <Col span={8}>
                      <Typography>Admin Email</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Email"
                        type="email"
                        size="large"
                        className="buttonBorderRadius"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row style={{width: '100%', marginTop: 10}}>
              <Col span={12}>
                <Form.Item
                  // label="Contact No"
                  name="contactNo"
                  rules={[{message: 'Contact No is required'}]}
                >
                  <Row>
                    <Col span={8}>
                      <Typography>Contact No</Typography>
                    </Col>
                    <Col span={16}>
                      <Input
                        style={{width: 300}}
                        placeholder="Contact No"
                        type="number"
                        size="large"
                        className="buttonBorderRadius"
                        value={values.phoneNo}
                        onChange={(e) =>
                          setFieldValue('phoneNo', e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={12} style={{paddingLeft: 25}}>
                <Form.Item
                  name="address"
                  rules={[{message: 'Address is required'}]}
                >
                  <Row>
                    <Col span={8}>
                      <Typography>Address</Typography>
                    </Col>
                    <Col span={16}>
                      <Input.TextArea
                        placeholder="Address"
                        type="text"
                        size="large"
                        className="buttonBorderRadius"
                        multiple={true}
                        maxLength={100}
                        value={values.address}
                        onChange={(e) =>
                          setFieldValue('address', e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row
              align="middle"
              justify="center"
              style={{width: '100%', marginTop: 87}}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#CECECE',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                }}
                onClick={() =>
                  setModalAttribute({
                    ...modalAttribute,
                    isVisible: false,
                    title: '',
                  })
                }
                size="large"
              >
                Cancel
              </Button>
              ,
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#8893F9',
                  borderColor: 'transparent',
                  width: 160,
                  borderRadius: 9,
                  color: '#FFF',
                  marginLeft: 70,
                }}
                onClick={handleSubmit}
                size="large"
              >
                Register
              </Button>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

function Login(props) {
  const {Header, Footer, Sider, Content} = Layout
  const [modalAttribute, setModalAttribute] = React.useState({
    isVisible: false,
    title: '',
  })
  const history = useHistory()

  if (checkLoginUser()) {
    const getCurrentAuthenticatedUser = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser()
        const userCognitoGroup =
          userData.signInUserSession.accessToken.payload['cognito:groups'][0]
        switch (userCognitoGroup) {
          case 'admin':
            history.push('/admin/dashboard')
            break
          case 'supplier':
            history.push('/supplier/dashboard')
            break
          case 'ea':
            history.push('/ea/dashboard')
            break
          default:
            return true
        }
      } catch (error) {
        console.error(error)
      }
    }

    getCurrentAuthenticatedUser()
  }

  return (
    <>
      <Layout className="bg-white">
        <Row>
          <Content className="w-50-vh h-100-percent flexRowCenter">
            <Col className="w-50-percent flexColCenter ">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={async (values) => {
                  try {
                    const user = await Auth.signIn(
                      values.email,
                      values.password,
                    )

                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                      // return history.push('/reset-password')
                      return history.push({
                        pathname: '/reset-password',
                        state: {
                          email: values.email,
                          password: values.password,
                        },
                      })
                    }

                    const _listUsers = await API.graphql({
                      query: listUsers,
                      variables: {
                        filter: {
                          email: {
                            contains: user.attributes.email,
                          },
                        },
                      },
                      authMode: 'AMAZON_COGNITO_USER_POOLS',
                    })

                    if (
                      _listUsers.data.listUsers.items[0].status !== 'APPROVED'
                    ) {
                      await Auth.signOut()
                      return notification['error']({
                        message: 'Error',
                        description: `Your account is ${capitalize(
                          _listUsers.data.listUsers.items[0].status,
                        )}.`,
                      })
                    } else if (
                      _listUsers.data.listUsers.items[0].status === 'APPROVED'
                    ) {
                      const userData = await Auth.currentAuthenticatedUser()

                      const userCognitoGroup =
                        userData.signInUserSession.accessToken.payload[
                          'cognito:groups'
                        ][0]
                      console.log('userCognitoGroup', userCognitoGroup)
                      switch (userCognitoGroup) {
                        case 'admin':
                          history.push('/admin/dashboard')
                          break
                        case 'supplier':
                          history.push('/supplier/dashboard')
                          break
                        case 'ea':
                          history.push('/ea/dashboard')
                          break
                        default:
                          return true
                      }
                    }
                  } catch (error) {
                    console.error(error)
                    return notification['error']({
                      message: 'Error',
                      description:
                        error.name === 'UserNotConfirmedException'
                          ? 'User not yet confirmed'
                          : error.name === 'UserNotFoundException'
                          ? 'User not found'
                          : error.name === 'NotAuthorizedException'
                          ? 'Incorrect username or password.'
                          : error.name,
                    })
                  }
                }}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => {
                  return (
                    <Form className="flexColCenter">
                      <Typography
                        className="text-title"
                        style={{color: '#8893F9'}}
                      >
                        LOG IN
                      </Typography>

                      <Form.Item
                        name="email"
                        rules={[{required: true, message: 'Email is required'}]}
                      >
                        <Typography
                          className="text-purple"
                          style={{color: '#8893F9'}}
                        >
                          Email
                        </Typography>
                        <Input
                          style={{width: 300}}
                          placeholder="Email"
                          type="email"
                          size="large"
                          className="buttonBorderRadius"
                          value={values.email}
                          onChange={(e) =>
                            setFieldValue('email', e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="password"
                        rules={[
                          {required: true, message: 'Password is required'},
                        ]}
                      >
                        <Typography style={{color: '#8893F9'}}>
                          Password
                        </Typography>
                        <Input.Password
                          style={{width: 300}}
                          className="buttonBorderRadius"
                          placeholder="Password"
                          type="password"
                          size="large"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          value={values.password}
                          onChange={(e) =>
                            setFieldValue('password', e.target.value)
                          }
                        />
                        <Typography
                          style={{
                            color: '#8893F9',
                            fontSize: 10,
                            marginTop: 10,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => history.push('/forgot-password')}
                        >
                          Forgot Password ?
                        </Typography>
                      </Form.Item>
                      <Form.Item
                        label=" "
                        colon={false}
                        className="marginTop15"
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            backgroundColor: '#8893F9',
                            borderColor: 'transparent',
                            width: 150,
                            borderRadius: 9,
                            color: '#FFF',
                          }}
                          size="large"
                          // onClick={() => history.push('/admin/dashboard')}
                          onClick={handleSubmit}
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                  )
                }}
              </Formik>
              <Space
                direction="horizontal"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                className="marginTop30"
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: '#8893F9',
                    borderColor: 'transparent',
                    width: 180,
                    borderRadius: 9,
                    color: '#FFF',
                  }}
                  size="large"
                  onClick={() =>
                    setModalAttribute({
                      ...modalAttribute,
                      isVisible: true,
                      title: 'Supplier',
                    })
                  }
                >
                  Register as Supplier
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: '#8893F9',
                    borderColor: 'transparent',
                    width: 180,
                    borderRadius: 9,
                    color: '#FFF',
                  }}
                  size="large"
                  onClick={() =>
                    setModalAttribute({
                      ...modalAttribute,
                      isVisible: true,
                      title: 'EA',
                    })
                  }
                >
                  Register as EA
                </Button>
              </Space>
            </Col>
          </Content>
          <Content className="w-50-vh">
            <Image
              height={'100vh'}
              width={'100vh'}
              src={require('../../assets/image/model.png')}
              preview={false}
            />
          </Content>
        </Row>
        <Modal
          title={`Register as ${modalAttribute.title}`}
          visible={modalAttribute.isVisible}
          width={950}
          footer={null}
          /*  footer={[
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#CECECE',
                borderColor: 'transparent',
                width: 160,
                borderRadius: 9,
                color: '#FFF',
              }}
              onClick={() =>
                setModalAttribute({
                  ...modalAttribute,
                  isVisible: false,
                  title: '',
                })
              }
              size="large"
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#8893F9',
                borderColor: 'transparent',
                width: 160,
                borderRadius: 9,
                color: '#FFF',
              }}
              onClick={() => {
                
                 setModalAttribute({
                  ...modalAttribute,
                  isVisible: false,
                  title: '',
                }) 
                Auth.signUp()
              }
              }
              size="large"
            >
              Register
             
            </Button>,
          ]} */
          onOk={() =>
            setModalAttribute({
              ...modalAttribute,
              isVisible: false,
              title: '',
            })
          }
          onCancel={() =>
            setModalAttribute({
              ...modalAttribute,
              isVisible: false,
              title: '',
            })
          }
        >
          <RegistrationForm
            title={modalAttribute.title}
            {...{modalAttribute}}
            {...{setModalAttribute}}
          />
        </Modal>
      </Layout>
    </>
  )
}

export default Login
