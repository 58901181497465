import React from 'react'
import {
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Avatar,
  PageHeader,
  Radio,
  Table,
  Divider,
  Checkbox,
  notification,
} from 'antd'
import {
  CaretDownFilled,
  UserOutlined,
  UserAddOutlined,
  DashboardOutlined,
  FileImageTwoTone,
  VideoCameraTwoTone,
  FileTwoTone,
} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import {listUsers} from '../../../backend/graphql/queries'
import {updateUser, updateBranch} from '../../../backend/graphql/mutations'
import {getSignedInUserDetails} from '../../../utils/checkValidity'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card

const MainMenuSider = ({setActiveSider}) => {
  const history = useHistory()
  return (
    <Sider style={{backgroundColor: '#FFF'}}>
      <Menu
        style={{width: 256, height: '100%'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
      >
        <Menu.Item
          icon={<DashboardOutlined />}
          onClick={() => {
            history.push('/supplier/dashboard')
          }}
        >
          Dashboard
        </Menu.Item>
        {getSignedInUserDetails().UserAttributes.find(
          (userAttribute) => userAttribute.Name === 'custom:userType',
        ) && (
          <Menu.Item
            icon={<UserAddOutlined />}
            onClick={() =>
              history.push({
                pathname: '/supplier/dashboard',
                state: {
                  activeSider: 3,
                },
              })
            }
          >
            Supplier Account Mgt
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}

const Wrapper = ({children, setActiveSider, user}) => {
  return (
    <div style={{backgroundColor: '#FAFAFA'}}>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          borderBottom: '1px solid #ecf0f1',
        }}
        className="site-page-header"
        onBack={() => null}
        backIcon={false}
        title=" "
        subTitle=" "
        avatar={{
          src: 'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/our.png',
          size: 65,
        }}
        extra={[<DropdownMenu {...{user}} />]}
      />
      <Layout style={{backgroundColor: '#FFF'}}>
        <MainMenuSider {...{setActiveSider}} />

        {children}
      </Layout>
    </div>
  )
}

const DropdownMenu = ({user}) => {
  const history = useHistory()

  const MenuToolbar = () => {
    return (
      <Menu>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => history.push('/supplier/profile')}
          >
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={async () => {
              try {
                await Auth.signOut()
                history.push('/')
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error.name,
                })
              }
            }}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown key="more" overlay={MenuToolbar}>
      <Button
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        <Row align="middle" justify="center" style={{marginTop: 15}}>
          <Col>
            {user.profilePictureKey ? (
              <RenderPicture
                s3Key={user.profilePictureKey}
                type="PHOTO"
                needRefetch={true}
                isSmall={true}
              />
            ) : (
              <Avatar size={32} icon={<UserOutlined />} />
            )}
          </Col>

          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Text strong>{user?.fullName || user?.email}</Text>

            <Text type="secondary">{user?.branch?.company?.companyName}</Text>
          </Col>

          <Col>
            <CaretDownFilled />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}

const RenderPicture = ({s3Key, type, needRefetch, isSmall}) => {
  const [picture, setPicture] = React.useState('')

  const init = async () => {
    try {
      const _picture = await Storage.get(s3Key)

      setPicture(_picture)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [needRefetch, s3Key])

  const _renderPicture = (type) => {
    switch (type) {
      case 'PHOTO':
        return (
          <Avatar
            size={isSmall ? 32 : 200}
            src={picture}
            style={{cursor: 'pointer'}}
          />
        )

      default:
        return (
          <Avatar
            size={isSmall ? 32 : 200}
            src={picture}
            style={{cursor: 'pointer'}}
          />
        )
    }
  }

  return <>{_renderPicture(type)}</>
}

function Profile() {
  const [user, setUser] = React.useState('')
  const [pageForm, setPageForm] = React.useState(1)
  const [fields, setFields] = React.useState({
    fullName: '',
    gender: '',
    email: '',
    contact: '',
    idNumber: '',
    address: '',
    staffCode: '',
    registrationNo: '',
    branchCode: '',
    designation: '',
    profilePictureKey: '',
  })

  const renderForm = () => {
    const data = [1, 2, 3, 4]

    switch (pageForm) {
      case 1:
        return (
          <>
            <Space
              direction="horizontal"
              style={{
                justifyContent: 'space-between',

                width: '100%',
                alignItems: 'start',
              }}
            >
              {!fields.profilePictureKey ? (
                <label htmlFor="uploadPhoto">
                  <Space
                    direction="vertical"
                    align="center"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Avatar size={200} icon={<UserOutlined />} />
                  </Space>
                  <input
                    hidden
                    type="file"
                    id="uploadPhoto"
                    accept="image/*"
                    onChange={async (e) => {
                      try {
                        if (e.target.files[0].size > 5000000) {
                          notification['error']({
                            message: 'Error',
                            description: 'Maximum file size is 5mb.',
                          })
                        }
                        const _upload = await Storage.put(
                          `photo-user-${user.id}-${(
                            Math.random() * 1e32
                          ).toString(36)}`,
                          e.target.files[0],
                          {
                            contentType: 'image/png',
                          },
                        )

                        setFields({...fields, profilePictureKey: _upload.key})
                        // setProfilePictureKey(_upload.key)
                      } catch (error) {
                        notification['error']({
                          message: 'Error',
                          description: JSON.stringify(error),
                        })
                      }
                    }}
                  />
                </label>
              ) : (
                <label htmlFor="uploadPhoto">
                  <RenderPicture
                    s3Key={fields.profilePictureKey}
                    type="PHOTO"
                    needRefetch={true}
                  />
                  <input
                    hidden
                    type="file"
                    id="uploadPhoto"
                    accept="image/*"
                    onChange={async (e) => {
                      try {
                        if (e.target.files[0].size > 5000000) {
                          notification['error']({
                            message: 'Error',
                            description: 'Maximum file size is 5mb.',
                          })
                        }
                        const _upload = await Storage.put(
                          `photo-user-${user.id}-${(
                            Math.random() * 1e32
                          ).toString(36)}`,
                          e.target.files[0],
                          {
                            contentType: 'image/png',
                          },
                        )

                        setFields({...fields, profilePictureKey: _upload.key})
                        // setProfilePictureKey(_upload.key)
                      } catch (error) {
                        notification['error']({
                          message: 'Error',
                          description: JSON.stringify(error),
                        })
                      }
                    }}
                  />
                </label>
              )}

              <Space direction="vertical">
                <Title style={{color: '#8893F9'}} level={5}>
                  Personal Information
                </Title>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Full Name</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 250}}
                      placeholder="Full Name"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.fullName}
                      onChange={(e) =>
                        setFields({...fields, fullName: e.target.value})
                      }
                    />
                  </Col>
                </Row>

                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Gender</Typography>
                  </Col>
                  <Col span={14}>
                    <Select
                      value={fields.gender}
                      style={{width: 250}}
                      onChange={(value) => {
                        setFields({...fields, gender: value})
                      }}
                    >
                      <Option value="default" disabled>
                        Select Gender
                      </Option>
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                    </Select>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Email</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 250}}
                      placeholder="Email"
                      type="email"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.email}
                      readOnly
                      disabled
                    />
                  </Col>
                </Row>
                {/*  <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Password</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 250}}
                      placeholder="Password"
                      type="password"
                      size="large"
                      className="buttonBorderRadius"
                    />
                  </Col>
                </Row> */}
              </Space>
              <Space direction="vertical" size={10} style={{width: 430}}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{marginTop: 40}}
                >
                  <Col span={10}>
                    <Typography>Contact</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 250}}
                      placeholder="Contact"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.contact}
                      onChange={(e) =>
                        setFields({...fields, contact: e.target.value})
                      }
                    />
                  </Col>
                </Row>
                {/*  <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>ID Number</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 250}}
                      placeholder="Contact"
                      type="number"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.idNumber}
                    />
                  </Col>
                </Row> */}
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Address</Typography>
                  </Col>
                  <Col span={14}>
                    <Input.TextArea
                      style={{width: 250}}
                      placeholder="Address"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      multiple={true}
                      maxLength={100}
                      value={fields.address}
                      onChange={(e) =>
                        setFields({...fields, address: e.target.value})
                      }
                    />
                  </Col>
                </Row>
              </Space>
            </Space>
            <Space
              direction="horizontal"
              style={{
                justifyContent: 'space-between',

                width: '80%',
                alignItems: 'start',
              }}
            >
              <Space direction="vertical">
                <Title style={{color: '#8893F9'}} level={5}>
                  Staff Information
                </Title>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Staff Code</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Staff Code"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.staffCode}
                      onChange={(e) =>
                        setFields({...fields, staffCode: e.target.value})
                      }
                    />
                  </Col>
                </Row>

                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Registration No</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Registration No"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.registrationNo}
                      onChange={(e) =>
                        setFields({...fields, registrationNo: e.target.value})
                      }
                    />
                  </Col>
                </Row>
                <Button
                  onClick={() => _editProfile()}
                  style={{
                    borderColor: 'transparent',
                    width: 150,
                    backgroundColor: '#34C217',
                    color: '#FFF',
                    borderRadius: 9,
                    marginTop: 40,
                    marginBottom: 20,
                  }}
                >
                  Edit
                </Button>
              </Space>
              <Space direction="vertical" size={10}>
                <Title style={{color: '#8893F9'}} level={5}>
                  Branch Information
                </Title>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Branch Code</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Branch Code"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.branchCode}
                      onChange={(e) =>
                        setFields({...fields, branchCode: e.target.value})
                      }
                    />
                  </Col>
                </Row>
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Typography>Designation</Typography>
                  </Col>
                  <Col span={14}>
                    <Input
                      style={{width: 300}}
                      placeholder="Designation"
                      type="text"
                      size="large"
                      className="buttonBorderRadius"
                      value={fields.designation}
                      onChange={(e) =>
                        setFields({...fields, designation: e.target.value})
                      }
                    />
                  </Col>
                </Row>
              </Space>
            </Space>
          </>
        )
      default:
        return <></>
    }
  }

  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      console.log('_listUsers', _listUsers)
      setUser(_listUsers.data.listUsers.items[0])
      setFields({
        address: _listUsers.data.listUsers.items[0].branch.address,
        branchCode: _listUsers.data.listUsers.items[0].branch.code,
        contact: _listUsers.data.listUsers.items[0].phoneNo,
        designation: _listUsers.data.listUsers.items[0].designation,
        email: _listUsers.data.listUsers.items[0].email,
        fullName: _listUsers.data.listUsers.items[0].fullName,
        gender: _listUsers.data.listUsers.items[0].gender,
        staffCode: _listUsers.data.listUsers.items[0].staffCode,
        registrationNo: _listUsers.data.listUsers.items[0].registrationNo,
        profilePictureKey: _listUsers.data.listUsers.items[0].profilePictureKey,
      })
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _editProfile = async () => {
    try {
      await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: user.id,
            fullName: fields.fullName,
            gender: fields.gender,
            email: fields.email,
            phoneNo: fields.contact,

            staffCode: fields.staffCode,
            registrationNo: fields.registrationNo,
            designation: fields.designation,
            profilePictureKey: fields.profilePictureKey,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: updateBranch,
        variables: {
          input: {
            id: user.branchID,
            code: fields.branchCode,
            address: fields.address,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      notification['success']({
        message: 'Success',
        description: 'Data Updated!',
      })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <Wrapper {...{user}}>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            padding: 15,
            marginLeft: '6%',
            backgroundColor: '#FFF',
          }}
        >
          <Space direction="vertical" size={50}>
            <Space direction="vertical">
              <Title level={3}>Profile</Title>
            </Space>
            {renderForm()}
          </Space>
        </Layout>
      </Content>
    </Wrapper>
  )
}

export default Profile
