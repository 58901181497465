import React from 'react'
import {
  Avatar,
  PageHeader,
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  notification,
} from 'antd'
import {
  UserOutlined,
  DashboardOutlined,
  CaretDownFilled,
  UserSwitchOutlined,
  FormOutlined,
  UserAddOutlined,
  BellOutlined,
  UploadOutlined,
  WarningOutlined,
  UsergroupAddOutlined,
  CalendarOutlined,
  IdcardOutlined,
  SearchOutlined,
  FilterOutlined,
  RightCircleOutlined,
  RightCircleTwoTone,
  ReconciliationTwoTone,
  BookTwoTone,
  SmileTwoTone,
  MehTwoTone,
  CalendarTwoTone,
  IdcardTwoTone,
  HighlightTwoTone,
} from '@ant-design/icons'
import Dashboard from './dashboard/Dashboard'
import Supplier from './supplier/Supplier'
import EmploymentAgency from './ea/EmploymentAgency'
import AccMgt from './accMgt/AccMgt'
import {useHistory} from 'react-router-dom'
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import {listUsers} from '../../backend/graphql/queries'
import {getSignedInUserDetails} from '../../utils/checkValidity'

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card
const {Header, Footer, Sider, Content} = Layout

const DropdownMenu = ({user}) => {
  const history = useHistory()

  const MenuToolbar = () => {
    return (
      <Menu>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => history.push('/supplier/profile')}
          >
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={async () => {
              try {
                await Auth.signOut()
                history.push('/')
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error.name,
                })
              }
            }}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
  }
  return (
    <Dropdown key="more" overlay={MenuToolbar}>
      <Button
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        <Row align="middle" justify="center" style={{marginTop: 15}}>
          <Col>
            {user.profilePictureKey ? (
              <RenderPicture
                s3Key={user.profilePictureKey}
                type="PHOTO"
                needRefetch={true}
                isSmall={true}
              />
            ) : (
              <Avatar size={32} icon={<UserOutlined />} />
            )}
          </Col>

          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Text strong>{user?.fullName || user?.email}</Text>

            <Text type="secondary">{user?.branch?.company?.companyName}</Text>
          </Col>

          <Col>
            <CaretDownFilled />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}

const RenderPicture = ({s3Key, type, needRefetch, isSmall}) => {
  const [picture, setPicture] = React.useState('')

  const init = async () => {
    try {
      const _picture = await Storage.get(s3Key)

      setPicture(_picture)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [needRefetch, s3Key])

  const _renderPicture = (type) => {
    switch (type) {
      case 'PHOTO':
        return (
          <Avatar
            size={isSmall ? 32 : 200}
            src={picture}
            style={{cursor: 'pointer'}}
          />
        )

      default:
        return (
          <Avatar
            size={isSmall ? 32 : 200}
            src={picture}
            style={{cursor: 'pointer'}}
          />
        )
    }
  }

  return <>{_renderPicture(type)}</>
}

const MainMenuSider = ({setActiveSider, activeSider}) => {
  return (
    <Sider style={{backgroundColor: '#FFF'}}>
      <Menu
        style={{width: 256, height: '100%'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
      >
        <Menu.Item
          icon={<DashboardOutlined />}
          onClick={() => {
            if (activeSider === 0) {
              return (window.location.href = '/supplier/dashboard')
            }
            setActiveSider(0)
          }}
        >
          Dashboard
        </Menu.Item>

        {getSignedInUserDetails().UserAttributes.find(
          (userAttribute) => userAttribute.Name === 'custom:userType',
        ) && (
          <Menu.Item
            icon={<UserAddOutlined />}
            onClick={() => setActiveSider(3)}
          >
            Supplier Account Mgt
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}

function RootContainer(props) {
  const [user, setUser] = React.useState('')
  const [activeSider, setActiveSider] = React.useState(
    props.location.state?.activeSider || 0,
  )
  const renderSiderContent = () => {
    switch (activeSider) {
      case 0:
        return <Dashboard />
      /* case 1:
        return <Supplier />
      case 2:
        return <EmploymentAgency /> */
      case 3:
        return <AccMgt />
      default:
        return null
    }
  }
  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            email: {
              eq: getSignedInUserDetails().UserAttributes.find(
                (userAttribute) => userAttribute.Name === 'email',
              ).Value,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setUser(_listUsers.data.listUsers.items[0])
      console.log('_listUsers12', _listUsers)
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }
  React.useEffect(() => {
    init()
  }, [])
  return (
    <div style={{backgroundColor: '#FAFAFA'}}>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          borderBottom: '1px solid #ecf0f1',
        }}
        className="site-page-header"
        onBack={() => null}
        backIcon={false}
        title=" "
        subTitle=" "
        avatar={{
          src: 'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/our.png',
          size: 65,
        }}
        extra={[<DropdownMenu {...{user}} />]}
      />
      <Layout style={{backgroundColor: '#FFF'}}>
        <MainMenuSider {...{activeSider}} {...{setActiveSider}} />

        {renderSiderContent()}
      </Layout>
    </div>
  )
}

export default RootContainer
