import React, {useEffect} from 'react'
import {
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Table,
  Button,
  Form,
  notification,
} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import {API} from '@aws-amplify/api'
import {
  listBranches,
  getBranch,
  listUsers,
  getUser,
} from '../../../backend/graphql/queries'
import {updateBranch} from '../../../backend/graphql/mutations'
import {Formik} from 'formik'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card
const {TextArea} = Input

export const Branch = (props) => {
  const [branches, setBranches] = React.useState([])

  const [selectedBranch, setSelectedBranch] = React.useState('')

  const init = async () => {
    try {
      const _listBranches = await API.graphql({
        query: listBranches,
        variables: {
          filter: {
            email: {
              ne: 'bdms_superadmin@nghaninn.com',
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBranches(_listBranches.data.listBranches.items)
    } catch (error) {
      console.error(error)
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error.errors[0].message),
      })
    }
  }

  const _getBranch = async (branchId) => {
    try {
      const __getBranch = await API.graphql({
        query: getBranch,
        variables: {
          id: branchId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setSelectedBranch(__getBranch.data.getBranch)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <Space direction="vertical" size={50}>
      <Row
        align="middle"
        justify="space-between"
        style={{backgroundColor: '#D8E2FF'}}
      >
        <Col>
          <Title style={{marginLeft: 15}}>BRANCH</Title>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text>CHOOSE BRANCH</Text>
            <Select
              defaultValue=""
              style={{width: 200}}
              onChange={(value) => _getBranch(value)}
            >
              <Option value="" disabled></Option>
              {props.branches
                ? props.branches.map((item) => (
                    <Option value={item.id}>
                      {item.company?.companyName +
                        ' - ' +
                        (item.isMainBranch ? 'Main Branch' : item.branchName)}
                    </Option>
                  ))
                : branches.map((item) => (
                    <Option value={item.id}>
                      {item.company?.companyName +
                        ' - ' +
                        (item.isMainBranch ? 'Main Branch' : item.branchName)}
                    </Option>
                  ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center" style={{marginTop: 15}}>
            <Text>TOTAL BRANCH</Text>
            <Title>
              {props.branches ? props.branches.length : branches.length}
            </Title>
          </Space>
        </Col>
        <Col>
          {/* <Button
            type="text"
            htmlType="submit"
            style={{
              backgroundColor: '#0CDF1C',
              borderColor: 'transparent',
              color: '#FFF',
              width: 150,
              marginRight: 15,
            }}
            onClick={() => true}
            icon={<SearchOutlined />}
          >
            Add Branch
          </Button>*/}
        </Col>
      </Row>
      <Formik
        enableReinitialize={true}
        initialValues={{
          branchName: selectedBranch?.branchName,
          telephoneNo: selectedBranch?.phone,
          branchCode: selectedBranch?.code,
          address: selectedBranch?.address,
          mobileNo: selectedBranch?.company?.user?.phoneNo,
          remarks: selectedBranch?.remarks,
          isMainBranch: selectedBranch?.isMainBranch,
        }}
        onSubmit={async (values) => {
          try {
            // TODO: update supplier detail
            await API.graphql({
              query: updateBranch,
              variables: {
                input: {
                  id: selectedBranch?.id,
                  code: values.branchCode,
                },
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            })

            notification['success']({
              message: 'Success',
              description: 'Supplier detail updated',
            })
          } catch (error) {
            notification['error']({
              message: 'Error',
              description: JSON.stringify(error),
            })
          }
        }}
      >
        {({handleSubmit, values, errors, setFieldValue, isSubmitting}) => {
          return (
            <Form name="branch_detail">
              <Row justify="space-around" align="middle">
                <Col span={10}>
                  <Form.Item name="branchName">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Branch Name</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Branch Name"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          disabled
                          readOnly
                          // value={selectedBranch?.branchName}
                          value={values.branchName}
                          onChange={(e) =>
                            setFieldValue('branchName', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="contactInfo">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Telephone No.</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Contact Info"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          disabled
                          readOnly
                          /*value={selectedBranch?.phone} */
                          value={values.telephoneNo}
                          onChange={(e) =>
                            setFieldValue('telephoneNo', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              {values.branchName && !values.isMainBranch && (
                <Row justify="space-around" align="middle">
                  <Col span={10}>
                    <Form.Item name="branchCode">
                      <Row align="middle" gutter={15}>
                        <Col span={5}>
                          <Typography>Branch Code</Typography>
                        </Col>
                        <Col span={5}>
                          <Input
                            style={{width: 300}}
                            placeholder="Branch Code"
                            type="text"
                            size="large"
                            className="buttonBorderRadius"
                            /*readOnly
                          value={selectedBranch?.code}*/
                            readOnly
                            disabled
                            value={values.branchCode}
                            onChange={(e) =>
                              setFieldValue('branchCode', e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item name="address">
                      <Row align="middle" gutter={15}>
                        <Col span={5}>
                          <Typography>Address</Typography>
                        </Col>
                        <Col span={16}>
                          <TextArea
                            style={{width: 300}}
                            placeholder="Address"
                            type="text"
                            size="large"
                            className="buttonBorderRadius"
                            rows={4}
                            readOnly
                            disabled
                            /*value={selectedBranch?.address} */
                            value={values.address}
                            onChange={(e) =>
                              setFieldValue('address', e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {/*   <Row justify="space-around" align="top">
                                <Col span={10}>
                  <Form.Item name="status">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Status</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Status"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          readOnly
                          value={selectedBranch?.status}
                          
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col> 
                <Col span={10}>
                  <Form.Item name="remarks">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Remarks</Typography>
                      </Col>
                      <Col span={16}>
                        <TextArea
                          style={{width: 300}}
                          placeholder="Remarks"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          rows={4}
                          readOnly
                          disabled
                          value={values.remarks}
                          onChange={(e) =>
                            setFieldValue('remarks', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row justify="space-around">
                <Col span={10}>
                  <Form.Item name="status">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Mobile No</Typography>
                      </Col>
                      <Col span={5}>
                        <Input
                          style={{width: 300}}
                          placeholder="Mobile No"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          readOnly
                          disabled
                          /*
                          value={selectedBranch?.company?.user?.phoneNo} */
                          value={values.mobileNo}
                          onChange={(e) =>
                            setFieldValue('mobileNo', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="remarks">
                    <Row align="middle" gutter={15}>
                      <Col span={5}>
                        <Typography>Remarks</Typography>
                      </Col>
                      <Col span={16}>
                        <TextArea
                          style={{width: 300}}
                          placeholder="Remarks"
                          type="text"
                          size="large"
                          className="buttonBorderRadius"
                          rows={4}
                          readOnly
                          disabled
                          /*
                          value={selectedBranch?.remarks} */
                          value={values.remarks}
                          onChange={(e) =>
                            setFieldValue('remarks', e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              {/*               <Row align="middle" justify="center">
                <Col>
                  <Button
                    style={{
                      borderColor: 'transparent',
                      // width: 150,
                      backgroundColor: selectedBranch?.id ? '#34C217' : '#666',
                      color: '#FFF',
                      borderRadius: 9,
                      marginBottom: 20,
                    }}
                    onClick={selectedBranch?.id ? handleSubmit : () => true}
                  >
                    Update Supplier Branch Code
                  </Button>
                </Col>
              </Row> */}
            </Form>
          )
        }}
      </Formik>
    </Space>
  )
}

export const Staff = (props) => {
  const [users, setUsers] = React.useState([])
  const [selectedUser, setSelectedUser] = React.useState('')

  const init = async () => {
    try {
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            deleted: {
              eq: false,
            },
            email: {
              ne: 'bdms_superadmin@nghaninn.com',
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setUsers(_listUsers.data.listUsers.items)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _getUser = async (userId) => {
    try {
      const __getUser = await API.graphql({
        query: getUser,
        variables: {
          id: userId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      setSelectedUser(__getUser.data.getUser)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <Space direction="vertical" size={50}>
      <Row
        align="middle"
        justify="space-between"
        style={{backgroundColor: '#D8E2FF'}}
      >
        <Col>
          <Title style={{marginLeft: 15}}>STAFF</Title>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text>CHOOSE STAFF</Text>
            <Select
              defaultValue=""
              style={{width: 200}}
              onChange={(value) => _getUser(value)}
            >
              <Option value="" disabled></Option>
              {props.users
                ? props.users.map((item) => (
                    <Option value={item.id}>{item.email}</Option>
                  ))
                : users.map((item) => (
                    <Option value={item.id}>{item.email}</Option>
                  ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" align="center" style={{marginTop: 15}}>
            <Text>TOTAL STAFF</Text>
            <Title>{props.users ? props.users.length : users.length}</Title>
          </Space>
        </Col>
        <Col>
          {/*  <Button
            type="text"
            htmlType="submit"
            style={{
              backgroundColor: '#0CDF1C',
              borderColor: 'transparent',
              color: '#FFF',
              width: 150,
              marginRight: 15,
            }}
            onClick={() => true}
            icon={<SearchOutlined />}
          >
            Add Staff
          </Button> */}
        </Col>
      </Row>
      <Form name="branch_detail">
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="branchName">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Full Name</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Staff Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.fullName}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="branchCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Branch Code</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Branch Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.branch?.code}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" align="middle">
          <Col span={10}>
            <Form.Item name="staffCode">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Staff Code</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Staff Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.staffCode}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="address">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Designation</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Designation"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.designation}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around" align="top">
          <Col span={10}>
            <Form.Item name="status">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Status</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="Status"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.status}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="email">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Email</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Email"
                    type="email"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.email}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col span={10}>
            <Form.Item name="idNumber">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>ID Number</Typography>
                </Col>
                <Col span={5}>
                  <Input
                    style={{width: 300}}
                    placeholder="ID Number"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.registrationNo}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="contact">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Contact</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact"
                    type="number"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                    value={selectedUser?.phoneNo}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col span={10}>
            <Form.Item name="registrationNo">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Registration No</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact"
                    type="number"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={10} style={{visibility: 'hidden'}}>
            <Form.Item name="registrationNo">
              <Row align="middle" gutter={15}>
                <Col span={5}>
                  <Typography>Registration No</Typography>
                </Col>
                <Col span={16}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact"
                    type="number"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Space>
  )
}

function SupplierEAAccountManagement() {
  return (
    <>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,

            paddingTop: 33,
            marginLeft: '5%',

            backgroundColor: '#FFF',
          }}
        >
          <Branch />
          <Staff />
        </Layout>
      </Content>
    </>
  )
}

export default SupplierEAAccountManagement
