import React from 'react'
import {
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Card,
  Input,
  Space,
  Select,
  Popover,
  Checkbox,
  List,
  Badge,
  notification,
  Pagination,
} from 'antd'
import {
  CaretDownFilled,
  BellOutlined,
  CalendarOutlined,
  IdcardOutlined,
  SearchOutlined,
  FilterOutlined,
} from '@ant-design/icons'

import VirtualList from 'rc-virtual-list'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {SupplierContext} from '../../../contexts/SupplierProvider'
import {API} from '@aws-amplify/api'

import {
  listUsers,
  listNotifications,
  listMigrantBySupplierBranchByStatus,
  listStatisticsByCompanyID,
  listStatisticsByBranchID,
} from '../../../backend/graphql/queries'
import {
  createMigrant,
  updateMigrant,
  updateNotification,
  createNotification,
} from '../../../backend/graphql/mutations'
import XLSX from 'xlsx'
import {getSignedInUserDetails} from '../../../utils/checkValidity'
import {capitalize, filter, isEmpty, orderBy, includes} from 'lodash'
import {RightCircleTwoTone, DownCircleTwoTone} from '@ant-design/icons'
import awsmobile from '../../../backend/aws-exports'

const {Sider, Content} = Layout

const {Text, Title, Link} = Typography

const {Option} = Select

const NotificationPopOver = ({userID}) => {
  const [notifications, setNotifications] = React.useState([])
  const init = async () => {
    try {
      const _listNotifications = await API.graphql({
        query: listNotifications,
        variables: {
          filter: {
            userID: {
              eq: userID,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _listNotificationsOrderByCreatedAt = orderBy(
        _listNotifications.data.listNotifications.items,
        ['createdAt'],
        ['desc'],
      )

      setNotifications(_listNotificationsOrderByCreatedAt)
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  const data = [
    {
      id: 1,
      staffID: 1,
      description: 'Ani status already Confirmed',
      isRead: false,
      createdOn: moment([[2022, 2, 6]]).fromNow(),
      readOn: moment([[2022, 2, 6]]).fromNow(),
    },
    {
      id: 2,
      staffID: 1,
      description: 'Siti status already Confirmed',
      isRead: false,
      createdOn: moment([[2022, 2, 6]]).fromNow(),
      readOn: moment([[2022, 2, 6]]).fromNow(),
    },
    {
      id: 1,
      staffID: 1,
      description: 'Ira status already Confirmed',
      isRead: false,
      createdOn: moment([[2022, 2, 6]]).fromNow(),
      readOn: moment([[2022, 2, 6]]).fromNow(),
    },
  ]
  return (
    <List>
      <VirtualList
        data={notifications}
        height={230}
        itemHeight={47}
        itemKey="id"
        // onScroll={onScroll}
      >
        {(item) => (
          <List.Item
            key={item.id}
            style={{cursor: 'pointer'}}
            onClick={async () => {
              try {
                await API.graphql({
                  query: updateNotification,
                  variables: {
                    input: {
                      id: item.id,
                      isRead: true,
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                init()
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: JSON.stringify(error),
                })
              }
            }}
          >
            <Row>
              <Col span={23}>
                <Space direction="vertical">
                  <Text type="secondary" style={{width: 50, fontSize: 13}}>
                    {item.description}
                  </Text>
                  <Text style={{color: '#8893F9', fontSize: 11}}>
                    {moment(item.createdAt).fromNow()}
                  </Text>
                </Space>
              </Col>
              {!item.isRead && (
                <Col span={1}>
                  <Badge count={1} size="default" color={'#8893F9'} />
                </Col>
              )}
            </Row>
          </List.Item>
        )}
      </VirtualList>
    </List>
  )
}

const PerformanceSalesPopOver = ({statisticsSummaryItems, type}) => {
  const [data, setData] = React.useState([])
  const [tempData, setTempData] = React.useState([])

  React.useEffect(() => {
    let _data = []
    if (type == 'OutPro') {
      statisticsSummaryItems.filter((item) => {
        if (item.outProcessed.length > 0) {
          console.log('item.referenceName', item.referenceName)
          _data.push({
            companyName: item.referenceName,
            number: item.outProcessed.length,
          })
        }
      })

      setData(_data)
      setTempData(_data)
    } else if (type == 'Confirmed') {
      statisticsSummaryItems.filter((item) => {
        if (item.confirmed.length > 0) {
          _data.push({
            companyName: item.referenceName,
            number: item.confirmed.length,
          })
        }
      })
      setData(_data)
      setTempData(_data)
    } else if (type == 'Reserved') {
      statisticsSummaryItems.filter((item) => {
        if (item.reserved.length > 0) {
          _data.push({
            companyName: item.referenceName,
            number: item.reserved.length,
          })
        }
      })
      setData(_data)
      setTempData(_data)
    }
  }, [])

  return (
    <Space direction="vertical">
      <Input
        // style={{width: 300}}
        placeholder="Search"
        type="text"
        size="middle"
        addonAfter={<SearchOutlined />}
        onChange={(e) => {
          let filtered = filter(tempData, function (item) {
            return (
              item.companyName
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) > -1
            )
          })

          setData(filtered)
        }}
      />
      <List>
        <VirtualList
          data={data}
          height={150}
          itemHeight={30}
          itemKey="id"
          // onScroll={onScroll}
        >
          {(item, index) => (
            <List.Item key={`company-${index}`} style={{cursor: 'pointer'}}>
              <Row justify="space-between" style={{width: 200}}>
                <Col style={{width: 190}}>
                  <Text style={{fontSize: 12}}>{item.companyName}</Text>
                </Col>
                <Col style={{width: 10}}>
                  <Text style={{fontSize: 12}}>{item.number}</Text>
                </Col>
              </Row>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </Space>
  )
}

const CurrentMDWStatusSider = ({userBranchID, userID}) => {
  const history = useHistory()
  const supplierContext = React.useContext(SupplierContext)
  const readTemplateFile = () => {}

  const [migrants, setMigrants] = React.useState([])
  const [templateData, setTemplateData] = React.useState('')

  const convertRegion = (text) => {
    switch (text) {
      case 'Aceh':
        return 'ACEH'
      case 'Sumatera Utara':
        return 'NORTH_SUMATRA'
      case 'Sumatera Selatan':
        return 'SOUTH_SUMATERA'
      case 'Sumatera Barat':
        return 'WEST_SUMATRA'
      case 'Riau':
        return 'RIAU'
      case 'Kepulauan Riau':
        return 'RIAU_ISLANDS'
      case 'Jambi':
        return 'JAMBI'
      case 'Kepulauan Bangka Belitung':
        return 'BANGKA_BELITUNG_ISLAND'
      case 'Bengkulu':
        return 'BENGKULU'
      case 'Lampung':
        return 'LAMPUNG'
      case 'DKI Jakarta':
        return 'CAPITAL_SPECIAL_REGION_OF_JAKARTA'
      case 'Banten':
        return 'BANTEN'
      case 'Jawa Barat':
        return 'WEST_JAVA'
      case 'Jawa Tengah':
        return 'CENTRAL_JAVA'
      case 'Jawa Timur':
        return 'EAST_JAVA'
      case 'DI Yogyakarta':
        return 'SPECIAL_REGION_OF_YOGYAKARTA'
      case 'Denpasar':
        return 'BALI'
      case 'Nusa Tenggara Barat':
        return 'WEST_NUSA_TENGGARA'
      case 'Kalimantan Selatan':
        return 'SOUTH_KALIMANTAN'
      case 'Kalimantan Timur':
        return 'EAST_KALIMANTAN'
      case 'Kalimantan Utara':
        return 'NORTH_KALIMANTAN'
      case 'Kalimantan Barat':
        return 'WEST_KALIMANTAN'
      case 'Kalimantan Tengah':
        return 'CENTRAL_KALIMANTAN'
      case 'Sulawesi Tengah':
        return 'CENTRAL_SULAWESI'
      case 'Sulawesi Barat':
        return 'WEST_SULAWESI'
      case 'Sulawesi Selatan':
        return 'SOUTH_SULAWESI'
      case 'Sulawesi Tenggara':
        return 'SOUTHEAST_SULAWESI'
      case 'Maluku':
        return 'MALUKU'
      case 'Maluku Utara':
        return 'NORTH_MALUKU'
      case 'Papua':
        return 'PAPUA'
      case 'Papua Barat':
        return 'WEST_PAPUA'
      case 'Gorontalo':
        return 'GORONTALO'
      default:
        return 'ACEH'
    }
  }
  const convertSalaryRange = (text) => {
    switch (text) {
      case '400 - 500 SGD':
        return 'RANGE_1'
      case '501 - 600 SGD':
        return 'RANGE_2'
      case '601 - above':
        return 'RANGE_3'
      default:
        return 'RANGE_3'
    }
  }

  const convertReligion = (text) => {
    switch (text) {
      case 'Free Thinker':
        return 'FREE_THINKER'
      case 'Catholic':
        return 'CATHOLIC'
      case 'Muslim':
        return 'MUSLIM'
      case 'Sikh':
        return 'SIKH'
      case 'Christian':
        return 'CHRISTIAN'
      case 'Buddhist':
        return 'BUDDHIST'
      case 'Hindu':
        return 'HINDU'
      case 'Others':
        return 'OTHERS'
      default:
        return 'OTHERS'
    }
  }

  const convertEducationLevel = (text) => {
    switch (text) {
      case 'College':
        return 'COLLEGE'
      case 'Secondary School':
        return 'SECONDARY_SCHOOL'
      case 'High School':
        return 'HIGH_SCHOOL'
      default:
        return 'HIGH_SCHOOL'
    }
  }

  const convertMaritalStatus = (text) => {
    switch (text) {
      case 'Single':
        return 'SINGLE'
      case 'Widowed':
        return 'WIDOWED'
      case 'Married':
        return 'MARRIED'
      case 'Divorced':
        return 'DIVORCED'
      case 'Separated':
        return 'SEPARATED'
      case 'Single Parents':
        return 'SINGLE_PARENT'
      case 'No Preferences':
        return 'NO_PREFERENCE'
      default:
        return 'NO_PREFERENCE'
    }
  }
  const convertToBoolean = (text) => {
    switch (text) {
      case 'Yes':
        return true
      case 'No':
        return false
      default:
        return false
    }
  }

  const convertRestDay = (text) => {
    switch (text) {
      case '0 day':
        return 0
      case '1 day':
        return 1
      case '2 day':
        return 2
      case '3 day':
        return 3
      case '4 day':
        return 4
      default:
        return 0
    }
  }

  const convertOfMethodEvaluationSkills = (text) => {
    switch (text) {
      case 'Based on MDW’s declaration, no evaluation/observation by Singapore EA or overseas training centre/EA day':
        return 'NO_EVALUATION_SG_EA'
      case 'Interviewed by Singapore EA':
        return 'INTERVIEWED_BY_SG_EA'
      case 'Interviewed via telephone/teleconference':
        return 'INTERVIEWED_VIA_TELEPHONE'
      case 'Interviewed via video conference':
        return 'INTERVIEWED_VIA_VIDEO_CONFERENCE'
      case 'Interviewed in person':
        return 'INTERVIEWED_IN_PERSON'
      case 'Interviewed in person and also made observation of MDW in the areas of work listed in table':
        return 'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION'
      default:
        return 'DECLARATION'
    }
  }

  const convertStateIfThirdPartyIsCertified = (text) => {
    switch (text) {
      case 'Interviewed via telephone/teleconference':
        return 'INTERVIEWED_VIA_TELEPHONE'
      case 'Interviewed via video conference':
        return 'INTERVIEWED_VIA_VIDEO_CONFERENCE'
      case 'Interviewed in person':
        return 'INTERVIEWED_IN_PERSON'
      case 'Interviewed via video conference':
        return 'INTERVIEWED_VIA_VIDEO_CONFERENCE'
      case 'Interviewed in person':
        return 'INTERVIEWED_IN_PERSON'
      case 'Interviewed in person and also made observation of MDW in the areas of work listed in table':
        return 'INTERVIEWED_IN_PERSON_AND_MADE_OBSERVATION'
      default:
        return 'NA'
    }
  }

  const convertAvailabilityOfMDWToBeInterviewed = (text) => {
    switch (text) {
      case 'MDW is not available for interview':
        return 'MDW_IS_NOT_AVAILABLE_FOR_INTERVIEW'
      case 'MDW can be interviewed by phone':
        return 'MDW_CAN_BE_INTERVIEWED_BY_PHONE'
      case 'MDW can be interviewed by video-conference':
        return 'MDW_CAN_BE_INTERVIEWED_BY_VIDEO_CONFERENCE'
      case 'MDW can be interviewed in person':
        return 'MDW_CAN_BE_INTERVIEWED_IN_PERSON'
      default:
        return 'NA'
    }
  }
  React.useEffect(() => {
    const init = async () => {
      try {
        const _listMigrants = await API.graphql({
          // query: listMigrants,
          query: listMigrantBySupplierBranchByStatus,
          variables: {
            filter: {
              deleted: {
                eq: 0,
              },
            },
          },
          variables: {
            suppliedBy: userBranchID,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })

        setMigrants(
          _listMigrants.data.listMigrantBySupplierBranchByStatus.items,
        )
      } catch (error) {
        notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }

    init()
  }, [])
  React.useEffect(() => {
    const initTemplateData = async () => {
      if (templateData) {
        console.log('templateDatax', templateData)
        try {
          notification['info']({
            message: 'Info',
            description: 'Import in progress',
          })

          const _listUsers = await API.graphql({
            query: listUsers,
            variables: {
              filter: {
                email: {
                  eq: getSignedInUserDetails().UserAttributes.find(
                    (userAttribute) => userAttribute.Name === 'email',
                  ).Value,
                },
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })

          const _templateData = Object.keys(templateData)[0]

          templateData[_templateData].map(async (row, index) => {
            if (index > 0) {
              if (row.length !== 157) {
                //If row doesn't follow template format
                return true
              }
              try {
                console.log('ROW', row)
                const _createMigrant = await API.graphql({
                  query: createMigrant,
                  variables: {
                    input: {
                      suppliedBy: _listUsers.data.listUsers.items[0].branchID,
                      referenceCode: row[0],
                      name: row[1],
                      dateOfBirth: moment(row[2]).format('YYYY-MM-DD'),
                      age: parseInt(row[3]),
                      placeOfBirth: row[4],
                      height: parseInt(row[5]),
                      weight: parseInt(row[6]),
                      nationality: row[7].toUpperCase(),
                      residentialAddress: row[8],
                      region: convertRegion(row[9]),
                      nationalID: row[10],
                      passportNumber: row[11],
                      salaryRange: convertSalaryRange(row[12]),
                      nameOfPort: row[13],
                      contactNumber: row[14],
                      nextOfKin: row[15],
                      nextOfKinContactNumber: row[16],
                      religion: convertReligion(row[17]),
                      educationLevel: convertEducationLevel(row[18]),
                      numberOfSiblings: parseInt(row[19]),
                      maritalStatus: convertMaritalStatus(row[20]),
                      childrenNumber: parseInt(row[21]),
                      agesOfChildren: row[22],
                      allergies: row[23],
                      illness: convertToBoolean(row[24]),
                      epilepsy: convertToBoolean(row[25]),
                      asthma: convertToBoolean(row[26]),
                      diabetes: convertToBoolean(row[27]),
                      hypertension: convertToBoolean(row[28]),
                      tuberculosis: convertToBoolean(row[29]),
                      heart_disease: convertToBoolean(row[30]),
                      malaria: convertToBoolean(row[31]),
                      operations: convertToBoolean(row[32]),
                      others: row[33],
                      physicalDisabilities: row[34],
                      dietaryRestriction: row[35],
                      careOfInfantsWillingnessA2: convertToBoolean(row[36]),
                      careOfInfantsDetailA2: row[37],
                      careOfInfantsHasExperienceYearA2: convertToBoolean(
                        row[38],
                      ),
                      careOfInfantsExperienceYearA2: row[39],
                      careOfInfantsAssesmentA2: parseInt(row[40]),
                      careOfElderlyWillingnessA2: convertToBoolean(row[41]),
                      careOfElderlyDetailA2: row[42],
                      careOfElderlyHasExperienceYearA2: convertToBoolean(
                        row[43],
                      ),
                      careOfElderlyExperienceYearA2: row[44],
                      careOfElderlyAssesmentA2: parseInt(row[45]),
                      careOfDisabledWillingnessA2: convertToBoolean(row[46]),
                      careOfDisabledDetailA2: row[47],
                      careOfDisabledHasExperienceYearA2: convertToBoolean(
                        row[48],
                      ),
                      careOfDisabledExperienceYearA2: row[49],
                      careOfDisabledAssesmentA2: parseInt(row[50]),
                      generalHouseworkWillingnessA2: convertToBoolean(row[51]),
                      generalHouseworkDetailA2: row[52],
                      generalHouseworkHasExperienceYearA2: convertToBoolean(
                        row[53],
                      ),
                      generalHouseworkExperienceYearA2: row[54],
                      generalHouseworkAssesmentA2: parseInt(row[55]),
                      cookingWillingnessA2: convertToBoolean(row[56]),
                      cookingDetailA2: row[57],
                      cookingHasExperienceYearA2: convertToBoolean(row[58]),
                      cookingExperienceYearA2: row[59],
                      cookingAssesmentA2: parseInt(row[60]),
                      languageDetailA2: row[61],
                      languageExperienceHasYearA2: convertToBoolean(row[62]),
                      languageExperienceYearA2: row[63],
                      languageAssesmentA2: parseInt(row[64]),
                      otherSkillsDetailA2: row[65],
                      otherSkillsHasExperienceYearA2: convertToBoolean(row[66]),
                      otherSkillsExperienceYearA2: row[67],
                      otherSkillsAssesmentA2: parseInt(row[68]),
                      foodHandlingPreferences: row[69],
                      hygieneCompetent: convertToBoolean(row[70]),
                      hygieneNeedTraining: convertToBoolean(row[71]),
                      hygieneTraining: row[72],
                      hygineAssesment: parseInt(row[73]),
                      adaptabilityCompetent: convertToBoolean(row[74]),
                      adaptabilityNeedTraining: convertToBoolean(row[75]),
                      adaptabilityTraining: row[76],
                      adaptabilityAssesment: parseInt(row[77]),
                      disciplineCompetent: convertToBoolean(row[78]),
                      disciplineNeedTraining: convertToBoolean(row[79]),
                      disciplineTraining: row[80],
                      disciplineAssesment: parseInt(row[81]),
                      coreSkills: row[82],
                      coreFitnessMentalCompetent: convertToBoolean(row[83]),
                      coreFitnessMentalNeedTraining: convertToBoolean(row[84]),
                      coreFitnessMentalTraining: row[85],
                      coreFitnessMentalAssesment: parseInt(row[86]),
                      coreFitnessPhysicalCompetent: convertToBoolean(row[87]),
                      coreFitnessPhysicalNeedTraining: convertToBoolean(
                        row[88],
                      ),
                      coreFitnessPhysicalTraining: row[89],
                      coreFitnessPhysicalAssesment: parseInt(row[90]),
                      restDaysPerMonth: convertRestDay(row[91]),
                      otherRemarks: row[92],
                      methodOfEvaluationSkills: JSON.stringify([
                        convertOfMethodEvaluationSkills(row[93]),
                      ]),
                      stateIfThirdPartyIsCertified:
                        convertStateIfThirdPartyIsCertified(row[94]),
                      stateIfThirdPartyIsCertifiedSpecify: row[95],
                      interviewedByOverseasTrainingCentre: row[96],
                      careOfInfantsWillingnessB1: convertToBoolean(row[97]),
                      careOfInfantsDetailB1: row[98],
                      careOfInfantsHasExperienceYearB1: convertToBoolean(
                        row[99],
                      ),
                      careOfInfantsExperienceYearB1: row[100],
                      careOfInfantsAssesmentB1: parseInt(row[101]),
                      careOfElderlyWillingnessB1: convertToBoolean(row[102]),
                      careOfElderlyDetailB1: row[103],
                      careOfElderlyHasExperienceYearB1: convertToBoolean(
                        row[104],
                      ),
                      careOfElderlyExperienceYearB1: row[105],
                      careOfElderlyAssesmentB1: parseInt(row[106]),
                      careOfDisabledWillingnessB1: convertToBoolean(row[107]),
                      careOfDisabledDetailB1: row[108],
                      careOfDisabledHasExperienceYearB1: convertToBoolean(
                        row[109],
                      ),
                      careOfDisabledExperienceYearB1: row[110],
                      careOfDisabledAssesmentB1: parseInt(row[111]),
                      generalHouseworkWillingnessB1: convertToBoolean(row[112]),
                      generalHouseworkDetailB1: row[113],
                      generalHouseworkHasExperienceYearB1: convertToBoolean(
                        row[114],
                      ),
                      generalHouseworkExperienceYearB1: row[115],
                      generalHouseworkAssesmentB1: parseInt(row[116]),
                      cookingWillingnessB1: convertToBoolean(row[117]),
                      cookingDetailB1: row[118],
                      cookingHasExperienceYearB1: convertToBoolean(row[119]),
                      cookingExperienceYearB1: row[120],
                      cookingAssesmentB1: parseInt(row[121]),
                      languageDetailB1: row[122],
                      languageExperienceHasYearB1: convertToBoolean(row[123]),
                      languageExperienceYearB1: row[124],
                      languageAssesmentB1: parseInt(row[125]),
                      otherSkillsDetailB1: row[126],
                      otherSkillsHasExperienceYearB1: convertToBoolean(
                        row[127],
                      ),
                      otherSkillsExperienceYearB1: row[128],
                      otherSkillsAssesmentB1: parseInt(row[129]),

                      employmentHistoryOverseasDateFrom1: moment(
                        row[130],
                      ).format('YYYY-MM-DD'),
                      employmentHistoryOverseasDateTo1: moment(row[131]).format(
                        'YYYY-MM-DD',
                      ),
                      employmentHistoryCountry1: row[132],
                      employmentHistoryEmployer1: row[133],
                      employmentHistoryWorkDuties1: row[134],
                      employmentHistoryWorkDutiesRemarks1: row[135],
                      employmentHistoryOverseasDateFrom2: moment(
                        row[136],
                      ).format('YYYY-MM-DD'),
                      employmentHistoryOverseasDateTo2: moment(row[137]).format(
                        'YYYY-MM-DD',
                      ),
                      employmentHistoryCountry2: row[138],
                      employmentHistoryEmployer2: row[139],
                      employmentHistoryWorkDuties2: row[140],
                      employmentHistoryWorkDutiesRemarks2: row[141],
                      employmentHistoryOverseasDateFrom3: moment(
                        row[142],
                      ).format('YYYY-MM-DD'),
                      employmentHistoryOverseasDateTo3: moment(row[143]).format(
                        'YYYY-MM-DD',
                      ),
                      employmentHistoryCountry3: row[144],
                      employmentHistoryEmployer3: row[145],
                      employmentHistoryWorkDuties3: row[146],
                      employmentHistoryWorkDutiesRemarks3: row[147],

                      hasWorkingExperienceInSingapore: convertToBoolean(
                        row[148],
                      ),
                      previousEmployer1: row[149],
                      previousEmployerFeedback1: row[150],
                      previousEmployer2: row[151],
                      previousEmployerFeedback2: row[152],
                      previousEmployer3: row[153],
                      previousEmployerFeedback3: row[154],
                      availabilityOfMDWToBeInterviewed:
                        convertAvailabilityOfMDWToBeInterviewed(row[155]),
                      otherRemarksC3: row[156],
                      additionalPhoto: '[]',
                      additionalVideoKey: '[]',
                      supportingDocuments: '[]',
                      deleted: 0,
                      status: 'PENDING',
                    },
                  },
                  authMode: 'AMAZON_COGNITO_USER_POOLS',
                })

                console.log('_createMigrant', _createMigrant)
              } catch (error) {
                console.log(error)
                /* return notification['error']({
                  message: 'Import Failed',
                  description: JSON.stringify(error),
                }) */
              }
            }
          })

          setTemplateData('')
          return notification['success']({
            message: 'Success',
            description: 'Import accomplished',
          })
        } catch (error) {
          console.log(error)

          notification['error']({
            message: 'Import Failed',
            description: JSON.stringify(error),
          })
        } finally {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }
    }
    initTemplateData()
  }, [templateData])

  return (
    <Sider width="270" style={{backgroundColor: '#FFF', padding: 10}}>
      <Popover
        content={<NotificationPopOver {...{userID}} />}
        overlayStyle={{
          width: 250,
        }}
        trigger="hover"
        placement="bottomLeft"
      >
        <Row
          align="middle"
          justify="space-between"
          style={{
            border: '1px solid #8893F9',

            borderRadius: 5,
            padding: 8,
          }}
        >
          <Col>Notifications</Col>
          <Col>
            <BellOutlined style={{color: '#8893F9', fontSize: 25}} />
          </Col>
        </Row>
      </Popover>
      <Space
        style={{
          backgroundColor: '#8893F9',
          borderRadius: 5,
          padding: 15,
          marginTop: 25,
          width: 250,
        }}
        direction="vertical"
        size={20}
        align="center"
      >
        <Text strong style={{color: '#FFF', fontSize: 18}}>
          Add New Bio
        </Text>

        <Space direction="vertical" size={10}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#FFF',
              borderColor: 'transparent',
              width: 147,
              borderRadius: 9,
              color: '#8893F9',
            }}
            size="middle"
            onClick={() => {
              supplierContext.clearMigrant()
              return history.push('/supplier/add-new-bio')
            }}
          >
            Single MDW Form
          </Button>

          <label htmlFor="uploadTemplateLabel">
            <div
              style={{
                backgroundColor: '#FFF',
                borderColor: 'transparent',
                width: 147,
                borderRadius: 9,
                color: '#8893F9',
                cursor: 'pointer',
                height: 34,
                textAlign: 'center',
                paddingTop: 5,
              }}
            >
              Upload Multi Bio
            </div>
            <input
              hidden
              type="file"
              id="uploadTemplateLabel"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                /*  console.log(e.target.files[0])
                const wb = XLSX.readFile(e.target.files[0])
                const snl = wb.SheetNames
                console.log(XLSX.utils.sheet_to_json(wb.Sheets[snl[0]])) */

                const reader = new FileReader()

                reader.onload = function (e) {
                  let data = e.target.result
                  data = new Uint8Array(data)
                  const _workbook = XLSX.read(data, {type: 'array'})
                  const workbook = XLSX.read(data, {
                    type: 'array',
                    sheets: _workbook.SheetNames[0],
                    // sheets: ['MDW Form'],
                    cellDates: true,
                  })

                  let result = {}
                  workbook.SheetNames.forEach(function (sheetName) {
                    const roa = XLSX.utils.sheet_to_json(
                      workbook.Sheets[sheetName],
                      {header: 1, defval: ''},
                    )
                    if (roa.length) result[sheetName] = roa
                  })
                  // see the result, caution: it works after reader event is done.
                  console.log('uploaded file result', result)
                  setTemplateData(result)
                }
                reader.readAsArrayBuffer(e.target.files[0])
              }}
            />
          </label>
          <Link
            style={{fontSize: 12, textDecoration: 'underline', color: '#FFF'}}
            href="https://bdms99afa01b1432447daa85f64271dc1c6061522-dev.s3.ap-southeast-1.amazonaws.com/public/MDW_Import_Template.xlsx"
          >
            {' '}
            Download Template{' '}
          </Link>
        </Space>
      </Space>
      <Space
        style={{
          backgroundColor: '#8893F9',
          borderRadius: 5,
          padding: 15,
          marginTop: 25,
          width: 250,
        }}
        direction="vertical"
        size={20}
      >
        <Text strong style={{color: '#FFF', fontSize: 18}}>
          CURRENT MDW STATUS
        </Text>

        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totSupplied.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Supplied&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {/* {migrants.length} */}
              {migrants.filter((migrant) => migrant.deleted === 0).length}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totPending.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Pending&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter(
                  (migrant) =>
                    migrant.status === 'PENDING' && migrant.deleted === 0,
                ).length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totSubmitted.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Submitted&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'SUBMITTED')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totAvailable.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Available&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'AVAILABLE')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totInactive.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Inactive&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter(
                  (migrant) =>
                    migrant.status === 'INACTIVE' && migrant.deleted === 0,
                ).length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totReserved.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Reserved
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'RESERVED')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totConfirm.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Confirm
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {
                migrants.filter((migrant) => migrant.status === 'CONFIRMED')
                  .length
              }
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <img
              src={require('../../../assets/icons/totSubmitted.png')}
              style={{width: 25, height: 25}}
            />
            <Text style={{color: '#FFF', fontSize: 13}}>
              &nbsp;&nbsp;Total Out Processed&nbsp;&nbsp;
            </Text>
          </Col>

          <Col>
            <Text strong style={{color: '#FFF', fontSize: 26}}>
              {migrants.filter((migrant) => migrant.status === 'OUTPRO').length}
            </Text>
          </Col>
        </Row>
      </Space>
    </Sider>
  )
}

const Migrant = ({
  id,
  picture,
  name,
  status,
  nationality,
  nationalityID,
  maritalStatus,
  religion,
  religionID,
  referenceCode,
  dueDate,
  suppliedBy,
  photoKey,
  userBranchID,
  getAllMigrantsBySupplierID,
  migrant,
}) => {
  const [_status, setStatus] = React.useState(status)

  const supplierContext = React.useContext(SupplierContext)

  const history = useHistory()

  const _updateMigrant = async (_status) => {
    try {
      let variables = {
        input: {
          id,
          status: _status,
          isInActive: _status === 'INACTIVE' ? true : false,
          inInactiveByUserID: getSignedInUserDetails().UserAttributes.find(
            (userAttribute) => userAttribute.Name === 'sub',
          ).Value,
        },
      }

      if (_status !== 'INACTIVE') {
        delete variables.input.inInactiveByUserID
      }

      await API.graphql({
        query: updateMigrant,
        variables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      if (status === 'RESERVED' || status === 'CONFIRMED') {
        await API.graphql({
          query: createNotification,
          variables: {
            input: {
              userID: migrant.reservedByUserID,
              description: `${name} status changed to ${capitalize(
                _status,
              )} by its supplier`,
              isRead: false,
              readOn: moment().format(),
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })
      }

      const _listUsersSuperAdmin = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            userType: {
              eq: 'SUPER_ADMIN',
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      _listUsersSuperAdmin.data.listUsers.items.map(async (item) => {
        await API.graphql({
          query: createNotification,
          variables: {
            input: {
              userID: item.id,
              description: `${name} status changed to ${capitalize(
                _status,
              )} by its supplier`,
              isRead: false,
              readOn: moment().format(),
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })
      })

      /*const _getBranch = await API.graphql({
        query: getBranch,
        variables: {
          id: suppliedBy,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

        await API.graphql({
        query: createNotification,
        variables: {
          input: {
            userID: _getBranch.data.getBranch.company.userID,
            description: `${name} status already ${capitalize(_status)}`,
            isRead: false,
            readOn: moment().format(),
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }) */
      getAllMigrantsBySupplierID()
      notification['success']({
        message: 'Success',
        description: 'MDW Bio status updated!',
      })
      return window.location.reload()
    } catch (error) {
      console.error(error)
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const menu2 = (
    <Menu>
      {/*  <Menu.Item
        onClick={() => {
          setStatus('CONFIRMED')
          _updateMigrant('CONFIRMED')
        }}
      >
        CONFIRMED
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          setStatus('PENDING')
          _updateMigrant('PENDING')
        }}
      >
        PENDING
      </Menu.Item>
      {/*
      <Menu.Item onClick={() => setStatus('EXTENDED')}>EXTENDED</Menu.Item>
      <Menu.Item onClick={() => setStatus('RESERVED')}>RESERVED</Menu.Item> */}
      {/*       <Menu.Item
        onClick={() => {
          setStatus('OUT PROCESSED')
          _updateMigrant('OUTPRO')
        }}
      >
        OUT PROCESSED
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          setStatus('INACTIVE')
          _updateMigrant('INACTIVE')
        }}
      >
        INACTIVE
      </Menu.Item>
      {/* <Menu.Item onClick={() => setStatus('AVAILABLE')}>AVAILABLE</Menu.Item> */}
    </Menu>
  )

  const menu3 = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setStatus('OUT PROCESSED')
          _updateMigrant('OUTPRO')
        }}
      >
        OUT PROCESSED
      </Menu.Item>
    </Menu>
  )

  return (
    <Card
      style={{
        borderRadius: 15,
        overflow: 'hidden',
      }}
      bordered
      hoverable
      cover={
        <>
          <img
            alt={`mdw-${id}-photo`}
            src={
              photoKey
                ? `https://${awsmobile.aws_user_files_s3_bucket}.s3.${awsmobile.aws_appsync_region}.amazonaws.com/public/${photoKey}`
                : require('../../../assets/image/profilePicture.png')
            }
            onClick={() => {
              supplierContext.getMigrant(id)
              history.push(`/bio/${id}`)
            }}
            style={{height: 135, objectFit: 'contain'}}
          />
          {
            // status === 'CONFIRMED' ||
            // status === 'OUT PROCESSED' ||
            status === 'AVAILABLE' ? (
              <Dropdown key="more" overlay={menu2} on>
                <Row
                  align="middle"
                  justify="center"
                  style={{
                    backgroundColor:
                      status === 'OUT PROCESSED'
                        ? '#CA75FA'
                        : status === 'AVAILABLE'
                        ? '#D8E2FF'
                        : '#AEFA96',
                  }}
                >
                  <Col>
                    <Text>{_status}</Text>
                  </Col>
                  <Col>
                    <CaretDownFilled />
                  </Col>
                </Row>
              </Dropdown>
            ) : status === 'CONFIRMED' ? (
              <Dropdown key="more" overlay={menu3} on>
                <Row
                  align="middle"
                  justify="center"
                  style={{
                    backgroundColor:
                      status === 'OUT PROCESSED'
                        ? '#CA75FA'
                        : status === 'AVAILABLE'
                        ? '#D8E2FF'
                        : '#AEFA96',
                  }}
                >
                  <Col>
                    <Text>{_status}</Text>
                  </Col>
                  <Col>
                    <CaretDownFilled />
                  </Col>
                </Row>
              </Dropdown>
            ) : (
              <Row
                align="middle"
                justify="center"
                style={{
                  backgroundColor:
                    _status === 'PENDING'
                      ? '#F95959'
                      : _status === 'INACTIVE'
                      ? '#D0D0D0'
                      : _status === 'EXTENDED' || _status === 'RESERVED'
                      ? '#ECFF42'
                      : _status === 'AVAILABLE'
                      ? '#D8E2FF'
                      : _status === 'SUBMITTED'
                      ? '#FDA91D'
                      : '#FCF889',
                }}
              >
                <Col>
                  <Text>
                    {_status === 'OUTPRO' ? 'OUT PROCESSED' : _status}
                  </Text>
                </Col>
              </Row>
            )
          }
        </>
      }
      actions={
        _status === 'RESERVED' && migrant.reserveExpiryOn
          ? [
              <Row justify="center" gutter={10}>
                <Col>
                  <Text style={{fontSize: 11, color: 'red'}}>
                    {migrant.isExtended && 'Extended '}Due Date
                  </Text>
                </Col>
                <Col>
                  <Text style={{fontSize: 11, color: 'red'}}>
                    {moment(migrant.reserveExpiryOn).format('DD/MM/YYYY')}
                  </Text>
                </Col>
              </Row>,
            ]
          : []
      }
      bodyStyle={{
        backgroundColor:
          migrant.status === 'RESERVED'
            ? moment(migrant.reserveExpiryOn).diff(moment(), 'days') >= 4
              ? '#FFF'
              : moment(migrant.reserveExpiryOn).diff(moment(), 'days') > 1 &&
                moment(migrant.reserveExpiryOn).diff(moment(), 'days') <= 3
              ? '#F0F789'
              : '#F1B0B0'
            : '#FFF',
      }}
    >
      <Space
        direction="vertical"
        onClick={() => history.push(`/bio/${id}`)}
        style={{width: '100%'}}
      >
        <Title level={4}>{name}</Title>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Nationality</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {nationality ? capitalize(nationality) : '-'}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Age</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {moment().diff(migrant.dateOfBirth, 'years')}
            </Text>
            {/* <Text style={{fontSize: 11}}>
              {nationalityID === 1 ? 'Cambodia' : 'Indonesia'}
            </Text> */}
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Religion</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {religion
                ? religion === 'FREE_THINKER'
                  ? 'Free Thinker'
                  : capitalize(religion)
                : '-'}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Marital Status</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>
              {maritalStatus
                ? maritalStatus === 'SINGLE_PARENT'
                  ? 'Single Parent '
                  : capitalize(maritalStatus)
                : '-'}
            </Text>
          </Col>
        </Row>
        <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Ref. Code</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>{referenceCode}</Text>
          </Col>
        </Row>
        {/* <Row align="middle" justify="space-between">
          <Col>
            <Text style={{fontSize: 11}}>Rsvp. due date</Text>
          </Col>
          <Col>
            <Text style={{fontSize: 11}}>{migrantDueDate}</Text>
          </Col>
        </Row> */}
      </Space>
    </Card>
  )
}

const SearchFilterContent = ({
  filterParams,
  setFilterParams,
  migrants,
  setMigrants,
  tempMigrants,
  setIsPopOverShown,
}) => {
  const defaultAllParams = {
    name: '',
    nationality: [],
    region: '',
    religion: [],
    maritalStatus: [],
    typeOfMaid: [],
    languageAbility: [],
    offDayPreferences: [],
    responsibility: [],
    agePreferences: [],
    educationLevel: [],
    experience: [],
    salaryRanges: [],
  }

  const [allParams, setAllParams] = React.useState(defaultAllParams)

  React.useEffect(() => {
    let filterLoadashResult = []

    // Handler filter by name
    filterLoadashResult = filter(tempMigrants, function (item) {
      return item.name.toLowerCase().indexOf(allParams.name.toLowerCase()) > -1
    })

    // Handler filter by nationality
    if (allParams.nationality.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.nationality, item.nationality)
      })
    }

    // Handler filter by Indonesia region
    if (allParams.region) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          allParams.region === item.region && item.nationality === 'INDONESIA'
        )
      })
    }

    // Handler filter by religion
    if (allParams.religion.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.religion, item.religion)
      })
    }

    // Handler filter by marital status
    if (allParams.maritalStatus.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.maritalStatus, item.maritalStatus)
      })
    }

    // Handler filter by type of maid
    if (allParams.typeOfMaid.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.typeOfMaid.includes('NEW') &&
            !item.employmentHistoryCountry1 &&
            !item.employmentHistoryCountry2 &&
            !item.employmentHistoryCountry3 &&
            !item.employmentHistoryCountry4 &&
            !item.employmentHistoryCountry5) ||
          (allParams.typeOfMaid.includes('EXPERIENCED') &&
            (item.employmentHistoryCountry1 ||
              item.employmentHistoryCountry2 ||
              item.employmentHistoryCountry3 ||
              item.employmentHistoryCountry4 ||
              item.employmentHistoryCountry5))
        )
      })

      /*  if (allParams.typeOfMaid.includes('EX-OVERSEAS')) {
        filterLoadashResult = filter(filterLoadashResult, function (item) {
          return (
            item.employmentHistoryOverseasDateFrom1 ||
            item.employmentHistoryOverseasDateTo1 ||
            item.employmentHistoryCountry1
          )
        })
      } */
    }

    // Handler filter by language abilities
    if (allParams.languageAbility.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.languageAbility.includes('ENGLISH') &&
            (item.languageDetailA2.toUpperCase().includes('ENGLISH') ||
              item.languageDetailB1.toUpperCase().includes('ENGLISH'))) ||
          (allParams.languageAbility.includes('INDONESIAN') &&
            (item.languageDetailA2.toUpperCase().includes('INDONESIAN') ||
              item.languageDetailB1.toUpperCase().includes('INDONESIAN') ||
              item.languageDetailA2.toUpperCase().includes('BAHASA') ||
              item.languageDetailB1.toUpperCase().includes('BAHASA') ||
              item.languageDetailA2.toUpperCase().includes('MALAYU') ||
              item.languageDetailB1.toUpperCase().includes('MALAYU') ||
              item.languageDetailA2.toUpperCase().includes('MELAYU') ||
              item.languageDetailB1.toUpperCase().includes('MALAYU'))) ||
          (allParams.languageAbility.includes('MANDARIN') &&
            (item.languageDetailA2.toUpperCase().includes('MANDARIN') ||
              item.languageDetailB1.toUpperCase().includes('MANDARIN')))
        )
      })
    }

    // Handler filter by off day preference
    if (allParams.offDayPreferences.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(
          allParams.offDayPreferences,
          String(item.restDaysPerMonth),
        )
      })
    }

    // Handler filter by type responsibility
    if (allParams.responsibility.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.responsibility.includes('CAREGIVER') &&
            (item.careOfInfantsHasExperienceYearA2 ||
              item.careOfInfantsDetailA2 ||
              item.careOfInfantsHasExperienceYearB1 ||
              item.careOfInfantsDetailB1)) ||
          (allParams.responsibility.includes('CARE_OF_ELDERLY') &&
            (item.careOfElderlyHasExperienceYearA2 ||
              item.careOfElderlyDetailA2 ||
              item.careOfElderlyHasExperienceYearB1 ||
              item.careOfElderlyDetailB1)) ||
          (allParams.responsibility.includes('CARE_FOR_INFANT') &&
            (item.careOfInfantsHasExperienceYearA2 ||
              item.careOfInfantsDetailA2 ||
              item.careOfInfantsHasExperienceYearB1 ||
              item.careOfInfantsDetailB1)) ||
          (allParams.responsibility.includes('CARE_FOR_DISABLED') &&
            (item.careOfDisabledHasExperienceYearA2 ||
              item.careOfDisabledDetailA2 ||
              item.careOfDisabledHasExperienceYearB1 ||
              item.careOfDisabledDetailB1)) ||
          (allParams.responsibility.includes('COOKING') &&
            (item.cookingHasExperienceYearA2 ||
              item.cookingDetailA2 ||
              item.cookingHasExperienceYearB1 ||
              item.cookingDetailB1)) ||
          (allParams.responsibility.includes('GENERAL_HOUSE_WORK') &&
            (item.generalHouseworkHasExperienceYearA2 ||
              item.generalHouseworkDetailA2 ||
              item.generalHouseworkHasExperienceYearB1 ||
              item.generalHouseworkDetailB1))
        )
      })
    }

    // Handler filter by age preferences
    if (allParams.agePreferences.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        let age = moment().diff(item.dateOfBirth, 'years')
        return (
          (allParams.agePreferences.includes('23-25') &&
            age >= 23 &&
            age <= 25) ||
          (allParams.agePreferences.includes('26-30') &&
            age >= 26 &&
            age <= 30) ||
          (allParams.agePreferences.includes('31-35') &&
            age >= 31 &&
            age <= 35) ||
          (allParams.agePreferences.includes('36-40') &&
            age >= 36 &&
            age <= 40) ||
          (allParams.agePreferences.includes('41-45') && age >= 41 && age <= 45)
        )
      })
    }

    // Handler filter by education level
    if (allParams.educationLevel.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return includes(allParams.educationLevel, item.educationLevel)
      })
    }

    // Handler filter by exoeriece / country
    if (allParams.experience.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.experience.includes('HOME_COUNTRY') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes(item?.nationality) ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes(item?.nationality) ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes(item?.nationality) ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes(item?.nationality) ||
              item.employmentHistoryCountry5
                ?.toUpperCase()
                ?.includes(item?.nationality))) ||
          (allParams.experience.includes('SINGAPORE') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('SINGAPORE') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('SINGAPORE'))) ||
          (allParams.experience.includes('MALAYSIA') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('MALAYSIA') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('MALAYSIA'))) ||
          (allParams.experience.includes('TAIWAN') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('TAIWAN') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('TAIWAN') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('TAIWAN') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('TAIWAN') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('TAIWAN'))) ||
          (allParams.experience.includes('HONGKONG') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('HONGKONG') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('HONGKONG') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('HONGKONG') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('HONGKONG') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('HONGKONG'))) ||
          (allParams.experience.includes('MIDDLE_EAST') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('MIDDLE_EAST') ||
              item.employmentHistoryCountry5
                .toUpperCase()
                .includes('MIDDLE_EAST'))) ||
          (allParams.experience.includes('BRUNEI') &&
            (item.employmentHistoryCountry1
              ?.toUpperCase()
              ?.includes('BRUNEI') ||
              item.employmentHistoryCountry2
                ?.toUpperCase()
                ?.includes('BRUNEI') ||
              item.employmentHistoryCountry3
                ?.toUpperCase()
                ?.includes('BRUNEI') ||
              item.employmentHistoryCountry4
                ?.toUpperCase()
                ?.includes('BRUNEI') ||
              item.employmentHistoryCountry5.toUpperCase().includes('BRUNEI')))
        )
      })
    }

    // Handler filter by salary range
    if (allParams.salaryRanges.length > 0) {
      filterLoadashResult = filter(filterLoadashResult, function (item) {
        return (
          (allParams.salaryRanges.includes('RANGE_1') &&
            item.salaryRange.includes('RANGE_1')) ||
          (allParams.salaryRanges.includes('RANGE_2') &&
            item.salaryRange.includes('RANGE_2')) ||
          (allParams.salaryRanges.includes('RANGE_3') &&
            item.salaryRange.includes('RANGE_3'))
        )
      })
    }

    setMigrants(filterLoadashResult)
  }, [allParams])

  return (
    <Space direction="vertical" size={5}>
      <Title level={5}></Title>
      <Row>
        <Col>
          <Input
            style={{width: 300}}
            placeholder="Search by name"
            type="text"
            size="middle"
            addonAfter={
              <SearchOutlined onClick={() => setIsPopOverShown(false)} />
            }
            value={allParams.name}
            onChange={(e) => setAllParams({...allParams, name: e.target.value})}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={15}>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Nationality</Text>
            <Checkbox
              value="INDONESIA"
              onChange={(e) => {
                if (!allParams.nationality.includes('INDONESIA')) {
                  setAllParams({
                    ...allParams,
                    nationality: [...allParams.nationality, e.target.value],
                  })
                } else {
                  const _nationality = allParams.nationality.filter(
                    (item) => item !== 'INDONESIA',
                  )

                  setAllParams({
                    ...allParams,
                    nationality: _nationality,
                  })
                }
              }}
            >
              Indonesia
            </Checkbox>
            <Select
              defaultValue="default"
              style={{width: 200}}
              onChange={(e) => {
                setAllParams({
                  ...allParams,
                  region: e,
                })
              }}
            >
              <Option value="default" disabled>
                Select Region
              </Option>
              <Option value="">All Region</Option>
              <Option value="ACEH">Aceh</Option>
              <Option value="BALI">Bali</Option>
              <Option value="BANGKA_BELITUNG_ISLAND">
                Bangka Belitung Island
              </Option>
              <Option value="BANTEN">Banten</Option>
              <Option value="BENGKULU">Bengkulu</Option>
              <Option value="CENTRAL_JAVA">Central Java</Option>
              <Option value="CENTRAL_KALIMANTAN">Central Kalimantan</Option>
              <Option value="CENTRAL_SULAWESI">Central Sulawesi</Option>
              <Option value="EAST_JAVA">East Java</Option>
              <Option value="EAST_KALIMANTAN">East Kalimantan</Option>
              <Option value="EAST_NUSA_TENGGARA">East Nusa Tenggara</Option>
              <Option value="GORONTALO">Gorontalo</Option>
              <Option value="CAPITAL_SPECIAL_REGION_OF_JAKARTA">
                Capital Special Region of Jakarta
              </Option>
              <Option value="JAMBI">Jambi</Option>
              <Option value="LAMPUNG">Lampung</Option>
              <Option value="MALUKU">Maluku</Option>
              <Option value="NORTH_KALIMANTAN">North Kalimantan</Option>
              <Option value="NORTH_MALUKU">North Maluku</Option>
              <Option value="NORTH_SULAWESI">North Sulawesi</Option>
              <Option value="NORTH_SUMATRA">North Sumatera</Option>
              <Option value="PAPUA">Papua</Option>
              <Option value="RIAU">Riau</Option>
              <Option value="RIAU_ISLANDS">Riau Islands</Option>
              <Option value="SOUTHEAST_SULAWESI">Southeast Sulawesi</Option>
              <Option value="SOUTH_KALIMANTAN">Southeast Kalimantan</Option>
              <Option value="SOUTH_SULAWESI">South Sulawesi</Option>
              <Option value="SOUTH_SUMATERA">South Sumatera</Option>
              <Option value="WEST_JAVA">West Java</Option>
              <Option value="WEST_KALIMANTAN">West Kalimantan</Option>
              <Option value="WEST_NUSA_TENGGARA">West Nusa Tenggara</Option>
              <Option value="WEST_PAPUA">West Papua</Option>
              <Option value="WEST_SULAWESI">West Sulawesi</Option>
              <Option value="WEST_SUMATRA">West Sumatera</Option>
              <Option value="SPECIAL_REGION_OF_YOGYAKARTA">
                Special Region of Yogyakarta
              </Option>
            </Select>
            <Checkbox
              value="CAMBODIA"
              onChange={(e) => {
                if (!allParams.nationality.includes('CAMBODIA')) {
                  setAllParams({
                    ...allParams,
                    nationality: [...allParams.nationality, e.target.value],
                  })
                } else {
                  const _nationality = allParams.nationality.filter(
                    (item) => item !== 'CAMBODIA',
                  )

                  setAllParams({
                    ...allParams,
                    nationality: _nationality,
                  })
                }
              }}
            >
              Cambodia
            </Checkbox>
            <Checkbox
              value="MYANMAR"
              onChange={(e) => {
                if (!allParams.nationality.includes('MYANMAR')) {
                  setAllParams({
                    ...allParams,
                    nationality: [...allParams.nationality, e.target.value],
                  })
                } else {
                  const _nationality = allParams.nationality.filter(
                    (item) => item !== 'MYANMAR',
                  )

                  setAllParams({
                    ...allParams,
                    nationality: _nationality,
                  })
                }
              }}
            >
              Myanmar
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Religion</Text>
            <Checkbox
              value="FREE_THINKER"
              onChange={(e) => {
                if (!allParams.religion.includes('FREE_THINKER')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'FREE_THINKER',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Free Thinker
            </Checkbox>
            <Checkbox
              value="CATHOLIC"
              onChange={(e) => {
                if (!allParams.religion.includes('CATHOLIC')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'CATHOLIC',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Catholic
            </Checkbox>
            <Checkbox
              value="MUSLIM"
              onChange={(e) => {
                if (!allParams.religion.includes('MUSLIM')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'MUSLIM',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Muslim
            </Checkbox>
            <Checkbox
              value="SIKH"
              onChange={(e) => {
                if (!allParams.religion.includes('SIKH')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'SIKH',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Sikh
            </Checkbox>
            <Checkbox
              value="CHRISTIAN"
              onChange={(e) => {
                if (!allParams.religion.includes('CHRISTIAN')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'CHRISTIAN',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Christian
            </Checkbox>
            <Checkbox
              value="BUDDHIST"
              onChange={(e) => {
                if (!allParams.religion.includes('BUDDHIST')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'BUDDHIST',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Buddhist
            </Checkbox>
            <Checkbox
              value="HINDU"
              onChange={(e) => {
                if (!allParams.religion.includes('HINDU')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'HINDU',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Hindu
            </Checkbox>
            <Checkbox
              value="OTHERS"
              onChange={(e) => {
                if (!allParams.religion.includes('OTHERS')) {
                  setAllParams({
                    ...allParams,
                    religion: [...allParams.religion, e.target.value],
                  })
                } else {
                  const _religion = allParams.religion.filter(
                    (item) => item !== 'OTHERS',
                  )

                  setAllParams({
                    ...allParams,
                    religion: _religion,
                  })
                }
              }}
            >
              Others
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Marital Status</Text>
            <Checkbox
              value="SINGLE"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('SINGLE')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'SINGLE',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Single
            </Checkbox>
            <Checkbox
              value="WIDOWED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('WIDOWED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'WIDOWED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Widowed
            </Checkbox>
            <Checkbox
              value="MARRIED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('MARRIED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'MARRIED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Married
            </Checkbox>
            <Checkbox
              value="DIVORCED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('DIVORCED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'DIVORCED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Divorced
            </Checkbox>
            <Checkbox
              value="SEPARATED"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('SEPARATED')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'SEPARATED',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Separated
            </Checkbox>
            <Checkbox
              value="SINGLE_PARENT"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('SINGLE_PARENT')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'SINGLE_PARENT',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              Single Parent
            </Checkbox>
            {/*  <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.maritalStatus.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    maritalStatus: [...allParams.maritalStatus, e.target.value],
                  })
                } else {
                  const _maritalStatus = allParams.maritalStatus.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    maritalStatus: _maritalStatus,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Type of maid</Text>
            <Checkbox
              value="NEW"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('NEW')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'NEW',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              New
            </Checkbox>
            {/*  <Checkbox
              value="EX-OVERSEAS"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('EX-OVERSEAS')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'EX-OVERSEAS',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              Ex-Overseas
            </Checkbox> 
             <Checkbox value="" >Transfer</Checkbox> */}
            <Checkbox
              value="EXPERIENCED"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('EXPERIENCED')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'EXPERIENCED',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              Experienced
            </Checkbox>
            {/* <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.typeOfMaid.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    typeOfMaid: [...allParams.typeOfMaid, e.target.value],
                  })
                } else {
                  const _typeOfMaid = allParams.typeOfMaid.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    typeOfMaid: _typeOfMaid,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Language ability</Text>
            <Checkbox
              value="ENGLISH"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('ENGLISH')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'ENGLISH',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              English
            </Checkbox>
            <Checkbox
              value="BAHASA"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('INDONESIAN')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      'INDONESIAN',
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'INDONESIAN',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              Bahasa Indonesia
            </Checkbox>
            <Checkbox
              value="MANDARIN"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('MANDARIN')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'MANDARIN',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              Mandarin
            </Checkbox>
            {/*             <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.languageAbility.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    languageAbility: [
                      ...allParams.languageAbility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _languageAbility = allParams.languageAbility.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    languageAbility: _languageAbility,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>
              Off days preferences
            </Text>
            <Checkbox
              value="0"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('0')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '0',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              0
            </Checkbox>
            <Checkbox
              value="1"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('1')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '1',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              1
            </Checkbox>
            <Checkbox
              value="2"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('2')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '2',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              2
            </Checkbox>
            <Checkbox
              value="3"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('3')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '3',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              3
            </Checkbox>
            <Checkbox
              value="4"
              onChange={(e) => {
                if (!allParams.offDayPreferences.includes('4')) {
                  setAllParams({
                    ...allParams,
                    offDayPreferences: [
                      ...allParams.offDayPreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _offDayPreferences = allParams.offDayPreferences.filter(
                    (item) => item !== '4',
                  )

                  setAllParams({
                    ...allParams,
                    offDayPreferences: _offDayPreferences,
                  })
                }
              }}
            >
              4
            </Checkbox>
          </Space>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Responsibility</Text>
            <Checkbox
              value="CAREGIVER"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CAREGIVER')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CAREGIVER',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Caregiver
            </Checkbox>
            <Checkbox
              value="CARE_OF_ELDERLY"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CARE_OF_ELDERLY')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CARE_OF_ELDERLY',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Care of Elderly
            </Checkbox>
            <Checkbox
              value="GENERAL_HOUSE_WORK"
              onChange={(e) => {
                if (!allParams.responsibility.includes('GENERAL_HOUSE_WORK')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'GENERAL_HOUSE_WORK',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              General Housework
            </Checkbox>
            <Checkbox
              value="CARE_FOR_INFANT"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CARE_FOR_INFANT')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CARE_FOR_INFANT',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Care for Infant/Children
            </Checkbox>
            <Checkbox
              value="CARE_FOR_DISABLED"
              onChange={(e) => {
                if (!allParams.responsibility.includes('CARE_FOR_DISABLED')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'CARE_FOR_DISABLED',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Care for Disabled
            </Checkbox>
            <Checkbox
              value="COOKING"
              onChange={(e) => {
                if (!allParams.responsibility.includes('C00KING')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'COOKING',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              Cooking
            </Checkbox>
            {/* <Checkbox
              value="NO_PREFERENCE"
              onChange={(e) => {
                if (!allParams.responsibility.includes('NO_PREFERENCE')) {
                  setAllParams({
                    ...allParams,
                    responsibility: [
                      ...allParams.responsibility,
                      e.target.value,
                    ],
                  })
                } else {
                  const _responsibilities = allParams.responsibility.filter(
                    (item) => item !== 'NO_PREFERENCE',
                  )

                  setAllParams({
                    ...allParams,
                    responsibility: _responsibilities,
                  })
                }
              }}
            >
              No Preference
            </Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Age Preference</Text>
            <Checkbox
              value="23-25"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('23-25')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '23-25',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              23 to 25
            </Checkbox>
            <Checkbox
              value="26-30"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('26-30')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '26-30',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              26 to 30
            </Checkbox>
            <Checkbox
              value="31-35"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('31-35')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '31-35',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              31 - 35
            </Checkbox>
            <Checkbox
              value="36-40"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('36-40')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '36-40',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              36 - 40
            </Checkbox>
            <Checkbox
              value="41-45"
              onChange={(e) => {
                if (!allParams.agePreferences.includes('41-45')) {
                  setAllParams({
                    ...allParams,
                    agePreferences: [
                      ...allParams.agePreferences,
                      e.target.value,
                    ],
                  })
                } else {
                  const _agePreferences = allParams.agePreferences.filter(
                    (item) => item !== '41-45',
                  )

                  setAllParams({
                    ...allParams,
                    agePreferences: _agePreferences,
                  })
                }
              }}
            >
              41 - 45
            </Checkbox>

            {/* <Checkbox value="">No Preference</Checkbox> */}
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Education Level</Text>
            <Checkbox
              value="COLLEGE"
              onChange={(e) => {
                if (!allParams.educationLevel.includes('COLLEGE')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'COLLEGE',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }}
            >
              College/Degree(&gt;=13yrs)
            </Checkbox>
            <Checkbox
              value="SECONDARY_SCHOOL"
              onChange={(e) => {
                if (!allParams.educationLevel.includes('SECONDARY_SCHOOL')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'SECONDARY_SCHOOL',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }}
            >
              Secondary School(8-9yrs)
            </Checkbox>
            <Checkbox
              value="HIGH_SCHOOL"
              onChange={(e) => {
                if (!allParams.educationLevel.includes('HIGH_SCHOOL')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'HIGH_SCHOOL',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }}
            >
              High School(10-12yrs)
            </Checkbox>
            <Checkbox
              value="NOT_IMPORTANT"
              /* onChange={(e) => {
                if (!allParams.educationLevel.includes('NOT_IMPORTANT')) {
                  setAllParams({
                    ...allParams,
                    educationLevel: [
                      ...allParams.educationLevel,
                      e.target.value,
                    ],
                  })
                } else {
                  const _educationLevel = allParams.educationLevel.filter(
                    (item) => item !== 'NOT_IMPORTANT',
                  )

                  setAllParams({
                    ...allParams,
                    educationLevel: _educationLevel,
                  })
                }
              }} */
            >
              Not Important
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Experience</Text>
            <Checkbox
              value="HOME_COUNTRY"
              onChange={(e) => {
                if (!allParams.experience.includes('HOME_COUNTRY')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'HOME_COUNTRY',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Home Country
            </Checkbox>
            <Checkbox
              value="SINGAPORE"
              onChange={(e) => {
                if (!allParams.experience.includes('SINGAPORE')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'SINGAPORE',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Singapore
            </Checkbox>
            <Checkbox
              value="MALAYSIA"
              onChange={(e) => {
                if (!allParams.experience.includes('MALAYSIA')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'MALAYSIA',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Malaysia
            </Checkbox>
            <Checkbox
              value="TAIWAN"
              onChange={(e) => {
                if (!allParams.experience.includes('TAIWAN')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'TAIWAN',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Taiwan
            </Checkbox>
            <Checkbox
              value="HONGKONG"
              onChange={(e) => {
                if (!allParams.experience.includes('HONGKONG')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'HONGKONG',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Hongkong
            </Checkbox>
            <Checkbox
              value="MIDDLE_EAST"
              onChange={(e) => {
                if (!allParams.experience.includes('MIDDLE_EAST')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'MIDDLE_EAST',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Middle East
            </Checkbox>
            <Checkbox
              value="BRUNEI"
              onChange={(e) => {
                if (!allParams.experience.includes('BRUNEI')) {
                  setAllParams({
                    ...allParams,
                    experience: [...allParams.experience, e.target.value],
                  })
                } else {
                  const _experience = allParams.experience.filter(
                    (item) => item !== 'BRUNEI',
                  )

                  setAllParams({
                    ...allParams,
                    experience: _experience,
                  })
                }
              }}
            >
              Brunei
            </Checkbox>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Text style={{textDecoration: 'underline'}}>Price Range</Text>
            <Checkbox
              value="RANGE_1"
              onChange={(e) => {
                if (!allParams.salaryRanges.includes('RANGE_1')) {
                  setAllParams({
                    ...allParams,
                    salaryRanges: [...allParams.salaryRanges, e.target.value],
                  })
                } else {
                  const _salaryRanges = allParams.salaryRanges.filter(
                    (item) => item !== 'RANGE_1',
                  )

                  setAllParams({
                    ...allParams,
                    salaryRanges: _salaryRanges,
                  })
                }
              }}
            >
              400 - 500 SGD
            </Checkbox>
            <Checkbox
              value="RANGE_2"
              onChange={(e) => {
                if (!allParams.salaryRanges.includes('RANGE_2')) {
                  setAllParams({
                    ...allParams,
                    salaryRanges: [...allParams.salaryRanges, e.target.value],
                  })
                } else {
                  const _salaryRanges = allParams.salaryRanges.filter(
                    (item) => item !== 'RANGE_2',
                  )

                  setAllParams({
                    ...allParams,
                    salaryRanges: _salaryRanges,
                  })
                }
              }}
            >
              501 - 600 SGD
            </Checkbox>
            <Checkbox
              value="RANGE_3"
              onChange={(e) => {
                if (!allParams.salaryRanges.includes('RANGE_3')) {
                  setAllParams({
                    ...allParams,
                    salaryRanges: [...allParams.salaryRanges, e.target.value],
                  })
                } else {
                  const _salaryRanges = allParams.salaryRanges.filter(
                    (item) => item !== 'RANGE_3',
                  )

                  setAllParams({
                    ...allParams,
                    salaryRanges: _salaryRanges,
                  })
                }
              }}
            >
              601 - Above
            </Checkbox>
          </Space>
        </Col>
      </Row>
      <Row justify="center" align="middle" gutter={10}>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: 'gray',
              borderColor: 'transparent',
              width: 150,
              borderRadius: 9,
              color: '#FFF',
            }}
            size="large"
            // onClick={() => setAllParams(defaultAllParams)}
            onClick={() => window.location.reload()}
          >
            Clear All
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 150,
              borderRadius: 9,
              color: '#FFF',
            }}
            size="large"
            onClick={() => setIsPopOverShown(false)}
          >
            Search
          </Button>
        </Col>
      </Row>
    </Space>
  )
}
const pageSize = 48
const MDWBio = ({userBranchID}) => {
  const [migrants, setMigrants] = React.useState([])
  const [tempMigrants, setTempMigrants] = React.useState([])

  const [filterParams, setFilterParams] = React.useState({})
  const [isPopOverShown, setIsPopOverShown] = React.useState(false)
  const [nextToken, setNextToken] = React.useState('')
  const [pagination, setPagination] = React.useState({
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  })
  const handleChangePagination = (page) => {
    return setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    })
  }
  const getAllMigrantsBySupplierID = async (nextToken = '') => {
    try {
      const variables = {
        suppliedBy: userBranchID,
        filter: {
          deleted: {
            eq: 0,
          },
        },
        nextToken,
      }

      if (!nextToken) {
        delete variables.nextToken
      }

      const _listMigrants = await API.graphql({
        query: listMigrantBySupplierBranchByStatus,
        variables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      const _migrants = migrants.concat(
        _listMigrants.data.listMigrantBySupplierBranchByStatus.items,
      )

      setMigrants(_migrants)
      setTempMigrants(_migrants)
      setPagination({...pagination, maxIndex: pageSize})

      if (_listMigrants.data.listMigrantBySupplierBranchByStatus.nextToken) {
        setNextToken(
          _listMigrants.data.listMigrantBySupplierBranchByStatus.nextToken,
        )
      } else {
        setNextToken('')
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    if (!isEmpty(filterParams)) {
      const refetchListMigrants = async () => {
        try {
          const _listMigrants = await API.graphql({
            query: listMigrantBySupplierBranchByStatus,

            variables: {
              suppliedBy: userBranchID,
              status: {
                eq: filterParams.filter.status.eq,
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })

          setMigrants(
            _listMigrants.data.listMigrantBySupplierBranchByStatus.items,
          )
          setPagination({
            current: 1,
            minIndex: 0,
            maxIndex: pageSize,
          })
        } catch (error) {
          console.error(error)
          notification['error']({
            message: 'Error',
            description: JSON.stringify(error),
          })
        }
      }

      refetchListMigrants()
    }
  }, [filterParams])

  React.useEffect(() => {
    getAllMigrantsBySupplierID()
  }, [])

  React.useEffect(() => {
    if (nextToken) {
      getAllMigrantsBySupplierID(nextToken)
    }
  }, [nextToken])

  return (
    <Space
      direction="vertical"
      size={20}
      style={{backgroundColor: '#FFF', padding: '20px 10px 0 10px'}}
    >
      <Row gutter={8}>
        <Popover
          content={
            <SearchFilterContent
              {...{filterParams}}
              {...{setFilterParams}}
              {...{migrants}}
              {...{setMigrants}}
              {...{tempMigrants}}
              {...{setIsPopOverShown}}
            />
          }
          title={
            <Row justify="space-between">
              <Col>Search MDW</Col>
              <Col>
                <Typography.Link
                  style={{color: 'red'}}
                  onClick={() => setIsPopOverShown(false)}
                >
                  Close
                </Typography.Link>
              </Col>
            </Row>
          }
          trigger="click"
          placement="topRight"
          visible={isPopOverShown}
        >
          <Col span="8">
            <Input
              addonAfter={
                <FilterOutlined onClick={() => setIsPopOverShown(true)} />
              }
              placeholder="Search &amp; Filter"
              readOnly
              onClick={() => setIsPopOverShown(true)}
            />
          </Col>
        </Popover>
        <Col span="8">
          {/*           <Input
            addonBefore={<SearchOutlined />}
            addonAfter={<CaretDownFilled />}
            placeholder="Status"
          /> */}
          <Select
            defaultValue="selectStatus"
            style={{width: 300}}
            value={filterParams?.status?.eq}
            onChange={(e) => {
              if (e) {
                setMigrants([])
                setTempMigrants([])
                setFilterParams({
                  filter: {
                    suppliedBy: {
                      eq: userBranchID,
                    },
                    status: {
                      eq: e,
                    },
                  },
                })
              } else {
                // getAllMigrantsBySupplierID()
                window.location.reload()
              }
            }}
          >
            <Option value="selectStatus" disabled>
              Select Status
            </Option>
            <Option value="">All Status</Option>
            <Option value="PENDING">Pending</Option>
            <Option value="SUBMITTED">Submitted</Option>
            <Option value="AVAILABLE">Available</Option>
            <Option value="INACTIVE">Inactive</Option>
            <Option value="CONFIRMED">Confirmed</Option>
            <Option value="EXTENDED">Extended</Option>
            <Option value="RESERVED">Reserved</Option>
            <Option value="OUTPRO">Out Processed</Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={2} justify="end">
        <Col>
          <Pagination
            pageSize={pageSize}
            current={pagination.current}
            total={migrants.length}
            onChange={handleChangePagination}
            showSizeChanger={false}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        {migrants.map(
          (migrant, index) =>
            index >= pagination.minIndex &&
            index < pagination.maxIndex && (
              <Col
                span={6}
                key={migrant.id}
                style={{marginTop: 10, marginBottom: 10}}
              >
                <Migrant
                  {...migrant}
                  {...{migrant}}
                  {...{userBranchID}}
                  {...{getAllMigrantsBySupplierID}}
                />
              </Col>
            ),
        )}
      </Row>
    </Space>
  )
}

const PerformanceSales = ({
  userCompanyID,
  userBranchID,
  userBranchIsMainBranch,
}) => {
  const [expandable, setExpandable] = React.useState({
    outPro: false,
    confirmed: false,
    reserved: false,
  })
  const [statisticsSummaryNumber, setStatisticsSummaryNumber] = React.useState({
    outProcessed: [],
    confirmed: [],
    reserved: [],
  })

  const [statisticsSummaryItems, setStatisticsSummaryItems] = React.useState('')

  React.useEffect(() => {
    const init = async () => {
      try {
        if (!userBranchIsMainBranch) {
          const statistics = await API.graphql({
            query: listStatisticsByBranchID,
            variables: {
              branchID: userBranchID,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })

          let totalOutpro = 0
          let totalReserved = 0
          let totalConfirmed = 0

          let summaryItems = []

          statistics.data.listStatisticsByBranchID.items.filter((item) => {
            totalOutpro += item.outProcessed.length
            totalReserved += item.reserved.length
            totalConfirmed += item.confirmed.length

            summaryItems = [...summaryItems, item]
          })

          setStatisticsSummaryNumber({
            ...statisticsSummaryNumber,
            outProcessed: totalOutpro,
            confirmed: totalConfirmed,
            reserved: totalReserved,
          })

          setStatisticsSummaryItems(summaryItems)
        } else if (userBranchIsMainBranch) {
          const statistics = await API.graphql({
            query: listStatisticsByCompanyID,
            variables: {
              companyID: userCompanyID,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })

          let totalOutpro = 0
          let totalReserved = 0
          let totalConfirmed = 0

          let summaryItems = []

          statistics.data.listStatisticsByCompanyID.items.filter((item) => {
            totalOutpro += item.outProcessed.length
            totalReserved += item.reserved.length
            totalConfirmed += item.confirmed.length

            summaryItems = [...summaryItems, item]
          })

          setStatisticsSummaryNumber({
            ...statisticsSummaryNumber,
            outProcessed: totalOutpro,
            confirmed: totalConfirmed,
            reserved: totalReserved,
          })

          setStatisticsSummaryItems(summaryItems)
        }
      } catch (error) {
        notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }

    init()
  }, [])

  return (
    <Space
      direction="vertical"
      size={70}
      style={{backgroundColor: '#FFF', padding: '20px 10px 0 10px'}}
    >
      <Row gutter={5}>
        {/*  <Col span="8">

          <Select defaultValue="selectCriteria" style={{width: 300}}>
            <Option value="selectCriteria" disabled>
              Select Criteria
            </Option>
            <Option value="ea">Employment Agency</Option>
            <Option value="supploer">Supplier</Option>
          </Select>
        </Col> */}
        <Col span="8">
          <Select defaultValue="sg" style={{width: 300}}>
            <Option value="selectCountry" disabled>
              MDW Deployment Country
            </Option>
            <Option value="sg">Singapore</Option>
            {/*  <Option value="CAMBODIA">Cambodia</Option>
            <Option value="INDONESIA">Indonesia</Option>
            <Option value="MYANMAR">Myanmar</Option> */}
          </Select>
        </Col>
        <Col span="8" style={{marginLeft: 66}}>
          <Button
            type="primary"
            style={{
              backgroundColor: '#10DF1D',
              borderColor: 'transparent',
              width: 160,
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span="8">
          <Card
            style={{
              width: 240,
              borderRadius: 15,
              overflow: 'hidden',
              backgroundColor: '#DCE0FF',
            }}
            bordered={true}
            cover={
              <Space
                direction="vertical"
                align="center"
                style={{paddingTop: 25}}
              >
                <Space direction="horizontal" align="end">
                  <SearchOutlined style={{color: '#8893F9', fontSize: 48}} />

                  <Space direction="vertical" align="start">
                    <Text style={{color: '#8893F9', fontSize: 13}}>
                      OUT PROCESSED
                    </Text>
                    <Text style={{color: '#8893F9', fontSize: 25}} strong>
                      {statisticsSummaryNumber.outProcessed}
                    </Text>
                  </Space>
                </Space>
              </Space>
            }
            actions={
              statisticsSummaryNumber.outProcessed > 0
                ? [
                    <Popover
                      content={
                        <PerformanceSalesPopOver
                          {...{statisticsSummaryItems}}
                          type="OutPro"
                        />
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Row
                        align="middle"
                        justify="center"
                        onClick={() =>
                          setExpandable({
                            ...expandable,
                            outPro: !expandable.outPro,
                          })
                        }
                      >
                        <Col>
                          <Text style={{color: '#8893F9'}}>
                            More Details&nbsp;
                          </Text>
                          {!expandable.outPro ? (
                            <RightCircleTwoTone twoToneColor="#8893F9" />
                          ) : (
                            <DownCircleTwoTone twoToneColor="#8893F9" />
                          )}
                        </Col>
                      </Row>
                    </Popover>,
                  ]
                : []
            }
          ></Card>
        </Col>
        <Col span="8">
          <Card
            style={{
              width: 240,
              borderRadius: 15,
              overflow: 'hidden',
              backgroundColor: '#DCE0FF',
            }}
            bordered={true}
            cover={
              <Space
                direction="vertical"
                align="center"
                style={{paddingTop: 25}}
              >
                <Space direction="horizontal" align="end">
                  <IdcardOutlined style={{color: '#8893F9', fontSize: 48}} />

                  <Space direction="vertical" align="start">
                    <Text style={{color: '#8893F9', fontSize: 13}}>
                      TOTAL CONFIRMED
                    </Text>
                    <Text style={{color: '#8893F9', fontSize: 25}} strong>
                      {statisticsSummaryNumber.confirmed}
                    </Text>
                  </Space>
                </Space>
              </Space>
            }
            actions={
              statisticsSummaryNumber.confirmed > 0
                ? [
                    <Popover
                      content={
                        <PerformanceSalesPopOver
                          {...{statisticsSummaryItems}}
                          type="Confirmed"
                        />
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Row
                        align="middle"
                        justify="center"
                        onClick={() =>
                          setExpandable({
                            ...expandable,
                            confirmed: !expandable.confirmed,
                          })
                        }
                      >
                        <Col>
                          <Text style={{color: '#8893F9'}}>
                            More Details&nbsp;
                          </Text>
                          {!expandable.confirmed ? (
                            <RightCircleTwoTone twoToneColor="#8893F9" />
                          ) : (
                            <DownCircleTwoTone twoToneColor="#8893F9" />
                          )}
                        </Col>
                      </Row>
                    </Popover>,
                  ]
                : []
            }
          ></Card>
        </Col>
        <Col span="8">
          <Card
            style={{
              width: 240,
              borderRadius: 15,
              overflow: 'hidden',
              backgroundColor: '#DCE0FF',
            }}
            bordered={true}
            cover={
              <Space
                direction="vertical"
                align="center"
                style={{paddingTop: 25}}
              >
                <Space direction="horizontal" align="end">
                  <CalendarOutlined style={{color: '#8893F9', fontSize: 48}} />

                  <Space direction="vertical" align="start">
                    <Text style={{color: '#8893F9', fontSize: 13}}>
                      TOTAL RESERVED
                    </Text>
                    <Text style={{color: '#8893F9', fontSize: 25}} strong>
                      {statisticsSummaryNumber.reserved}
                    </Text>
                  </Space>
                </Space>
              </Space>
            }
            actions={
              statisticsSummaryNumber.reserved > 0
                ? [
                    <Popover
                      content={
                        <PerformanceSalesPopOver
                          {...{statisticsSummaryItems}}
                          type="Reserved"
                        />
                      }
                      trigger="click"
                      placement="bottomRight"
                    >
                      <Row
                        align="middle"
                        justify="center"
                        onClick={() =>
                          setExpandable({
                            ...expandable,
                            reserved: !expandable.reserved,
                          })
                        }
                      >
                        <Col>
                          <Text style={{color: '#8893F9'}}>
                            More Details&nbsp;
                          </Text>
                          {!expandable.reserved ? (
                            <RightCircleTwoTone twoToneColor="#8893F9" />
                          ) : (
                            <DownCircleTwoTone twoToneColor="#8893F9" />
                          )}
                        </Col>
                      </Row>
                    </Popover>,
                  ]
                : []
            }
          ></Card>
        </Col>
      </Row>
    </Space>
  )
}

function Dashboard() {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

  const [userID, setUserID] = React.useState('')
  const [userBranchID, setUserBranchID] = React.useState('')
  const [userBranchIsMainBranch, setUserBranchIsMainBranch] =
    React.useState(false)
  const [userCompanyID, setUserCompanyID] = React.useState('')

  React.useEffect(() => {
    const init = async () => {
      try {
        const _listUsers = await API.graphql({
          query: listUsers,
          variables: {
            filter: {
              email: {
                eq: getSignedInUserDetails().UserAttributes.find(
                  (userAttribute) => userAttribute.Name === 'email',
                ).Value,
              },
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })
        setUserID(_listUsers.data.listUsers.items[0].id)
        setUserBranchID(_listUsers.data.listUsers.items[0].branchID)
        setUserBranchIsMainBranch(
          _listUsers.data.listUsers.items[0].branch.isMainBranch,
        )
        setUserCompanyID(_listUsers.data.listUsers.items[0].branch.company.id)
      } catch (error) {
        notification['error']({
          message: 'Error',
          description: JSON.stringify(error),
        })
      }
    }

    init()
  }, [])
  const selectedTabStyle = {
    backgroundColor: '#8893F9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingTop: 10,
  }
  const unSelectTabStyle = {
    borderBottom: '1px solid #8893F9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingTop: 10,
  }

  if (!userBranchID) {
    return true
  }

  return (
    <>
      <Content
        style={{
          backgroundColor: '#FFF',
          border: '1px solid #8893F9',
          borderRadius: 5,

          marginLeft: '5%',
          marginTop: 10,
        }}
      >
        <Space direction="vertical" style={{width: '100%'}}>
          <Row>
            <Col
              style={
                selectedTabIndex === 0 ? selectedTabStyle : unSelectTabStyle
              }
              span={12}
              onClick={() => setSelectedTabIndex(0)}
            >
              <Title
                level={3}
                style={{
                  color: selectedTabIndex !== 0 ? '#8893F9' : '#FFF',
                }}
              >
                MDW Bio
              </Title>
            </Col>
            <Col
              style={
                selectedTabIndex === 1 ? selectedTabStyle : unSelectTabStyle
              }
              span={12}
              onClick={() => setSelectedTabIndex(1)}
            >
              <Title
                level={3}
                style={{
                  color: selectedTabIndex !== 1 ? '#8893F9' : '#FFF',
                }}
              >
                Performance Sales
              </Title>
            </Col>
          </Row>
          <Layout>
            {selectedTabIndex === 0 ? (
              <MDWBio {...{userBranchID}} />
            ) : (
              <PerformanceSales
                {...{userCompanyID}}
                {...{userBranchID}}
                {...{userBranchIsMainBranch}}
              />
            )}
          </Layout>
        </Space>
      </Content>
      <CurrentMDWStatusSider {...{userBranchID}} {...{userID}} />
    </>
  )
}

export default Dashboard
