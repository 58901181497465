/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import {Auth} from '@aws-amplify/auth'

const awsmobile = {
  aws_project_region: 'ap-southeast-1',
  aws_cognito_identity_pool_id:
    'ap-southeast-1:ee1f20b4-2646-419e-9090-eddfcfc781e1',
  aws_cognito_region: 'ap-southeast-1',
  aws_user_pools_id: 'ap-southeast-1_xWapQqZAy',
  aws_user_pools_web_client_id: '1jpqrh50pmlor8su15i7mmtjlp',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'bdms99afa01b1432447daa85f64271dc1c6062709-prod',
  aws_user_files_s3_bucket_region: 'ap-southeast-1',
  aws_appsync_graphqlEndpoint:
    'https://c2nwcymymnes7nglafnscwifie.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-3llqpbdygbgdtgjjxm7jxkieda',
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken(),
      }
    },
  },
}

export default awsmobile
