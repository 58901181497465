import React from 'react'
import {Result, Button} from 'antd'
import {NavLink} from 'react-router-dom'

const Page404 = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary">
          <NavLink push to="/">
            Back Home
          </NavLink>
        </Button>
      }
    />
  )
}

export default Page404
