import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {PrivateRoute} from './PrivateRoute'

import {NotFound} from '../pages'
import {
  Login,
  SetPassword,
  ForgotPassword,
  ResetPassword,
} from '../pages/authentication'
import {
  RootContainer as AdminContainer,
  BioForm as AdminBioForm,
  SupplierDetail,
  EmploymentAgencyDetail,
  Profile as AdminProfile,
} from '../pages/admin'
import {
  RootContainer as SupplierContainer,
  BioForm,
  Profile,
} from '../pages/supplier'
import {
  RootContainer as EAContainer,
  BioForm as EABioForm,
  BioPrint as EABioPrint,
  Profile as EAProfile,
} from '../pages/ea'

const RoutePath = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/set-password" component={SetPassword} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />

      <PrivateRoute path="/admin/dashboard" component={AdminContainer} />
      <PrivateRoute path="/supplier/dashboard" component={SupplierContainer} />
      <PrivateRoute path="/ea/dashboard" component={EAContainer} />
      <PrivateRoute path="/supplier/add-new-bio" component={BioForm} />
      <PrivateRoute path="/bio/:id" component={BioForm} />
      <PrivateRoute path="/admin/bio/:id" component={AdminBioForm} />
      <PrivateRoute path="/ea/bio/:id" component={EABioForm} />
      {/* <PrivateRoute path="/ea/bio/print/:id" component={EABioPrint} /> 
      <PrivateRoute path="/ea/bio/print" component={EABioPrint} />
      */}
      <PrivateRoute path="/print/bio/:id" component={EABioPrint} />
      <PrivateRoute path="/admin/supplier/:id" component={SupplierDetail} />
      <PrivateRoute path="/admin/ea/:id" component={EmploymentAgencyDetail} />

      <PrivateRoute path="/supplier/profile" component={Profile} />
      <PrivateRoute path="/ea/profile" component={EAProfile} />
      <PrivateRoute path="/admin/profile" component={AdminProfile} />

      <Route path="/not-found" component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default RoutePath
