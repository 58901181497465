import React from 'react'
import {
  Dropdown,
  Button,
  Menu,
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Carousel,
} from 'antd'
const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card

function Bio() {
  return (
    <Content style={{backgroundColor: '#FFF', padding: 10}}>
      <Layout
        style={{
          border: '1px solid #8893F9',
          borderRadius: 5,
          padding: 15,
          marginLeft: '6%',
          backgroundColor: '#FFF',
        }}
      >
        <Space direction="vertical" size={50}>
          <Space direction="vertical">
            <Title level={3}>BIO-DATA OF MIGRANT DOMESTIC WORKER (MDW)</Title>

            <Text>
              *Please ensure that you run through the information within the
              biodata as it is an important document to help you select a
              suitable MDW
            </Text>
          </Space>
          <Title style={{color: '#8893F9'}} level={5}>
            A.1. Personal Information
          </Title>
          <Space
            direction="horizontal"
            style={{
              justifyContent: 'space-between',

              width: '100%',
            }}
          >
            <Space direction="vertical">
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>1. Name</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>2. Date of Birth</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Name"
                    type="date"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>3. Place of birth</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Place of Birth"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>4. Height & Weight</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Height"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>5. Nationality</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Nationality"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>
                    6. Residential address in home country
                  </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Place of Birth"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>7. Region</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Region"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>8. National ID</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="National ID"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>9. Passport Number</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Passport Number"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>10. Salary Range</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Salary Range"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>
                    11. Name of port/ airport to be repatriated to
                  </Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Name of port"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>12. Contact number in home country</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact number in home country"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>12 a. Next of Kin</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Next of Kin"
                    size="large"
                    className="buttonBorderRadius"
                    type="text"
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>12 b. Contact Number (Next of Kin)</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Contact number in home country"
                    size="large"
                    className="buttonBorderRadius"
                    type="text"
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>13. Religion</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Religion"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>14. Education Level</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Religion"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>15. Number of Siblings</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Number of Siblings"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>16. Marital Status</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Married"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>17. Number of Children</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Married"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10} style={{visibility: 'hidden'}}>
                  <Typography>17. Number of Children</Typography>
                </Col>
                <Col span={14}>
                  <Typography>Age(s) of children (if any)</Typography>
                  <Input
                    style={{width: 300}}
                    placeholder="Married"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical">
              <img
                alt="example"
                src={require('../assets/image/migrant1full.png')}
                style={{width: 293, height: 380}}
              />
              <Text style={{color: 'red'}}>Max Photo 5mb, file JPEG & PNG</Text>
              {/* <Carousel>
                <div>
                  <h3>1</h3>
                </div>
                <div>
                  <h3>2</h3>
                </div>
                <div>
                  <h3>3</h3>
                </div>
                <div>
                  <h3>4</h3>
                </div>
              </Carousel> */}
            </Space>
          </Space>
          <Text strong>Price Code</Text>
          <Space
            direction="horizontal"
            style={{justifyContent: 'space-between', width: '90%'}}
          >
            <Space direction="vertical">
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>Country Code</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Country Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>Supplier Price Code</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Supplier Price Code"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>MDW Deployment Country</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="MDW Deployment Country"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical">
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>Supplier Name</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Supplier Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>

              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <Typography>EC / No EC</Typography>
                </Col>
                <Col span={14}>
                  <Input
                    style={{width: 300}}
                    placeholder="Supplier Name"
                    type="text"
                    size="large"
                    className="buttonBorderRadius"
                    readOnly
                  />
                </Col>
              </Row>
            </Space>
          </Space>
          <Space
            align="center"
            direction="vertical"
            style={{width: '100%', marginTop: 40}}
            size={10}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#8893F9',
                borderColor: 'transparent',
                width: 150,
              }}
              onClick={() => true}
            >
              Continue
            </Button>
            <Text>1 of 4 form</Text>
          </Space>
        </Space>
      </Layout>
    </Content>
  )
}

export default Bio
