import React from 'react'
import {Typography} from 'antd'

const {Text} = Typography
const ErrorText = ({children}) => (
  <Text style={{textAlign: 'left', width: '100%'}} type="danger">
    {children}
  </Text>
)

export default ErrorText
