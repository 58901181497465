import React from 'react'
import {
  Row,
  Col,
  Typography,
  Layout,
  Tabs,
  Card,
  Input,
  Space,
  Select,
  Table,
  Button,
  Menu,
  Avatar,
  PageHeader,
  Dropdown,
  notification,
} from 'antd'
import {
  DashboardOutlined,
  UserSwitchOutlined,
  FormOutlined,
  UserAddOutlined,
  UserOutlined,
  CaretDownFilled,
} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'
import {Branch, Staff} from '../accMgt/SupplierEAAccMgt'
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {
  listBranches,
  getBranch,
  listUsers,
  getUser,
} from '../../../backend/graphql/queries'
import {
  updateUser,
  updateBranch,
  updateCompany,
} from '../../../backend/graphql/mutations'

const {Header, Footer, Sider, Content} = Layout

const {Text, Title} = Typography
const {TabPane} = Tabs
const {Option} = Select
const {Meta} = Card
const columns = [
  {
    title: 'No.',
    dataIndex: 'key',
    defaultSortOrder: 'descend',
    sorter: (a, b) => b.key - a.key,
  },
  {
    title: 'Supplier Name',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
  {
    title: 'Supplier Code',
    render: () => <Input placeholder="Supplier Code" />,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: (a, b) => a.address - b.address,
  },
  {
    title: 'Actions',

    render: () => (
      <Row justify="space-between">
        <Col>
          <Button
            type="primary"
            style={{
              backgroundColor: '#8893F9',
              borderColor: 'transparent',
              width: 150,
            }}
            onClick={() => true}
          >
            Approve
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              backgroundColor: '#707070',
              borderColor: 'transparent',
              width: 150,
            }}
            onClick={() => true}
          >
            Reject
          </Button>
        </Col>
      </Row>
    ),
  },
]
const columns2 = [
  {
    title: 'No.',
    dataIndex: 'key',
    defaultSortOrder: 'descend',
    sorter: (a, b) => b.key - a.key,
  },
  {
    title: 'Supplier Name',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },
  {
    title: 'Supplier Code',
    dataIndex: 'code',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.code - b.code,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: (a, b) => a.address - b.address,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status - b.status,
  },
  {
    title: 'Details',

    render: () => (
      <Button
        type="primary"
        htmlType="submit"
        style={{
          backgroundColor: '#8893F9',
          borderColor: 'transparent',
          width: 150,
        }}
        onClick={() => true}
      >
        Details
      </Button>
    ),
  },
]

const data = [
  {
    key: '1',
    name: 'Supplier A',
    address: 'Jln Pelabuhan Ratu 2 No 18',
  },
  {
    key: '2',
    name: 'Supplier B',
    address: 'Jln Pelabuhan Ratu 2 No 18',
  },
]

const data2 = [
  {
    key: '1',
    name: 'Mayo Ethereum',
    code: 32,
    address: 'Jln Pelabuhan Ratu 2 No 18',
    status: 'Active',
  },
  {
    key: '2',
    name: 'Raca Ana',
    code: 42,
    address: 'Jln Pelabuhan Ratu 2 No 18',
    status: 'Rejected',
  },
  {
    key: '3',
    name: 'Cardana Ono',
    code: 32,
    address: 'Jln Pelabuhan Ratu 2 No 18',
    status: 'Active',
  },
]

const MainMenuSider = ({setActiveSider}) => {
  const history = useHistory()
  return (
    <Sider style={{backgroundColor: '#FFF'}}>
      <Menu
        style={{width: 256, height: '100%'}}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
      >
        <Menu.Item
          icon={<DashboardOutlined />}
          onClick={() => {
            // setActiveSider(0)
            history.push('/admin/dashboard')
          }}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          icon={<UserSwitchOutlined />}
          // onClick={() => setActiveSider(1)}
          onClick={() => history.push('/admin/dashboard')}
        >
          Supplier
        </Menu.Item>
        <Menu.Item
          icon={<FormOutlined />}
          // onClick={() => setActiveSider(2)}
          onClick={() => history.push('/admin/dashboard')}
        >
          Employment Agencies
        </Menu.Item>
        <Menu.Item
          icon={<UserAddOutlined />}
          onClick={() => {
            // setActiveSider(3)
            history.push('/admin/dashboard')
          }}
        >
          OUR Account Mgt
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

const Wrapper = ({children, setActiveSider}) => {
  return (
    <div style={{backgroundColor: '#FAFAFA'}}>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          borderBottom: '1px solid #ecf0f1',
        }}
        className="site-page-header"
        onBack={() => null}
        backIcon={false}
        title=" "
        subTitle=" "
        avatar={{
          src: 'https://smqportalbucket173505-dev.s3.ap-southeast-1.amazonaws.com/public/download/our.png',
          size: 65,
        }}
        extra={[<DropdownMenu />]}
      />
      <Layout style={{backgroundColor: '#FFF'}}>
        <MainMenuSider {...{setActiveSider}} />

        {children}
      </Layout>
    </div>
  )
}

const DropdownMenu = () => {
  const history = useHistory()

  const MenuToolbar = () => {
    return (
      <Menu>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => history.push('/supplier/profile')}
          >
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          {/* <a rel="noopener noreferrer" onClick={() => history.push('/')}> */}
          <a
            rel="noopener noreferrer"
            onClick={async () => {
              try {
                await Auth.signOut()

                history.push('/')
              } catch (error) {
                notification['error']({
                  message: 'Error',
                  description: error.name,
                })
              }
            }}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Dropdown key="more" overlay={MenuToolbar}>
      <Button
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        <Row align="middle" justify="center" style={{marginTop: 15}}>
          <Col>
            <Avatar size={32} icon={<UserOutlined />} />
          </Col>

          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Text strong>John Doe</Text>

            <Text type="secondary">Multiway Employement Pte Ltd</Text>
          </Col>

          <Col>
            <CaretDownFilled />
          </Col>
        </Row>
      </Button>
    </Dropdown>
  )
}

const FormDetail = ({id}) => {
  const [selectedBranch, setSelectedBranch] = React.useState({
    branchName: '',
    adminName: '',
    adminEmail: '',
    address: '',
    remarks: '',
    regNo: '',
    mobileNo: '',
    telephoneNo: '',
    status: '',
    userId: '',
    companyId: '',
  })

  const init = async () => {
    try {
      const __getBranch = await API.graphql({
        query: getBranch,
        variables: {
          id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      // setSelectedBranch(__getBranch.data.getBranch)
      setSelectedBranch({
        branchName: __getBranch.data.getBranch.company.companyName,
        adminName: __getBranch.data.getBranch.company.user.fullName,
        adminEmail: __getBranch.data.getBranch.company.user.email,
        address: __getBranch.data.getBranch.address,
        remarks: __getBranch.data.getBranch.remarks,
        regNo: __getBranch.data.getBranch.company.user.registrationNo,
        mobileNo: '',
        telephoneNo: __getBranch.data.getBranch.company.user.phoneNo,
        mobileNo: __getBranch.data.getBranch.company.user.mobileNo,
        status: __getBranch.data.getBranch.status,
        userId: __getBranch.data.getBranch.company.userID,
        companyId: __getBranch.data.getBranch.companyID,
      })
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  const _editSupplier = async () => {
    try {
      await API.graphql({
        query: updateCompany,
        variables: {
          input: {
            id: selectedBranch.companyId,
            companyName: selectedBranch.branchName,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: updateBranch,
        variables: {
          input: {
            id: id,
            address: selectedBranch.address,
            remarks: selectedBranch.remarks,
            status: selectedBranch.status,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: selectedBranch.userId,
            fullName: selectedBranch.adminName,
            registrationNo: selectedBranch.regNo,
            phoneNo: selectedBranch.telephoneNo,
            mobileNo: selectedBranch.mobileNo,
            status:
              selectedBranch.status === 'ACTIVE'
                ? 'APPROVED'
                : selectedBranch.status === 'PENDING'
                ? 'PENDING'
                : 'REJECTED',
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      notification['success']({
        message: 'Success',
        description: 'Data Updated!',
      })
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <>
      <Space
        direction="horizontal"
        style={{
          justifyContent: 'space-around',

          // width: '90%',
          alignItems: 'start',
          marginBottom: 20,
        }}
      >
        <Space direction="vertical">
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Company Name</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Company Name"
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.branchName}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    branchName: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Owner's Name</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Owner's Name"
                type="text"
                size="large"
                className="buttonBorderRadius"
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    adminName: e.target.value,
                  })
                }
              />
            </Col>
          </Row>

          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>EA Email</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="EA Email"
                type="email"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.adminEmail}
                readOnly
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Address</Typography>
            </Col>
            <Col span={14}>
              <Input.TextArea
                style={{width: 380}}
                placeholder="Address"
                type="text"
                size="large"
                className="buttonBorderRadius"
                multiple={true}
                maxLength={100}
                value={selectedBranch.address}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    address: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Remarks</Typography>
            </Col>
            <Col span={14}>
              <Input.TextArea
                style={{width: 250}}
                placeholder="Remarks"
                type="text"
                size="large"
                className="buttonBorderRadius"
                multiple={true}
                maxLength={100}
                value={selectedBranch.remarks}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    remarks: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </Space>
        <Space direction="vertical" size={10}>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>EA Reg No</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="EA Reg. No."
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.regNo}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    regNo: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Mobile No.</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Mobile No."
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.mobileNo}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    mobileNo: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Typography>Telephone No.</Typography>
            </Col>
            <Col span={14}>
              <Input
                style={{width: 250}}
                placeholder="Telephone No."
                type="text"
                size="large"
                className="buttonBorderRadius"
                value={selectedBranch.telephoneNo}
                onChange={(e) =>
                  setSelectedBranch({
                    ...selectedBranch,
                    telephoneNo: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          {selectedBranch.status && (
            <Row justify="space-between" align="middle">
              <Col span={10}>
                <Typography>Status</Typography>
              </Col>
              <Col span={14}>
                <Select
                  defaultValue={selectedBranch.status}
                  style={{width: 250}}
                  onChange={(value) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      status: value,
                    })
                  }
                >
                  <Option value="default" disabled>
                    Select Status
                  </Option>
                  <Option value="ACTIVE">Active</Option>
                  <Option value="PENDING">Pending</Option>
                  <Option value="REJECTED">Rejected</Option>
                </Select>
              </Col>
            </Row>
          )}
        </Space>
      </Space>

      <Row align="middle" justify="center">
        <Col>
          <Button
            style={{
              borderColor: 'transparent',
              width: 150,
              backgroundColor: '#34C217',
              color: '#FFF',
              borderRadius: 9,
              marginBottom: 20,
            }}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </>
  )
}

function EmploymentAgencyDetail(props) {
  const [branches, setBranches] = React.useState([])
  const [users, setUsers] = React.useState([])

  const init = async () => {
    try {
      const _listBranches = await API.graphql({
        query: listBranches,
        variables: {
          id: props.match.params.id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setBranches(_listBranches.data.listBranches.items)
      console.log('SUPP_BRANCH', _listBranches.data.listBranches.items)
      const _listUsers = await API.graphql({
        query: listUsers,
        variables: {
          filter: {
            branchID: {
              eq: props.match.params.id,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })

      setUsers(_listUsers.data.listUsers.items)
      console.log('SUPP_STAFF', _listUsers.data.listUsers.items)
    } catch (error) {
      return notification['error']({
        message: 'Error',
        description: JSON.stringify(error),
      })
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <Wrapper>
      <Content style={{backgroundColor: '#FFF', padding: 10}}>
        <Layout
          style={{
            border: '1px solid #8893F9',
            borderRadius: 5,
            paddingTop: 33,
            marginLeft: '6%',
            backgroundColor: '#FFF',
          }}
        >
          <FormDetail id={props.match.params.id} />
          <Branch {...{branches}} />
          <Staff {...{users}} />
        </Layout>
      </Content>
    </Wrapper>
  )
}

export default EmploymentAgencyDetail
